import React, { Component } from 'react';
import Builder from '../../..';
import Main from './main';
export default class index extends Component {
  componentDidMount() {
    const { auth, resource } = this.props;
    if (typeof auth === 'undefined') {
      return null;
    }

    const { preprocessing } = auth;
    const preprocessFunction = Builder.build(preprocessing, resource);

    if (typeof preprocessFunction === 'function') {
      preprocessFunction();
    }
  }

  renderMain = () => {
    const { auth, resource } = this.props;
    if (typeof auth === 'undefined') {
      return <>This is main block</>;
    }

    const { main, mainStyle, sidebar } = auth;
    if (typeof main === 'undefined') {
      return <>This is main block</>;
    }

    return <Main main={main} mainStyle={mainStyle} resource={resource} sidebar={sidebar} />;
  };

  render() {
    return this.renderMain();
  }
}
