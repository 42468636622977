import Builder from '..';

export const build = (MappingConfig, resource) => {
  const data = Builder.build(MappingConfig.data, resource);
  if (Array.isArray(data)) {
    if (MappingConfig.newItem) {
      return data.map((element, index) => {
        return Builder.traverseBuild(MappingConfig.newItem, {
          ...resource,
          variableEnvironment: { ...resource.variableEnvironment, e: element, element, index, data },
        }); 
      });
    }

    if (MappingConfig.newElement) {
      return data.map((element, index) => {
        return Builder.traverseBuild(MappingConfig.newElement, {
          ...resource,
          variableEnvironment: { ...resource.variableEnvironment, e: element, element, index, data },
        });
      });
    }

    if (MappingConfig.newE) {
      return data.map((element, index) => {
        return Builder.traverseBuild(MappingConfig.newE, {
          ...resource,
          variableEnvironment: { ...resource.variableEnvironment, element, index, data },
        });
      });
    }

    return data.map((element, index) => {
      const mapFunction = Builder.traverseBuild(MappingConfig.mapFunction, resource);
      if (typeof mapFunction === 'function') {
        return mapFunction(element, index, data);
      } else {
        return element;
      }
    });
  } else {
    return [];
  }
};

export const type = 'MappingExpression';
