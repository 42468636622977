import Carousel from './Carousel';
import UIBuilder from '../BaseComponent';
import { UI_COMPONENTS_TYPE_FIELD } from '../../../../Constant/Constant';

export default class CarouselBuilder extends UIBuilder {
  constructor() {
    super();
    this.schema = {
      data: UI_COMPONENTS_TYPE_FIELD.JSON,
      autoplay: UI_COMPONENTS_TYPE_FIELD.BOOLEAN,
      dots: UI_COMPONENTS_TYPE_FIELD.BOOLEAN,
      renderItem: UI_COMPONENTS_TYPE_FIELD.JSON,
      sampleNextArrow: UI_COMPONENTS_TYPE_FIELD.JSON,
      samplePrevArrow: UI_COMPONENTS_TYPE_FIELD.JSON,
    };
  }

  getType = () => {
    return 'carousel';
  };

  getComponent = () => {
    return Carousel;
  };
}
