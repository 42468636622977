import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Text, View } from '../../../../React-Native/types';
import Switch from '../../../Switch';

class BooleanModal extends Component {
  render() {
    const { theme, value } = this.props;
    return (
      <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>
        <Text style={{ fontFamily: theme.fonts.fontMedium, fontSize: 18, color: theme.colors.textInfor }}>Enable/Disable: </Text>
        <Switch active={value} onChange={this.props.onChangeValue} />
      </View>
    );
  }
}

export default connect((state) => {
  return {
    theme: state.theme,
  };
})(BooleanModal);
