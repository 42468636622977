import React, { Component } from 'react';
import Logger from '../../../../Helper/Logger';

class MenuItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
  }

  render() {
    const { item } = this.props;
    const { pre, title, aft, onClick } = item;
    return (
      <tr
        onClick={() => {
          this.props.closeContextMenu();
          onClick?.(item);
        }}
        style={{
          cursor: 'pointer',
          backgroundColor: this.state.hover ? '#e0e0e0' : '#f1f3f4',
          color: 'black',
        }}
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}>
        <td>{pre}</td>
        <td
          style={{
            padding: '7px 30px 7px 30px',
            fontFamily:
              "-apple-system, BlinkMacSystemFont, 'Segoe UI Variable', 'Segoe UI', system-ui, ui-sans-serif, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
            fontSize: '10pt',
          }}>
          {title}
        </td>
        <td>{aft}</td>
      </tr>
    );
  }
}

export default class contextmenu extends Component {
  render() {
    const contextMenu = this.props.contextMenu;
    const items = Array.isArray(contextMenu.menu) ? contextMenu.menu : [];
    const { x, y, width, height, display } = contextMenu;
    if (display) {
      Logger.terminalInfo('render context menu', contextMenu);
      return (
        <div
          style={{
            position: 'absolute',
            top: y,
            left: x,
            width,
            height,
            zIndex: 999,
            boxShadow: '0px 0px 2px #C0C0C0',
            background: '#f1f3f4',
            borderRadius: '4px',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: 'lightgray',
            padding: '3px',
          }}>
          <table>
            <tbody>
              {items.map((item) => (
                <MenuItem item={item} closeContextMenu={this.props.closeContextMenu} />
              ))}
            </tbody>
          </table>
        </div>
      );
    }
    return <></>;
  }
}
