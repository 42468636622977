import FileExplorer from "./FileExplorer";
import DownloadExplorer from "./DownloadExplorer";
import UploadExplorer from "./UploadExplorer";
import Empty from "./Empty";

const Explorers = {
  FileExplorer,
  DownloadExplorer,
  UploadExplorer,
  Empty,
};

export default Explorers;
