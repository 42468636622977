export const mergedObject = (arrayOfObjects) =>
  arrayOfObjects.reduce((result, currentObject) => {
    for (const key in currentObject) {
      if (currentObject.hasOwnProperty(key)) {
        result[key] = currentObject[key];
      }
    }
    return result;
  }, {});

const makeStyle = ({ styleName, value }) => {
  switch (styleName) {
    case 'paddingHorizontal':
      return {
        paddingLeft: value,
        paddingRight: value,
      };

    case 'paddingVertical':
      return {
        paddingTop: value,
        paddingBottom: value,
      };

    case 'marginHorizontal':
      return {
        marginLeft: value,
        marginRight: value,
      };

    case 'marginVertical':
      return {
        marginTop: value,
        marginBottom: value,
      };

    case 'lineHeight':
      return {
        lineHeight: `${value}px`,
      };

    default:
      return { [styleName]: value };
  }
};

export const makeStyles = (styles) => {
  let initStyle = styles;
  if (Array.isArray(styles)) {
    initStyle = mergedObject(styles);
  }

  let _style = {};
  Object.keys(initStyle || {}).forEach((styleName) => {
    _style = { ..._style, ...makeStyle({ styleName, value: initStyle[styleName] }) };
  });
  return { display: 'flex', flexDirection: 'column', position: 'relative', ..._style };
};
