const qrComponent = (
    data = {
        value: 1,
        size: 30,
    },
) => {
    return {
        type: 'QRCode',
        children: [],
        props: {
            value: data.value,
        },
    };
};

export default qrComponent;
