import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FastImage, StyleSheet, Text, TouchableOpacity, View } from '../../React-Native/types';
class WifiComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ssid: '',
            password: '',
            showWifi: false,
            isShowPass: false,
            isEdit: false,
        };
    }

    render() {
        const { theme, data } = this.props;
        const { ssid, active } = data;
        return (
            <TouchableOpacity
                style={{ ...styles.wificomponent, ...this.props.style }}
                onPress={() => {
                    this.props.onOpenDetailWifi({ wifi: data, wifis: [] });
                }}>
                <View style={[styles.content, { backgroundColor: theme.colors.appColor, opacity: active ? 1 : 0.5 }]}>
                    <View style={styles.wifi}>
                        <FastImage source={theme.icons.wifi} style={{ height: 20, width: 20, marginRight: 15 }} />
                        <Text
                            style={[{ color: theme.colors.textColor, fontWeight: active ? '700' : '500', fontSize: active ? 16 : 14 }]}
                            placeholderTextColor={theme.colors.placeholderColor}>
                            {ssid}
                        </Text>
                    </View>
                    <View style={[styles.arrowDown]}>
                        <FastImage source={theme.icons.goTo} style={{ height: 16, width: 16 }} />
                    </View>
                </View>
            </TouchableOpacity>
        );
    }
}

export default connect((state) => {
    return {
        theme: state.theme,
        lang: state.lang,
    };
}, {})(WifiComponent);

const styles = StyleSheet.create({
    arrowDown: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    wifi: {
        marginRight: 16,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
    },
    content: {
        width: '100%',
        borderRadius: 16,
        paddingHorizontal: 16,
        paddingVertical: 24,
        marginTop: '10%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
});
