import { jwtDecode } from 'jwt-decode';

export const isTokenExpired = (token) => {
  try {
    let { exp } = jwtDecode(token);
    return exp - 20 < Date.now() / 1000;
  } catch (error) {
    return false;
  }
};

export const isTokenValid = (token) => {
  try {
    jwtDecode(token);
    return true;
  } catch (error) {
    return false;
  }
};
