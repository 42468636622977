import { combineReducers } from 'redux';
import ChangeLangReducer from './lang';
import ThemeSelectionReducer from './theme';

const reducer = combineReducers({
  lang: ChangeLangReducer,
  theme: ThemeSelectionReducer,
});

export default reducer;
