import Builder from '..';

export const build = (ConditionConfig, resource) => {
  const { array1, array2 } = ConditionConfig;
  const array1Value = Builder.traverseBuild(array1, resource);
  const array2Value = Builder.traverseBuild(array2, resource);

  const _array1Value = Array.isArray(array1Value) ? array1Value : [];
  const _array2Value = Array.isArray(array2Value) ? array2Value : [];
  return _array1Value.concat(_array2Value);
};

export const type = 'ConcatArray';
