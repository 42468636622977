import { _API } from '../API';
import { isTokenExpired, isTokenValid } from './utils';
import Store from '../../Redux';
import { BASE_URL, API } from './constants';
import { Storage } from '../Storage';
import { fRedux } from '../fRedux';
import { CommonModalController } from '../CommonModal';
import Logger from '../../Helper/Logger';

const BASE_URI = BASE_URL.prod;

const refreshTokenAPI = async () => {
  try {
    const refreshToken = Store.getState().filiot?.refreshToken;
    if (refreshToken && refreshToken !== 'null' && refreshToken !== undefined) {
      const uri = `${BASE_URI}${API.AUTH.REFRESH_TOKEN}`;
      const form = { refreshToken };

      const response = await fetch(uri, {
        method: 'POST',
        body: JSON.stringify({ ...form }),
        headers: {
          accept: '*/*',
          'Content-Type': 'application/json',
        },
      });
      const responseData = await response.json();

      return responseData;
    }
  } catch (error) {
    return null;
  }
};

class FAPI {
  constructor() {
    this.api = new _API();
    this.init();
    this.refreshTokenRequest = null;
  }

  init() {
    this.api.setInterceptor(
      {
        success: async (param) => {
          let accessToken = Store.getState().filiot?.accessToken;
          if (accessToken !== '' && accessToken !== null && accessToken !== undefined && accessToken !== 'null' && isTokenExpired(accessToken)) {
            this.refreshTokenRequest = this.refreshTokenRequest ? this.refreshTokenRequest : refreshTokenAPI();

            const newToken = await this.refreshTokenRequest;
            if (newToken) {
              Storage.set({ name: 'accessToken', value: newToken.accessToken });
              Storage.set({ name: 'refreshToken', value: newToken.refreshToken });
              fRedux.updateObject({
                path: 'accessToken',
                data: newToken.accessToken,
              });

              fRedux.updateObject({
                path: 'refreshToken',
                data: newToken.refreshToken,
              });

              accessToken = newToken.accessToken;
            } else {
              Storage.set({ name: 'accessToken', value: '' });
              Storage.set({ name: 'refreshToken', value: '' });
            }
            this.refreshTokenRequest = null;
          }
          if (isTokenValid(accessToken)) {
            return {
              ...param,
              headers: {
                ...param.headers,
                Authorization: accessToken,
              },
            };
          } else {
            return {
              ...param,
              headers: {
                ...param.headers,
              },
            };
          }
        },
        error: (error) => {
          Logger.terminalInfo('inteceptor error', error);
        },
      },
      {
        success: (res) => {
          return res;
        },
        error: (error) => {
          Logger.terminalInfo('interceptor response error: ', error);
          if (error?.response?.status === 403 && error?.response?.data?.errorCode === '08024200') {
            Store.dispatch(
              fRedux.updateObject({
                path: 'verifyAuth',
                data: false,
              }),
            );
            CommonModalController.display({
              area: [{}],
              animation: '',
              onCloseModal: () => {},
            });
          }
          return Promise.reject(error);
        },
      },
    );
  }

  get(uri = '', headers = {}, query = {}) {
    return this.api.get(uri, headers, query);
  }

  post(uri = '', headers = {}, form = {}) {
    return this.api.post(uri, headers, form);
  }

  put(uri = '', headers = {}, form = {}) {
    return this.api.put(uri, headers, form);
  }

  delete(uri = '', headers = {}, form) {
    return this.api.del(uri, headers, form);
  }
}

export default new FAPI();
