import React, { Component, useEffect, useState } from 'react';
import { Dropdown, Space, Divider, Button, theme, Table } from 'antd';
import ResourceTable from './resource';
import { ViewMode } from '../Constant/Preview';

const { useToken } = theme;

const SYSTEM_KEYS = ['accessToken', 'refreshToken', 'init', 'isPreview', 'location', 'pathname', 'search', 'location.pathname', 'location.search'];

export default function Toolbar(props) {
  const [appResource, setResource] = useState([
    {
      key: 'isPreview',
      value: props.appResource?.isPreview,
      deleteable: false,
      editing: false,
      editKeyAble: false,
      editValueAble: false,
    },
    {
      key: 'init',
      value: props.appResource?.init,
      deleteable: false,
      editing: false,
      editKeyAble: false,
      editValueAble: false,
    },
    {
      key: 'accessToken',
      value: props.appResource?.accessToken,
      deleteable: false,
      editing: false,
      editKeyAble: false,
      editValueAble: true,
    },
    {
      key: 'refreshToken',
      value: props.appResource?.refreshToken,
      deleteable: false,
      editing: false,
      editKeyAble: false,
      editValueAble: true,
    },
    {
      key: 'location.pathname',
      value: props.appResource?.location?.pathname,
      deleteable: false,
      editing: false,
      editKeyAble: false,
      editValueAble: false,
    },
    {
      key: 'location.search',
      value: props.appResource?.location?.search,
      deleteable: false,
      editing: false,
      editKeyAble: false,
      editValueAble: false,
    },
    {
      key: 'location',
      value: props.appResource?.location,
      deleteable: false,
      editing: false,
      editKeyAble: false,
      editValueAble: false,
    },
    ...Object.keys(props.appResource)
      .filter((key) => !SYSTEM_KEYS.includes(key))
      .map((key) => {
        return {
          key: key,
          value: props.appResource?.[key],
          deleteable: true,
          editing: false,
          editKeyAble: false,
          editValueAble: true,
        };
      }),
  ]);

  useEffect(() => {
    setResource([
      {
        key: 'isPreview',
        value: props.appResource?.isPreview,
        deleteable: false,
        editing: false,
        editKeyAble: false,
        editValueAble: false,
      },
      {
        key: 'init',
        value: props.appResource?.init,
        deleteable: false,
        editing: false,
        editKeyAble: false,
        editValueAble: false,
      },
      {
        key: 'accessToken',
        value: props.appResource?.accessToken,
        deleteable: false,
        editing: false,
        editKeyAble: false,
        editValueAble: true,
      },
      {
        key: 'refreshToken',
        value: props.appResource?.refreshToken,
        deleteable: false,
        editing: false,
        editKeyAble: false,
        editValueAble: true,
      },
      {
        key: 'location',
        value: props.appResource?.location,
        deleteable: false,
        editing: false,
        editKeyAble: false,
        editValueAble: false,
      },
      {
        key: 'location.pathname',
        value: props.appResource?.location?.pathname,
        deleteable: false,
        editing: false,
        editKeyAble: false,
        editValueAble: false,
      },
      {
        key: 'location.search',
        value: props.appResource?.location?.search,
        deleteable: false,
        editing: false,
        editKeyAble: false,
        editValueAble: false,
      },
      ...Object.keys(props.appResource)
        .filter((key) => !SYSTEM_KEYS.includes(key))
        .map((key) => {
          return {
            key: key,
            value: props.appResource?.[key],
            deleteable: true,
            editing: false,
            editKeyAble: false,
            editValueAble: true,
          };
        }),
    ]);
  }, [props.appResource]);

  const { token } = useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    width: '800px',
    padding: '15px 20px',
  };

  const onChangeAppResource = (dataSource) => {
    const newResource = Object.fromEntries(dataSource.map(({ key, value }) => [key, value]));
    props?.onChangeAppResource(newResource);
  };

  return (
    <div
      style={{
        height: '40px',
        position: 'fixed',
        top: '0px',
        zIndex: 999,
        width: '100%',
        fontSzie: '12px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'pink',
      }}>
      <Dropdown
        dropdownRender={() => {
          return (
            <div style={contentStyle}>
              <ResourceTable dataSource={appResource} onChangeDataSource={onChangeAppResource} />
            </div>
          );
        }}
        trigger={'click'}>
        <Button style={{ marginLeft: '5px', marginRight: '5px' }}> Resource</Button>
      </Dropdown>

      <div>
        <Button style={{ marginLeft: '5px', marginRight: '5px' }} onClick={() => props.setVisualPreview()}>
          Visual Mode: {props.visualPreviewMode === true ? 'true' : 'false'}
        </Button>
        <Button style={{ marginLeft: '5px', marginRight: '5px' }} onClick={() => props.setResolution(ViewMode.SmartPhone)}>
          SmartPhone
        </Button>
        <Button style={{ marginLeft: '5px', marginRight: '5px' }} onClick={() => props.setResolution(ViewMode.Tablet)}>
          Tablet
        </Button>
        <Button style={{ marginLeft: '5px', marginRight: '5px' }} onClick={() => props.setResolution(ViewMode.Desktop)}>
          Desktop
        </Button>
      </div>
    </div>
  );
}
