import React, { Component } from 'react';
import { buildReactNode } from '../../../../../../Helper/App/View/Attribute/builder';
import { FlatList } from '../../React-Native/types';

export default class FlatListFil extends Component {
  renderItem = (data) => {
    let { itemComponent, __components } = this.props;
    let _itemComponent = { ...itemComponent };
    _itemComponent.props = { ..._itemComponent.props, data: { item: { ...data.item }, data } };
    Object.keys(_itemComponent.props).forEach((key) => {
      if (typeof _itemComponent.props[key] === 'function') {
        const func = _itemComponent.props[key];
        _itemComponent.props[key] = (value) => {
          func?.(value, data.index);
          // Logger.terminalInfo('func', func.toString());
        };
        // Logger.terminalInfo('component', itemComponent.props[key].toString());
      }
    });
    buildReactNode(_itemComponent, __components);
    return _itemComponent.component;
  };

  render() {
    return <FlatList data={this.props.data} renderItem={this.renderItem} keyExtractor={(item, index) => index} />;
  }
}
