import React, { Component } from 'react';
import BaseComponent from '../BaseComponent/BaseComponent';
import Modal from '../Modal/modal';
import AppDetailAttribute from './AppDetailAttribute';
import { acronymToFull, createDetailExtensionAttributeComponent } from '../../../../Helper/App/Core/Helper/extention/extensionattribute';
import DecorationController from '../../../../Controller/Decoration';
import DefaultSettingConfig from './AppDetailAttribute/Samples/Configs';
import { toString } from '../../../../Helper/App/View/Attribute/extractor';
import Logger from '../../../../Helper/Logger';

class AttributeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onHover: false,
    };
  }

  render() {
    const { attribute, index, showIndex } = this.props;
    return (
      <div
        style={{
          padding: '10px 0px 10px 0px',
          borderBottomWidth: '1px',
          borderBottomColor: 'rgb(237 237 237)',
          borderBottomStyle: 'solid',
          cursor: 'pointer',
          fontWeight: this.state.onHover ? '700' : undefined,
        }}
        onClick={() => this.props.onOpenDetailAttribute(attribute)}
        onMouseEnter={() => this.setState({ onHover: true })}
        onMouseLeave={() => this.setState({ onHover: false })}>
        {showIndex ? `${index + 1}. ` : ''}
        {attribute.title}
      </div>
    );
  }
}

class ReactNativeListAttribute extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      visibleDetail: false,
      attribute: {},
    };
    this.setting = {};
  }

  componentDidUpdate(prevProps, prevState) {
    if (typeof this.props.currentEntity !== 'undefined' && this.props.mqttConnected === true) {
      // fMqtt.requestDeviceSetting({ deviceId: this.props.currentEntity?.originId });
      // fMqtt.requestDeviceAutomation({ deviceId: this.props.currentEntity?.originId });
    }
  }

  onOpenDetailAttribute = (attribute) => {
    this.setState({
      visibleDetail: true,
      attribute,
    });
  };

  onCloseDetailAttributeModal = () => {
    this.setState({ visibleDetail: false });
  };

  renderComponent() {
    Logger.terminalInfo('this.props.settingConfig', JSON.stringify(this.props.settingConfig));
    Logger.terminalInfo('this.props.report_setting', JSON.stringify(this.props.report_setting));
    Logger.terminalInfo('this.props.currentEntity', JSON.stringify(this.props.currentEntity));
    Logger.terminalInfo('this.props.mqttConnected', JSON.stringify(this.props.mqttConnected));
    if (
      typeof this.props.settingConfig !== 'undefined' &&
      typeof this.props.currentEntity !== 'undefined' &&
      this.props.settingConfig !== null &&
      this.props.currentEntity !== null
    ) {
      const settingConfig = this.props.settingConfig || {};
      const report_setting = this.props.report_setting || {};
      const attributesIncludes = this.props.attributesIncludes || [];
      const attributesExcludes = this.props.attributesExcludes || [];
      this.setting = { ...this.setting, ...report_setting };

      const cEntity = this.props.currentEntity || {};

      const attributes = [];

      const settingConfigComponents = [];

      Array.isArray(settingConfig?.attributes) &&
        settingConfig?.attributes.forEach((attribute) => {
          const name = attribute.name;
          if (attributes.findIndex((attribute) => attribute.name === name) === -1) {
            const title = toString(attribute.title, { lang: { ...settingConfig?.externalData?.lang?.['vi'] } });
            Logger.terminalInfo('title', title);
            attributes.push({ title, name });
          }
        });

      Object.keys(report_setting).forEach((keyAttribute) => {
        const attributeContent = { ...report_setting[keyAttribute], name: keyAttribute };

        if (attributeContent.isExtension) {
          let fullAttributeContent = attributeContent;

          let fullAttributeContentDisplay;

          if (attributeContent.isShort) {
            fullAttributeContent = { ...acronymToFull(attributeContent, DecorationController.getDictionary()) };
          }

          if (attributeContent.isExtension) {
            fullAttributeContentDisplay = createDetailExtensionAttributeComponent(fullAttributeContent, keyAttribute);
            settingConfigComponents.push(fullAttributeContentDisplay);
          }
          if (attributes.findIndex((attribute) => attribute.name === keyAttribute) === -1) {
            attributes.push({ title: keyAttribute, name: keyAttribute, display: fullAttributeContentDisplay });
          }
        }
      });

      const cEntitySettingConfig = cEntity.deviceInfo?.info?.settingConfig ?? DefaultSettingConfig(cEntity.originId);

      settingConfigComponents.forEach((settingConfigComponent) => {
        const currentExtensionIndex = cEntitySettingConfig.attributes
          .map?.((attribute) => attribute.name)
          ?.findIndex?.((e) => e === settingConfigComponent.name);

        currentExtensionIndex !== -1
          ? (cEntitySettingConfig.attributes[currentExtensionIndex] = settingConfigComponent)
          : cEntitySettingConfig.attributes.push(settingConfigComponent);
      });

      Logger.terminalInfo('this.setting', JSON.stringify(this.setting));
      Logger.terminalInfo('attributes', JSON.stringify(attributes));
      Logger.terminalInfo('cEntitySettingConfig.attributes', JSON.stringify(cEntitySettingConfig.attributes));

      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}>
          <Modal
            visible={this.state.visibleDetail}
            onRequestClose={this.onCloseDetailAttributeModal}
            style={{
              content: {
                width: '500px',
                height: '600px',
                backgroundColor: 'rgb(249, 249, 252)',
              },
            }}>
            <AppDetailAttribute
              cEntity={{
                ...this.props.currentEntity,
                setting: this.setting,
              }}
              cEntityAttribute={this.state.attribute?.name}
              settingConfig={{
                ...cEntitySettingConfig,
                attributes: cEntitySettingConfig.attributes,
              }}
              closeModal={this.onCloseDetailAttributeModal}
            />
          </Modal>
          {attributes
            .filter(
              (attribute) =>
                (Array.isArray(attributesIncludes) && attributesIncludes.length > 0 ? attributesIncludes.includes(attribute.name) : true) &&
                (Array.isArray(attributesExcludes) && attributesExcludes.length > 0 ? !attributesExcludes.includes(attribute.name) : true),
            )
            .map((attribute, index) => {
              return (
                <AttributeComponent attribute={attribute} onOpenDetailAttribute={this.onOpenDetailAttribute} index={index} showIndex={this.props.showIndex} />
              );
            })}
        </div>
      );
    }

    return <></>;
  }
}

export default ReactNativeListAttribute;
