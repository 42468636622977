import { Collapse, Table } from 'antd';
import React, { Component } from 'react';
import Logger from '../Helper/Logger';
const { Panel } = Collapse;

const customPanelStyle = {
  background: 'rgba(0, 0, 0, 0.02)',
  borderRadius: 4,
  border: 0,
  overflow: 'hidden',
};

export default class Preprocessing extends Component {
  render() {
    Logger.terminalInfo('Preprocessingresource', this.props.resource);
    return (
      <Collapse
        accordion={false}
        style={{
          padding: '0px',
        }}
        className='selected-view'>
        <Panel header={<span>Page Preprocessing</span>} key='1' style={customPanelStyle}>
          <span>Coming Soon</span>
        </Panel>
      </Collapse>
    );
  }
}
