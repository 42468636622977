const homekitComponent = (
    data = {
        value: {
            code: '12312312',
            link: '',
            name: '',
        },
        size: 200,
    },
) => {
    return {
        type: 'HomeKit',
        props: {
            value: data.value,
            size: data.size,
        },
    };
};

export default homekitComponent;
