import React, { Component } from 'react';
import Tabs from './Tabs';
import Explorers from './Explorer';
import Logger from '../../Helper/Logger';

class Explorer extends Component {
  render() {
    const { currentTab } = this.props;
    Logger.terminalInfo('currentTab', currentTab);
    const ExplorerComponent = currentTab.explorer ?? Explorers.Empty;
    return (
      <div
        style={{
          width: '300px',
          height: '100%',
          backgroundColor: 'rgb(26 26 26)',
          color: 'rgb(151 151 151)',
        }}>
        <ExplorerComponent {...this.props} />
      </div>
    );
  }
}

export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.tabs = [
      {
        name: 'File',
        select: true,
        iconHide: require('../Resource/Image/folder-hide.png'),
        iconShow: require('../Resource/Image/folder-show.png'),
        onClick: () => {},
        explorer: Explorers.FileExplorer,
      },
      {
        name: 'Upload',
        select: false,
        iconHide: require('../Resource/Image/upload-hide.png'),
        iconShow: require('../Resource/Image/upload-show.png'),
        onClick: () => {},
        // explorer: Explorers.DownloadExplorer,
      },
      {
        name: 'Download',
        select: false,
        iconHide: require('../Resource/Image/download-hide.png'),
        iconShow: require('../Resource/Image/download-show.png'),
        onClick: () => {},
        explorer: Explorers.UploadExplorer,
      },
    ];
    this.state = {
      currentTab: this.tabs[0],
    };
  }

  onChangeCurrentTab = (tab) => {
    this.setState({ currentTab: tab });
  };

  render() {
    const { currentTab } = this.state;
    return (
      <div
        style={{
          width: '350px',
          height: '100vh',
          backgroundColor: 'rgb(20,20,20)',
          display: 'flex',
          flexDirection: 'row',
        }}>
        <Tabs
          tabs={this.tabs.map((tab) => ({
            ...tab,
            onClick: () => {
              Logger.terminalInfo('onClick');
              this.onChangeCurrentTab(tab);
            },
          }))}
          currentTab={currentTab}
          onChangeCurrentTab={this.onChangeCurrentTab}
        />
        <Explorer
          currentTab={currentTab}
          treeFile={this.props.treeFile}
          currentFile={this.props.currentFile}
          setSelectFile={this.props.setSelectFile}
          setTreeFile={this.props.setTreeFile}
          closeFile={this.props.closeFile}
          onPublish={this.props.onPublish}
        />
      </div>
    );
  }
}
