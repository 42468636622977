import React from 'react';
import Components from '../Components';

const DynamicComponent = ({ jsonData, __components }) => {
  const components = { ...__components, ...Components };
  const { type, props, children } = jsonData;

  if (type === 'Text' && typeof children === 'string') {
    return React.createElement(components[type], { ...props, components }, children);
  }

  const childrenComponents =
    typeof children !== 'undefined'
      ? Array.isArray(children) && children.length > 0
        ? children.map((child, index) => <DynamicComponent key={props?.key || index} jsonData={child} __components={components} />)
        : React.createElement(components[type], { ...props, components }, children)
      : null;

  return React.createElement(components[type], { ...props, components }, childrenComponents);
};

export default DynamicComponent;
