import React, { Component } from 'react';
import { TouchableOpacity, StyleSheet, Text, ScrollView, View, Image } from '../../React-Native/types';
import { connect } from 'react-redux';
import TimerComponent from './item';
import Logger from '../../../../../../Helper/Logger';

class Items extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timer: [],
      visibleDetailTimer: false,
      dataDetailTimer: {},
    };
  }

  addTimer = () => {
    const { timer } = this.state;
    if (timer.length < 10) {
      this.navigateToDetailTimer({ isNew: true });
    }
  };

  navigateToDetailTimer = (timer) => {
    this.props.navigateDetail({ ...timer });
  };

  editTimer = (timer) => {
    Logger.terminalInfo('timer', timer);
    const [minutes, hours, day, month, day_week] = timer.str.split(' ');
    Logger.terminalInfo('minutes, hours', minutes, hours);

    if (!isNaN(parseInt(minutes, 10)) && !isNaN(parseInt(hours, 10))) {
      this.navigateToDetailTimer({ ...timer, isNew: false });
    }
  };

  render() {
    const { theme, lang, timers } = this.props;
    const { timer, dataDetailTimer } = this.state;
    Logger.terminalInfo('timers', timers);
    return (
      <View
        style={{
          width: '100%',
          flex: 1,
        }}>
        <View
          style={{
            width: '100%',
            paddingBottom: 16,
            paddingHorizontal: 24,
            paddingTop: 50,
          }}>
          <View
            style={{
              width: '100%',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <TouchableOpacity
              style={{
                position: 'absolute',
                left: -16,
                padding: 16,
              }}
              onPress={this.props.goBack}>
              <Image source={theme.icons.back} style={{ width: 32, height: 32 }} />
            </TouchableOpacity>
            <Text
              style={{
                color: theme.colors.textColor,
                fontFamily: theme.fonts.fontBold,
                width: '100%',
                flex: 1,
                fontSize: 20,
                lineHeight: 28,
                maxWidth: '70%',
                textAlign: 'center',
              }}
              ellipsizeMode={'tail'}
              numberOfLines={1}>
              {lang.timerGateway}
            </Text>
            <TouchableOpacity
              style={{
                position: 'absolute',
                right: -16,
                padding: 16,
              }}
              onPress={this.addTimer}>
              <Image source={theme.icons.add} style={{ width: 32, height: 32 }} />
            </TouchableOpacity>
          </View>
        </View>
        <Text
          style={{
            color: 'rgb(251 9 9)',
            fontFamily: theme.fonts.fontBold,
            width: '100%',
            fontSize: 14,
            lineHeight: 28,
            textAlign: 'left',
            paddingLeft: 28,
            paddingRight: 16,
          }}>
          (*) Thời gian hẹn giờ được cài đặt gần nhất.
        </Text>

        {Array.isArray(timers) && timers.length > 0 ? (
          <View style={{ width: '100%', height: '100%', justifyContent: 'center', flexDirection: 'row' }}>
            <ScrollView style={{ paddingHorizontal: 16 }}>
              {timers.map((item) => {
                Logger.terminalInfo('item', item);
                return <TimerComponent timer={item} editTimer={this.props.editTimer} navigateEditTimer={this.editTimer} />;
              })}
            </ScrollView>
          </View>
        ) : (
          <View
            style={{
              marginTop: 20,
            }}>
            <Text style={[styles.change, { color: theme.colors.textColor, fontFamily: theme.fonts.fontBold, textAlign: 'center' }]}>
              Không có dữ liệu hẹn giờ
            </Text>
          </View>
        )}
      </View>
    );
  }
}

export default connect((state) => {
  return {
    theme: state.theme,
    lang: state.lang,
  };
}, {})(Items);

const styles = StyleSheet.create({
  change: {
    fontSize: 18,
    lineHeight: 24,
  },
  btn: {
    paddingVertical: 8,
    paddingHorizontal: 8,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    marginTop: 50,
    width: '100%',
    alignSelf: 'center',
    height: 56,
  },
});
