import React from 'react';
import { BaseModalRename, _connect } from './modalrename';
import { FastImage, StyleSheet, Text, TextInput, TouchableOpacity, View } from '../../React-Native/types';

class ModalContentRenameWithError extends BaseModalRename {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
        };
    }

    aRef = [{ ref: undefined, id: 'rename', label: this.props.placeholder || this.props.lang.rename }];

    render() {
        const { theme, lang, label, placeholder, title, secureTextEntry, error } = this.props;
        const { name } = this.state;

        return (
            <View style={styles.root}>
                <View style={[styles.container, { backgroundColor: theme.colors.appColor }]}>
                    <Text style={[styles.title, { color: theme.colors.textColor, fontFamily: theme.fonts.fontBold }]}>{title || lang.rename}</Text>
                    <View style={[styles.row, { backgroundColor: theme.colors.appColorThird }]}>
                        <TextInput
                            style={[styles.input, { color: theme.colors.textColor, fontFamily: theme.fonts.fontMedium }]}
                            placeholder={placeholder || lang.rename}
                            placeholderTextColor={theme.colors.placeholderColor}
                            value={name}
                            onChangeText={this.onChangeText}
                            secureTextEntry={secureTextEntry || false}
                            inputAccessoryViewID={'rename'}
                            ref={(ref) => (this.aRef[0].ref = ref)}
                            keyboardAppearance={theme.type || 'default'}
                        />
                        {typeof name !== 'undefined' && name !== '' && (
                            <TouchableOpacity style={styles.clearBtn} onPress={this.onClearValue}>
                                <FastImage source={theme.icons.cancel} style={styles.iconCancel} resizeMode={'cover'} />
                            </TouchableOpacity>
                        )}
                    </View>
                    <Text style={[{ color: theme.colors.errorColor }, styles.errorText]}>{error}</Text>

                    <View style={styles.layoutBtn}>
                        <TouchableOpacity style={[styles.btnCancel, { borderColor: theme.colors.borderColorSecond }]} onPress={this.onCloseModal}>
                            <Text style={[styles.cancel, { color: theme.colors.textColor, fontFamily: theme.fonts.fontMedium }]}>{lang.cancel}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={[styles.btnRename, { backgroundColor: theme.colors.activeColor }]} onPress={this.onSaveRename}>
                            <Text
                                style={[styles.rename, { color: theme.colors.textColorBtn, fontFamily: theme.fonts.fontMedium }]}
                                numberOfLines={1}
                                ellipsizeMode={'tail'}>
                                {label || lang.rename}
                            </Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        );
    }
}

export default _connect(ModalContentRenameWithError);

const styles = StyleSheet.create({
    errorText: {
        fontSize: 14,
        marginTop: 6,
        marginLeft: 8,
        marginBottom: 16,
        height: 20,
    },
    rename: {
        fontSize: 14,
        lineHeight: 24,
    },
    btnRename: {
        flex: 1,
        height: 48,
        paddingVertical: 11,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 16,
    },
    cancel: {
        fontSize: 14,
        lineHeight: 24,
    },
    btnCancel: {
        flex: 1,
        height: 48,
        paddingVertical: 10,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 16,
        borderWidth: 1,
        marginRight: 16,
    },
    layoutBtn: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    iconCancel: {
        width: 20,
        height: 20,
    },
    clearBtn: {
        paddingVertical: 18,
        paddingHorizontal: 14,
    },
    input: {
        flex: 1,
        fontSize: 14,
    },
    row: {
        width: '100%',
        height: 56,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 25,
        marginBottom: 10,
        paddingLeft: 20,
        borderRadius: 16,
    },
    title: {
        fontSize: 16,
        lineHeight: 24,
        textAlign: 'center',
    },
    container: {
        marginHorizontal: 24,
        padding: 24,
        borderRadius: 16,
    },
    root: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
});
