import Wifi from '../Attributes/Wifi/wifi_old.json';

import Electricmeter from './electricmeter';
import AirCondition from './aircondition';
import WaterPurifier from './waterpurifier';
import { isSubDevice } from '../../../../../../../Helper/App/Core/Helper/device';
import { getDeviceConnectionByDeviceType, getDeviceFamilyByDeviceType } from '../../../../../../../Helper/App/Core/Helper/deviceresource';
import { Connection, Family } from '../../../../../../../Helper/App/Core/Constant/DeviceResource';

const DefaultSettingConfig = (originId) => {
    let deviceType;
    if (isSubDevice(originId)) {
        deviceType = originId.split('_')[3].toString();
    } else {
        deviceType = originId.split('_')[1].toString();
    }
    let deviceFamily = getDeviceFamilyByDeviceType(deviceType);
    let deviceConnection = getDeviceConnectionByDeviceType(deviceType);

    switch (deviceFamily) {
        case Family.water_purifier:
            return WaterPurifier;

        case Family.smart_electric_meter:
            return Electricmeter;

        case Family.white_light:
            break;

        case Family.rgb:
            break;

        case Family.switch:
            break;

        case Family.fan:
            break;

        case Family.air_conditioning:
            return AirCondition;

        case Family.motor:
            break;

        case Family.dimmer:
            break;

        case Family.tv:
            break;

        default:
            break;
    }

    const attributes = [];
    switch (deviceConnection) {
        case Connection.ble_wifi:
        case Connection.eth_wifi:
        case Connection.wifi:
            attributes.push(Wifi);
    }

    return {
        externalData: {
            theme: {},
        },
        attributes: [],
        version: 'v0.0.1',
    };
};

export const DefaultSettingConfigData = (attributeName) => {
    switch (attributeName) {
        case 'warning':
            return {};
        case 'wifi':
            return [];
        case 'button_disable':
            return 0;
        case 'meter_reading_date':
            return 1;
        case 'relay_action_init':
            return 0;
        case 'timer_hcl':
            return {
                enable: true,
                list: [
                    { cron: '0 0 0 * * *', action: { state: { temp: 17 } } },
                    { cron: '0 0 1 * * *', action: { state: { temp: 17 } } },
                    { cron: '0 0 2 * * *', action: { state: { temp: 17 } } },
                    { cron: '0 0 3 * * *', action: { state: { temp: 17 } } },
                    { cron: '0 0 4 * * *', action: { state: { temp: 17 } } },
                    { cron: '0 0 5 * * *', action: { state: { temp: 17 } } },
                    { cron: '0 0 6 * * *', action: { state: { temp: 17 } } },
                    { cron: '0 0 7 * * *', action: { state: { temp: 17 } } },
                    { cron: '0 0 8 * * *', action: { state: { temp: 17 } } },
                    { cron: '0 0 9 * * *', action: { state: { temp: 17 } } },
                    { cron: '0 0 10 * * *', action: { state: { temp: 17 } } },
                    { cron: '0 0 11 * * *', action: { state: { temp: 17 } } },
                    { cron: '0 0 12 * * *', action: { state: { temp: 17 } } },
                    { cron: '0 0 13 * * *', action: { state: { temp: 17 } } },
                    { cron: '0 0 14 * * *', action: { state: { temp: 17 } } },
                    { cron: '0 0 15 * * *', action: { state: { temp: 17 } } },
                    { cron: '0 0 16 * * *', action: { state: { temp: 17 } } },
                    { cron: '0 0 17 * * *', action: { state: { temp: 17 } } },
                    { cron: '0 0 18 * * *', action: { state: { temp: 17 } } },
                    { cron: '0 0 19 * * *', action: { state: { temp: 17 } } },
                    { cron: '0 0 20 * * *', action: { state: { temp: 17 } } },
                    { cron: '0 0 21 * * *', action: { state: { temp: 17 } } },
                    { cron: '0 0 22 * * *', action: { state: { temp: 17 } } },
                    { cron: '0 0 23 * * *', action: { state: { temp: 17 } } },
                ],
            };
        case 'timer':
            return [];
        case 'updateFirmware':
            break;
        default:
            break;
    }
};

export default DefaultSettingConfig;
