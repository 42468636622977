import Switch from './Switch';
import UIBuilder from '../BaseComponent';
import { UI_COMPONENTS_TYPE_FIELD } from '../../../../Constant/Constant';

export default class SwitchBuilder extends UIBuilder {
  constructor() {
    super();
    this.schema = {
      value: UI_COMPONENTS_TYPE_FIELD.STRING,
      test: UI_COMPONENTS_TYPE_FIELD.JSON,
      default: UI_COMPONENTS_TYPE_FIELD.JSON,
      switch: UI_COMPONENTS_TYPE_FIELD.JSON,
    };
  }

  getType = () => {
    return 'switch_component';
  };

  getComponent = () => {
    return Switch;
  };
}
