import Logger from '../../Helper/Logger';
import ControlEnv from '../env';

class _Storage {
  set({ name, value }) {
    try {
      Logger.terminalInfo('Storage set:::', name, value);
      if (typeof value === 'string') {
        localStorage.setItem(name, value);
      } else {
        localStorage.setItem(name, JSON.stringify(value));
      }
      return true;
    } catch (error) {
      //Logger.terminalInfo('Storage => set error: ', error);
      return false;
    }
  }

  get({ name }) {
    try {
      if (ControlEnv.getEnv().preview === true) {
        if (['accessToken', 'refreshToken'].includes(name)) {
          return null;
        }
        const value = localStorage.getItem(name);
        return value;
      }

      const value = localStorage.getItem(name);
      return value;
    } catch (error) {
      //Logger.terminalInfo('Storage => get error: ', error);
    }
  }

  remove({ name }) {
    try {
      localStorage.removeItem(name);
    } catch (error) {
      //Logger.terminalInfo('Storage => removeAccessToken error: ', error);
    }
  }
}

export const Storage = new _Storage();
