import './App.css';

import React, { Component } from 'react';
import Builder from './Builder';
import DecorationController from './Controller/Decoration';
import { Provider } from 'react-redux';
import Store from './Redux';
import Controller from './Controller';
import Project from './Project';
import FiliotEditor from './FiliotEditor';
import { ProjectConfigToTreeFile, updateObjectProperty } from './Helper/utils';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { ControlSource } from './Constant/ControlFlow';
import Toolbar from './Toolbar';
import { v4 as uuidv4 } from 'uuid';
import { ViewMode } from './Constant/Preview';
import LeftSideToolBar from './SideToolbar';
import {
  addUIViewOfPageInProject,
  changePageInProject,
  changeUIViewOfPageInProject,
  changeViewRouteInProject,
  deleteUIViewOfPageInProject,
} from './Helper/app';
import { TYPE_WINDOWS } from './Constant/Constant';
import Logger from './Helper/Logger';

function removeKeyWithValueUUID(obj) {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(removeKeyWithValueUUID);
  }

  const newObj = {};
  for (const key in obj) {
    const value = obj[key];
    if (key === 'key' && isUUIDv4(value)) {
      continue;
    }
    newObj[key] = removeKeyWithValueUUID(value);
  }
  return newObj;
}

function isUUIDv4(str) {
  const uuidv4Regex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidv4Regex.test(str);
}

let channel = undefined;

const getPreviewResolution = ({ domWidth, domHeight, viewModeWidth, viewModeHeight }) => {
  Logger.terminalInfo('domWidth', domWidth);
  Logger.terminalInfo('domHeight', domHeight);
  Logger.terminalInfo('viewModeWidth', viewModeWidth);
  Logger.terminalInfo('viewModeHeight', viewModeHeight);

  if (domWidth / domHeight >= viewModeWidth / viewModeHeight) {
    const width = (domHeight * 0.9 * viewModeWidth) / viewModeHeight;
    const height = domHeight * 0.9;
    const resolutionRatio = width / viewModeWidth;
    return {
      height,
      width,
      resolutionRatio,
    };
  } else {
    const width = domWidth * 0.9;
    const height = (domWidth * 0.9) / (viewModeWidth / viewModeHeight);
    const resolutionRatio = width / viewModeWidth;
    return {
      height,
      width,
      resolutionRatio,
    };
  }
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appResource: {},
      project: Project,
      visualPreviewMode: false,
    };
    this.appid = null;
    this.editorid = null;
    this.project = Project;
    this.isPreview = false;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextState) !== JSON.stringify(this.state)) {
      return true;
    }

    if (
      JSON.stringify(nextProps.location?.pathname) === JSON.stringify(this.state.appResource.location?.pathname) &&
      JSON.stringify(nextProps.location?.key) !== JSON.stringify(this.state.appResource.location?.key)
    ) {
      return false;
    }

    return true;
  }

  componentDidMount() {
    this.appid = uuidv4();

    const { location } = this.props;

    const hostname = window.location.hostname;
    const appCode = hostname.split('.')[0];

    const searchParams = Object.fromEntries(new URLSearchParams(location.search).entries());
    const isPreview = searchParams.preview === 'true';
    const editorid = searchParams.editorid;
    if (isPreview) {
      this.isPreview = true;
      this.editorid = editorid;
      Controller.openPreviewMode({ editorid });
    }

    const previewResolution = getPreviewResolution({
      domWidth: document.documentElement.clientWidth - 300,
      domHeight: window.innerHeight,
      viewModeWidth: ViewMode.Desktop.width,
      viewModeHeight: ViewMode.Desktop.height,
    });

    const appResource = {
      isPreview,
      init: false,
      location: {
        pathname: location.pathname,
        search: location.search,
      },
      accessToken: Controller.execute({ serviceName: 'Storage', methodName: 'get', args: { name: 'accessToken' }, source: ControlSource.SYSTEM }),
      refreshToken: Controller.execute({
        serviceName: 'Storage',
        methodName: 'get',
        args: { name: 'refreshToken' },
        source: ControlSource.SYSTEM,
      }),
      appCode,
      // width: document.documentElement.clientWidth,
      // height: window.innerHeight,
      width: isPreview ? previewResolution.width : document.documentElement.clientWidth,
      height: isPreview ? previewResolution.height : window.innerHeight,
      resolutionRatio: isPreview ? previewResolution.resolutionRatio : 1,
      typeWindow: isPreview ? ViewMode.Desktop.type : {},
    };

    this.setAppResource(appResource);

    window.addEventListener('resize', () => {
      const width = document.documentElement.clientWidth;
      const height = window.innerHeight;
      this.setAppResource({
        ...this.state.appResource,
        width,
        height,
      });
    });

    document.addEventListener(
      'keydown',
      (e) => {
        Logger.terminalInfo('e.key', e.key);
        if (e.key === 'Delete') {
          if (this.state.appResource.selectedPreviewView) {
            const uri = this.state.appResource.location.pathname;
            const typeView = this.state.appResource.typeWindow.name;
            const project = deleteUIViewOfPageInProject(this.state.appResource.selectedPreviewView, uri, typeView, this.state.project);
            this.setPreviewProject(project);
          }
        }
      },
      true,
    );

    channel = new BroadcastChannel(appCode + 'abcxyz');
    // Listen for messages
    channel.onmessage = (event) => {
      const data = event.data;
      Logger.terminalInfo('Received message:', event.data);
      if (data?.type === 'update_project') {
        Logger.terminalInfo('data?.editorid', data?.editorid);
        Logger.terminalInfo('this.editorid', this.editorid);
        if (this.isPreview) {
          if (data?.appid === this.editorid) {
            this.setProject(data.value);
          } else {
            Logger.terminalInfo('Not my editor');
          }
        }
      }

      if (data?.type === 'update_url') {
        // setUrl(data.value);
      }

      if (data?.type === 'init_preview') {
        Logger.terminalInfo('init_preview this.project', this.project);
        if (!this.isPreview) {
          channel.postMessage({ type: 'update_project', value: this.state.project, appid: this.appid });
        }
      }

      if (data?.type === 'update_project_visuale_preview') {
        Logger.terminalInfo('init_preview this.project', this.isPreview, data?.editorid, this.appid, data.value);
        if (!this.isPreview) {
          if (data?.editorid === this.appid) {
            channel.postMessage({ type: 'update_project', value: data.value, appid: this.appid });
            this.setProject(data.value);
          }
        }
      }
    };

    channel.onmessageerror = (event) => {
      Logger.terminalInfo('Received message error:', event.data);
    };

    Promise.allSettled([
      Controller.execute({
        serviceName: 'FAWS',
        methodName: 'getConfiguration',
        args: appCode,
        appResource,
        source: ControlSource.SYSTEM,
      }),
      DecorationController.init(),
    ]).then((results) => {
      const project = results[0].status === 'fulfilled' ? results[0].value : null;
      Controller.execute({
        serviceName: 'fRedux',
        methodName: 'updateObjects',
        args: [
          {
            path: 'env',
            data: {
              appCode: appCode,
            },
          },
          {
            path: 'accessToken',
            data: appResource.accessToken,
          },
          {
            path: 'refreshToken',
            data: appResource.refreshToken,
          },
        ],
        appResource,
        source: ControlSource.USER,
      });
      if (process.env.NODE_ENV === 'production' && typeof project !== 'undefined' && project !== null) {
        this.setInitProject(project);
      } else {
        if (process.env.NODE_ENV === 'development') {
          this.setInitProject(Project);
        }
      }
      this.setAppResource({ ...appResource, init: true });

      if (isPreview) {
        channel.postMessage({ type: 'init_preview', value: true });
      }
    });
  }

  setInitProject = (project) => {
    const authRoutes = project?.auth?.main?.pages || [];
    const containerRoutes = project?.container?.main?.pages || [];
    const formatedProject = {
      ...project,
      auth: {
        ...project.auth,
        main: {
          ...project.auth.main,
          pages: authRoutes.map((authRoute) => {
            const view = { ...(authRoute.view ?? {}) };
            try {
              delete authRoute.view;
            } catch (error) {}
            return {
              ...authRoute,
              [TYPE_WINDOWS.LARGE.name]: typeof authRoute[TYPE_WINDOWS.LARGE.name] === 'undefined' ? view : { ...authRoute[TYPE_WINDOWS.LARGE.name] },
              [TYPE_WINDOWS.MEDIUM.name]: typeof authRoute[TYPE_WINDOWS.MEDIUM.name] === 'undefined' ? view : { ...authRoute[TYPE_WINDOWS.MEDIUM.name] },
              [TYPE_WINDOWS.SMALL.name]: typeof authRoute[TYPE_WINDOWS.SMALL.name] === 'undefined' ? view : { ...authRoute[TYPE_WINDOWS.SMALL.name] },
            };
          }),
        },
      },
      container: {
        ...project.container,
        main: {
          ...project.container.main,
          pages: containerRoutes.map((containerRoute) => {
            const view = { ...(containerRoute.view ?? {}) };
            try {
              delete containerRoute.view;
            } catch (error) {}
            return {
              ...containerRoute,
              [TYPE_WINDOWS.LARGE.name]: typeof containerRoute[TYPE_WINDOWS.LARGE.name] === 'undefined' ? view : { ...containerRoute[TYPE_WINDOWS.LARGE.name] },
              [TYPE_WINDOWS.MEDIUM.name]:
                typeof containerRoute[TYPE_WINDOWS.MEDIUM.name] === 'undefined' ? view : { ...containerRoute[TYPE_WINDOWS.MEDIUM.name] },
              [TYPE_WINDOWS.SMALL.name]: typeof containerRoute[TYPE_WINDOWS.SMALL.name] === 'undefined' ? view : { ...containerRoute[TYPE_WINDOWS.SMALL.name] },
            };
          }),
        },
      },
    };
    Logger.terminalInfo('formatedProject', formatedProject);
    this.setProject(formatedProject);
  };

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.location) !== JSON.stringify(this.props.location)) {
      const { location } = this.props;

      this.setAppResource({
        ...this.state.appResource,
        location: {
          pathname: location.pathname,
          search: location.search,
        },
        accessToken: Controller.execute({ serviceName: 'Storage', methodName: 'get', args: { name: 'accessToken' }, source: ControlSource.SYSTEM }),
        refreshToken: Controller.execute({
          serviceName: 'Storage',
          methodName: 'get',
          args: { name: 'refreshToken' },
          source: ControlSource.SYSTEM,
        }),
      });
    }

    if (prevState.appResource.init === true && this.state.appResource.init === false) {
      const hostname = window.location.hostname;
      const appCode = hostname.split('.')[0];
      const { appResource } = this.state;

      Promise.allSettled([
        Controller.execute({
          serviceName: 'FAWS',
          methodName: 'getConfiguration',
          args: appCode,
          appResource,
          source: ControlSource.SYSTEM,
        }),
        DecorationController.init(),
      ]).then((results) => {
        const project = results[0].status === 'fulfilled' ? results[0].value : null;
        Controller.execute({
          serviceName: 'fRedux',
          methodName: 'updateObjects',
          args: [
            {
              path: 'env',
              data: {
                appCode: appCode,
              },
            },
            {
              path: 'accessToken',
              data: appResource.accessToken,
            },
            {
              path: 'refreshToken',
              data: appResource.refreshToken,
            },
          ],
          appResource,
          source: ControlSource.USER,
        });

        if (process.env.NODE_ENV === 'production' && typeof project !== 'undefined' && project !== null) {
          this.setInitProject(project);
        } else {
          if (process.env.NODE_ENV === 'development') {
            this.setInitProject(Project);
          }
        }

        this.setAppResource({ ...appResource, init: true });
      });
    }
  }

  publishProjectConfig = () => {
    const { project } = this.state;
    const hostname = window.location.hostname;
    const appCode = hostname.split('.')[0];
    const { appResource } = this.state;
    Controller.execute({
      serviceName: 'FAWS',
      methodName: 'pushConfiguration',
      args: { appCode, configuration: project },
      appResource,
      source: ControlSource.SYSTEM,
    });

    // Logger.terminalInfo('publishProjectConfig');
  };

  setAppResource = (appResource) => {
    this.setState({ appResource: { ...appResource, width: Math.round(appResource.width), height: Math.round(appResource.height) } });
  };

  setProject = (project) => {
    this.project = project;
    this.setState({ project });
  };

  setPreviewProject = (project) => {
    if (this.isPreview) {
      this.project = project;
      Logger.terminalInfo('setPreviewProject project', project);
      channel?.postMessage({ type: 'update_project_visuale_preview', value: project, editorid: this.editorid });
      this.setState({ project });
    }
  };

  setSelectedView = (selectedView) => {
    if (this.isPreview) {
      this.setAppResource({ ...this.state.appResource, selectedPreviewView: selectedView });
    }
  };

  setVisualPreview = () => {
    this.setState({ visualPreviewMode: !this.state.visualPreviewMode });
  };

  onChangePageInProject = (page, uri, typeView) => {
    const project = changePageInProject(page, uri, typeView, this.state.project);
    this.setPreviewProject(project);
  };

  onChangeViewRouteInProject = (view, uri, typeView) => {
    const project = changeViewRouteInProject(view, uri, typeView, this.state.project);
    this.setPreviewProject(project);
  };

  onAddUIViewOfPageInProject = (UIView, uri, typeView) => {
    const project = addUIViewOfPageInProject(UIView, uri, typeView, this.state.project);
    this.setPreviewProject(project);
  };

  onChangeUIViewOfPageInProject = (UIView, uri, typeView) => {
    const project = changeUIViewOfPageInProject(UIView, uri, typeView, this.state.project);
    this.setPreviewProject(project);
  };

  render() {
    const { project, appResource, visualPreviewMode } = this.state;
    const { location } = this.props;
    if (location.pathname === '/f-admin') {
      Logger.terminalInfo('render f-admin', ProjectConfigToTreeFile(project));
      return (
        <FiliotEditor
          treeFile={removeKeyWithValueUUID(ProjectConfigToTreeFile(project))}
          onChangeValue={(value, currentFile) => {
            const path = currentFile.key.split('.');

            Logger.terminalInfo('FiliotEditor ::: value', value);
            const newProject = updateObjectProperty(project, path, value);
            channel?.postMessage({ type: 'update_project', value: newProject, appid: this.appid });
            this.setProject(newProject);
          }}
          onPublish={this.publishProjectConfig}
          editorid={this.appid}
        />
      );
    }

    Logger.terminalInfo('resourceresourceresource', appResource);

    if (appResource.isPreview === true) {
      const App = Builder.build(project, {
        appResource: {
          ...appResource,
          resolutionRatio: appResource.resolutionRatio ?? 1,
          visualPreviewMode,
          onChangePageInProject: this.onChangePageInProject,
          onChangeViewRouteInProject: this.onChangeViewRouteInProject,
          setSelectedView: this.setSelectedView,
        },
      });

      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Toolbar
            appResource={appResource}
            visualPreviewMode={visualPreviewMode}
            setVisualPreview={this.setVisualPreview}
            setResolution={(resolution) => {
              const previewResolution = getPreviewResolution({
                domWidth: document.documentElement.clientWidth,
                domHeight: window.innerHeight,
                viewModeWidth: resolution.width,
                viewModeHeight: resolution.height,
              });
              this.setAppResource({
                ...appResource,
                width: previewResolution.width,
                height: previewResolution.height,
                resolutionRatio: previewResolution.resolutionRatio,
                typeWindow: resolution.type,
              });
            }}
            onChangeAppResource={(appResource) => {
              Logger.terminalInfo('setResourceeeeeeeeeeeeee', appResource);
              this.setAppResource({ ...appResource, init: false });
            }}
          />
          <div
            style={{
              display: 'flex',
              width: '100%',
            }}>
            <LeftSideToolBar
              onAddUIViewOfPageInProject={this.onAddUIViewOfPageInProject}
              onChangeUIViewOfPageInProject={this.onChangeUIViewOfPageInProject}
              setSelectedView={this.setSelectedView}
              appResource={appResource}
              visualPreviewMode={this.state.visualPreviewMode}
              project={project}
            />
            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                marginTop: '50px',
                alignItems: 'center',
              }}>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'center',
                  width: appResource.width + 4,
                  height: appResource.height + 4,
                  border: '2px solid #bfbfbf',
                  borderRadius: '8px',
                  boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
                }}>
                <Provider store={Store}>{React.isValidElement(App) ? App : <div>Not work</div>}</Provider>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      const App = Builder.build(project, { appResource: { ...appResource, resolutionRatio: appResource.resolutionRatio ?? 1 } });

      return (
        <div
          style={{
            flexDirection: 'row',
            flex: 1,
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
          }}>
          <Provider store={Store}>{React.isValidElement(App) ? App : <div>Not work</div>}</Provider>{' '}
        </div>
      );
    }
  }
}

export default withRouter(App);
