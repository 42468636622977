export const Family = {
    air_conditioning: '001',
    water_heater: '002',
    refridgerator: '003',
    washing_machine: '004',
    fan: '005',
    motor: '006',
    clean: '007',
    HVAC: '008',
    bathroom: '009',
    kitchen: '00a',
    luminance: '00b',
    temperature_sensor: '00c',
    humidity_sensor: '00d',
    rain_sensor: '00e',
    gas_sensor: '00f',
    pressure_sensor: '010',
    smoke_sensor: '011',
    water_leak_sensor: '012',
    PM25_sensor: '013',
    CO_sensor: '014',
    CO2_sensor: '015',
    CH4_sensor: '016',
    occupancy_sensor: '017',
    motion_sensor: '018',
    door_sensor: '019',
    contact_sensor: '01a',
    multi_function_sensor: '01b',
    rgb: '01c',
    white_light: '01d',
    dimmer: '01e',
    lighting_remote: '01f',
    push_button: '020',
    socket: '021',
    power_strip: '022',
    switch: '023',
    hc: '024',
    router: '025',
    tv: '026',
    speaker: '027',
    sports: '028',
    wearable: '029',
    sleep: '02a',
    personal_care: '02b',
    smart_electric_meter: '02c',
    smart_water_meter: '02d',
    breaker: '02e',
    video_surveilliance: '02f',
    smart_lock: '030',
    safe_box: '031',
    mini_lock_accessory: '032',
    surroundings: '033',
    life: '034',
    microwave: '035',
    gas_stove: '036',
    juicer: '037',
    blender: '038',
    induction_cooktop: '039',
    coffee_maker: '03a',
    smart_kettle: '03b',
    dishwasher: '03c',
    rice_cooker: '03d',
    water_purifier: '03e',
    bread_maker: '03f',
};

export const Category = {
    large_appliances: '001',
    small_appliances: '002',
    sensors: '003',
    lighting: '004',
    gateway: '005',
    security_surveilliance: '006',
    energy: '007',
    entertainment: '008',
    electrical: '009',
    industry_agriculture: '00a',
    exercise_health: '00b',
    kitchen: '00c',
};

export const Connection = {
    eth: '01',
    eth_wifi: '02',
    ble: '03',
    wifi: '04',
    ble_wifi: '05',
    zigbee: '06',
    zwave: '07',
    knx: '08',
    mobile_wave: '09',
};
