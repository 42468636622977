import Builder from '..';
import * as ExeBlock from '../ExeBlock';

export const build = (IfElseConfig, resource) => {
  const { trueBranch, falseBranch, test } = IfElseConfig;
  const testValue = Builder.build(test, resource);

  if (testValue === true) {
    if (trueBranch && Array.isArray(trueBranch)) {
      if (typeof resource === 'undefined') {
        resource = {};
      }

      return Builder.build(
        {
          type: ExeBlock.type,
          trueBranch,
        },
        resource,
      );
    } else if (trueBranch && trueBranch.type === ExeBlock.type) {
      if (typeof resource === 'undefined') {
        resource = {};
      }
      return Builder.build(
        {
          type: ExeBlock.type,
          trueBranch,
        },
        resource,
      );
    }
  } else {
    return Builder.build(falseBranch, resource);
  }
};

export const type = 'IfElse';
