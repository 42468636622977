import Builder from '..';

export const build = (FindInArrayExpressionConfig, resource) => {
  const array = Builder.build(FindInArrayExpressionConfig.array, resource);
  if (Array.isArray(array)) {
    const findElement = array.find((element, index) => {
      const checkFunction = Builder.build(FindInArrayExpressionConfig.checkFunction, resource);
      return checkFunction(element, index, array) === true;
    });
    return findElement ?? null;
  } else {
    return null;
  }
};

export const type = 'FindInArrayExpression';
