import { Family } from '../Constant/DeviceResource';
import { getDeviceFamilyByDeviceType, getDeviceConnectionByDeviceType } from './deviceresource';
import { Connection } from '../Constant/DeviceResource';

const Common = {
    warning: 'warning',
    timer: 'timer',
    updateFirmware: 'update_firmware',
    wifi: 'wifi',
};

const Button = {
    button_disable: 'button_disable',
    relay_action_init: 'relay_action_init',
};

const ElectricMetter = {
    meter_reading_date: 'meter_reading_date',
};

const AirCondition = {
    timer_hcl: 'timer_hcl',
};

const Attributes = { ...Button, ...Common, ...ElectricMetter, ...AirCondition };

export const isSubDevice = (deviceId) => {
    if (deviceId && deviceId !== undefined && deviceId.split('_').length === 5) {
        return true;
    }
    return false;
};

export const isHub = (deviceId) => {
    if (!isSubDevice(deviceId)) {
        if (deviceId.split('_')[1]?.slice(5, 8) === '024') {
            return true;
        }
    }
    return false;
};

export const getParentId = (deviceId) => {
    if (isSubDevice(deviceId)) {
        //Logger.terminalInfo(deviceId);
        let word = deviceId.split('_');
        return word[0] + '_' + word[1] + '_' + word[2];
    }
    return null;
};

export const getListSubdvOfHC = (hcId, devicesInHome) => {
    let listSubdv = [];
    if (devicesInHome.length) {
        devicesInHome.map((item) => {
            if (item.originId.includes(hcId)) {
                listSubdv.push(item.originId);
            }
        });
    }

    return listSubdv;
};

export const mockSetting = (originId) => {
    let deviceType;
    if (isSubDevice(originId)) {
        deviceType = originId.split('_')[3].toString();
    } else {
        deviceType = originId.split('_')[1].toString();
    }
    let deviceFamily = getDeviceFamilyByDeviceType(deviceType);
    let deviceConnection = getDeviceConnectionByDeviceType(deviceType);
    let attributes = {};
    switch (deviceFamily) {
        case Family.water_purifier:
            attributes[Attributes.warning] = {};
            break;
        case Family.smart_electric_meter:
            attributes[Attributes.button_disable] = 0;
            attributes[Attributes.warning] = {};
            attributes[Attributes.relay_action_init] = 0;
            attributes[Attributes.meter_reading_date] = 1;

            break;
        case Family.white_light:
            break;
        case Family.rgb:
            break;
        case Family.switch:
            break;
        case Family.fan:
            break;
        case Family.air_conditioning:
            break;
        case Family.motor:
            break;
        case Family.dimmer:
            break;
        case Family.tv:
            break;
        default:
            break;
    }
    switch (deviceConnection) {
        case Connection.ble_wifi:
        case Connection.eth_wifi:
        case Connection.wifi:
            attributes[Attributes.wifi] = {};
    }
    attributes[Attributes.updateFirmware] = {};
    return { data: { setting: attributes } };
};

export const mockAutomation = (originId) => {
    let deviceType;
    if (isSubDevice(originId)) {
        deviceType = originId.split('_')[3].toString();
    } else {
        deviceType = originId.split('_')[1].toString();
    }
    let deviceFamily = getDeviceFamilyByDeviceType(deviceType);
    let deviceConnection = getDeviceConnectionByDeviceType(deviceType);
    let automation = {};
    switch (deviceFamily) {
        case Family.water_purifier:
            break;
        case Family.smart_electric_meter:
            automation.rules = {
                timer: [],
            };
            break;
        case Family.white_light:
            break;
        case Family.rgb:
            break;
        case Family.switch:
            break;
        case Family.fan:
            break;
        case Family.air_conditioning:
            break;
        case Family.motor:
            break;
        case Family.dimmer:
            break;
        case Family.tv:
            break;
        default:
            break;
    }
    switch (deviceConnection) {
        case Connection.ble_wifi:
        case Connection.eth_wifi:
        case Connection.wifi:
    }
    return { data: { automation } };
};
