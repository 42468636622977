import Warning from '../Attributes/Warning/warning_purifier.json';
import UpdateFirmware from '../Attributes/update_firmware.json';
import Wifi from '../Attributes/Wifi/wifi_old.json';
import externalData from './externalData';

const config = {
    externalData,
    attributes: [Wifi, Warning, UpdateFirmware],
    version: 'v0.0.1',
};

export default config;
