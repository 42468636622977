import Logger from "../../../../../Logger";

const defaultComponent = (
  data = {
    label: 'attribute 1',
    parameter: 'A1',
    value: 1,
    type: 'number',
    min: 0, // option
    max: 10, // option
    marginHorizontal: 0,
  },
) => {
  Logger.terminalInfo('datadatadatadata', data);
  return {
    type: 'TouchModal',
    children: [],
    props: {
      onChangeValue: [
        {
          functionName: 'onChangeState',
          args: [[`ex_att_${data?.parameter}`, 'par'], `${data?.parameter}`],
        },
        {
          functionName: 'onChangeState',
          args: [[`ex_att_${data?.parameter}`, 'val']],
        },
        // {
        //     functionName: 'onChangeState',
        //     args: [[`ex_att_${data?.parameter}`, `${data?.parameter}`]],
        // },
        {
          functionName: 'onSaveSetting',
          args: [[`ex_att_${data?.parameter}`]],
        },
      ],
      min: data.min || 0,
      max: data.max || 100,
      type: data.type ?? 'string',
      edit: data.edit ?? true,
      value: data.value ?? 0,
      title: {
        content: data.label || 'Attribute name',
        style: { container: { marginVertical: 2, marginHorizontal: data.marginHorizontal } },
      },
      ...data,
    },
  };
};

export default defaultComponent;
