import Logger from '../../Helper/Logger';
import fAPI from '../fAPI';
import { BASE_URL, API } from '../fAPI/constants';
import { fSpace } from '../fSpace';

const BASE_URI = BASE_URL.prod;

/**
 * @param { email: string }: email of user.
 * @param { password: string }: password of user.
 * @param { appCode: string }: appCode of app.
 * @param { fcmToken: string }: firebase token of user.
 * @param { deviceUID: string }: deviceUID of app.
 * @param { os: string }: os of app.
 * @param { callback: json }: callback of function.
 * @param { callback.success: function }: function will call when sign in success.
 * @param { callback.fail: function }: function will call when sign in fail.
 */

async function getInvitations({
  userId,
  page,
  page_size,
  id,
  spaceId,
  status,
  token,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.INVITATION.GET_INVITATIONS}`;
    Logger.terminalInfo('uri', uri);
    const response = await fAPI.get(
      uri,
      {
        Authorization: token,
        'x-user': userId,
      },
      {
        page,
        page_size,
        id,
        spaceId,
        status,
      },
    );

    typeof callback?.success === 'function' && callback.success(response);

    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

async function getAllInvitations({
  userId,
  page,
  page_size,
  id,
  spaceId,
  status,
  token,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    fSpace.getSpaces({
      token,
      callback: {
        success: (response) => {
          if (Array.isArray(response.items)) {
            Promise.all(
              response.items.map((space) => {
                const uri = `${BASE_URI}${API.INVITATION.GET_INVITATIONS}`;
                return fAPI.get(
                  uri,
                  {
                    Authorization: token,
                    'x-user': userId,
                  },
                  {
                    page,
                    page_size,
                    id,
                    spaceId,
                    status,
                  },
                );
              }),
            ).then((invitationResponses) => {
              Logger.terminalInfo('invitationResponseinvitationResponseinvitationResponseinvitationResponse', invitationResponses);
              typeof callback?.success === 'function' &&
                callback.success({ items: invitationResponses.map((invitationResponse) => invitationResponse.items).flat() });
            });
          }
        },
      },
    });
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

async function createInvitation({
  userId,
  spaceId,
  memberEmail,
  appCode,
  message,
  token,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.INVITATION.CREATE_INVITATION}`;
    Logger.terminalInfo('uri', uri);
    const response = await fAPI.post(
      uri,
      {
        Authorization: token,
        'x-user': userId,
      },
      {
        memberEmail,
        spaceId,
        message,
        appCode,
      },
    );

    typeof callback?.success === 'function' && callback.success(response);

    return { status: true, result: response };
  } catch (error) {
    let message;
    if (error.errors[0].errorCode == '06044600') {
      message = 'Vui lòng nhập email thành viên!';
    } else {
      if (error.errors[0].errorCode == '06043700') {
        message = 'Lời mời đã tồn tại!';
      }
    }
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, message: message });
    return { status: false, message: message };
  }
}

async function updateInvitation({
  userId,
  invitationId,
  status,
  token,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.INVITATION.UPDATE_INVITATION(invitationId)}`;
    Logger.terminalInfo('uri', uri);
    const response = await fAPI.put(
      uri,
      {
        Authorization: token,
        'x-user': userId,
      },
      {
        status,
      },
    );

    typeof callback?.success === 'function' && callback.success(response);

    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

async function deleteInvitation({
  userId,
  invitationId,
  token,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.INVITATION.DELETE_INVITATION(invitationId)}`;
    Logger.terminalInfo('uri', uri);
    const response = await fAPI.post(
      uri,
      {
        Authorization: token,
        'x-user': userId,
      },
      {},
    );

    typeof callback?.success === 'function' && callback.success(response);

    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

export const fInvitation = {
  getInvitations,
  createInvitation,
  updateInvitation,
  deleteInvitation,
  getAllInvitations,
};
