import BaseComponent from '../BaseComponent/BaseComponent';
import Select, { defaultTheme } from 'react-select';

export default class Selects extends BaseComponent {
  renderComponent = () => {
    const { style, items, value, placeholder_color } = this.props;
    let _items = undefined;
    let _value = undefined;

    if (value === undefined || value === null) {
      _items = items;
      _value = null;
    } else {
      if (typeof items?.find((item) => item.label === value.label && item.value === value.value) === 'undefined') {
        _value = items?.[0] || undefined;
        _items = items;
      } else {
        _items = items;
        _value = value;
      }
    }

    return (
      <Select
        {...this.restProps}
        options={_items}
        isMulti={this.props.isMulti}
        placeholder={this.props.placeholder}
        isClearable={this.props.isClearable ?? true}
        isSearchable={this.props.searchAble}
        theme={(theme) => ({
          ...defaultTheme,
          ...theme,
        })}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            ...style,
            height: '100%',
            '&:hover': { borderColor: null },
          }),
          container: (baseStyles, state) => ({
            ...baseStyles,
            ...style,
            height: '100%',
          }),
          placeholder: (baseStyles, state) => ({
            ...baseStyles,
            color: placeholder_color,
          }),
          menuList: (baseStyles, state) => ({
            ...baseStyles,
            ...style,
          }),
          indicatorSeparator: () => ({
            display: 'none',
          }),
        }}
        value={_value}
        onChange={(newValue) => {
          if (newValue === null) {
            typeof this.props.onClear === 'function' && this.props.onClear();
          } else {
            typeof this.props.onChange === 'function' && this.props.onChange(newValue);
          }
        }}
      />
    );
  };
}
