import _ from 'lodash';
import { validateTextMarkdown } from './TextMarkdown/validate';
import { ingoreTextMarkdownName } from '../Constant/ingore';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

// Module
import * as App from './UI/App';
import * as FiliotComponent from './FiliotComponent';
import * as TextMarkdown from './TextMarkdown';
import * as Function from './Function';
import * as Callee from './Callee';
import * as Condition from './Condition';
import ConditionComponent from './UI/Components/ConditionComponent';
import * as Switch from './Switch';
// import * as CloudView from './UI/Components/CloudView';
import * as MappingExpression from './MappingExpression';
import * as VariableDeclaration from './VariableDeclaration';
import * as ReturnStatement from './ReturnStatement';
import * as Return from './Return';
import * as BinaryExpression from './BinaryExpression';
import * as ConcatArray from './ConcatArray';
import * as FindInArrayExpression from './FindInArrayExpression';
import * as FindIndexInArrayExpression from './FindIndexInArrayExpression';
import * as SpreadObject from './SpreadObject';
import * as ArrayLength from './ArrayLength';
import * as Define from './Define';
import * as ExeBlock from './ExeBlock';
import * as IfElse from './IfElse';
import * as UnaryExpression from './UnaryExpression';
import * as ConditionExpression from './ConditionExpression';
import * as FilterExpression from './FilterExpression';
import * as ForEachExpression from './ForEachExpression';
import * as SwitchExpression from './SwitchExpression';
import * as ObjectKeys from './ObjectKeys';
import * as Assign from './Assign';
import * as Date from './Date';
import * as ValidateEmail from './ValidateEmail';
import * as SetTimeOut from './SetTimeOut';
import * as ClearTimeOut from './ClearTimeOut';
import * as SetInterval from './SetInterval';
import * as ClearInterval from './ClearInterval';

// Module UI
import View from './UI/Components/View';
import Area from './UI/Components/Area';
import Image from './UI/Components/Image';
import SwitchComponent from './UI/Components/SwitchComponent';
import List from './UI/Components/List';
import TextInput from './UI/Components/TextInput';
import * as Page from './UI/Components/Page';
import VerticalBarChart from './UI/Components/VerticalBarChart';
import * as RouteManager from './UI/RouteManager';
import * as Route from './UI/Route';
import Select from './UI/Components/Select';
import Table from './UI/Components/Table';
import Input from './UI/Components/Input';
import Carousel from './UI/Components/Carousel';
import LineChart from './UI/Components/LineChart';
import PieChart from './UI/Components/PieChart';
import Text from './UI/Components/Text';
import Modal from './UI/Components/Modal';
import Switch2 from './UI/Components/Switch';
import ReactNativeListAttribute from './UI/Components/ReactNativeListAttribute';
import Button from './UI/Components/Button';
import Pagination from './UI/Components/Pagination';

class Builder {
  constructor() {
    this.builders = {};
    this.types = [];
    this.init();
    this.initUIModule();
    this.buildings = {};
  }

  initUIModule() {
    this.push(App);
    this.push(new SwitchComponent());
    this.push(new View());
    this.push(new Area());
    this.push(new Image());
    this.push(new List());
    this.push(new TextInput());
    this.push({ ...Page, isUI: true });
    this.push(new VerticalBarChart());
    this.push({ ...RouteManager, isUI: true });
    this.push({ ...Route, isUI: true });
    this.push(new Select());
    this.push(new Table());
    this.push(new Input());
    this.push(new Text());
    this.push(new LineChart());
    this.push(new PieChart());
    this.push(new Modal());
    this.push(new ConditionComponent());
    this.push(new Carousel());
    this.push(new Switch2());
    this.push(new ReactNativeListAttribute());
    this.push(new Button());
    this.push(new Pagination());
  }

  init() {
    // Logger.terminalInfo('const ', typeof new Area().build);
    this.push({ ...App, isUI: true });
    this.push({ ...FiliotComponent, isUI: true });
    this.push(TextMarkdown);
    this.push(Function);
    this.push(Callee);
    this.push(Condition);
    this.push(Switch);
    // this.push(CloudView);
    this.push(Map);
    this.push(MappingExpression);
    this.push(VariableDeclaration);
    this.push(MappingExpression);
    this.push(ReturnStatement);
    this.push(BinaryExpression);
    this.push(UnaryExpression);
    this.push(ConditionExpression);
    this.push(FilterExpression);
    this.push(ForEachExpression);
    this.push(ConcatArray);
    this.push(FindInArrayExpression);
    this.push(ArrayLength);
    this.push(SpreadObject);
    this.push(Return);
    this.push(FindIndexInArrayExpression);
    this.push(Define);
    this.push(ExeBlock);
    this.push(IfElse);
    this.push(SwitchExpression);
    this.push(ObjectKeys);
    this.push(Assign);
    this.push(Date);
    this.push(ValidateEmail);
    this.push(SetTimeOut);
    this.push(ClearTimeOut);
    // this.push(SetInterval);
    // this.push(ClearInterval);
  }

  push(buildModule) {
    const type = buildModule?.type || buildModule?.getType?.();
    if (typeof type === 'string' && typeof buildModule.build === 'function') {
      this.builders[type] = {
        type,
        build: buildModule.build,
        isUI: buildModule.isUI ?? false,
        schema: buildModule.isUI ? buildModule.getSchema?.() : {},
      };
      this.types.push(type);
    }
  }

  build(configuration, _resource, parentIsRouter = false) {
    if (configuration === null) {
      return null;
    }

    if (React.isValidElement(configuration)) {
      return configuration;
    }

    if (typeof configuration === 'string' && validateTextMarkdown(configuration)) {
      const str = configuration;
      configuration = {
        type: TextMarkdown.type,
        data: str,
      };
    }

    if (
      typeof configuration !== 'undefined' &&
      configuration !== null &&
      _.isObject(configuration) &&
      !Array.isArray(configuration) &&
      typeof configuration.key === 'undefined' &&
      typeof configuration.type !== 'undefined' &&
      this.builders[configuration.type]?.isUI === true
    ) {
      configuration.key = uuidv4();
    }

    // Logger.terminalInfo('------------- BUILDING -------------');
    // Logger.terminalInfo('configuration', configuration);
    // Logger.terminalInfo('configuration type', configuration?.type);
    // Logger.terminalInfo('_resource', _resource);
    // Logger.terminalInfo('\n');

    if (typeof configuration !== 'undefined' && typeof configuration.type === 'string' && typeof this.builders[configuration.type] !== 'undefined') {
      if (configuration.type === Page.type) {
        if (parentIsRouter === true) {
          this.buildings[configuration.key] = this.builders[configuration.type].build(configuration, _resource);
          return this.buildings[configuration.key];
        } else {
          this.buildings[configuration.key] = <></>;
          return this.buildings[configuration.key];
        }
      }

      this.buildings[configuration.key] = this.builders[configuration.type]?.build(configuration, _resource);
      return this.buildings[configuration.key];
    }

    return configuration;
  }

  traverseBuild(configuration, resource) {
    if (
      typeof configuration !== 'undefined' &&
      configuration !== null &&
      _.isObject(configuration) &&
      !Array.isArray(configuration) &&
      typeof configuration.key === 'undefined' &&
      typeof configuration.type !== 'undefined' &&
      this.builders[configuration.type]?.isUI === true
    ) {
      configuration.key = uuidv4();
    }

    const type = configuration?.type;
    switch (true) {
      case configuration === null:
        return null;
      case configuration === undefined:
        return undefined;
      case React.isValidElement(configuration):
        return configuration;
      case typeof configuration.type !== 'undefined' && this.types.includes(type):
        return this.build(configuration, resource);
      case typeof configuration == 'string' && validateTextMarkdown(configuration):
        const str = configuration;
        configuration = {
          type: TextMarkdown.type,
          data: str,
        };
        return this.build(configuration, resource);
      case Array.isArray(configuration):
        return configuration.map((_configuration) => this.traverseBuild(_configuration, resource));
      case _.isObject(configuration):
        return Object.fromEntries(Object.keys(configuration).map((key) => [key, this.traverseBuild(configuration[key], resource)]));

      default:
        return configuration;
    }
  }

  traverseBuildTextMarkdown(configuration, resource) {
    if (
      typeof configuration !== 'undefined' &&
      configuration !== null &&
      _.isObject(configuration) &&
      !Array.isArray(configuration) &&
      typeof configuration.key === 'undefined' &&
      typeof configuration.type !== 'undefined' &&
      this.builders[configuration.type]?.isUI === true
    ) {
      configuration.key = uuidv4();
    }

    switch (true) {
      case configuration === null:
        return null;
      case configuration === undefined:
        return undefined;
      case React.isValidElement(configuration):
        return configuration;
      case typeof configuration.type == 'string' && (configuration.type === Function.type || configuration.type === FiliotComponent.type):
        return configuration;

      case typeof configuration == 'string' && validateTextMarkdown(configuration):
        const str = configuration;
        if (ingoreTextMarkdownName.includes(str.slice(2, str.length - 2).split('.')[0])) {
          return configuration;
        }
        configuration = {
          type: TextMarkdown.type,
          data: str,
        };
        return this.build(configuration, resource);
      case Array.isArray(configuration):
        return configuration.map((_configuration) => this.traverseBuildTextMarkdown(_configuration, resource));
      case _.isObject(configuration):
        return Object.fromEntries(Object.keys(configuration).map((key) => [key, this.traverseBuildTextMarkdown(configuration[key], resource)]));

      default:
        return configuration;
    }
  }
}

export default new Builder();
