import Select from './Select';
import Wifi from './ConfigWifi';
import Calendar from './Calendar';
import UpdateFirmware from './UpdateFirmware';
import WarningItem from './Warning/warningItem';
import FlatList from './FlatList';
// import TimerHCL from './TimerHCL';
import Modal from './Modal';
import Switch from './Switch';
import TouchableOpacityModal from './TouchModal';
import ValueNumberSlider from './ValueNumberSlider';
import QRCode from './QR';
import InfoItem from './InfoItem';
import HomeKit from './HomeKit';
import AirConditionTimerMosbus from './AirConditionTimerMosbus';
import AirConditionTimerMosbusGateway from './AirConditionTimerMosbusGateway';
import AirConditionLimitTimeUseMobus from './AirConditionLimitTimeUseMobus';
import * as ReactBaseComponent from '../React-Native/types';
import Store from '../FixErrorComponent/Redux';
import { Provider } from 'react-redux';

const components = {
  ...ReactBaseComponent,
  // Text: Text,
  // Fragment: Fragment,
  // TimerComponent: TimerComponent,
  // Timer: Timer,
  // WifiComponent: WifiComponent,
  Wifi: (props) => (
    <Provider store={Store}>
      <Wifi {...props} />
    </Provider>
  ),
  Calendar: (props) => (
    <Provider store={Store}>
      <Calendar {...props} />
    </Provider>
  ),
  UpdateFirmware: (props) => (
    <Provider store={Store}>
      <UpdateFirmware {...props} />
    </Provider>
  ),
  WarningItem: (props) => (
    <Provider store={Store}>
      <WarningItem {...props} />
    </Provider>
  ),
  Select: (props) => (
    <Provider store={Store}>
      <Select {...props} />
    </Provider>
  ),
  FlatList: (props) => (
    <Provider store={Store}>
      <FlatList {...props} />
    </Provider>
  ),
  TimerHCL: (props) => (
    <Provider store={Store}>
      <div>Timer HCL</div>
      {/* <TimerHCL {...props} /> */}
    </Provider>
  ),
  Modal: (props) => (
    <Provider store={Store}>
      <Modal {...props} />
    </Provider>
  ),
  Switch: (props) => (
    <Provider store={Store}>
      <Switch {...props} />
    </Provider>
  ),
  TouchModal: (props) => (
    <Provider store={Store}>
      <TouchableOpacityModal {...props} />
    </Provider>
  ),
  ValueNumberSlider: (props) => (
    <Provider store={Store}>
      <ValueNumberSlider {...props} />
    </Provider>
  ),
  QRCode: (props) => (
    <Provider store={Store}>
      <QRCode {...props} />
    </Provider>
  ),
  InfoItem: (props) => (
    <Provider store={Store}>
      <InfoItem {...props} />
    </Provider>
  ),
  HomeKit: (props) => (
    <Provider store={Store}>
      <HomeKit {...props} />
    </Provider>
  ),
  AirConditionTimerMosbus: (props) => (
    <Provider store={Store}>
      <AirConditionTimerMosbus {...props} />
    </Provider>
  ),
  AirConditionLimitTimeUseMobus: (props) => (
    <Provider store={Store}>
      <AirConditionLimitTimeUseMobus {...props} />
    </Provider>
  ),
  AirConditionTimerMosbusGateway: (props) => (
    <Provider store={Store}>
      <AirConditionTimerMosbusGateway {...props} />
    </Provider>
  ),
};

export default components;
