export const WIDTH_LARGE_DEFAULT_MIN = 1000;
export const WIDTH_LARGE_DEFAULT_MAX = 1920 + (1920 * 2) / 16;
export const WIDTH_LARGE_DEFAULT = 1920;
export const HEIGHT_LARGE_DEFAULT = 80;

export const WIDTH_MEDIUM_DEFAULT_MIN = 600;
export const WIDTH_MEDIUM_DEFAULT_MAX = 1366;
export const WIDTH_MEDIUM_DEFAULT = 820;
export const HEIGHT_MEDIUM_DEFAULT = 80;

export const WIDTH_SMALL_DEFAULT_MIN = 292.5;
export const WIDTH_SMALL_DEFAULT_MAX = 780;
export const WIDTH_SMALL_DEFAULT = 390;
export const HEIGHT_SMALL_DEFAULT = 80;

export const LARGE_RATIO_MIN = 22;
export const LARGE_RATIO_MAX = 26;

export const MEDIUM_RATIO_MIN = 10;
export const MEDIUM_RATIO_MAX = 14;

export const SMALL_RATIO_MIN = 3.5;
export const SMALL_RATIO_MAX = 8;
export const SMALL_RATIO = 4;

export const LARGE_COL = 1200;
export const LARGE_ROW = 50;
export const MEDIUM_COL = 500;
export const MEDIUM_ROW = 50;
export const SMALL_COL = 250;
export const SMALL_ROW = 50;