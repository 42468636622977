const theme = {
    type: 'dark',
    fonts: {
        fontLight: 'RobotoLight',
        fontRegular: 'RobotoRegular',
        fontMedium: 'RobotoMedium',
        fontBold: 'RobotoBold',
    },
    colors: {
        appColor: '#000',
    },
    icons: {},
};

export default theme;
