import React from 'react';
import BaseComponent from '../BaseComponent/BaseComponent';
import Builder from '../../..';

class Area extends BaseComponent {
  renderComponent(x, y, width, height) {
    const resource = this.props.resource;
    const area = this.props.area ?? [];
    const style = this.style || {};

    return (
      <div
        {...this.restProps}
        onClick={() => {
          this.props.onClick?.();
        }}
        style={{
          ...style,
          // height: (heightBase * height) / this.row,
          // width: (widthBase * width) / this.col,
          width: '100%',
          height: '100%',
          position: 'relative',
          overflow: 'auto',
        }}>
        {area.map((item, index) => {
          const FiliotJSXElement = Builder.build(item, {
            ...resource,
            appResource: {
              ...resource.appResource,
            },
          });

          if (React.isValidElement(FiliotJSXElement)) {
            return FiliotJSXElement;
          } else {
            return <></>;
          }
        })}
      </div>
    );

    // return (
    //   <FilArea
    //     {...this.restProps}
    //     style={{
    //       height: (heightBase * height) / this.row,
    //       width: (widthBase * width) / this.col,
    //     }}>
    //     {area.map((item, index) => {
    //       const FiliotJSXElement = Builder.build(item, {
    //         ...resource,
    //         ...this.props,
    //         widthBase: this.widthBase,
    //         heightBase: this.heightBase,
    //       });

    //       if (React.isValidElement(FiliotJSXElement)) {
    //         return FiliotJSXElement;
    //       } else {
    //         return <></>;
    //       }
    //     })}
    //   </FilArea>
    // );
  }
}

export default Area;
