import { connect } from 'react-redux';
import Page from './Page';

// const PageConnectedComponent = connect(
//   (state) => {
//     return {
//       reduxState: state,
//     };
//   },
//   {},
//   (stateProps, dispatchProps, ownProps) => {
//     return {

//       ...ownProps, // Include ownProps
//       ...stateProps, // Include stateProps
//       ...dispatchProps, // Include dispatchProps as actions
//     };
//   },
// )(Page);

export const build = (PageConfig, resource) => {
  return <Page resource={resource} PageConfig={PageConfig} />;
};

export const type = 'page';
