export const WarningTypes = {
    voltage: 'voltage',
    current: 'current',
    power: 'power',
    tds: 'tds',
    water_in: 'water_in',
    filter: 'filter',
};

export const WarningRange = {
    [WarningTypes.voltage]: {
        min: 150,
        max: 265,
    },
    [WarningTypes.current]: {
        min: 1,
        max: 60,
    },
    [WarningTypes.power]: {
        min: 1,
        max: 10000,
    },
    [WarningTypes.tds]: {
        min: 40,
        max: 150,
    },
};

export const WarningConditions = {
    low: 'low',
    high: 'high',
    on_off: 'on_off',
};

export const WarningActions = {
    warning: 'warning',
    active_protect: 'active_protect',
};
