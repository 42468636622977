import Builder from "..";

const operators = {
  "-": "-",
  "+": "+",
  "!": "!",
  "~": "~",
  typeof: '"typeof"',
  void: "void",
  delete: "delete",
  throw: "throw",
};

export const build = (UnaryExpressionConfig, resource) => {
  const { args, operator } = UnaryExpressionConfig;
  const argsValue = Builder.traverseBuild(args, resource);
  switch (operator) {
    case operators["-"]:
      return -argsValue;
    case operators["+"]:
      return +argsValue;
    case operators["!"]:
      return !argsValue;
    case operators["~"]:
      return ~argsValue;
    case operators["typeof"]:
      return typeof argsValue;
    case operators["void"]:
    case operators["delete"]:
    case operators["throw"]:
    default:
      return "unknown";
  }
};

export const type = "UnaryExpression";
