import { Button, Collapse, Form, Input, Table } from 'antd';
import React, { Component, useEffect, useRef, useState } from 'react';
import { Tooltip, Select } from 'antd';
import Builder from '../Builder';
import { UI_COMPONENTS_TYPE_FIELD } from '../Constant/Constant';
import { Editor } from '@monaco-editor/react';
import { isJson } from '../Helper/utils';
import AddImage from './Resource/Image/add.png';
import { formatCode } from '../Helper/prettier';
import { Checkbox } from 'antd';
import Logger from '../Helper/Logger';

const { Panel } = Collapse;

const customPanelStyle = {
  background: 'rgba(0, 0, 0, 0.02)',
  borderRadius: 4,
  border: 0,
  overflow: 'hidden',
};

const CustomHeader = (props) => {
  const { schemaOfView, view } = props;
  const tooltipRef = useRef(null);

  const restKeys = typeof schemaOfView === 'object' ? Object.keys(schemaOfView).filter((key) => !Object.keys(view).includes(key)) : [];

  const [form] = Form.useForm();

  const [key, setKey] = useState(restKeys?.[0]);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleFinish = (values) => {
    props.onChangeSelectedView({ key: values['newKey'], value: values['newValue'], type: schemaOfView[values['newKey']] });
    setTooltipOpen(false);
  };

  useEffect(() => {
    form.setFieldsValue({
      newKey: restKeys?.[0],
      value: undefined,
      type: undefined,
    });
  }, [view]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setTooltipOpen(false); // Close the Tooltip if clicked outside of it
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} ref={tooltipRef}>
      <span>Selected View</span>

      <Tooltip
        trigger={'click'}
        color='rgb(26, 26, 26)'
        placement={'bottom'}
        open={tooltipOpen}
        title={
          <Form
            form={form}
            onFinish={handleFinish}
            initialValues={{
              newKey: restKeys?.[0],
            }}
            onClick={(e) => e.stopPropagation()}>
            <Form.Item
              label={<label style={{ color: '#fff' }}>New Key</label>}
              name={'newKey'}
              rules={[{ required: true, message: `Please input your new key!` }]}
              labelCol={{ style: { color: '#fff' } }}>
              <Select defaultValue={restKeys[0]} value={restKeys[0]} onChange={(value) => setKey(value)}>
                {restKeys.map((restKey) => {
                  return <Select.Option value={restKey}>{restKey}</Select.Option>;
                })}
              </Select>
            </Form.Item>

            <InputValue type={schemaOfView[key]} value={undefined} _key={'newValue'} />
            <Form.Item
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '5px',
              }}
              name='submit'>
              <Button
                type='primary'
                htmlType='submit'
                onClick={() => {
                  setTooltipOpen(false);
                }}>
                Add
              </Button>
            </Form.Item>
          </Form>
        }>
        <Button
          type='text'
          icon={<img src={AddImage} alt='Image' style={{ width: 14, height: 14 }} />}
          size={'small'}
          onClick={(e) => {
            e.stopPropagation();
            setTooltipOpen(true);
          }}
        />
      </Tooltip>
    </div>
  );
};

const builders = Object.values(Builder.builders);
const UIViews = Array.isArray(builders)
  ? builders.filter((builder) => {
      return builder.isUI;
    })
  : [];

const InputValue = (props) => {
  const { type, value, _key } = props;

  const [formattedValue, setFormatValue] = useState(value);

  useEffect(() => {
    if (type === UI_COMPONENTS_TYPE_FIELD.JSON) {
      formatCode(value).then((formattedValue) => {
        setFormatValue(formattedValue);
      });
    }
  }, [value, type, _key]);

  Logger.terminalInfo('valuevaluevaluevalue', value);

  switch (type) {
    case UI_COMPONENTS_TYPE_FIELD.MARKDOWN:
      return (
        <Form.Item label={<label style={{ color: '#fff' }}>{_key}</label>} name={_key} rules={[{ required: true, message: `Please input your ${_key}!` }]}>
          <Input placeholder={type} defaultValue={value} type={'text'} value={value} />
        </Form.Item>
      );

    case UI_COMPONENTS_TYPE_FIELD.BOOLEAN:
      return (
        <Form.Item name={_key}>
          <Select defaultValue={value}>
            <Select.Option value={true}>True</Select.Option>
            <Select.Option value={false}>False</Select.Option>
          </Select>
        </Form.Item>
      );
    case UI_COMPONENTS_TYPE_FIELD.NUMBER:
      return (
        <Form.Item
          label={<label style={{ color: '#fff' }}>{_key}</label>}
          name={_key}
          rules={[{ required: true, message: `Please input your ${_key}!` }]}
          labelCol={{ style: { color: '#fff' } }}>
          <Input placeholder={type} defaultValue={value} type={'number'} value={value} />
        </Form.Item>
      );
    case UI_COMPONENTS_TYPE_FIELD.STRING:
      return (
        <Form.Item label={<label style={{ color: '#fff' }}>{_key}</label>} name={_key} rules={[{ required: true, message: `Please input your ${_key}!` }]}>
          <Input placeholder={type} defaultValue={value} type={'text'} value={value} />
        </Form.Item>
      );
    case UI_COMPONENTS_TYPE_FIELD.JSON:
      return (
        <Form.Item name={_key}>
          <Editor
            height='300px'
            width={'240px'}
            theme='vs-dark'
            onChange={(value, event) => isJson(value)}
            defaultLanguage={'json'}
            defaultValue={formattedValue}
            value={formattedValue}
            options={{
              fontSize: '10px',
              minimap: {
                enabled: false,
              },
              lineDecorationsWidth: '2px',
              lineNumbersMinChars: 1,
            }}
          />
        </Form.Item>
      );
    case UI_COMPONENTS_TYPE_FIELD.ANY:
    default:
      return (
        <Form.Item label={<label style={{ color: '#fff' }}>{_key}</label>} name={_key} rules={[{ required: true, message: `Please input your ${_key}!` }]}>
          <Input placeholder={type} defaultValue={value} type={'text'} value={value} />
        </Form.Item>
      );
  }
};

const MyFormTooltip = (props) => {
  const [form] = Form.useForm();

  const [formattedValue, setFormatValue] = useState(props.value);

  const [type, setType] = useState(props.type);

  useEffect(() => {
    if (type === UI_COMPONENTS_TYPE_FIELD.JSON) {
      formatCode(JSON.stringify(props.value, null, 0)).then((formattedValue) => {
        setFormatValue(formattedValue);
      });
    }
  }, [props.value, type]);

  useEffect(() => {
    if (props.type !== type) {
      setType(props.type);
    }
  }, [props.type]);

  const value =
    type === UI_COMPONENTS_TYPE_FIELD.JSON
      ? props.value === '' || typeof props.value === 'undefined' || props.value === null
        ? JSON.stringify({})
        : formattedValue
      : props.value === '' || typeof props.value === 'undefined' || props.value === null
        ? 'unknown'
        : props.value;

  useEffect(() => {
    form.setFieldValue(props._key, value);
  });

  const handleFinish = (values) => {
    props.onChangeSelectedView({ key: [props._key], value: values[props._key], type });
    // You can handle form submission here
  };

  if (['key', 'type'].includes(props._key)) {
    return (
      <Tooltip trigger={'click'} color='rgb(26, 26, 26)' open={false}>
        <span>{props.value?.toString()}</span>
      </Tooltip>
    );
  }

  Logger.terminalInfo('render My Tool Tip', type);

  return (
    <Tooltip
      trigger={'click'}
      color='rgb(26, 26, 26)'
      title={
        <Form
          form={form}
          onFinish={handleFinish}
          initialValues={{
            // Use initialValues to set default values
            [props._key]: value, // Default value for name field
          }}>
          <InputValue type={type} value={value} _key={props._key} />
          <Form.Item
            style={{
              display: 'flex',
              marginTop: '5px',
            }}
            label={<label style={{ color: '#fff' }}>Markdown</label>}
            name='markdown'>
            <Checkbox
              onChange={(e) => {
                Logger.terminalInfo('e.target.checked', e.target.checked);
                setType(e.target.checked ? UI_COMPONENTS_TYPE_FIELD.MARKDOWN : props.type);
              }}
            />
          </Form.Item>

          <Form.Item
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '5px',
            }}
            name='submit'>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </Form.Item>
        </Form>
      }>
      <span
        style={{
          display: 'block',
          paddingRight: '8px',
          maxWidth: '150px',
          cursor: 'pointer',
          maxHeight: '40px',
          cursor: 'pointer',
          overflow: 'hidden',
        }}>
        {value?.toString()}
      </span>
    </Tooltip>
  );
};

export default class SelectedView extends Component {
  onChangeSelectedView = (newProps) => {
    const uri = this.props.resource.location.pathname;
    const typeView = this.props.resource.typeWindow.name;
    let newSelectedView = { ...this.props.view };
    switch (newProps.type) {
      case UI_COMPONENTS_TYPE_FIELD.ANY:
        newSelectedView = { ...this.props.view, [newProps.key]: newProps.value };
        break;
      case UI_COMPONENTS_TYPE_FIELD.NUMBER:
        newSelectedView = { ...this.props.view, [newProps.key]: parseFloat(newProps.value) };
        break;
      case UI_COMPONENTS_TYPE_FIELD.BOOLEAN:
        newSelectedView = { ...this.props.view, [newProps.key]: newProps.value?.toString() === 'true' };
        break;
      case UI_COMPONENTS_TYPE_FIELD.JSON:
        newSelectedView = { ...this.props.view, [newProps.key]: JSON.parse(newProps.value) };
        break;
      case UI_COMPONENTS_TYPE_FIELD.STRING:
        newSelectedView = { ...this.props.view, [newProps.key]: newProps.value };
        break;
      case UI_COMPONENTS_TYPE_FIELD.MARKDOWN:
        newSelectedView = { ...this.props.view, [newProps.key]: newProps.value };
        break;
      default:
        newSelectedView = { ...this.props.view, [newProps.key]: newProps.value };
        break;
    }
    // const newSelectedView = { ...this.props.view, ...newProps };
    if (this.props.visualPreviewMode) {
      this.props.onChangeUIViewOfPageInProject(newSelectedView, uri, typeView);
      this.props.setSelectedView(newSelectedView);
    }
  };

  render() {
    const { view, visualPreviewMode } = this.props;
    if (visualPreviewMode === false) {
      return (
        <Collapse
          accordion={false}
          style={{
            padding: '0px',
          }}
          className='selected-view'
          items={[
            {
              key: '1',
              label: 'Selected View',
            },
          ]}></Collapse>
      );
    }

    if (typeof view !== 'undefined') {
      const typeView = view?.type;

      const schemaOfView = UIViews.find((UIView) => UIView.type === typeView)?.schema;

      const data = Object.keys(view).map((key) => {
        return {
          key: key,
          value: { _key: key, value: view[key], type: schemaOfView[key] || 'string' },
        };
      });

      const defaultColumns = [
        {
          title: 'key',
          dataIndex: 'key',
          key: 'key',
        },
        {
          title: 'value',
          dataIndex: 'value',
          key: 'value',
          render: (props) => {
            return <MyFormTooltip value={props.value} type={props.type} _key={props._key} onChangeSelectedView={this.onChangeSelectedView} />;
          },
        },
      ];

      return (
        <Collapse
          accordion={false}
          style={{
            padding: '0px',
          }}
          className='selected-view'>
          <Panel
            header={<CustomHeader view={view} schemaOfView={schemaOfView} onChangeSelectedView={this.onChangeSelectedView} />}
            key='1'
            style={customPanelStyle}>
            <Table
              dataSource={data}
              pagination={false}
              style={{ width: '99%' }}
              showHeader={false}
              rowClassName={'selected-view-row'}
              columns={defaultColumns}
            />
          </Panel>
        </Collapse>
      );
    } else {
      return (
        <Collapse
          accordion={false}
          style={{
            padding: '0px',
          }}
          className='selected-view'>
          <Panel header={<span>Selected View</span>} key='1' style={customPanelStyle}>
            <Table
              dataSource={[]}
              pagination={false}
              style={{ width: '99%' }}
              showHeader={false}
              rowClassName={'selected-view-row'}
              columns={[
                {
                  title: 'key',
                  dataIndex: 'key',
                  key: 'key',
                },
                {
                  title: 'value',
                  dataIndex: 'value',
                  key: 'value',
                },
              ]}
            />
          </Panel>
        </Collapse>
      );
    }
  }
}
