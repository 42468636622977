import React, { Component } from 'react';
import Builder from '../../..';
import Store from '../../../../Redux';
import Debugger from './debugger';
import Coordinates from './coordinates';
import ViewPage from './view';
import { getRedux } from './utils';
import { extractValue } from '../../../../Helper/utils';
import _, { head } from 'lodash';
import ContextMenu from './contextmenu';
import Logger from '../../../../Helper/Logger';

const themesDebugger = [
  'apathy',
  'apathy:inverted',
  'ashes',
  'bespin',
  'brewer',
  'bright:inverted',
  'bright',
  'chalk',
  'codeschool',
  'colors',
  'eighties',
  'embers',
  'flat',
  'google',
  'grayscale',
  'grayscale:inverted',
  'greenscreen',
  'harmonic',
  'hopscotch',
  'isotope',
  'marrakesh',
  'mocha',
  'monokai',
  'ocean',
  'paraiso',
  'pop',
  'railscasts',
  'rjv-default',
  'shapeshifter',
  'shapeshifter:inverted',
  'solarized',
  'summerfruit',
  'summerfruit:inverted',
  'threezerotwofour',
  'tomorrow',
  'tube',
  'twilight',
];

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      debugger: localStorage.getItem('debugger') || false,
      debuggerTheme: localStorage.getItem('debuggerTheme') || themesDebugger[0],
      debugCoordinates: localStorage.getItem('debugCoordinates') || false,
      contextMenu: {
        menu: [],
        display: false,
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      },
    };
    this.onPressKeys = [];
    this.analysisRedux = getRedux(props.PageConfig);
    this.pageRef = React.createRef();
  }

  setContextMenu = (contextMenu) => {
    const rect = this.pageRef.current.getBoundingClientRect();
    this.setState({ contextMenu: { ...contextMenu, x: contextMenu.x - rect.left, y: contextMenu.y - rect.top } });
  };

  closeContextMenu = () => {
    this.setState({ contextMenu: { ...this.state.contextMenu, display: false } });
  };

  componentDidMount() {
    document.addEventListener(
      'keydown',
      (e) => {
        this.onPressKeys.push(e.key);
        if (['Control', 'b'].every((e) => this.onPressKeys.includes(e))) {
          this.setState({
            debugger: !this.state.debugger,
          });
          localStorage.setItem('debugger', (!this.state.debugger).toString());
        }

        if (['b', 'c'].every((e) => this.onPressKeys.includes(e))) {
          if (this.state.debugger) {
            let currentThemeIndex = themesDebugger.findIndex((name) => name === this.state.debuggerTheme);
            if (currentThemeIndex === themesDebugger.length - 1) {
              currentThemeIndex = 0;
            } else {
              currentThemeIndex = currentThemeIndex + 1;
            }
            this.setState({
              debuggerTheme: themesDebugger[currentThemeIndex],
            });
            localStorage.setItem('debuggerTheme', themesDebugger[currentThemeIndex].toString());
          }
        }

        if (['Control', 'm'].every((e) => this.onPressKeys.includes(e))) {
          this.setState({
            debugCoordinates: !this.state.debugCoordinates,
          });
          localStorage.setItem('debugCoordinates', (!this.state.debugCoordinates).toString());
        }
      },
      true,
    );

    document.addEventListener('keyup', (e) => {
      this.onPressKeys = [...this.onPressKeys].filter((key) => key !== e.key);
    });
  }

  onChangePageViewConfig = (page) => {
    const uri = this.props.resource.path;
    const typeWindow = this.props.resource.typeWindow.name;
    this.props.resource.onChangePageInProject(page, uri, typeWindow);
  };

  onSetSelectedView = (selectedView) => {
    this.props.resource.setSelectedView(selectedView);
  };

  render() {
    const { resource, PageConfig, reduxState } = this.props;

    const overWidth = PageConfig.overWidth ?? true;
    const overHeight = PageConfig.overHeight ?? true;

    const { page } = PageConfig;

    const pageResource = {
      ...resource,
      appResource: { ...resource.appResource, overWidth, overHeight },
      reduxResource: { ...resource.reduxResource, ...Store.getState().filiot },
    };
    const pageWidthByFilUnit = PageConfig.width ?? pageResource?.appResource?.col;
    const pageHeightByFilUnit = PageConfig.height ?? pageResource?.appResource?.row;
    const pageWidthByPx = (pageResource?.appResource?.widthBase * pageWidthByFilUnit) / pageResource?.appResource?.col;
    const pageHeightByPx = (pageResource?.appResource?.heightBase * pageHeightByFilUnit) / pageResource?.appResource?.row;

    const width = pageWidthByPx < pageResource?.appResource?.containerWidth ? pageResource?.appResource?.containerWidth : pageWidthByPx;
    const height = pageHeightByPx < pageResource?.appResource?.containerHeight ? pageResource?.appResource?.containerHeight : pageHeightByPx;
    const pageStyle = PageConfig.style || {};

    Logger.terminalInfo('width', width, height);

    return (
      <div
        style={{
          width,
          height,
          // position: 'relative',
          display: 'flex',
          backgroundColor: PageConfig.background,
          justifyContent: 'center',
          alignItems: 'center',
          ...pageStyle,
        }}
        ref={this.pageRef}>
        {/* <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}> */}
        <Coordinates
          width={width}
          height={height}
          pageResource={pageResource}
          widthByFilUnit={pageWidthByFilUnit}
          heightByFilUnit={pageHeightByFilUnit}
          isCoordinate={this.state.debugCoordinates}
        />
        <Debugger resource={pageResource} isDebug={this.state.debugger} debuggerTheme={this.state.debuggerTheme} />
        <ContextMenu contextMenu={this.state.contextMenu} closeContextMenu={this.closeContextMenu} />
        <ViewPage
          preprocessing={PageConfig.preprocessing || PageConfig.componentDidMount}
          page={page}
          pageResource={pageResource}
          width={pageWidthByFilUnit}
          height={pageHeightByFilUnit}
          viewHeight={height}
          viewWidth={width}
          onChangePageViewConfig={this.onChangePageViewConfig}
          setContextMenu={this.setContextMenu}
          onSetSelectedView={this.onSetSelectedView}
        />
      </div>
    );
  }
}

export default Page;
