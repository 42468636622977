import React, { Component } from "react";

export default class index extends Component {
  render() {
    return (
      <div
        style={{
          color: "white",
        }}
      >
        UploadExplorer
      </div>
    );
  }
}
