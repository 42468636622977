import Logger from '../../Helper/Logger';
import fAPI from '../fAPI';
import { BASE_URL, API } from '../fAPI/constants';
import { fSpace } from '../fSpace';

const BASE_URI = BASE_URL.prod;

/**
 * @param { email: string }: email of user.
 * @param { password: string }: password of user.
 * @param { appCode: string }: appCode of app.
 * @param { fcmToken: string }: firebase token of user.
 * @param { deviceUID: string }: deviceUID of app.
 * @param { os: string }: os of app.
 * @param { callback: json }: callback of function.
 * @param { callback.success: function }: function will call when sign in success.
 * @param { callback.fail: function }: function will call when sign in fail.
 */

async function getAreaBySpaceId({
  userId,
  page,
  page_size,
  spaceId,
  token,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.AREA.GET_AREAS_BY_SPACEID(spaceId)}`;
    const response = await fAPI.get(
      uri,
      {
        Authorization: token,
        'x-user': userId,
      },
      {
        page,
        page_size,
      },
    );
    Logger.terminalInfo('getAreaBySpaceId: ', response, typeof callback?.success === 'function', callback?.success);
    typeof callback?.success === 'function' &&
      callback.success({
        ...response,
        items: Array.isArray(response.items)
          ? response.items.sort((a, b) => {
              if (a.pos === null) return 1;
              if (b.pos === null) return -1;
              return a.pos - b.pos;
            })
          : [],
      });

    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

async function getAreas({
  token,
  page_size,
  page,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    fSpace.getSpaces({
      token,
      callback: {
        success: (response) => {
          if (Array.isArray(response.items)) {
            Promise.all(
              response.items.map((space) => {
                const uri = `${BASE_URI}${API.AREA.GET_AREAS_BY_SPACEID(space.id)}`;
                return fAPI.get(
                  uri,
                  {
                    Authorization: token,
                  },
                  {
                    page_size,
                    page,
                  },
                );
              }),
            ).then((areaResponses) => {
              Logger.terminalInfo('areaResponsesareaResponsesareaResponsesareaResponses', areaResponses);
              typeof callback?.success === 'function' && callback.success({ items: areaResponses.map((areaResponse) => areaResponse.items).flat() });
            });
          }
        },
      },
    });
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

async function createArea({
  userId,
  spaceId,
  name,
  imageIndex,
  pos,
  token,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.AREA.CREATE_AREA}`;
    Logger.terminalInfo('uri', uri);
    const response = await fAPI.post(
      uri,
      {
        Authorization: token,
        'x-user': userId,
      },
      {
        spaceId,
        name,
        imageIndex,
        pos,
      },
    );

    typeof callback?.success === 'function' && callback.success(response);
    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}
async function getEntitiesBySpaceIdAndAreaId({
  userId,
  spaceId,
  id,
  token,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.AREA.GET_ENTITIES_BY_SPACEID_AND_AREAID(id, spaceId)}`;
    Logger.terminalInfo('uri', uri);
    const response = await fAPI.get(
      uri,
      {
        Authorization: token,
        'x-user': userId,
      },
      {},
    );

    typeof callback?.success === 'function' && callback.success(response);

    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}
async function updateArea({
  userId,
  spaceId,
  id,
  name,
  imageIndex,
  pos,
  token,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.AREA.UPDATE_AREA(id)}`;
    Logger.terminalInfo('uri', uri);
    const response = await fAPI.put(
      uri,
      {
        Authorization: token,
        'x-user': userId,
      },
      {
        spaceId,
        name,
        imageIndex,
        pos,
      },
    );

    typeof callback?.success === 'function' && callback.success(response);

    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}
async function updateAreas({
  spaceId,
  areas,
  userId,
  token,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.AREA.UPDATE_AREAS}`;
    Logger.terminalInfo('uri', uri);
    const response = await fAPI.put(
      uri,
      {
        Authorization: token,
        'x-user': userId,
      },
      {
        spaceId,
        areas,
      },
    );

    typeof callback?.success === 'function' && callback.success(response);

    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}
async function deleteArea({
  spaceId,
  id,
  token,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.AREA.DELETE_AREA(id)}`;
    Logger.terminalInfo('uri', uri);
    const response = await fAPI.delete(
      uri,
      {
        Authorization: token,
      },
      {
        spaceId,
      },
    );

    typeof callback?.success === 'function' && callback.success(response);

    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}
// function

export const fArea = {
  getAreaBySpaceId,
  createArea,
  getEntitiesBySpaceIdAndAreaId,
  updateArea,
  updateAreas,
  deleteArea,
  getAreas,
};
