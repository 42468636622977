import { Storage } from '../Storage';
import { fRedux } from '../fRedux';
import fAPI from '../fAPI';
import { BASE_URL, API } from '../fAPI/constants';
import Logger from '../../Helper/Logger';

const BASE_URI = BASE_URL.prod;

/**
 * Sign in to Filiot Auth.
 * @param { email: string }: email of user.
 * @param { password: string }: password of user.
 * @param { appCode: string }: appCode of app.
 * @param { fcmToken: string }: firebase token of user.
 * @param { deviceUID: string }: deviceUID of app.
 * @param { os: string }: os of app.
 * @param { callback: json }: callback of function.
 * @param { callback.success: function }: function will call when sign in success.
 * @param { callback.fail: function }: function will call when sign in fail.
 */
async function signIn({
  email,
  password,
  appCode,
  fcmToken,
  deviceUID,
  os,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.AUTH.SIGN_IN}`;
    Logger.terminalInfo('uri', uri);
    const response = await fAPI.post(
      uri,
      {},
      {
        email,
        password,
        appCode,
        fcmToken,
        deviceUID,
        os,
      },
    );

    Storage.set({ name: 'accessToken', value: response.accessToken });
    Storage.set({ name: 'refreshToken', value: response.refreshToken });
    typeof callback?.success === 'function' && callback.success(response);

    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

async function signUp({
  email,
  appCode,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.AUTH.SIGN_UP}`;
    Logger.terminalInfo('URI: ', uri);
    const response = await fAPI.post(
      uri,
      {},
      {
        email,
        appCode,
      },
    );
    typeof callback?.success === 'function' && callback.success(response);
    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

async function logout({
  fcmToken = 'abc',
  accessToken = '',
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.AUTH.SIGN_OUT}`;
    Logger.terminalInfo('URI: ', uri, fcmToken);
    const response = await fAPI.post(uri, { accessToken }, { fcmToken });
    typeof callback?.success === 'function' && callback.success(response);
    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

async function verifyToken(token) {
  try {
    const headers = {
      Authorization: token,
    };

    const uri = `${BASE_URI}${API.AUTH.VERIFY_TOKEN}`;

    const response = await fAPI.get(uri, headers);
    //123Logger.terminalInfo(response, await ACCESS_TOKEN());
    return { status: true, result: response };
  } catch (error) {
    return { status: false, error };
  }
}

function checkFAuth() {
  return true;
}

async function refresh({
  refreshToken,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.AUTH.REFRESH_TOKEN}`;
    Logger.terminalInfo('URI: ', uri);
    const response = await fAPI.post(
      uri,
      {},
      {
        refreshToken,
      },
    );
    typeof callback?.success === 'function' && callback.success(response);
    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

async function verifyUser({
  email,
  name,
  action,
  confirmToken,
  password,
  appCode,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.AUTH.VERIFY_USER}`;
    Logger.terminalInfo('URI: ', uri);
    const response = await fAPI.post(
      uri,
      {},
      {
        email,
        name,
        action,
        confirmToken,
        password,
        appCode,
      },
    );
    typeof callback?.success === 'function' && callback.success(response);
    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

async function forgotPassword({
  email,
  appCode,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.AUTH.FORGOT_PASSWORD}`;
    Logger.terminalInfo('URI: ', uri);
    const response = await fAPI.post(
      uri,
      {},
      {
        email,
        appCode,
      },
    );
    typeof callback?.success === 'function' && callback.success(response);
    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

async function updatePassword({
  email,
  newPassword,
  confirmToken,
  appCode,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.AUTH.UPDATE_PASSWORD}`;
    Logger.terminalInfo('URI: ', uri);
    const response = await fAPI.post(
      uri,
      {},
      {
        email,
        newPassword,
        confirmToken,
        appCode,
      },
    );
    typeof callback?.success === 'function' && callback.success(response);
    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

async function setPassword({
  oldPassword,
  token,
  newPassword,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.AUTH.SET_PASSWORD}`;
    Logger.terminalInfo('URI: ', uri);
    const response = await fAPI.post(
      uri,
      {
        Authorization: token,
      },
      {
        oldPassword,
        newPassword,
      },
    );
    typeof callback?.success === 'function' && callback.success(response);
    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

// function

export const fAuth = {
  signIn,
  verifyToken,
  signUp,
  checkFAuth,
  logout,
  refresh,
  forgotPassword,
  verifyUser,
  updatePassword,
  setPassword,
};
