import DecorationController from '../../../../../Controller/Decoration';
import defaultComponent from './components/default';
import accordionComponent from './components/accordion';
import headerComponent from './components/header';
import qrComponent from './components/qrcode';
import infoComponent from './components/info';
import infoTextComponent from './components/infotext';
import textComponent from './components/text';
import homekitQrComponent from './components/homekitqrcode';
import homekitComponent from './components/homekit';
import validate from '../../../View/Attribute/validate';

const defaultAcronymHashMap = {
  ex_att: 'extension_attribute',
  lbl: 'label',
  grp: 'groups',
  att: 'attributes',
  cpn: 'component',
  par: 'parameter',
  val: 'value',
  typ: 'type',
  m: 'min',
  M: 'max',
  l: 'label',
  c: 'component',
  p: 'parameter',
  v: 'value',
  t: 'type',
  s: 'step',
};

const systemFunctionNames = {
  onChangeState: 'onChangeState',
  onSaveSetting: 'onSaveSetting',
};

const systemFunctionSchema = {
  type: 'array',
  items: {
    anyOf: Object.values(systemFunctionNames).map((functionName) => ({
      type: 'object',
      properties: {
        [functionName]: { type: 'array' },
      },
      required: [functionName],
      additionalProperties: false,
    })),
  },
  minItems: 1,
};

const convertFunctionSchema = {
  type: 'object',
  properties: {
    convert: {
      type: 'string',
    },
    value: {
      type: 'array',
    },
  },
  required: ['convert', 'value'],
};

function buildFunctionFromJson(jsonData) {
  // Extracting the properties from JSON data
  const { functionName, parameters, body } = jsonData;

  // Dynamically creating a new Function using the Function constructor
  const newFunction = new Function(...parameters, body);

  // Return the newly created function
  return newFunction;
}

export const acronymToFull = (obj, _acronymHashMap) => {
  const acronymHashMap = { ..._acronymHashMap, ...defaultAcronymHashMap };
  if (Array.isArray(obj)) {
    return obj.map((item) => acronymToFull(item, acronymHashMap));
  }

  const newObj = {};
  //   Logger.terminalInfo("newObj", newObj);

  for (const key in obj) {
    const value = obj[key];
    if (Array.isArray(value)) {
      if (validate(value, systemFunctionSchema)) {
        newObj[typeof acronymHashMap[key] !== 'undefined' ? acronymHashMap[key] : key] = Array.isArray(value)
          ? value.map((_function) => {
              return {
                functionName: Object.keys(_function)[0],
                args: Object.values(_function)[0],
              };
            })
          : [];
      } else {
        newObj[typeof acronymHashMap[key] !== 'undefined' ? acronymHashMap[key] : key] = value.map((item) =>
          typeof item === 'object' ? acronymToFull(item, acronymHashMap) : item,
        );
      }
    } else if (typeof value === 'object' && value !== null) {
      if (validate(value, convertFunctionSchema)) {
        if (typeof buildFunctionFromJson(DecorationController.getConvert().find((e) => e.functionName === value['convert'])) !== 'undefined') {
          newObj[typeof acronymHashMap[key] !== 'undefined' ? acronymHashMap[key] : key] = buildFunctionFromJson(
            DecorationController.getConvert().find((e) => e.functionName === value['convert']),
          )(value['value']);
        }
      } else {
        newObj[typeof acronymHashMap[key] !== 'undefined' ? acronymHashMap[key] : key] = acronymToFull(value, acronymHashMap);
      }
    } else {
      newObj[typeof acronymHashMap[key] !== 'undefined' ? acronymHashMap[key] : key] = value;
    }
  }

  return newObj;
};

export const createComponentbyData = (
  data = {
    label: 'attribute 1',
    component: 'default',
    parameter: 'A1',
    value: 1,
    type: 'number',
    min: 0, // option
    max: 10, // option
  },
) => {
  const components = {
    default: 'default',
    select: 'select',
    header: 'header',
    accordion: 'accordion',
    qr: 'qr',
    info: 'info',
    infotext: 'infotext',
    text: 'text',
    homekitqr: 'homekitqr',
    homekit: 'homekit',
  };

  // if (data.parameter === undefined) return null;
  switch (data.component) {
    case components.default:
      return defaultComponent(data);
    case components.header:
      return headerComponent(data);
    case components.accordion:
      return accordionComponent(data);
    case components.qr:
      return qrComponent(data);
    case components.select:
      return null;
    case components.info:
      return infoComponent(data);
    case components.infotext:
      return infoTextComponent(data);
    case components.text:
      return textComponent(data);
    case components.homekitqr:
      return homekitComponent(data);
    case components.homekit:
      return homekitComponent(data);
    default:
      return defaultComponent(data);
  }
};

const buildComponentByJsonData = (jsonData) => {
  if (Array.isArray(jsonData)) {
    return jsonData.map((item) => buildComponentByJsonData(item));
  }

  let newObj = {};

  for (const key in jsonData) {
    if (key === defaultAcronymHashMap.cpn) {
      const component = createComponentbyData(jsonData);
      newObj = { ...newObj, ...component };
    }
  }

  return newObj;
};

export const createDetailExtensionAttributeComponent = (extensionAttributeData, name) => {
  let ex_att = null;
  if (extensionAttributeData !== undefined) {
    ex_att = {
      name,
      title: extensionAttributeData.name || 'EX ATT',
      schema: { actions: [{ action: 'received', schema: {} }] },
      display: {
        type: 'View',
        children: [buildComponentByJsonData({ component: 'header', ...extensionAttributeData.header })],
        props: { style: { width: '100%', flex: 1 } },
      },
    };
    let listAttr = {
      type: 'ScrollView',
      children: buildComponentByJsonData(extensionAttributeData.detail),
    };

    ex_att.display.children.push(listAttr);
  }
  return ex_att;
};
