import Builder from '..';
import moment from 'moment-timezone';

function isValidDate(dateString) {
  const date = new Date(dateString);
  return date instanceof Date && !isNaN(date);
}

export const build = (DateConfig, resource) => {
  try {
    const _date = Builder.build(DateConfig.date, resource);
    const _timeZone = Builder.build(DateConfig.timeZone, resource);
    const format = Builder.build(DateConfig.format, resource);
    if (isValidDate(_date)) {
      const date = new Date(_date);
      if (_timeZone) {
        return moment(date)
          .tz(_timeZone)
          .format(format ?? 'DD/MM/YYYY HH:mm:ss');
      }
      return moment(date).format(format ?? 'DD/MM/YYYY HH:mm:ss');
    }
    return null;
  } catch (error) {
    return undefined;
  }
};

export const type = 'Date';
