import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from '../Modal';
// import { onUpdateModal } from '../../../../../../Redux/action';
import QrCode from '../QR';
// import FiliotController from '../../../../../../Core/filiotController';
import { Clipboard, Dimensions, FastImage, FlatList, ImageBackground, NativeEventEmitter, NativeModules, Platform, StyleSheet, Text, TouchableOpacity, View } from '../../React-Native/types';

const { height } = Dimensions.get('window');

class HomeKit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            homes: [],
            accessories: [],
            homeSelected: '',
            accessory: '',
        };
        if (Platform.OS === 'ios') {
            this.HomeKitModule = NativeModules.Homekit;
            this.myModuleEvt = new NativeEventEmitter(this.HomeKitModule);
        }
    }

    componentDidMount() {
        const { cEntity } = this.props;
        // FiliotController.updateDeviceSetting({ deviceId: cEntity.originId, data: { wake_up_homekit: true } });

        if (Platform.OS === 'ios') {
            this.myModuleEvt.addListener('findNewAccessory', (accessory) => {
                const { name } = this.props.value;
                if (name === accessory.accessory.name) {
                    this.setState({
                        accessory: accessory.accessory.name,
                    });
                }
            });
            this.myModuleEvt.addListener('removeNewAccessory', (accessory) => {});
            this.myModuleEvt.addListener('updateListHome', (homes) => {
                this.setState({
                    homes,
                });
            });

            this.HomeKitModule.getListHome();

            this.HomeKitModule.startSearchingForNewAccessories();
        }
    }

    componentWillUnmount() {
        if (Platform.OS === 'ios') {
            this.HomeKitModule.stopSearchingForNewAccessories();
        }
    }

    onChangeArea = (item) => {
        this.setState({
            homeSelected: item,
        });
    };

    renderItem = ({ item }) => {
        const { theme, lang } = this.props;
        const { homeSelected } = this.state;
        const styles = { ...style, ...this.style };
        return (
            <TouchableOpacity style={[{ borderBottomColor: theme.colors.borderColor }, styles.btnArea]} onPress={() => this.onChangeArea(item)}>
                <Text
                    style={[
                        {
                            color: theme.colors.textColor,
                            fontFamily: theme.fonts.fontBold,
                        },
                        styles.areaName,
                    ]}
                    ellipsizeMode={'tail'}
                    numberOfLines={1}>
                    {item || lang.uncategorized}
                </Text>
                {homeSelected === item && <FastImage source={theme.icons.check} style={styles.select} />}
            </TouchableOpacity>
        );
    };

    renderModal = () => {
        const { lang, theme } = this.props;
        const { homes, homeSelected } = this.state;
        const styles = { ...style, ...this.style };

        return (
            <View style={[{ backgroundColor: theme.colors.appColor }, styles.contentModal]}>
                <View style={styles.headerModal}>
                    <Text style={[{ color: theme.colors.textColor, fontFamily: theme.fonts.fontBold }, styles.title]}>{lang.selectArea}</Text>
                    <TouchableOpacity style={styles.btnCloseModal} onPress={this.onCloseModal}>
                        <FastImage source={theme.icons.cancelFour} style={styles.closeModal} />
                    </TouchableOpacity>
                </View>
                <View style={[{ paddingTop: 8, paddingHorizontal: 0, marginTop: 20 }, styles.body]}>
                    {homes.length > 0 ? (
                        <FlatList
                            showsHorizontalScrollIndicator={false}
                            showsVerticalScrollIndicator={false}
                            keyExtractor={this.keyExtractor}
                            data={homes}
                            renderItem={this.renderItem}
                            numColumns={1}
                            extraData={homeSelected}
                        />
                    ) : (
                        <View style={styles.emptyz}>
                            <FastImage source={theme.icons.emptyBox} style={styles.empty} resizeMode={'contain'} />
                            <Text
                                ellipsizeMode={'tail'}
                                numberOfLines={1}
                                style={[{ fontFamily: theme.fonts.fontLight, color: theme.colors.textColor }, styles.text]}>
                                {lang.noAreaYet}
                            </Text>
                        </View>
                    )}
                </View>
                <TouchableOpacity
                    style={[{ backgroundColor: homeSelected === '' ? theme.colors.inactiveColor : theme.colors.activeColor }, styles.contentBtn]}
                    onPress={this.addHomeKit}
                    disabled={homeSelected === ''}>
                    <Text style={[{ color: theme.colors.textColorBtn, fontFamily: theme.fonts.fontBold }, styles.textBtn]}>{lang.done}</Text>
                </TouchableOpacity>
            </View>
        );
    };

    selectArea = () => {
        this.setState({ showModal: true });
    };

    addHomeKit = () => {
        const { accessory, homeSelected } = this.state;
        const { code, link, name } = this.props.value;
        this.setState({
            showModal: false,
        });
        Clipboard.setString(code);
        this.props.onUpdateModal({
            visible: true,
            loading: false,
            isSuccess: true,
            content: 'Setup code was copied',
        });
        this.HomeKitModule.addAccessoryToHome(name, homeSelected).then((result) => {
            // dùng tên accessory và tên home
            if (result === null) {
                this.setState({
                    accessory: '',
                    homeSelected: '',
                });
            }
        });
    };

    onCloseModal = () => {
        this.setState({
            showModal: false,
        });
    };

    resetFactoryHomekit = () => {
        const { cEntity } = this.props;
        // FiliotController.updateDeviceSetting({ deviceId: cEntity.originId, data: { reset_factory_homekit: true } });
    };

    render() {
        const { value, lang, theme } = this.props;
        const { code, link, name } = value;
        const size = this.props.size || 100;
        const { showModal, accessory } = this.state;

        const styles = { ...style, ...this.style };
        return (
            <>
                <ImageBackground source={theme.icons.homekitbg} style={styles.homekitBannerBg}>
                    <View style={styles.homekitBannerContainer}>
                        <FastImage source={theme.icons.homekit} style={styles.homekitBanner} />
                    </View>
                </ImageBackground>
                <Text
                    style={[
                        {
                            color: theme.colors.textColor,
                        },
                        styles.homekitTitle,
                    ]}>
                    {lang.homekitsetupcode}
                </Text>
                <View style={styles.homekitInfoContainer}>
                    <View style={styles.homekitDetailInfoContainer}>
                        <View style={styles.homekitInfoItem}>
                            <Text style={[{ color: theme.colors.textInfor, fontFamily: theme.fonts.fontMedium }, styles.typeInfor]}>Tên thiết bị: </Text>
                            <Text style={[{ color: theme.colors.textInfor, fontFamily: theme.fonts.fontMedium }, styles.typeInfor]}>{name}</Text>
                        </View>
                        <View style={styles.homekitInfoItem}>
                            <Text style={[{ color: theme.colors.textInfor, fontFamily: theme.fonts.fontMedium }, styles.typeInfor]}>Mã code: </Text>
                            <Text style={[{ color: theme.colors.textInfor, fontFamily: theme.fonts.fontMedium }, styles.typeInfor]}>{code}</Text>
                        </View>
                    </View>
                    <View style={styles.homekitQrContainer}>
                        <View style={styles.homekitQrBorder}>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                                <FastImage source={theme.icons.homekit} style={{ height: size / 2, width: size / 2 }} />
                                <View style={{ justifyContent: 'space-between', height: size / 2, marginBottom: 8 }}>
                                    <Text style={{ fontSize: 16, color: theme.colors.textColor, fontFamily: theme.fonts.fontBold, letterSpacing: 2 }}>
                                        {code?.slice?.(0, 4) || '----'}
                                    </Text>

                                    <Text style={{ fontSize: 16, color: theme.colors.textColor, fontFamily: theme.fonts.fontBold, letterSpacing: 2 }}>
                                        {code?.slice?.(4, 8) || '----'}
                                    </Text>
                                </View>
                            </View>
                            <QrCode value={link} size={size} />
                        </View>
                    </View>
                </View>
                {Platform.OS === 'ios' && (
                    <View style={[{ backgroundColor: theme.colors.appColorThird }, styles.root]}>
                        <View style={styles.layoutBtnAddDevice}>
                            <TouchableOpacity
                                style={[{ backgroundColor: accessory === '' ? theme.colors.inactiveColor : theme.colors.activeColor }, styles.btn]}
                                onPress={this.selectArea}
                                disabled={accessory === ''}>
                                <Text style={[{ color: theme.colors.textColorBtn, fontFamily: theme.fonts.fontBold }, styles.textBtn]}>{lang.addADevice}</Text>
                            </TouchableOpacity>
                            {accessory === '' && (
                                <TouchableOpacity
                                    style={[{ backgroundColor: theme.colors.activeColor, marginTop: 20 }, styles.btn]}
                                    onPress={this.resetFactoryHomekit}>
                                    <Text style={[{ color: theme.colors.textColorBtn, fontFamily: theme.fonts.fontBold }, styles.textBtn]}>
                                        {'Reset Factory Homekit'}
                                    </Text>
                                </TouchableOpacity>
                            )}
                        </View>
                    </View>
                )}

                <Modal visible={showModal} onCloseModal={this.onCloseModal} position={'bottom'} style={{ height: '50%' }}>
                    {this.renderModal()}
                </Modal>
            </>
        );
    }
}

export default connect(
    (state) => {
        return {
            theme: state.theme,
            lang: state.lang,
            cEntity: state.entity.listEntityChecked[0],
        };
    },
    // { onUpdateModal },
)(HomeKit);

const style = StyleSheet.create({
    homekitQrBorder: {
        borderRadius: 10,
        borderWidth: 4,
        borderColor: 'black',
        padding: 8,
        width: 126,
    },
    homekitQrContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        flex: 2,
    },
    homekitInfoItem: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginVertical: 8,
    },
    homekitDetailInfoContainer: {
        marginRight: 20,
        flex: 3,
        justifyContent: 'center',
    },
    homekitInfoContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginHorizontal: 50,
    },
    homekitTitle: {
        fontSize: 30,
        fontWeight: '700',
        textAlign: 'center',
        marginVertical: 20,
    },
    homekitBanner: {
        height: 120,
        width: 120,
    },
    homekitBannerBg: {
        marginHorizontal: 30,
        marginTop: 20,
        paddingVertical: 40,
        borderRadius: 40,
    },
    homekitBannerContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    typeInfor: {
        fontSize: 14,
        lineHeight: 24,
        flex: 1,
    },
    layoutBtnAddDevice: {
        width: '100%',
        paddingHorizontal: 24,
        paddingBottom: 16,
        marginTop: 50,
    },
    text: {
        fontSize: 17,
        lineHeight: 25,
        marginTop: 25,
    },
    empty: {
        width: 166,
        height: 166,
    },
    emptyz: {
        width: '100%',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '20%',
    },
    contentBtn: {
        width: '100%',
        height: 56,
        borderRadius: 16,
        justifyContent: 'center',
        alignItems: 'center',
    },
    select: {
        width: 20,
        height: 20,
    },
    closeModal: {
        width: 24,
        height: 24,
    },
    btnCloseModal: {
        position: 'absolute',
        right: 0,
    },
    headerModal: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
    },
    contentModal: {
        width: '100%',
        height: '100%',
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        paddingHorizontal: 24,
        paddingTop: 24,
        paddingBottom: 16,
    },
    textBtn: {
        fontSize: 16,
        lineHeight: 24,
    },
    btn: {
        width: '100%',
        height: 56,
        borderRadius: 16,
        justifyContent: 'center',
        alignItems: 'center',
    },
    areaName: {
        fontSize: 14,
        lineHeight: 24,
    },
    btnArea: {
        width: '100%',
        paddingVertical: 16,
        alignItems: 'center',
        marginVertical: 6,
        borderBottomWidth: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    selectArea: {
        fontSize: 14,
        lineHeight: 22,
        marginTop: 24,
        marginBottom: 2,
    },
    row: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 8,
    },
    body: {
        width: '100%',
        flex: 1,
        paddingHorizontal: 24,
    },
    title: {
        fontSize: 20,
        lineHeight: 28,
        minWidth: '70%',
        textAlign: 'center',
    },
    root: {
        width: '100%',
        flex: 1,
    },
});
