import { Mqtt } from '../Mqtt';
import { actions, hostname, types } from './constant';
import { makeBodyMessage, getParentId, isSubDevice, getDeviceId, getTopicType, isOnline, isFiliotOriginId } from './utils';
import { fRedux } from '../fRedux';
import Logger from '../../Helper/Logger';

const ACL_DEVICES = (deviceId) => {
  return [deviceId + '/heartbeat', deviceId + '/report_state', deviceId + '/report_setting', deviceId + '/info'];
};

class FMQTT {
  constructor() {
    this.client = new Mqtt();
    this.userId = undefined;
    this.token = undefined;
    this.initListener();
    this.listTopic = {};
    this.online = 0;
    this.offline = 0;
    this.deviceHeartBeats = {};
    this.path = '';
    this.reduxUpdates = [];
    this.autoReconnect = true;

    // this.intervalUpdate = setInterval(() => {
    //   console.log('this.reduxUpdates.length', this.reduxUpdates.length);
    //   if (this.reduxUpdates.length >= 1) {
    //     const reduxUpdate = this.reduxUpdates[0];
    //     this.reduxUpdates.shift();
    //     fRedux.updateObjects(reduxUpdate);
    //   }
    // }, 1000);

    this.inactiveTimeout = null;

    const handleVisibilityChange = () => {
      if (document.hidden) {
        // Tab is inactive
        // Set a timeout to call the API after 5 minutes (300000 milliseconds)
        this.inactiveTimeout = setTimeout(() => {
          this.disconnect({ autoReconnect: false });
        }, 120000); // 5 minutes
      } else {
        // Tab is active again
        // Clear the timeout if it was set
        console.log('handleVisibilityChange', handleVisibilityChange);
        this.autoReconnect = true;
        this.connect();

        if (this.inactiveTimeout) {
          clearTimeout(this.inactiveTimeout);
          this.inactiveTimeout = null;
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
  }

  connect = (connectOptions = { userId: '', token: '', options: '' }) => {
    try {
      if (connectOptions.userId && connectOptions.token) {
        this.userId = connectOptions.userId;
        this.token = connectOptions.token;
      }
      if (this.userId && this.token !== '') {
        this.client.connect(hostname, {
          // clientId: `${this.userId}-${Math.random()
          //   .toString(16)
          //   .substring(2, 8)}`,
          clientId: `${this.userId}-${Math.random().toString(16).substring(2, 8)}`,
          username: this.userId,
          password: this.token,
          resubsribe: true,
          clean: true,
          reconnectPeriod: 1000,
          connectTimeout: 30 * 1000,
        });
      }
    } catch (error) {
      Logger.terminalInfo('connect error', error);
    }
  };

  subscribeWithListDeviceId = (listDeviceId) => {
    if (listDeviceId.length === 0) {
      Object.keys(this.listTopic).forEach((topic) => {
        this.deleteTopicsOfDevice(this.listTopic[topic]?.deviceId);
      });
    } else {
      Object.keys(this.listTopic).forEach((topic) => {
        if (listDeviceId.find((element) => this.listTopic[topic]?.deviceId === element) === undefined) {
          try {
            this.deleteTopicsOfDevice(this.listTopic[topic]?.deviceId);
          } catch (error) {}
        }
      });
    }

    listDeviceId.forEach((deviceId) => {
      this.addTopicsOfDeviceToList(deviceId);
    });
    this.subscribeAll();
  };

  addTopicsOfDeviceToList = (deviceId) => {
    if (isSubDevice(deviceId)) {
      this.listTopic[getParentId(deviceId) + '/' + deviceId + '/state'] = {
        status: false,
        deviceId,
      };
      this.listTopic[getParentId(deviceId) + '/' + deviceId + '/report'] = {
        status: false,
        deviceId,
      };
    } else {
      ACL_DEVICES(deviceId).forEach((topic) => {
        this.listTopic[topic] = { status: false, deviceId };
      });
    }
  };

  deleteTopicsOfDevice = (deviceId) => {
    try {
      if (deviceId && deviceId !== undefined) {
        if (isSubDevice(deviceId)) {
          if (this.client.isConnected) {
            this.client.unsubscribe(getParentId(deviceId) + '/' + deviceId + '/state');
          }
          delete this.listTopic[getParentId(deviceId) + '/' + deviceId + '/state'];
        } else {
          if (this.client.isConnected) {
            ACL_DEVICES(deviceId).forEach((topic) => {
              this.client.unsubscribe(topic);
            });
          }
          ACL_DEVICES(deviceId).forEach((topic) => {
            delete this.listTopic[topic];
          });
        }
      }
    } catch (error) {}
  };

  addNewDevice = (deviceId) => {
    if (typeof deviceId !== 'undefined' && deviceId !== null && typeof deviceId === 'string') {
      this.addTopicsOfDeviceToList(deviceId);
      if (this.client.isConnected) {
        if (isSubDevice(deviceId)) {
          if (
            !(
              (this.listTopic[getParentId(deviceId) + '/' + deviceId + '/state']?.status === true) &
              (this.listTopic[getParentId(deviceId) + '/' + deviceId + '/report']?.status === true)
            )
          ) {
            this.disconnect();
          }
        } else {
          ACL_DEVICES(deviceId).forEach((topic) => {
            this.listTopic[topic] = { status: false, deviceId };
          });

          if (!ACL_DEVICES(deviceId).every((topic) => this.listTopic[topic]?.status === true)) {
            this.disconnect();
          }
        }
      }
    }
  };

  addNewDevices = ({ deviceIds }) => {
    Logger.terminalInfo('deviceIds', deviceIds);
    if (Array.isArray(deviceIds)) {
      deviceIds.forEach((deviceId) => {
        this.addTopicsOfDeviceToList(deviceId);
      });
    }
    Logger.terminalInfo('this.client.isConnected', this.client.isConnected);
    if (this.client.isConnected) {
      if (Array.isArray(deviceIds)) {
        if (
          deviceIds.findIndex((deviceId) => {
            if (isSubDevice(deviceId)) {
              if (
                !(
                  (this.listTopic[getParentId(deviceId) + '/' + deviceId + '/state']?.status === true) &
                  (this.listTopic[getParentId(deviceId) + '/' + deviceId + '/report']?.status === true)
                )
              ) {
                return true;
              }
            } else {
              ACL_DEVICES(deviceId).forEach((topic) => {
                this.listTopic[topic] = { status: false, deviceId };
              });

              if (!ACL_DEVICES(deviceId).every((topic) => this.listTopic[topic]?.status === true)) {
                return true;
              }
            }

            return false;
          }) !== -1
        ) {
          this.disconnect();
        }
      }
    }
  };

  //external function
  deleteDevice = (deviceId) => {
    this.deleteTopicsOfDevice(deviceId);
    if (this.client.isConnected) {
      this.disconnect();
    }
  };

  subscribeAll = () => {
    if (this.client.isConnected) {
      // Object.keys(this.listTopic).forEach((topic) => {
      // this.subscribe(Object.keys(this.listTopic), 1);
      this.subscribe(Object.keys(this.listTopic), 0);
      // this.subscribe(
      //   [
      //     'odexmjqzndgw_00501024000_18f37a57ff7/report_state',
      //     'odexmjqzndgw_00501024000_18f37a57ff7/report_setting',
      //     'odexmjqzndgw_00501024000_18f37a57ff7/heartbeat',
      //     'odexmjqzndgw_00501024000_18f37a57ff7/info',
      //   ],
      //   1,
      // );
      // });
    } else {
      this.connect();
    }
  };

  subscribe = (topics = [], qos) => {
    Logger.terminalInfo('Filiot Mqtt subscribe to: ', topics);
    this.client.subscribe(topics, { qos }, (err, granted) => {
      try {
        Logger.terminalInfo('err, granted', JSON.stringify(err), JSON.stringify(granted));
        if (err === null && Array.isArray(granted)) {
          granted.forEach(({ topic, qos }) => {
            const _topic = this.getDidSubscribeTopic(topic);
            if (this.listTopic[_topic]) {
              this.listTopic[_topic].status = true;
            }
            // Logger.terminalInfo('Filiot Mqtt subscribed to success: ', _topic);
          });
        }
      } catch (error) {
        Logger.terminalInfo('Filiot Mqtt subscribed to error: ', topics, error);
      }
    });
  };

  disconnect = (options = { clearConnectOption: false, autoReconnect: true }) => {
    if (options?.clearConnectOption) {
      this.userId = undefined;
      this.token = undefined;
      this.listTopic = {};
    }

    this.autoReconnect = true;
    if (options.autoReconnect === false) {
      this.autoReconnect = false;
    }

    this.client.disconnect();
  };

  unsubcirbe = (deviceId) => {
    ACL_DEVICES(deviceId).forEach((topic) => {
      delete this.listTopic[topic];
    });
    this.disconnect();
  };

  //external function
  publishMessage = ({ deviceId, parentId, data, flag = { type: '', service: '', action: '' }, mac = '', qos = '1' }) => {
    // Type : [state, setting, ota]
    try {
      if (this.client.isConnected) {
        let msg = makeBodyMessage(data, flag, this.userId, mac);
        if (msg && typeof deviceId === 'string') {
          if (isFiliotOriginId(parentId)) {
            const topic = parentId + '/' + deviceId + '/' + flag.type;
            const message = JSON.stringify(msg);
            const options = { qos };
            // TODO
            this.client.publish(topic, message, options, (err) => {
              if (typeof err === 'undefined') {
                Logger.terminalInfo(`Publish message success ::: topic ::: ${topic} ::: message ::: ${message}`);
              } else {
                Logger.terminalInfo(`Publish message error ::: topic ::: ${topic} ::: message ::: ${message} ::: err ::: ${err}`);
              }
            });
          } else if (isSubDevice(deviceId)) {
            const topic = getParentId(deviceId) + '/' + deviceId + '/' + flag.type;
            const message = JSON.stringify(msg);
            const options = { qos };
            // TODO
            this.client.publish(topic, message, options, (err) => {
              if (typeof err === 'undefined') {
                Logger.terminalInfo(`Publish message success ::: topic ::: ${topic} ::: message ::: ${message}`);
              } else {
                Logger.terminalInfo(`Publish message error ::: topic ::: ${topic} ::: message ::: ${message} ::: err ::: ${err}`);
              }
            });
          } else {
            const topic = deviceId + '/' + flag.type;
            const message = JSON.stringify(msg);
            const options = { qos };
            this.client.publish(topic, message, options, (err) => {
              if (typeof err === 'undefined') {
                Logger.terminalInfo(`Publish message success ::: topic ::: ${topic} ::: message ::: ${message}`);
              } else {
                Logger.terminalInfo(`Publish message error ::: topic ::: ${topic} ::: message ::: ${message} ::: err ::: ${err}`);
              }
            });
          }
          return true;
        }
      } else {
        Logger.terminalInfo('Publish message client disconnect');
      }

      return false;
    } catch (error) {
      return false;
    }
  };

  requestDeviceSetting = (message = { deviceId: '', parentId: '' }) => {
    const { deviceId, parentId } = message;
    Logger.terminalInfo('requestDeviceSetting', message);
    this.publishMessage({ deviceId, parentId, data: {}, flag: { action: actions.request_setting, type: types.command }, mac: '', qos: '1' });
  };

  requestDeviceAutomation = (message = { deviceId: '', parentId: '' }) => {
    const { deviceId, parentId } = message;
    this.publishMessage({ deviceId, parentId, data: {}, flag: { action: actions.request_automation, type: types.command }, mac: this.wifiMac, qos: '1' });
  };

  updateDeviceState = ({ message = { deviceId: '', data: {}, parentId: '' }, service = 'app' }) => {
    let { deviceId, data, parentId } = message;

    this.publishMessage({
      deviceId,
      parentId,
      data,
      flag: {
        action: actions.update_state,
        type: types.command,
        service: service,
      },
      mac: this.wifiMac,
      qos: '1',
    });
  };

  updateDeviceAutomation = (message = { deviceId: '', parentId: '', data: { rules: {}, scenes: {}, groups: {} } }) => {
    const { deviceId, data, parentId } = message;
    // mqtt.publishMessage(deviceId, data, { action: actions.update_setting, type: types.ota });
    this.publishMessage({
      deviceId,
      parentId,
      data,
      flag: {
        action: actions.update_automation,
        type: types.automation,
      },
      mac: this.wifiMac,
      qos: '1',
    });
  };

  deleteDeviceSetting = (message = { deviceId: '', parentId: '', data: {} }) => {
    const { deviceId, data, parentId } = message;
    // mqtt.publishMessage(deviceId, data, { action: actions.update_setting, type: types.ota });
    this.publishMessage({
      deviceId,
      parentId,
      data,
      flag: {
        action: actions.delete_setting,
        type: types.setting,
      },
      mac: this.wifiMac,
      qos: '1',
    });
  };

  updateDeviceOta = (message = { deviceId: '', parentId: '', data: {} }) => {
    const { deviceId, data, parentId } = message;
    // mqtt.publishMessage(deviceId, data, { action: actions.update_setting, type: types.ota });
    this.publishMessage({
      deviceId,
      parentId,
      data,
      flag: {
        action: actions.update_ota,
        type: types.ota,
      },
      mac: this.wifiMac,
      qos: '1',
    });
  };

  updateDeviceSetting = (message = { deviceId: '', parentId: '', data: {} }) => {
    const { deviceId, data, parentId } = message;
    // mqtt.publishMessage(deviceId, data, { action: actions.update_setting, type: types.ota });
    this.publishMessage({
      deviceId,
      parentId,
      data,
      flag: {
        action: actions.update_setting,
        type: types.setting,
      },
      mac: this.wifiMac,
      qos: '1',
    });
  };

  updateDeviceSettingRetail = (message = { deviceId: '', parentId: '', data: {} }) => {
    const { deviceId, data, parentId } = message;
    // mqtt.publishMessage(deviceId, data, { action: actions.update_setting, type: types.ota });
    this.publishMessage({
      deviceId,
      parentId,
      data,
      flag: {
        action: actions.update_setting,
        type: types.command,
      },
      mac: this.wifiMac,
      qos: '1',
    });
  };

  requestDeviceState = (message = { deviceId: '', parentId: '' }) => {
    const { deviceId, parentId } = message;
    // mqtt.publishMessage(deviceId, {}, { action: actions.request_state, type: types.command });
    setTimeout(() => {
      this.publishMessage({ deviceId, parentId, data: {}, flag: { action: actions.request_state, type: types.command }, mac: this.wifiMac, qos: '1' });
    }, 2000);
    this.publishMessage({ deviceId, parentId, data: {}, flag: { action: actions.request_state, type: types.command }, mac: this.wifiMac, qos: '1' });
  };

  initListener = () => {
    Logger.terminalInfo('initListener');
    this.client.setOnConnect(() => {
      fRedux.updateObjects([{ path: `fMqtt.isConnected`, data: true }]);
      this.subscribeAll();
    });

    this.client.setOnMessage((topic, message) => {
      try {
        Logger.terminalInfo('topic, message', topic, message);
        let deviceId = getDeviceId(topic);
        let topicType = getTopicType(topic);
        let msg = {};
        // Logger.terminalInfo('deviceId, topicType', deviceId, topicType);

        switch (topicType) {
          case 'state':
            message = JSON.parse(message);
            if (message.response?.state) {
              msg = {
                deviceId: deviceId,
                state: message.response.state,
              };
            } else {
              // TODO
              if (message.response?.action === 'scan') {
                msg = {
                  deviceId: deviceId,
                  devices: message.response?.data?.devices,
                };
              } else {
                msg = {
                  deviceId: deviceId,
                  devices_getlist: message.response?.data?.devices,
                };
              }
            }
            this.event = 'update_device_state';
            this.msg = { ...msg, activeRender: true };
            this.counterMsgPerCycle++;
            this.path = topic;
            break;
          case 'info':
            message = JSON.parse(message);

            if (message.data !== undefined) {
              msg = {
                deviceId,
                info: message.data,
              };
              msg.info.msg_version = message.version;
            } else {
              msg = {
                deviceId,
                info: message.response,
              };
              msg.info.action = 'report_info';
              msg.info.msg_version = '0.1';
            }

            this.counterMsgPerCycle++;
            this.event = 'device_info';
            this.msg = { ...msg, activeRender: true };
            this.path = topic;
            break;
          case 'heartbeat':
            msg = {
              channel: 'mqtt',
              deviceId: deviceId,
              isOnline: isOnline(message),
            };
            this.counterMsgPerCycle++;
            this.event = 'device_connection_status';
            this.msg = { ...msg, activeRender: true };
            if (msg.isOnline === true) {
              if (this.deviceHeartBeats[deviceId] === false) {
                this.online++;
                this.offline--;
              } else if (typeof this.deviceHeartBeats[deviceId] === 'undefined') {
                this.online++;
              }
              this.deviceHeartBeats[deviceId] = true;
            } else {
              if (this.deviceHeartBeats[deviceId] === true) {
                this.offline++;
                this.online--;
              } else if (typeof this.deviceHeartBeats[deviceId] === 'undefined') {
                this.offline++;
              }
              this.deviceHeartBeats[deviceId] = false;
            }
            this.path = topic;
            break;
          case 'report':
            message = JSON.parse(message);
            msg = {
              deviceId,
              data: message.data,
            };
            this.counterMsgPerCycle++;
            this.event = 'device_report';
            this.msg = { ...msg, activeRender: true };
            this.path = `${deviceId}/${message?.data?.action ?? topicType}`;
            break;
          case 'report_setting':
            message = JSON.parse(message);
            msg = {
              deviceId,
              data: message.data,
            };
            this.counterMsgPerCycle++;
            this.event = 'device_report';
            this.msg = { ...msg, activeRender: true };
            this.path = topic;
            break;
          case 'report_state':
            message = JSON.parse(message);
            msg = {
              deviceId,
              data: message.data,
            };
            this.counterMsgPerCycle++;
            this.event = 'device_report';
            this.msg = { ...msg, activeRender: true };
            this.path = topic;
            break;
          default:
            break;
        }

        // this.reduxUpdates.push([
        //   { path: `fMqtt.data.${this.path}`, data: this.msg },
        //   {
        //     path: `fMqtt.recent`,
        //     data: {
        //       topic,
        //       data: this.msg,
        //       at: Date.now(),
        //     },
        //   },
        //   {
        //     path: `fMqtt.onlines`,
        //     data: this.online,
        //   },
        //   {
        //     path: `fMqtt.offlines`,
        //     data: this.offline,
        //   },
        // ]);

        fRedux.updateObjects([
          { path: `fMqtt.data.${this.path}`, data: this.msg },
          {
            path: `fMqtt.recent`,
            data: {
              topic,
              data: this.msg,
              at: Date.now(),
            },
          },
        ]);
      } catch (error) {}
    });

    this.client.setOnDisconnect((param) => {
      Logger.terminalInfo('Filiot mqtt Disconnected');
      fRedux.updateObjects([{ path: `fMqtt.isConnected`, data: false }]);
      if (this.autoReconnect) {
        this.connect();
      }
    });
  };

  getDidSubscribeTopic = (deviceId) => {
    let re = new RegExp('[a-z0-9]+_[0-9a-f]+_[a-z0-9-]+(|_[0-9a-f]+_[A-Za-z0-9]+)/(state|info|heartbeat|report)', 'g');
    let a = deviceId.match(re);
    if (a?.length > 0) {
      return a[0];
    }
    return null;
  };
}

export default new FMQTT();
