import React from 'react';
import BaseComponent from '../BaseComponent/BaseComponent';
import Logger from '../../../../Helper/Logger';

export default class List extends BaseComponent {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {
    // super.componentDidMount();
    Logger.terminalInfo('list ref', this.props.cpnRef?.current?.clientHeight, this.props.Item);
    // Logger.terminalInfo(
    //   'Listttttttttt ref',
    //   this.props.cpnRef.current?.clientHeight,
    //   this.props.cpnRef.current?.clientWidth,
    //   this.props.cpnRef.current?.getBoundingClientRect().top,
    // );
  }

  renderComponent(x, y, width, height) {
    const { data, renderItem, item } = this.props;
    let Item = () => {};
    if (typeof renderItem === 'function') {
      Item = renderItem;
    }

    if (typeof item === 'function') {
      Item = item;
    }
    if (data && Array.isArray(data)) {
      return (
        <div
          ref={this.props.cpnRef}
          style={{
            ...this.style,
            display: 'flex',
            flexDirection: this.props.horizontal ? 'row' : 'column',
          }}>
          {data.map((item, index) => {
            return <Item item={item} index={index} key={index} />;
          })}
        </div>
      );
    }
    return <></>;
  }

  adjustStyle() {
    const { x, y, width, height } = this.props.blockcompiledcomponent;
    const { col, row, widthBase, heightBase } = this.props.resource;

    Logger.terminalInfo('this.props.showAllItem ', this.props.showAllItem);
    Logger.terminalInfo('this.props.resource', this.props.resource);
    if (typeof x === 'undefined' || typeof y === 'undefined') {
      let baseStyle = {
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'flex-start',
        display: 'flex',
      };
      // baseStyle = Object.assign(baseStyle, style);
      baseStyle = { ...baseStyle, ...this.style };
      baseStyle.width = (width / col) * widthBase;
      baseStyle.height = (height / row) * heightBase;
      baseStyle.flexDirection = this.props.horizontal ? 'row' : 'column';
      // baseStyle.flexWrap = 'wrap';
      baseStyle.overflow = this.props.showAllItem ? 'visible' : 'auto';
      return baseStyle;
    } else {
      let baseStyle = {
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'flex-start',
        display: 'flex',
      };
      // baseStyle = Object.assign(baseStyle, style);
      baseStyle = { ...baseStyle, ...this.style };
      baseStyle.width = (width / col) * widthBase;
      baseStyle.height = (height / row) * heightBase;
      baseStyle.flexDirection = this.props.horizontal ? 'row' : 'column';
      // baseStyle.flexWrap = 'wrap';
      baseStyle.overflow = this.props.showAllItem ? 'visible' : 'auto';

      return baseStyle;
    }
  }
}
