import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Router } from 'react-router-dom';
// Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
// Bootstrap Bundle JS
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

import { createBrowserHistory } from 'history';
import { Route, Switch } from 'react-router-dom/cjs/react-router-dom';

export const history = createBrowserHistory({ forcerefresh: true });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Router history={history}>
    <Switch>
      <Route path='*'>
        <App />
      </Route>
    </Switch>
  </Router>,
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(Logger.terminalInfo))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
