import mqtt from 'mqtt';
import { fRedux } from '../fRedux';
import Logger from '../../Helper/Logger';

export class Mqtt {
  constructor() {
    this.client = undefined;
    this.isConnected = false;
    this.onConnectCallBack = () => {};
    this.onDisconnectCallBack = () => {};
  }

  connect(hostname, options) {
    if (this.isConnected === false) {
      try {
        Logger.terminalInfo('mqtt connecting');

        this.client = mqtt.connect(hostname, options);

        try {
          this.client?.on('connect', () => {
            this.isConnected = true;
            Logger.terminalInfo('mqtt connected');
            this.onConnectCallBack?.();
          });
        } catch (error) {
          Logger.terminalInfo(`onConnect mqtt error: error ${error.message}`);
        }

        try {
          this.client?.on('end', () => {
            this.isConnected = false;
            Logger.terminalInfo('mqtt disconnect');
            this.onDisconnectCallBack?.();
          });
        } catch (error) {
          Logger.terminalInfo(`onConnect mqtt error: error ${error.message}`);
        }

        try {
          this.client?.on('message', (topic, message) => {
            const textDecoder = new TextDecoder('utf-8');
            const resultString = textDecoder.decode(message);
            fRedux.updateObjects([
              { path: `mqtt.data.${topic}`, data: resultString },
              {
                path: `mqtt.recent`,
                data: {
                  topic,
                  data: resultString,
                  at: Date.now(),
                },
              },
            ]);
            this.onMessageCallBack?.(topic, resultString);
          });
        } catch (error) {
          Logger.terminalInfo(`onConnect mqtt error: error ${error.message}`);
        }

        try {
          this.client?.on('reconnect', () => {
            Logger.terminalInfo('reconnectttttttttttttttttttttttt');
          });
        } catch (error) {
          Logger.terminalInfo(`onConnect mqtt error: error ${error.message}`);
        }
      } catch (error) {
        Logger.terminalInfo(`Connect mqtt error: hostname ${hostname} | options ${options} | error ${error.message}`);
      }
    }
  }

  setOnConnect(cb) {
    try {
      this.onConnectCallBack = cb;
    } catch (error) {
      Logger.terminalInfo(`onConnect mqtt error: error ${error.message}`);
    }
  }

  setOnDisconnect(cb) {
    try {
      this.onDisconnectCallBack = cb;
    } catch (error) {
      Logger.terminalInfo(`onDisconnect mqtt error: error ${error.message}`);
    }
  }

  setOnMessage(cb) {
    try {
      this.onMessageCallBack = cb;
    } catch (error) {}
  }

  publish(topic, message, options, callback) {
    try {
      if (this.isConnected) {
        this.client.publish(topic, message, options, callback);
      }
    } catch (error) {
      Logger.terminalInfo(`Publish mqtt error: topic ${topic} | message ${message} | error ${error.message}`);
    }
  }

  subscribe(topics, options, callback) {
    try {
      Logger.terminalInfo('this.isConnected', this.isConnected);
      if (this.isConnected) {
        // this.client.subscribeAsync(topics, options, (err, granted) => {
        //   Logger.terminalInfo('subscribe', err, granted);
        //   typeof callback === 'function' && callback(err, granted);
        // });
        this.client.subscribe(topics, options, (err, granted) => {
          Logger.terminalInfo('subscribe', JSON.stringify(err), JSON.stringify(granted));
          typeof callback === 'function' && callback(err, granted);
        });
      }
    } catch (error) {
      Logger.terminalInfo(`Subscribe mqtt error: topic ${topics} | error ${error.message}`);
    }
  }

  unsubscribe(topic, options, callback) {
    try {
      if (this.isConnected) {
        this.client.unsubscribe(topic, options, callback);
      }
    } catch (error) {
      Logger.terminalInfo(`Unsubscribe mqtt error: topic ${topic} | error ${error.message}`);
    }
  }

  disconnect() {
    try {
      if (this.isConnected) {
        Logger.terminalInfo('mqtt disconnecting');
        this.client.endAsync();
      }
    } catch (error) {
      Logger.terminalInfo(`End mqtt error: error ${error.message}`);
    }
  }
}

export default new Mqtt();
