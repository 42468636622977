import main from './Main';

const auth = {
  preprocessing: {},
  main: main,
  mainStyle: {
    backgroundColor: '#ededed',
  },
};

export default auth;
