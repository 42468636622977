// import React, { Component } from 'react';
// import { v4 as uuidv4 } from 'uuid';
// import Builder from '../../../..';
// import * as TextMarkdownBuilder from '../../../../TextMarkdown';
// import { connect } from 'react-redux';
// import { getMarkdownUI, getString, getWordsBetweenCurlies } from '../util';
// import _ from 'lodash';
// import { getStyle } from '../style';
// import withStyles from 'react-jss';

// function shallowCompare(obj1, obj2) {
//   // Check for null values
//   if (obj1 === null && obj2 === null) {
//     return true;
//   }

//   // Check for null values individually
//   if (obj1 === null || obj2 === null) {
//     return false;
//   }

//   // Check for null values
//   if (typeof obj1 === 'undefined' && typeof obj2 === 'undefined') {
//     return true;
//   }

//   // Check for null values individually
//   if (typeof obj1 === 'undefined' || typeof obj2 === 'undefined') {
//     return false;
//   }

//   const keys1 = Object.keys(obj1);
//   const keys2 = Object.keys(obj2);

//   if (keys1.length !== keys2.length) {
//     return false;
//   }

//   for (const key of keys1) {
//     if (obj1[key] !== obj2[key]) {
//       return false;
//     }
//   }

//   return true;
// }

// export const ignoreProps = ['children', 'onClick'];

// export function removeObjectKeys(originalObject, ignoreList) {
//   const newObj = { ...originalObject };

//   ignoreList.forEach((key) => {
//     if (newObj.hasOwnProperty(key)) {
//       delete newObj[key];
//     }
//   });

//   return newObj;
// }

// const blockStyleChecked = ['x', 'y', 'width', 'height', 'blockStyle', 'style'];
// const shapeSubsriberResource = ['heightBase', 'widthBase', 'col', 'row', 'typeWindow'];
// class Block extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       reRender: false,
//       isSelected: false,
//       rndModalVisible: false,
//     };
//     this.col = 50;
//     this.row = 150;
//     this.blockId = uuidv4();
//     this.showModal = false;

//     const resource = props.resource;

//     this.markdowns = getMarkdownUI(resource?.appResource?.rawcomponent);
//     this.reduxMarkdowns = {};
//     this.reduxResourceSubPaths = {};

//     Object.keys(this.markdowns).forEach((markdown) => {
//       const markdownsBuildWithAppResource = getWordsBetweenCurlies(
//         TextMarkdownBuilder.build(
//           {
//             type: TextMarkdownBuilder.type,
//             data: markdown,
//           },
//           { appResource: resource.appResource, variableEnvironment: resource.variableEnvironment },
//           false,
//         ),
//       ).map(({ fields }) => fields.join('.'));

//       markdownsBuildWithAppResource.forEach((key) => {
//         this.reduxMarkdowns[key] = this.markdowns[markdown];
//       });
//     });

//     this.currentMarkdownsValue = {};
//     this.propertiesChanged = [];

//     this.blockcompiledcomponent = resource?.appResource?.blockcompiledcomponent ?? {};
//     this.compiledComponent = Object.fromEntries(
//       Object.keys(resource?.appResource?.rawcomponent)
//         .filter((key) => key.slice(0, 5) !== 'block')
//         .map((key) => {
//           return [
//             key,
//             Builder.traverseBuild(resource?.appResource?.rawcomponent[key], {
//               ...resource,
//               reduxResource: {
//                 ...resource.reduxResource,
//                 ...props.reduxState.filiot,
//               },
//             }),
//           ];
//         }),
//     );
//     this.blockHeight = null;
//     this.top = 0;

//     this.blockRef = React.createRef();
//     this.children = <></>;
//     this.className = undefined;
//     this.blockStyle = getStyle(
//       this.blockcompiledcomponent.x,
//       this.blockcompiledcomponent.y,
//       this.blockcompiledcomponent.width,
//       this.blockcompiledcomponent.height,
//       this.blockcompiledcomponent.zIndex,
//       this.blockcompiledcomponent.style,
//       resource.appResource,
//     );
//     this.RenderBlock = this.renderBlock;
//     this.StyledBlockComponent = this.RenderBlock;
//     this.resource = props.resource;
//   }

//   componentDidMount() {
//     const cpnRef = this.blockRef;

//     this.top = cpnRef?.current?.getBoundingClientRect()?.top;
//     this.blockHeight = cpnRef?.current?.clientHeight;
//     const FromTop = cpnRef?.current?.getBoundingClientRect()?.top + cpnRef?.current?.clientHeight + window.scrollY;
//     // this.props.resource.onUpdateViewComponentRef?.(this.blockHeight + this.top, this.blockcompiledcomponent.key, this.blockcompiledcomponent);
//     this.props.resource?.appResource?.onUpdateViewComponentRef?.(FromTop, this.blockId, this.blockcompiledcomponent, {
//       top: cpnRef?.current?.getBoundingClientRect()?.top,
//       clientHeight: cpnRef?.current?.clientHeight,
//       scrollY: window.scrollY,
//     });
//   }

//   componentDidUpdate(prevProps, prevState) {
//     const cpnRef = this.blockRef;
//     const cpnRefHeight = cpnRef?.current?.clientHeight;

//     const FromTop = cpnRef?.current?.getBoundingClientRect()?.top + cpnRef?.current?.clientHeight + window.scrollY;

//     if (this.blockHeight !== cpnRefHeight) {
//       this.blockHeight = cpnRefHeight;
//       // this.props.resource.onUpdateViewComponentRef?.(this.blockHeight + this.top, this.blockcompiledcomponent.key, this.blockcompiledcomponent);
//       this.props.resource?.appResource?.onUpdateViewComponentRef?.(FromTop, this.blockId, this.blockcompiledcomponent, {
//         top: cpnRef?.current?.getBoundingClientRect()?.top,
//         clientHeight: cpnRef?.current?.clientHeight,
//         scrollY: window.scrollY,
//       });
//     }
//   }

//   reRender = () => {
//     this.setState({
//       reRender: !this.state.reRender,
//     });
//   };

//   shouldComponentUpdate(nextProps, nextState) {
//     if (!shallowCompare(nextState, this.state)) {
//       return true;
//     }

//     const resource = nextProps.resource;
//     const reduxState = nextProps.reduxState;

//     this.resource['reduxResource'] = { ...resource.reduxResource, ...reduxState.filiot };

//     const markdownsChanged = [];

//     if (Array.isArray(nextProps.reduxState.filiot.updatePaths)) {
//       nextProps.reduxState.filiot.updatePaths.forEach((updatePath) => {
//         const joinUpdatePath = updatePath.join('.').toString();
//         const findReduxMarkdown = Object.keys(this.reduxMarkdowns).find((key) => {
//           return joinUpdatePath.startsWith(key) || key.startsWith(joinUpdatePath);
//         });
//         if (typeof findReduxMarkdown !== 'undefined') {
//           markdownsChanged.push(findReduxMarkdown);
//           this.propertiesChanged = this.propertiesChanged.concat(this.reduxMarkdowns[findReduxMarkdown]);
//         }
//       });
//     }

//     if (
//       JSON.stringify(resource.appResource) !== JSON.stringify(this.props.resource.appResource) ||
//       JSON.stringify(resource.variableEnvironment) !== JSON.stringify(this.props.resource.variableEnvironment)
//     ) {
//       this.resource['appResource'] = resource.appResource;
//       this.resource['variableEnvironment'] = resource.variableEnvironment;

//       Object.keys(this.markdowns).forEach((markdown) => {
//         const newMarkdownValue = Builder.build(markdown, this.resource);
//         if (!_.isEqual(newMarkdownValue, this.currentMarkdownsValue[markdown])) {
//           this.currentMarkdownsValue[markdown] = newMarkdownValue;
//           markdownsChanged.push(markdown);
//           this.propertiesChanged = this.propertiesChanged.concat(this.markdowns[markdown]);
//         }
//       });
//     }

//     this.propertiesChanged = [...new Set(this.propertiesChanged)];

//     if (this.propertiesChanged.length > 0) {
//       const rawcomponent = resource.appResource?.rawcomponent;

//       this.propertiesChanged.forEach((propertyChanged) => {
//         const findProperty =
//           Object.keys(this.blockcompiledcomponent).find((key) => `block${key.charAt(0).toUpperCase() + key.slice(1)}` === propertyChanged) ||
//           ['x', 'y', 'width', 'height', typeof rawcomponent.blockStyle === 'undefined' ? 'style' : '', 'key', 'name'].find((key) => key === propertyChanged);

//         if (typeof findProperty !== 'undefined') {
//           this.blockcompiledcomponent[findProperty] = Builder.traverseBuild(rawcomponent[propertyChanged], this.resource);
//         }
//       });

//       if (
//         [
//           ...Object.keys(this.blockcompiledcomponent).map((key) => `block${key.charAt(0).toUpperCase() + key.slice(1)}`),
//           'x',
//           'y',
//           'width',
//           'height',
//           typeof rawcomponent.blockStyle === 'undefined' ? 'style' : '',
//           'key',
//           'name',
//         ].some((property) => this.propertiesChanged.includes(property))
//       ) {
//         this.blockStyle = getStyle(
//           this.blockcompiledcomponent.x,
//           this.blockcompiledcomponent.y,
//           this.blockcompiledcomponent.width,
//           this.blockcompiledcomponent.height,
//           this.blockcompiledcomponent.zIndex,
//           this.blockcompiledcomponent.style,
//           this.resource?.appResource,
//         );

//         this.className = undefined;

//         if (Object.keys(this.blockStyle).findIndex((key) => key.slice(0, 2) === '&:') !== -1) {
//           this.className = getString(8);
//           this.StyledBlockComponent = withStyles(
//             (theme) => {
//               return { [this.className]: this.blockStyle };
//             },
//             { generateId: () => this.className },
//           )(this.RenderBlock);
//         }
//       }
//       return true;
//     }

//     if (
//       shapeSubsriberResource.findIndex(
//         (subscriberName) => nextProps?.resource?.appResource?.[subscriberName] !== this.props.resource?.appResource?.[subscriberName],
//       ) !== -1
//     ) {
//       this.blockStyle = getStyle(
//         this.blockcompiledcomponent.x,
//         this.blockcompiledcomponent.y,
//         this.blockcompiledcomponent.width,
//         this.blockcompiledcomponent.height,
//         this.blockcompiledcomponent.zIndex,
//         this.blockcompiledcomponent.style,
//         this.resource?.appResource,
//       );

//       this.className = undefined;

//       if (Object.keys(this.blockStyle).findIndex((key) => key.slice(0, 2) === '&:') !== -1) {
//         this.className = getString(8);
//         this.StyledBlockComponent = withStyles(
//           (theme) => {
//             return { [this.className]: this.blockStyle };
//           },
//           { generateId: () => this.className },
//         )(this.RenderBlock);
//       }

//       return true;
//     }

//     return false;
//   }

//   selectBlock = () => {
//     const { resource, rawComponent } = this.props;
//     if (resource?.appResource?.visualPreviewMode) {
//       this.props.resource?.appResource?.onSelectCpnInVisualMode?.(rawComponent);
//     }
//   };

//   renderBlock = () => {
//     const { resource, children } = this.props;
//     const blockcompiledcomponent = this.blockcompiledcomponent ?? {};
//     const rawcomponent = resource.appResource.rawcomponent;
//     this.propertiesChanged.forEach((propertyChanged) => {
//       this.compiledComponent[propertyChanged] = Builder.traverseBuild(rawcomponent[propertyChanged], this.resource);
//     });

//     this.compiledComponent['blockStyle'] = this.blockStyle;
//     this.compiledComponent['resource'] = resource;
//     this.compiledComponent['blockcompiledcomponent'] = blockcompiledcomponent;

//     this.children = React.cloneElement(children, this.compiledComponent);

//     return (
//       <div
//         className={this.className}
//         {...blockcompiledcomponent}
//         ref={this.blockRef}
//         id={blockcompiledcomponent.key}
//         key={blockcompiledcomponent.key}
//         style={typeof this.className === 'string' ? {} : { ...this.blockStyle }}>
//         {this.children}
//       </div>
//     );
//   };

//   render() {
//     const StyledBlockComponent = this.StyledBlockComponent;
//     return <StyledBlockComponent {...this.props} />;
//   }
// }

// export default connect((state) => {
//   return {
//     reduxState: state,
//   };
// })(Block);

import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Builder from '../../../..';
import { connect } from 'react-redux';
import { blockStyleChecked, getMarkdownUI, getString } from '../util';
import _ from 'lodash';
import { getStyle } from '../style';
import withStyles from 'react-jss';

function shallowCompare(obj1, obj2) {
  // Check for null values
  if (obj1 === null && obj2 === null) {
    return true;
  }

  // Check for null values individually
  if (obj1 === null || obj2 === null) {
    return false;
  }

  // Check for null values
  if (typeof obj1 === 'undefined' && typeof obj2 === 'undefined') {
    return true;
  }

  // Check for null values individually
  if (typeof obj1 === 'undefined' || typeof obj2 === 'undefined') {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
}

export const ignoreProps = ['children', 'onClick'];

export function removeObjectKeys(originalObject, ignoreList) {
  const newObj = { ...originalObject };

  ignoreList.forEach((key) => {
    if (newObj.hasOwnProperty(key)) {
      delete newObj[key];
    }
  });

  return newObj;
}

const shapeSubsriberResource = ['heightBase', 'widthBase', 'col', 'row', 'typeWindow'];
class Block extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reRender: false,
      isSelected: false,
      rndModalVisible: false,
    };
    this.col = 50;
    this.row = 150;
    this.blockId = uuidv4();
    this.showModal = false;

    const resource = props.resource;

    this.markdowns = getMarkdownUI(resource.appResource?.rawcomponent);
    this.currentMarkdownsValue = {};
    this.propertiesChanged = [];

    this.blockcompiledcomponent = resource.appResource?.blockcompiledcomponent ?? {};
    this.compiledComponent = Object.fromEntries(
      Object.keys(resource.appResource?.rawcomponent)
        .filter((key) => key.slice(0, 5) !== 'block')
        .map((key) => {
          return [
            key,
            Builder.traverseBuild(resource.appResource?.rawcomponent[key], {
              ...resource,
              reduxResource: {
                ...resource.reduxResource,
                ...props.reduxState.filiot,
              },
            }),
          ];
        }),
    );

    this.blockHeight = null;
    this.top = 0;

    this.blockRef = React.createRef();
    this.children = <></>;
    this.className = undefined;
    this.blockStyle = getStyle(
      this.blockcompiledcomponent.x,
      this.blockcompiledcomponent.y,
      this.blockcompiledcomponent.width,
      this.blockcompiledcomponent.height,
      this.blockcompiledcomponent.zIndex,
      this.blockcompiledcomponent.style,
      props.resource?.appResource,
    );
    this.RenderBlock = this.renderBlock;
    this.StyledBlockComponent = this.RenderBlock;
  }

  componentDidMount() {
    const cpnRef = this.blockRef;

    this.top = cpnRef?.current?.getBoundingClientRect()?.top;
    this.blockHeight = cpnRef?.current?.clientHeight;
    const FromTop = cpnRef?.current?.getBoundingClientRect()?.top + cpnRef?.current?.clientHeight + window.scrollY;
    // this.props.resource.onUpdateViewComponentRef?.(this.blockHeight + this.top, this.blockcompiledcomponent.key, this.blockcompiledcomponent);
    this.props.resource?.appResource.onUpdateViewComponentRef?.(FromTop, this.blockId, this.blockcompiledcomponent, {
      top: cpnRef?.current?.getBoundingClientRect()?.top,
      clientHeight: cpnRef?.current?.clientHeight,
      scrollY: window.scrollY,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const cpnRef = this.blockRef;
    const cpnRefHeight = cpnRef?.current?.clientHeight;

    const FromTop = cpnRef?.current?.getBoundingClientRect()?.top + cpnRef?.current?.clientHeight + window.scrollY;

    if (this.blockHeight !== cpnRefHeight) {
      this.blockHeight = cpnRefHeight;
      // this.props.resource.onUpdateViewComponentRef?.(this.blockHeight + this.top, this.blockcompiledcomponent.key, this.blockcompiledcomponent);
      this.props.resource?.appResource.onUpdateViewComponentRef?.(FromTop, this.blockId, this.blockcompiledcomponent, {
        top: cpnRef?.current?.getBoundingClientRect()?.top,
        clientHeight: cpnRef?.current?.clientHeight,
        scrollY: window.scrollY,
      });
    }
  }

  reRender = () => {
    this.setState({
      reRender: !this.state.reRender,
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (!shallowCompare(nextState, this.state)) {
      return true;
    }

    const markdownsChanged = [];
    const resource = nextProps.resource;
    this.blockcompiledcomponent = resource?.appResource?.blockcompiledcomponent;
    const rawcomponent = resource?.appResource?.rawcomponent;
    const reduxState = nextProps.reduxState;
    Object.keys(this.markdowns).forEach((markdown) => {
      const newMarkdownValue = Builder.build(markdown, {
        ...resource,
        reduxResource: {
          ...resource.reduxResource,
          ...reduxState.filiot,
        },
      });
      if (!_.isEqual(newMarkdownValue, this.currentMarkdownsValue[markdown])) {
        this.currentMarkdownsValue[markdown] = newMarkdownValue;
        markdownsChanged.push(markdown);
      }
    });

    if (markdownsChanged.length > 0) {
      this.propertiesChanged = [];
      markdownsChanged.forEach((markdownChannged) => {
        this.propertiesChanged = this.propertiesChanged.concat(this.markdowns[markdownChannged]);
      });

      this.propertiesChanged = [...new Set(this.propertiesChanged)];

      this.propertiesChanged.forEach((propertyChanged) => {
        const findProperty =
          Object.keys(this.blockcompiledcomponent).find((key) => `block${key.charAt(0).toUpperCase() + key.slice(1)}` === propertyChanged) ||
          blockStyleChecked(rawcomponent).find((key) => key === propertyChanged);

        if (typeof findProperty !== 'undefined') {
          this.blockcompiledcomponent[findProperty] = Builder.traverseBuild(rawcomponent[propertyChanged], {
            ...resource,
            reduxResource: {
              ...resource.reduxResource,
              ...reduxState.filiot,
            },
          });
        }
      });

      if (
        [
          ...Object.keys(this.blockcompiledcomponent).map((key) => `block${key.charAt(0).toUpperCase() + key.slice(1)}`),
          ...blockStyleChecked(rawcomponent),
        ].some((property) => this.propertiesChanged.includes(property))
      ) {
        this.blockStyle = getStyle(
          this.blockcompiledcomponent.x,
          this.blockcompiledcomponent.y,
          this.blockcompiledcomponent.width,
          this.blockcompiledcomponent.height,
          this.blockcompiledcomponent.zIndex,
          this.blockcompiledcomponent.style,
          resource?.appResource,
        );

        this.className = undefined;

        if (Object.keys(this.blockStyle).findIndex((key) => key.slice(0, 2) === '&:') !== -1) {
          this.className = getString(8);
          this.StyledBlockComponent = withStyles(
            (theme) => {
              return { [this.className]: this.blockStyle };
            },
            { generateId: () => this.className },
          )(this.RenderBlock);
        }
      }

      return true;
    } else if (
      shapeSubsriberResource.findIndex(
        (subscriberName) => nextProps?.resource?.appResource?.[subscriberName] !== this.props.resource?.appResource?.[subscriberName],
      ) !== -1
    ) {
      this.blockStyle = getStyle(
        this.blockcompiledcomponent.x,
        this.blockcompiledcomponent.y,
        this.blockcompiledcomponent.width,
        this.blockcompiledcomponent.height,
        this.blockcompiledcomponent.zIndex,
        this.blockcompiledcomponent.style,
        resource?.appResource,
      );

      this.className = undefined;

      if (Object.keys(this.blockStyle).findIndex((key) => key.slice(0, 2) === '&:') !== -1) {
        this.className = getString(8);
        this.StyledBlockComponent = withStyles(
          (theme) => {
            return { [this.className]: this.blockStyle };
          },
          { generateId: () => this.className },
        )(this.RenderBlock);
      }

      return true;
    } else {
      return false;
    }
  }

  selectBlock = () => {
    const { resource, rawComponent } = this.props;
    if (resource?.appResource.visualPreviewMode) {
      this.props.resource?.appResource.onSelectCpnInVisualMode?.(rawComponent);
    }
  };

  renderBlock = () => {
    const { resource, reduxState, children } = this.props;
    const blockcompiledcomponent = this.blockcompiledcomponent ?? {};
    this.propertiesChanged.forEach((propertyChanged) => {
      this.compiledComponent[propertyChanged] = Builder.traverseBuild(resource.appResource?.rawcomponent[propertyChanged], {
        ...resource,
        reduxResource: {
          ...resource.reduxResource,
          ...reduxState.filiot,
        },
      });
    });

    this.compiledComponent['blockStyle'] = this.blockStyle;
    this.compiledComponent['resource'] = resource;
    this.compiledComponent['blockcompiledcomponent'] = blockcompiledcomponent;

    this.children = React.cloneElement(children, this.compiledComponent);

    return (
      <div
        className={this.className}
        {...blockcompiledcomponent}
        ref={this.blockRef}
        id={blockcompiledcomponent.key}
        key={blockcompiledcomponent.key}
        style={typeof this.className === 'string' ? {} : { ...this.blockStyle }}>
        {this.children}
      </div>
    );
  };

  render() {
    const StyledBlockComponent = this.StyledBlockComponent;
    return <StyledBlockComponent {...this.props} />;
  }
}

export default connect((state) => {
  return {
    reduxState: state,
  };
})(Block);
