import Builder from '..';

const operators = {
  '==': '==',
  '!=': '!=',
  '===': '===',
  '!==': '!==',
  '<': '"<"',
  '>': '>',
  '>=': '>=',
  '<<': '<<',
  '>>': '>>',
  '>>>': '>>>',
  '+': '+',
  '-': '-',
  '*': '*',
  '/': '/',
  '%': '%',
  '**': '**',
  '|': '|',
  '^': '^',
  '&': '&',
  in: 'in',
  instanceof: 'instanceof',
  '|>': '|>',
  '||': '||',
  '&&': '&&',
  '??': '??',
};

export const build = (BinaryExpressionConfig, resource) => {
  const { left, right, operator } = BinaryExpressionConfig;
  const leftValue = Builder.traverseBuild(left, resource);
  const rightValue = Builder.traverseBuild(right, resource);
  switch (operator) {
    case operators['==']:
      return leftValue == rightValue;
    case operators['!=']:
      return leftValue != rightValue;
    case operators['===']:
      return leftValue === rightValue;
    case operators['!==']:
      return leftValue !== rightValue;
    case operators['<']:
      return leftValue < rightValue;
    case operators['>']:
      return leftValue > rightValue;
    case operators['>=']:
      return leftValue >= rightValue;
    case operators['<<']:
      return leftValue << rightValue;
    case operators['>>']:
      return leftValue >> rightValue;
    case operators['>>>']:
      return leftValue >>> rightValue;
    case operators['+']:
      return leftValue + rightValue;
    case operators['-']:
      return leftValue - rightValue;
    case operators['*']:
      return leftValue * rightValue;
    case operators['/']:
      return leftValue / rightValue;
    case operators['%']:
      return leftValue % rightValue;
    case operators['**']:
      return leftValue ** rightValue;
    case operators['|']:
      return leftValue | rightValue;
    case operators['^']:
      return leftValue ^ rightValue;
    case operators['&']:
      return leftValue & rightValue;
    case operators['in']:
      return leftValue in rightValue;
    case operators['instanceof']:
      return leftValue instanceof rightValue;
    case operators['||']:
      return leftValue || rightValue;
    case operators['&&']:
      return leftValue && rightValue;
    case operators['??']:
      return leftValue ?? rightValue;
    default:
      return 'unknown';
  }
};

export const type = 'BinaryExpression';
