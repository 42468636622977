import BaseComponent from '../BaseComponent/BaseComponent';

export default class image extends BaseComponent {
  renderComponent() {

    const { value, alt, src } = this.props;

    return (
      <img
        {...this.restProps}
        style={this.style}
        src={value || src}
        alt={alt}
      />
    );
  }
}
