import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Text, TouchableOpacity, View } from '../../../../React-Native/types';

class DayWeekModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
        };
    }

    render() {
        const { theme, lang } = this.props;
        const styles = { ...style, ...this.style };
        const { value } = this.state;

        const getDayInWeek = (number) => {
            switch (number.toString()) {
                case '0':
                    return 'T2';
                case '1':
                    return 'T3';
                case '2':
                    return 'T4';
                case '3':
                    return 'T5';
                case '4':
                    return 'T6';
                case '5':
                    return 'T7';
                case '6':
                    return 'CN';

                default:
                    return '';
            }
        };

        return (
            <>
                <View style={{ flexDirection: 'row', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                    {['0', '1', '2', '3', '4', '5', '6'].map((day) => {
                        return (
                            <TouchableOpacity
                                key={day}
                                onPress={() => {
                                    this.setState({
                                        value: [...(Array.isArray(value) ? [...value] : [true, true, true, true, true, true, true])].map((e, index) => {
                                            if (index === parseInt(day)) {
                                                return !e;
                                            }
                                            return e;
                                        }),
                                    });
                                }}
                                style={[
                                    styles.dayInWeek,
                                    {
                                        backgroundColor: value?.[day] ? theme.colors.activeColor : theme.colors.appColor,
                                        borderColor: theme.colors.activeColor,
                                    },
                                ]}>
                                <Text
                                    style={{
                                        color: value?.[day] ? theme.colors.textColorBtn : theme.colors.activeColor,
                                    }}>
                                    {getDayInWeek(day)}
                                </Text>
                            </TouchableOpacity>
                        );
                    })}
                </View>
                <View style={styles.bottom}>
                    <Button title={lang.save} color={'#000'} onPress={() => this.props.save(value)} />
                </View>
            </>
        );
    }
}

const style = {
    dayInWeek: {
        marginRight: 6,
        paddingHorizontal: 16,
        paddingVertical: 5,
        borderWidth: 1,
        borderRadius: 40,
        marginTop: 16,
    },
    bottom: {
        alignItems: 'center',
        paddingBottom: 20,
        marginTop: 20,
    },
};

export default connect((state) => {
    return {
        theme: state.theme,
        lang: state.lang,
    };
})(DayWeekModal);
