import crypto from 'crypto-js';
import API from '../API';
import { URI } from './constant';

class AWS {
  constructor() {
    this.baseURI = process.env.REACT_APP_AWS_BASE_URI || 'https://api.filiot.com/lego/v1';
    this.secretKey = process.env.REACT_APP_FAWS_SECRET_KEY;
  }

  async listObjects(secret = { accessKeyId: '', secretAccessKey: '' }, query = { bucketName: '', prefix: '' }) {
    const apiKey = crypto.AES.encrypt(JSON.stringify(secret), this.secretKey).toString();

    const { bucketName, prefix } = query;

    const uri = `${this.baseURI}/${URI.LIST_OBJECTS}?Bucket=${bucketName}&&Prefix=${prefix}`;
    const response = await API.get(uri, {
      'Content-Type': 'application/json',
      'api-key': apiKey,
    });
    return response;
  }

  async uploadObjects(secret = { accessKeyId: '', secretAccessKey: '' }, query = { bucketName: '', prefix: '' }, objects = []) {
    const apiKey = crypto.AES.encrypt(JSON.stringify(secret), this.secretKey).toString();
    const { bucketName, prefix } = query;

    const uri = `${this.baseURI}/${URI.UPLOAD_OBJECTS}?Bucket=${bucketName}`;

    const response = await API.post(
      uri,
      {
        'Content-Type': 'application/json',
        'api-key': apiKey,
      },
      objects,
    );
    return response;
  }

  async uploadObject(secret = { accessKeyId: '', secretAccessKey: '' }, query = { bucketName: '', prefix: '' }, object) {
    const apiKey = crypto.AES.encrypt(JSON.stringify(secret), this.secretKey).toString();
    const { bucketName, prefix } = query;

    const uri = `${this.baseURI}/${URI.UPLOAD_OBJECT}?Bucket=${bucketName}`;

    const response = await API.post(uri, {
      headers: {
        'Content-Type': 'application/json',
        'api-key': apiKey,
      },
      object,
    });
    return response;
  }
}

export default new AWS();
