class ControllerEnvironment {
  constructor() {
    this.env = undefined;
  }

  setEnv(env) {
    this.env = env;
  }

  getEnv() {
    return this.env;
  }
}

export default new ControllerEnvironment();
