import { Col, Collapse, Row } from 'antd';
import React, { Component } from 'react';
import Builder from '../Builder';
import Logger from '../Helper/Logger';

export default class ToolBox extends Component {
  onSelectToolItem = (UIView) => {
    Logger.terminalInfo('ToolBox resource', this.props.resource);
    const uri = this.props.resource.location.pathname;
    const typeView = this.props.resource.typeWindow.name;
    this.props.onAddUIViewOfPageInProject(
      {
        type: UIView.type,
        x: 0,
        y: 0,
        width: 5,
        height: 5,
        style: {
          borderColor: '#eeeeee',
          borderWidth: '1px',
        },
        value: 'value',
      },
      uri,
      typeView,
    );
  };

  render() {
    const { visualPreviewMode } = this.props;
    const builders = Object.values(Builder.builders);
    Logger.terminalInfo('builders', builders);
    const UIViews = Array.isArray(builders)
      ? builders.filter((builder) => {
          Logger.terminalInfo('builder', builder);
          Logger.terminalInfo(' builder.isUI ', builder.isUI);
          return builder.isUI;
        })
      : [];
    Logger.terminalInfo('UIViews', UIViews);

    if (visualPreviewMode === false) {
      return (
        <Collapse
          accordion={false}
          style={{
            padding: '0px',
          }}
          className='selected-view'
          items={[
            {
              key: '1',
              label: 'Tool Box',
            },
          ]}></Collapse>
      );
    }

    return (
      <Collapse
        items={[
          {
            key: '1',
            label: 'Tool Box',
            children: (
              <div
                style={{
                  padding: '8px 10px',
                }}>
                <Row gutter={[16, 24]}>
                  {UIViews.map((UIView) => (
                    <Col className='gutter-row' span={6}>
                      <div
                        style={{
                          background: '#0092ff',
                          fontFamily:
                            "-apple-system, BlinkMacSystemFont, 'Segoe UI Variable', 'Segoe UI', system-ui, ui-sans-serif, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
                          fontSize: '10pt',
                          justifyContent: 'center',
                          alignItems: 'center',
                          display: 'flex',
                          cursor: 'pointer',
                          borderRadius: '3px',
                          padding: '8px 8px',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap' /* Don't forget this one */,
                          textOverflow: 'ellipsis',
                        }}
                        className='toolbox-item'
                        onClick={() => this.onSelectToolItem(UIView)}>
                        {UIView.type}
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            ),
          },
        ]}
        style={{
          padding: '0px',
        }}
        className='selected-view'
      />
    );
  }
}
