import Builder from '..';

export const build = (SwitchExpressionConfig, resource) => {
  const test = Builder.build(SwitchExpressionConfig?.test, resource);
  const switchReturns = SwitchExpressionConfig.switch;
  const defaultReturn = Builder.traverseBuild(SwitchExpressionConfig?.default, resource);

  if (test === -1) {
    return defaultReturn;
  } else {
    if (Array.isArray(switchReturns)) {
      if (typeof switchReturns?.[test] !== 'undefined') {
        return Builder.traverseBuild(switchReturns[test], resource);
      } else {
        return undefined;
      }
    } else {
      return 'Switch Return is invalid';
    }
  }
};

export const type = 'SwitchExpression';
