import Logger from '../../Helper/Logger';
import fAPI from '../fAPI';
import { BASE_URL, API } from '../fAPI/constants';

const BASE_URI = BASE_URL.prod;

/**
 * @param { email: string }: email of user.
 * @param { password: string }: password of user.
 * @param { appCode: string }: appCode of app.
 * @param { fcmToken: string }: firebase token of user.
 * @param { deviceUID: string }: deviceUID of app.
 * @param { os: string }: os of app.
 * @param { callback: json }: callback of function.
 * @param { callback.success: function }: function will call when sign in success.
 * @param { callback.fail: function }: function will call when sign in fail.
 */

async function getSpaces({
  token,
  page_size,
  page,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.SPACE.GET_SPACES}`;
    const response = await fAPI.get(
      uri,
      {
        Authorization: token,
      },
      {
        page,
        page_size,
      },
    );

    typeof callback?.success === 'function' && callback.success(response);

    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

async function getMembers({
  token,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    getSpaces({
      token,
      callback: {
        success: (response) => {
          if (Array.isArray(response.items)) {
            Promise.all(
              response.items.map((space) => {
                const uri = `${BASE_URI}${API.SPACE.GET_MEMBERS_BY_SPACEID(space.id)}`;
                return fAPI.get(
                  uri,
                  {
                    Authorization: token,
                  },
                  {},
                );
              }),
            ).then((membersResponses) => {
              typeof callback?.success === 'function' &&
                callback.success({
                  items: membersResponses
                    // eslint-disable-next-line no-sparse-arrays
                    .map((membersResponse) => [
                      { ...membersResponse.user, owner: true, spaceId: membersResponse.id, spaceName: membersResponse.name },
                      ...membersResponse.members.map((member) => ({
                        ...member,
                        spaceId: membersResponse.id,
                        spaceName: membersResponse.name,
                        owner: false,
                      })),
                      ...(Array.isArray(membersResponse.invitations) ? membersResponse.invitations?.map((invitation) => ({ ...invitation })) : []),
                      ,
                    ])
                    .flat(),
                });
            });
          }
        },
      },
    });
  } catch (error) {}
}

async function getMemberBySpaceId({
  userId,
  spaceId,
  token,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.SPACE.GET_AREAS_BY_SPACEID(spaceId)}`;
    Logger.terminalInfo('uri', uri);
    const response = await fAPI.get(
      uri,
      {
        Authorization: token,
        'x-user': userId,
      },
      {},
    );

    typeof callback?.success === 'function' && callback.success(response);

    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

async function getAreaBySpaceId({
  userId,
  spaceId,
  token,
  page_size,
  page,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.SPACE.GET_AREAS_BY_SPACEID(spaceId)}`;
    Logger.terminalInfo('uri', uri);
    const response = await fAPI.get(
      uri,
      {
        Authorization: token,
        'x-user': userId,
      },
      { page_size, page },
    );

    typeof callback?.success === 'function' && callback.success(response);

    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

async function getEntityBySpaceId({
  spaceId,
  userId,
  token,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.SPACE.GET_ENTITIES_BY_SPACEID(spaceId)}`;
    Logger.terminalInfo('uri', uri);
    const response = await fAPI.get(
      uri,
      {
        Authorization: token,
        'x-user': userId,
      },
      {},
    );

    typeof callback?.success === 'function' && callback.success(response);

    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

async function getShareEntities({
  userId,
  memberId,
  spaceId,
  token,
  page,
  page_size,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.SPACE.GET_SHARE_ENTITIES(spaceId, memberId)}`;
    Logger.terminalInfo('uri', uri);
    const response = await fAPI.get(
      uri,
      {
        Authorization: token,
        'x-user': userId,
      },
      { page, page_size },
    );

    typeof callback?.success === 'function' && callback.success(response);

    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

async function getRequestStateBySpaceId({
  spaceId,
  token,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.SPACE.GET_REQUEST_STATE_BY_SPACEID(spaceId)}`;
    Logger.terminalInfo('uri', uri);
    const response = await fAPI.get(
      uri,
      {
        Authorization: token,
      },
      {},
    );

    typeof callback?.success === 'function' && callback.success(response);

    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

async function createSpace({
  spaceId,
  name,
  pos,
  lng,
  lat,
  address,
  imageIndex,
  token,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.SPACE.CREATE_SPACE}`;
    Logger.terminalInfo('uri', uri);
    const response = await fAPI.post(
      uri,
      {
        Authorization: token,
      },
      {
        name,
        pos,
        lng,
        lat,
        address,
        imageIndex,
      },
    );

    typeof callback?.success === 'function' && callback.success(response);

    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

async function updateSpace({
  userId,
  spaceId,
  name,
  pos,
  lng,
  lat,
  address,
  imageIndex,
  token,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.SPACE.UPDATE_SPACE(spaceId)}`;
    Logger.terminalInfo('uri', uri);
    const response = await fAPI.put(
      uri,
      {
        Authorization: token,
        'x-user': userId,
      },
      {
        name,
        pos,
        lng,
        lat,
        address,
        imageIndex,
      },
    );

    typeof callback?.success === 'function' && callback.success(response);

    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

async function deleteSpace({
  userId,
  spaceId,
  token,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.SPACE.DELETE_SPACE(spaceId)}`;
    Logger.terminalInfo('uri', uri);
    const response = await fAPI.delete(
      uri,
      {
        Authorization: token,
        'x-user': userId,
      },
      {},
    );

    typeof callback?.success === 'function' && callback.success(response);

    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

async function deleteMember({
  userId,
  memberId,
  spaceId,
  role,
  token,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.SPACE.DELETE_MEMBER(spaceId)}`;
    Logger.terminalInfo('uri', uri);
    const response = await fAPI.delete(
      uri,
      {
        Authorization: token,
        'x-user': userId,
      },
      {
        memberId,
        role,
        invitationId: undefined,
      },
    );

    typeof callback?.success === 'function' && callback.success(response);

    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

export const fSpace = {
  getSpaces,
  getMemberBySpaceId,
  getAreaBySpaceId,
  getEntityBySpaceId,
  getShareEntities,
  getRequestStateBySpaceId,
  createSpace,
  updateSpace,
  deleteSpace,
  deleteMember,
  getMembers,
};
