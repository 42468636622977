import Builder from '..';

export const build = (FilterConfig, resource) => {
  const data = Builder.build(FilterConfig.data, resource);

  if (Array.isArray(data) && data.length > 0) {
    if (FilterConfig.filter) {
      return data.filter((element, index) => {
        return Builder.build(FilterConfig.filter, {
          ...resource,
          variableEnvironment: { ...resource.variableEnvironment, element, index, data },
        });
      });
    }
    return data.filter((element, index) => {
      const filterFunction = Builder.build(FilterConfig.filterFunction, resource);
      if (typeof filterFunction === 'function') {
        return filterFunction(element, index, data) === true;
      }
      return false;
    });
  } else {
    return [];
  }
};

export const type = 'FilterExpression';
