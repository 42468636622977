import React, { Component } from "react";

export default class PathFile extends Component {
  render() {
    const { currentFile } = this.props;
    return (
      <div
        style={{
          height: "30px",
          width: "100%",
          backgroundColor: "#1e1e1e",
          color: "rgb(151 151 151)",
          paddingLeft: "15px",
          fontSize: "14px",
          fontFamily: `"Droid Sans Mono", "monospace", monospace`,
        }}
      >
        {`${currentFile.path}`}
      </div>
    );
  }
}
