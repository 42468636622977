import List from './List';
import UIBuilder from '../BaseComponent';
import { UI_COMPONENTS_TYPE_FIELD } from '../../../../Constant/Constant';

export default class ListBuilder extends UIBuilder {
  constructor() {
    super();
    this.schema = {
      data: UI_COMPONENTS_TYPE_FIELD.JSON,
      horizontal: UI_COMPONENTS_TYPE_FIELD.BOOLEAN,
      item: UI_COMPONENTS_TYPE_FIELD.JSON,
    };
  }

  getType = () => {
    return 'list';
  };

  getComponent = () => {
    return List;
  };
}
