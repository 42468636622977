import Builder from '..';

export const build = (DefineConfig, resource) => {
  try {
    const variableName = DefineConfig.name;
    const variableValue = Builder.traverseBuild(DefineConfig.init, resource);

    if (typeof resource === 'undefined') {
      resource = {};
    }

    if (typeof resource.variableEnvironment === 'undefined') {
      resource.variableEnvironment = {};
    }

    resource.variableEnvironment[variableName] = variableValue;
    // return resource;
    return variableValue;
  } catch (error) {
    return undefined;
  }
};

export const type = 'Define';
