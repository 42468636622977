// import DeviceInfo from "react-native-device-info";

// const uniqueId = DeviceInfo.getUniqueId();
// const IpAddress = DeviceInfo.getIpAddressSync();
// const macAdress = DeviceInfo.getMacAddressSync();

const originDeviceIdPattern = '[a-z0-9]+_[0-9a-f]+_[a-z0-9-]+';
const subDeviceIdPattern = '[a-z0-9]+_[0-9a-f]+_[a-z0-9-]+_[0-9a-f]+_[A-Za-z0-9-]+';
const flags = {
  command: {
    app: {
      update_state: 'update_state',
      request_state: 'request_state',
      request_setting: 'request_setting',
      request_automation: 'request_automation',
      update_setting: 'update_setting',
    },
    rule: {
      update_state: 'update_state',
    },
  },
  ota: {
    app: {
      update_ota: 'update_ota',
    },
  },
  heartbeat: {
    device: {},
  },
  info: {
    device: {
      report_info: 'report_info',
    },
  },
  setting: {
    app: {
      scan: 'scan',
      update_setting: 'update_setting',
      delete_setting: 'delete_setting',
    },
  },
  automation: {
    app: {
      update_automation: 'update_automation',
    },
  },
  report: {
    device: {
      report_state: 'report_state',
      report_setting: 'report_setting',
      report_automation: 'report_automation',
      get_list: 'get_list',
      delete_device: 'delete_device',
    },
  },
};

const types = {
  command: 'command',
  setting: 'setting',
  heartbeat: 'heartbeat',
  info: 'info',
  state: 'state',
  ota: 'ota',
  report: 'report',
  automation: 'automation',
};

export function getDeviceId(inputText) {
  if (inputText) {
    if (isTopicOfSubDevice(inputText)) {
      let reOfSubDevice = new RegExp(subDeviceIdPattern, 'i');
      let outputReOfSubDevice = inputText.match(reOfSubDevice);
      if (outputReOfSubDevice !== null) {
        return outputReOfSubDevice[0];
      }
    }
    let reOfOriginDevice = new RegExp('^' + originDeviceIdPattern, 'i');
    let outputReOfOriginDevice = inputText.match(reOfOriginDevice);
    if (outputReOfOriginDevice !== null) {
      return outputReOfOriginDevice[0];
    }
  }

  return null;
}

export function getTopicType(topic) {
  if (checkMessageType(topic, types.state)) {
    return types.state;
  }
  if (checkMessageType(topic, types.info)) {
    return types.info;
  }
  if (checkMessageType(topic, types.heartbeat)) {
    return types.heartbeat;
  }

  if (checkMessageType(topic, types.report)) {
    return types.report;
  }
  return null;
}

export function makeBodyMessage(data = {}, flag = { type: '', action: '', service: 'app' }, _userId, wifiMac) {
  // const { type, service, action } = flag;
  let { type, service, action } = flag;
  service = service ? service : 'app';
  // service = 'app';

  //Logger.terminalInfo('flag', JSON.stringify(service));
  if (Object.values(types).includes(type) && Object.keys(flags[type]).includes(service) && Object.values(flags[type][service]).includes(action)) {
    switch (flag.type) {
      case types.command:
        // ????????????????
        if (action === 'update_setting') {
          return {
            uid: '',
            session_id: '',
            version: '1.0',
            created_at: Date.now(),
            header: {
              source: { service_name: service, id: _userId },
              destination: 'broker',
              requested_by: {
                service_name: service,
                id: _userId,
                //   uniqueId,
                wifiMac,
                //   wifiIp: IpAddress,
                //   macAdress,
              },
            },
            data: {
              action,
              setting: data,
              options: {},
            },
          };
        /////////////////
        } else {
          return {
            uid: '',
            session_id: '',
            version: '1.0',
            created_at: Date.now(),
            header: {
              source: { service_name: service, id: _userId },
              destination: 'broker',
              requested_by: {
                service_name: service,
                id: _userId,
                //   uniqueId,
                wifiMac,
                //   wifiIp: IpAddress,
                //   macAdress,
              },
            },
            data: {
              action,
              state: data,
              options: {},
            },
          };
        }

      //
      case types.ota:
        if (data?.msg_version === '0.1') {
          return {
            uid: '1',
            audit: {
              service: 'app',
              createdBy: _userId,
              createdAt: Date.now(),
            },
            query: {
              actor: 'app',
              action: 'upgrade',
              data,
              options: {},
            },
            options: {},
          };
        }
        return {
          uid: '',
          session_id: '',
          version: '1.0',
          created_at: Date.now(),
          header: {
            source: { service_name: 'app', id: _userId },
            destination: 'broker',
            // uniqueId,
            wifiMac,
            // wifiIp: IpAddress,
            // macAdress,
          },
          data: {
            action,
            ota_info: data,
            options: {},
          },
        };

      case types.setting:
        return {
          uid: '',
          session_id: '',
          version: '1.0',
          created_at: Date.now(),
          header: {
            source: { service_name: 'app', id: _userId },
            destination: 'broker',
            // uniqueId,
            wifiMac,
            // wifiIp: IpAddress,
            // macAdress,
          },
          data: {
            action,
            setting: data,
            options: {},
          },
        };
      case types.automation:
        return {
          uid: '',
          session_id: '',
          version: '1.0',
          created_at: Date.now(),
          header: {
            source: { service_name: 'app', id: _userId },
            destination: 'broker',
            // uniqueId,
            wifiMac,
            // wifiIp: IpAddress,
            // macAdress,
          },
          data: {
            action,
            rules: data.rules,
            scenes: data.scenes,
            groups: data.groups,
            options: {},
          },
        };
      default:
        return null;
    }
  }

  return null;
}

const checkMessageType = (inputText, topicType) => {
  let reOfOriginDevice = new RegExp('^' + originDeviceIdPattern + '/' + topicType, 'i');
  let reOfSubDevice = new RegExp('^' + originDeviceIdPattern + '/' + subDeviceIdPattern + '/' + topicType, 'i');
  let outputReOfOriginDevice = inputText.match(reOfOriginDevice);
  let outputReOfSubDevice = inputText.match(reOfSubDevice);
  if (outputReOfOriginDevice !== null || outputReOfSubDevice !== null) {
    return true;
  }
  return false;
};

const isTopicOfSubDevice = (inputText) => {
  if (inputText) {
    let reOfSubDevice = new RegExp('^' + originDeviceIdPattern + '/' + subDeviceIdPattern + '/', 'i');
    let outputReOfSubDevice = inputText.match(reOfSubDevice);
    if (outputReOfSubDevice !== null) {
      return true;
    }
  }

  return false;
};

export const isOnline = (device_connection_status) => {
  if (device_connection_status.toLowerCase() === 'Online'.toLowerCase()) {
    return true;
  }
  return false;
};

export const isSubDevice = (deviceId) => {
  if (deviceId && deviceId !== undefined && typeof deviceId === 'string' && deviceId.split('_').length === 5) {
    return true;
  }
  return false;
};

export const getParentId = (deviceId) => {
  if (isSubDevice(deviceId)) {
    //Logger.terminalInfo(deviceId);
    let word = deviceId.split('_');
    return word[0] + '_' + word[1] + '_' + word[2];
  }
  return null;
};

export const isFiliotOriginId = (originId) => {
  try {
    if (originId) {
      let reOfOriginDevice = new RegExp('^' + originDeviceIdPattern, 'i');
      let outputReOfOriginDevice = originId.match(reOfOriginDevice);
      if (outputReOfOriginDevice !== null) {
        return true;
      }
    }

    return false;
  } catch (error) {}
};
