import Logger from '../../Helper/Logger';
import fAPI from '../fAPI';
import { BASE_URL, API } from '../fAPI/constants';

const BASE_URI = BASE_URL.prod;

/**
 * @param { email: string }: email of user.
 * @param { password: string }: password of user.
 * @param { appCode: string }: appCode of app.
 * @param { fcmToken: string }: firebase token of user.
 * @param { deviceUID: string }: deviceUID of app.
 * @param { os: string }: os of app.
 * @param { callback: json }: callback of function.
 * @param { callback.success: function }: function will call when sign in success.
 * @param { callback.fail: function }: function will call when sign in fail.
 */

async function getRules({
  spaceId,
  userId,
  page_size,
  page,
  token,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.RULE.GET_RULES}`;
    Logger.terminalInfo('uri', uri);
    const response = await fAPI.get(
      uri,
      {
        Authorization: token,
        'x-user': userId,
      },
      {
        spaceId,
        page,
        page_size,
      },
    );

    typeof callback?.success === 'function' &&
      callback.success({
        ...response,
        items: response.items.sort(function (a, b) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(a.createdAt) - new Date(b.createdAt);
        }),
      });

    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

async function createRule({
  userId,
  ownerId,
  spaceId,
  name,
  start,
  token,
  input,
  HICId,
  output,
  trigger,
  active,
  type,
  logo,
  logic,
  processedAt,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.RULE.GET_RULES}`;
    Logger.terminalInfo('uri', uri);
    const response = await fAPI.post(
      uri,
      {
        Authorization: token,
        'x-user': userId,
      },
      {
        userId,
        ownerId,
        spaceId,
        name,
        start,
        token,
        input,
        HICId,
        output,
        trigger,
        active,
        type,
        logo,
        processedAt,
        logic,
      },
    );

    typeof callback?.success === 'function' && callback.success(response);

    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

async function updateRule({
  userId,
  id,
  name,
  HCId,
  input,
  output,
  type,
  token,
  spaceId,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.RULE.UPDATE_RULE(id)}`;
    Logger.terminalInfo('uri', uri);
    const response = await fAPI.put(
      uri,
      {
        Authorization: token,
        'x-user': userId,
      },
      {
        name,
        HCId,
        input,
        output,
        type,
        spaceId,
      },
    );

    typeof callback?.success === 'function' && callback.success(response);

    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

async function deleteRule({
  id,
  userId,
  token,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.RULE.DELETE_RULE(id)}`;
    Logger.terminalInfo('uri', uri);
    const response = await fAPI.delete(
      uri,
      {
        Authorization: token,
        'x-user': userId,
      },
      {},
    );

    typeof callback?.success === 'function' && callback.success(response);

    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

async function executeGroup({
  id,
  userId,
  state,
  token,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.RULE.EXECUTE_GROUP(id)}`;
    Logger.terminalInfo('uri', uri);
    const response = await fAPI.put(
      uri,
      {
        Authorization: token,
        'x-user': userId,
      },
      {
        state,
      },
    );

    typeof callback?.success === 'function' && callback.success(response);

    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

export const fRules = {
  getRules,
  createRule,
  updateRule,
  deleteRule,
  executeGroup,
};
