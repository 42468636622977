import React, { Component } from 'react';
import Items from './items';
import Detail from './detail';
import Select from './select';
import { MOSBUS_ROLES, cmd } from './constants';

const SCREENS = {
  ITEMS: 'ITEMS',
  DETAIL: 'DETAIL',
  SELECT_DEVICES: 'SELECT_DEVICES',
};

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentScreen: SCREENS.ITEMS,
      currentTimer: {
        isNew: false,
      },
      selectedDevices: [],
    };
    this.mosbusRole = MOSBUS_ROLES.SLAVE;
    const { cEntity } = props;
    const currentChildrenEntities = cEntity.currentChildrenEntities;
    const currentParentEntity = cEntity.currentParentEntity;

    if (
      (currentChildrenEntities === null ||
        typeof currentChildrenEntities === 'undefined' ||
        (Array.isArray(currentChildrenEntities) && currentChildrenEntities.length === 0)) &&
      typeof currentParentEntity?.originId === 'string'
    ) {
      this.mosbusRole = MOSBUS_ROLES.SLAVE;
    }

    if (
      (currentParentEntity === null || typeof currentParentEntity === 'undefined') &&
      Array.isArray(currentChildrenEntities) &&
      currentChildrenEntities.length > 0
    ) {
      this.mosbusRole = MOSBUS_ROLES.GATEWAY;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.cEntity?.currentChildrenEntities) !== JSON.stringify(this.props.cEntity?.currentChildrenEntities) ||
      JSON.stringify(prevProps.cEntity?.currentParentEntity) !== JSON.stringify(this.props.cEntity?.currentParentEntity)
    ) {
      const { cEntity } = this.props;
      const currentChildrenEntities = cEntity.currentChildrenEntities;
      const currentParentEntity = cEntity.currentParentEntity;
      if (
        (currentChildrenEntities === null ||
          typeof currentChildrenEntities === 'undefined' ||
          (Array.isArray(currentChildrenEntities) && currentChildrenEntities.length === 0)) &&
        typeof currentParentEntity?.originId === 'string'
      ) {
        this.mosbusRole = MOSBUS_ROLES.SLAVE;
      }

      if (
        (currentParentEntity === null || typeof currentParentEntity === 'undefined') &&
        Array.isArray(currentChildrenEntities) &&
        currentChildrenEntities.length === 0
      ) {
        this.mosbusRole = MOSBUS_ROLES.GATEWAY;
      }
    }
  }

  navigateItems = () => {
    this.setState({
      currentScreen: SCREENS.ITEMS,
    });
  };

  navigateDetail = (timer) => {
    this.setState({
      currentScreen: SCREENS.DETAIL,
      currentTimer: timer,
    });
  };

  backDetail = () => {
    this.setState({
      currentScreen: SCREENS.DETAIL,
    });
  };

  backItems = () => {
    this.setState({
      currentScreen: SCREENS.ITEMS,
    });
  };

  navigateSelectDevices = () => {
    this.setState({
      currentScreen: SCREENS.SELECT_DEVICES,
    });
  };

  addTimer = (timer) => {
    if (Array.isArray(this.state.selectedDevices) && this.state.selectedDevices.length > 0) {
      if (this.mosbusRole === MOSBUS_ROLES.GATEWAY) {
        this.props.onUpdateSettingRetail?.({
          cron: {
            cmd: cmd.add,
            device_id: this.state.selectedDevices.map((selectedDevice) => selectedDevice.originId),
            ...timer,
          },
        });
      }
    } else {
      this.props.onUpdateSettingRetail?.({
        cron: {
          cmd: cmd.add,
          ...timer,
        },
      });
    }
  };

  editTimer = (timer) => {
    this.props.onUpdateSettingRetail?.({
      cron: {
        cmd: cmd.edit,
        ...timer,
      },
    });
  };

  deleteTimer = (timer) => {
    this.props.onUpdateSettingRetail?.({
      cron: {
        cmd: cmd.del,
        ...timer,
      },
    });
  };

  setSelectedDevices = (selectedDevices) => {
    this.setState({
      selectedDevices,
    });
  };

  renderScreen = () => {
    const { timers, cEntity } = this.props;
    const { currentTimer, selectedDevices } = this.state;
    switch (this.state.currentScreen) {
      case SCREENS.ITEMS:
        return <Items timers={timers} navigateDetail={this.navigateDetail} goBack={this.props.goBack} editTimer={this.editTimer} />;
      case SCREENS.DETAIL:
        return (
          <Detail
            currentTimer={currentTimer}
            navigateItems={this.navigateItems}
            navigateSelectDevices={this.navigateSelectDevices}
            mosbusRole={this.mosbusRole}
            selectedDevices={selectedDevices}
            editTimer={this.editTimer}
            deleteTimer={this.deleteTimer}
            addTimer={this.addTimer}
            backItems={this.backItems}
          />
        );
      case SCREENS.SELECT_DEVICES:
        return (
          <Select
            currentTimer={currentTimer}
            selectedDevices={selectedDevices}
            setSelectedDevices={this.setSelectedDevices}
            devices={Array.isArray(cEntity.currentChildrenEntities) ? cEntity.currentChildrenEntities : []}
            backDetail={this.backDetail}
          />
        );
      default:
        return <></>;
    }
  };

  render() {
    return this.renderScreen();
  }
}
