import Image from './Image';

import UIBuilder from '../BaseComponent';
import { UI_COMPONENTS_TYPE_FIELD } from '../../../../Constant/Constant';

export default class ImageBuilder extends UIBuilder {
  constructor() {
    super();
    this.schema = {
      value: UI_COMPONENTS_TYPE_FIELD.STRING,
    };
  }

  getType = () => {
    return 'image';
  };

  getComponent = () => {
    return Image;
  };
}
