import React, { useEffect } from 'react';
import Sidebar from './sidebar';
import Main from './main';
import Builder from '../../..';
import Header from './header';

export default function Container(props) {
  useEffect(() => {
    const { container, resource } = props;
    if (typeof container === 'undefined') {
      return null;
    }

    const { preprocessing } = container;
    const preprocessFunction = Builder.build(preprocessing, resource);

    if (typeof preprocessFunction === 'function') {
      preprocessFunction();
    }
  }, []);

  const renderSideBar = () => {
    const { container, resource } = props;
    if (typeof container === 'undefined') {
      return null;
    }

    const { sidebar } = container;
    if (typeof sidebar === 'undefined') {
      return null;
    }

    return <Sidebar sidebar={Builder.traverseBuild(sidebar, resource)} resource={resource} />;
  };

  const renderHeader = () => {
    const { container, resource } = props;
    if (typeof container === 'undefined') {
      return null;
    }

    const { header } = container;
    if (typeof header === 'undefined') {
      return null;
    }

    return <Header header={header} resource={resource} />;
  };

  const renderMain = () => {
    const { container, resource } = props;
    if (typeof container === 'undefined') {
      return <>This is main block</>;
    }

    const { main, mainStyle, sidebar } = container;
    if (typeof main === 'undefined') {
      return <>This is main block</>;
    }

    return <Main main={main} mainStyle={mainStyle} resource={resource} sidebar={sidebar} />;
  };

  const { container, resource } = props;
  const isPreview = resource?.appResource?.isPreview;
  const { header } = container;
  const headerHeight = (header?.height || 80) * resource?.appResource?.resolutionRatio;

  return (
    <>
      {renderHeader()}
      {renderSideBar()}
      <div style={{ marginTop: isPreview && typeof header !== 'undefined' ? `-${headerHeight}px` : '0px' }}>{renderMain()}</div>
    </>
  );
}
