import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from '../Modal';
import { FastImage, ScrollView, StyleSheet, Text, TouchableOpacity, View } from '../../React-Native/types';

class ButtonDisable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      showModal: false,
      select: 0,
      currentValue: 0,
    };
  }

  componentDidMount() {
    const { data } = this.props;
    const { value } = data;
    this.setState({ select: value, currentValue: value });
  }

  onCloseModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  onSelectOption = (item) => {
    this.setState({ select: item.value });
  };

  onSaveOption = () => {
    const { select } = this.state;
    this.changeValue(select);
    this.saveValue(select);
    this.setState({ showModal: false });
  };

  changeValue = (text) => {
    this.setState({ currentValue: text });
  };

  saveValue = (select) => {
    this.props.onChangeValue?.(select);
  };

  renderModal = () => {
    const { theme, lang } = this.props;
    const { select } = this.state;
    return (
      <View style={[styles.contentModal, { backgroundColor: theme.colors.appColor }]}>
        <Text style={[styles.titleAction, { color: theme.colors.textColor, fontFamily: theme.fonts.fontBold }]}>{lang.choose}</Text>
        <ScrollView style={{ width: '100%' }}>
          {this.props.data?.items?.map?.((item, index) => {
            return (
              <TouchableOpacity
                style={[styles.row, { borderTopWidth: index !== 0 ? 1 : 0, borderTopColor: theme.colors.borderColor }]}
                key={item?.id || 'key' + index}
                onPress={() => this.onSelectOption(item)}>
                <View style={{ maxWidth: '93%' }}>
                  <Text style={[styles.textSelect, { color: theme.colors.textColor, fontFamily: theme.fonts.fontBold }]}>{item.label}</Text>
                </View>
                {item.value === select && <FastImage source={theme.icons.check} style={{ width: 20, height: 20 }} />}
              </TouchableOpacity>
            );
          })}
        </ScrollView>
        <TouchableOpacity style={[styles.btn, { backgroundColor: theme.colors.activeColor }]} onPress={this.onSaveOption}>
          <Text style={[styles.textBtn, { color: theme.colors.textColorBtn, fontFamily: theme.fonts.fontBold }]}>{lang.save}</Text>
        </TouchableOpacity>
      </View>
    );
  };

  goBack = () => {
    const { navigation } = this.props;
    navigation.goBack();
  };

  getLabel = () => {
    const item = this.props.data?.items?.find?.((_item) => {
      return _item.value === this.state.currentValue;
    });
    return item?.label || 'Select';
  };

  render() {
    const { showModal } = this.state;
    const { theme } = this.props;

    console.log('showModal', showModal);
    return (
      <View style={{ width: '100%', height: '100%' }}>
        <View style={styles.content}>
          <View style={styles.layoutBtn}>
            <TouchableOpacity style={[styles.btnStatus, { backgroundColor: theme.colors.appColor }]} onPress={this.onCloseModal}>
              <View style={{ flex: 1 }}>
                <Text style={[styles.textBtn, { color: theme.colors.textColor, fontFamily: theme.fonts.fontMedium }]}>{this.getLabel()}</Text>
              </View>
              <FastImage source={theme.icons.goTo} style={{ width: 24, height: 24 }} />
            </TouchableOpacity>
          </View>
        </View>
        <Modal
          visible={showModal}
          onRequestClose={this.onCloseModal}
          position={'bottom'}
          style={{
            content: {
              width: '300px',
              height: '600px',
            },
          }}>
          {this.renderModal()}
        </Modal>
      </View>
    );
  }
}

export default connect((state) => {
  return {
    theme: state.theme,
    lang: state.lang,
  };
}, {})(ButtonDisable);

const styles = StyleSheet.create({
  title: {
    fontSize: 20,
    lineHeight: 28,
    maxWidth: '70%',
    textAlign: 'center',
    flex: 1,
  },
  back: {
    width: 32,
    height: 32,
  },
  btnBack: {
    position: 'absolute',
    left: -16,
    padding: 16,
  },
  rowHeader: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    width: '100%',
    paddingBottom: 16,
    paddingHorizontal: 24,
    paddingTop: 50,
  },
  titleAction: {
    fontSize: 18,
    lineHeight: 26,
    marginBottom: 20,
  },
  textSelect: {
    fontSize: 14,
    lineHeight: 24,
  },
  row: {
    width: '100%',
    paddingVertical: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  contentModal: {
    width: '100%',
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  btnStatus: {
    width: '100%',
    height: 60,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 16,
    flexDirection: 'row',
    paddingHorizontal: 20,
  },
  layoutBtn: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  text: {
    fontSize: 16,
    lineHeight: 24,
    marginBottom: 24,
  },
  textBtn: {
    fontSize: 14,
    lineHeight: 24,
  },
  btn: {
    width: '100%',
    height: 56,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 16,
    marginTop: 50,
    marginBottom: 16,
  },
  content: {
    width: '100%',
    flex: 1,
  },
  root: {
    width: '100%',
    flex: 1,
    justifyContent: 'center',
  },
});
