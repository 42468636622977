import React from 'react';
import { connect } from 'react-redux';
import { FastImage, StyleSheet, Text, TextInput, TouchableOpacity, View } from '../../React-Native/types';

class ModalRename extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.name || '',
    };
  }

  onClearValue = () => {
    this.setState({ name: '' });
  };

  onChangeText = (text) => {
    this.setState({ name: text });
  };

  onSaveRename = () => {
    const { name } = this.state;
    this.props.saveRename?.(name);
  };

  onCloseModal = () => {
    this.props.onCloseAnimate?.();
  };

  render() {
    const { theme, lang, label, placeholder, title } = this.props;
    const { name } = this.state;
    return (
      <View style={styles.root}>
        <View style={[styles.container, { backgroundColor: theme.colors.appColor }]}>
          <Text style={[styles.title, { color: theme.colors.textColor, fontFamily: theme.fonts.fontBold }]}>{title || lang.rename}</Text>
          <View style={styles.row}>
            <TextInput
              style={[styles.input, { color: theme.colors.textColor, borderColor: theme.colors.borderInputColor }]}
              placeholder={placeholder || lang.rename}
              placeholderTextColor={theme.colors.placeholderColor}
              value={name}
              onChangeText={this.onChangeText}
            />
            {typeof name !== 'undefined' && name !== '' && (
              <TouchableOpacity style={styles.clearBtn} onPress={this.onClearValue}>
                <FastImage source={theme.icons.cancel} style={styles.iconCancel} resizeMode={'cover'} />
              </TouchableOpacity>
            )}
          </View>
          <View style={styles.layoutBtn}>
            <TouchableOpacity style={[styles.btnCancel, { borderColor: theme.colors.borderInputColor }]} onPress={this.onCloseModal}>
              <Text style={[styles.cancel, { color: theme.colors.textColor, fontFamily: theme.fonts.fontMedium }]}>{lang.cancel}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.btnRename, { backgroundColor: theme.colors.textColor, borderColor: theme.colors.textColor }]}
              onPress={this.onSaveRename}>
              <Text style={[styles.rename, { color: theme.colors.textColorBtn, fontFamily: theme.fonts.fontMedium }]}>{label || lang.rename}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  }
}

export default connect((state) => {
  return {
    theme: state.theme,
    lang: state.lang,
  };
})(ModalRename);

export const BaseModalRename = ModalRename;
export const _connect = (cp) =>
  connect((state) => {
    return {
      theme: state.theme,
      lang: state.lang,
    };
  })(cp);

const styles = StyleSheet.create({
  rename: {
    fontSize: 16,
    lineHeight: 24,
  },
  btnRename: {
    flex: 1,
    height: 44,
    paddingHorizontal: 10,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    borderWidth: 1,
  },
  cancel: {
    fontSize: 16,
    lineHeight: 24,
  },
  btnCancel: {
    flex: 1,
    height: 44,
    paddingHorizontal: 10,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    borderWidth: 1,
    marginRight: 16,
  },
  layoutBtn: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconCancel: {
    width: 18,
    height: 18,
  },
  clearBtn: {
    position: 'absolute',
    right: 8,
  },
  input: {
    width: '100%',
    height: 44,
    borderRadius: 8,
    borderWidth: 1,
    paddingLeft: 8,
    paddingRight: 26,
    fontSize: 16,
  },
  row: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 25,
  },
  title: {
    fontSize: 16,
    lineHeight: 24,
    textAlign: 'center',
  },
  container: {
    marginHorizontal: 24,
    padding: 24,
    borderRadius: 8,
  },
  root: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
