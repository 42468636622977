import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { TYPE_WINDOWS } from '../../../../Constant/Constant';
import withStyles from 'react-jss';
import { getString } from './util';
import Logger from '../../../../Helper/Logger';

function shallowCompare(obj1, obj2) {
  // Check for null values
  if (obj1 === null && obj2 === null) {
    return true;
  }

  // Check for null values individually
  if (obj1 === null || obj2 === null) {
    return false;
  }

  // Check for null values
  if (typeof obj1 === 'undefined' && typeof obj2 === 'undefined') {
    return true;
  }

  // Check for null values individually
  if (typeof obj1 === 'undefined' || typeof obj2 === 'undefined') {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
}

export const ignoreProps = [
  'x',
  'y',
  'style',
  'width',
  'height',
  'value',
  'component',
  'widthBase',
  'heightBase',
  'col',
  'row',
  'compiledMarkdownComponent',
  'fontSizeRatio',
  'type',
  'blockStyle',
  'area',
  'type',
  'inputType',
  'page',
  'zIndex',
  'pxWidth',
  'pxHeight',
  'classes',
];

export function removeObjectKeys(originalObject, ignoreList) {
  const newObj = { ...originalObject };

  ignoreList.forEach((key) => {
    if (newObj.hasOwnProperty(key)) {
      delete newObj[key];
    }
  });

  return { ...newObj, className: originalObject.classes?.[originalObject.className] };
}

const fontSizeRatioStandard = (fontSizeRatio, typeWindow) => {
  let a, b, c, d;
  switch (typeWindow) {
    case TYPE_WINDOWS.LARGE.type:
      a = 0.38095238095247663;
      b = -1.042857142857245;
      c = 0.9976190476191614;
      d = 0.66428571428571;
      return a * fontSizeRatio * fontSizeRatio * fontSizeRatio + b * fontSizeRatio * fontSizeRatio + c * fontSizeRatio + d;
    case TYPE_WINDOWS.SMALL.type:
    case TYPE_WINDOWS.MEDIUM.type:
      a = -1.8904761904762364;
      b = 5.181428571428742;
      c = -3.9638095238096;
      d = 1.6728571428572558;
      return a * fontSizeRatio * fontSizeRatio * fontSizeRatio + b * fontSizeRatio * fontSizeRatio + c * fontSizeRatio + d;

    default:
      break;
  }
};

export const getFontSize = ({ originFontSize, fontSizeRatio, typeWindow }) => {
  let fontSize = originFontSize ?? '16px';

  if (typeof fontSize === 'string') {
    const unit = fontSize.slice(fontSize.length - 2, fontSize.length);
    if (unit === 'px') {
      fontSize = parseFloat(fontSize.slice(0, fontSize.length));
    }
  }

  if (isNaN(fontSize) || typeof fontSize === 'undefined') {
    fontSize = undefined;
  }

  return fontSizeRatio ? fontSize * fontSizeRatioStandard(fontSizeRatio, typeWindow?.type) : fontSize;
};

export default class BaseUI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reRender: false,
      isSelected: false,
      rndModalVisible: false,
    };
    this.col = 50;
    this.row = 150;
    this.blockId = uuidv4();
    this.showModal = false;
    this.restProps = removeObjectKeys(this.props, ignoreProps);

    this.RenderComponent = (props) => {
      this.restProps = removeObjectKeys(this.props, ignoreProps);
      this.restProps.className = this.className;
      return this.renderComponent(props);
    };

    this.className = undefined;
    this.style = {};

    const fontSize = getFontSize({
      originFontSize: props?.fontSize || props.style?.fontSize,
      fontSizeRatio: props.resource.appResource?.fontSizeRatio,
      typeWindow: props.resource.appResource?.typeWindow,
    });

    const style = { ...props.style, fontSize } ?? {};

    if (Object.keys(style).findIndex((key) => key.slice(0, 2) === '&:') !== -1) {
      this.setStyle(style);
      this.className = getString(8);
      this.WrapStyledTypeComponent = withStyles({ [this.className]: this.style }, { generateId: () => this.className })(this.RenderComponent);
      this.setStyle(style);
    } else {
      this.setStyle(style);
      this.WrapStyledTypeComponent = this.RenderComponent;
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextProps.style) !== JSON.stringify(this.props.style)) {
      const resource = nextProps.resource;
      const fontSizeRatio = resource.appResource?.fontSizeRatio;
      const typeWindow = resource.appResource?.typeWindow;

      const fontSize = getFontSize({ originFontSize: nextProps?.fontSize || nextProps.style?.fontSize, fontSizeRatio, typeWindow });

      const style = { ...nextProps.style, fontSize };

      this.className = undefined;

      if (Object.keys(style).findIndex((key) => key.slice(0, 2) === '&:') !== -1) {
        this.setStyle(style);
        this.className = getString(8);
        this.WrapStyledTypeComponent = withStyles({ [this.className]: this.style }, { generateId: () => this.className })(this.RenderComponent);
        this.setStyle(style);
      } else {
        this.setStyle(style);
      }
    }

    return true;
  }

  setStyle(style) {
    this.style = {
      width: '100%',
      height: '100%',
      display: 'flex',
      ...style,
    };

    this.style =
      typeof this.className === 'string'
        ? {}
        : {
            ...this.style,
            ...this.adjustStyle(),
          };
  }

  getStyle() {}

  adjustStyle() {}

  renderComponent(x, y, width, height) {
    const { style, value } = this.props;
    return (
      <div
        style={{
          ...style,
        }}>
        {value}
      </div>
    );
  }

  render() {
    const WrapStyledTypeComponent = this.WrapStyledTypeComponent;

    return <WrapStyledTypeComponent {...this.props} />;
  }
}
