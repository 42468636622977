import React, { Component } from 'react';
import { Image, ScrollView, StyleSheet, Text, TouchableOpacity, View } from '../../React-Native/types';
import { connect } from 'react-redux';
import Switch from './switch';
import TimePicker from 'react-time-picker';

class AirConditionLimitTimeUseMobus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      limit_time: Array.isArray(props.limit_time) ? props.limit_time : [],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.limit_time) !== JSON.stringify(this.props.limit_time)) {
      this.setState({ limit_time: this.props.limit_time });
    }
  }

  onChangeTime = (time, index) => {
    this.setState({
      limit_time: [...this.state.limit_time].map((_limit_time, _limit_time_index) => {
        if (_limit_time_index === index) {
          return { ..._limit_time, ...time };
        }
        return _limit_time;
      }),
    });
  };

  onChangeEnable = (enable, index) => {
    this.setState({
      limit_time: [...this.state.limit_time].map((_limit_time, _limit_time_index) => {
        if (_limit_time_index === index) {
          return { ..._limit_time, enable };
        }
        return _limit_time;
      }),
    });
  };

  save = () => {
    this.props.onChangeValue?.({ limit_time: this.state.limit_time });
    this.props.goBack?.();
  };

  render() {
    const { theme, lang } = this.props;
    const { limit_time } = this.state;
    return (
      <View
        style={{
          width: '100%',
          flex: 1,
        }}>
        <View
          style={{
            width: '100%',
            paddingBottom: 16,
            paddingHorizontal: 24,
            paddingTop: 50,
          }}>
          <View
            style={{
              width: '100%',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <TouchableOpacity
              style={{
                position: 'absolute',
                left: -16,
                padding: 16,
              }}
              onPress={this.props.goBack}>
              <Image source={theme.icons.back} style={{ width: 32, height: 32 }} />
            </TouchableOpacity>
            <Text
              style={{
                color: theme.colors.textColor,
                fontFamily: theme.fonts.fontBold,
                width: '100%',
                flex: 1,
                fontSize: 20,
                lineHeight: 28,
                maxWidth: '70%',
                textAlign: 'center',
              }}
              ellipsizeMode={'tail'}
              numberOfLines={1}>
              {'Giới hạn thời gian sử dụng'}
            </Text>
            <TouchableOpacity
              style={{
                position: 'absolute',
                right: -16,
                padding: 16,
              }}
              onPress={this.save}>
              <Text
                style={{
                  color: theme.colors.textColor,
                  fontFamily: theme.fonts.fontBold,
                  width: '100%',
                  flex: 1,
                  fontSize: 16,
                  lineHeight: 28,
                  maxWidth: '70%',
                  textAlign: 'center',
                }}>
                Lưu
              </Text>
            </TouchableOpacity>
          </View>
        </View>
        <View style={{ width: '100%', height: '100%', justifyContent: 'center', flexDirection: 'column', paddingBottom: '40px' }}>
          <Text
            style={{
              color: 'rgb(251 9 9)',
              fontFamily: theme.fonts.fontBold,
              width: '100%',
              flex: 1,
              fontSize: 14,
              lineHeight: 28,
              textAlign: 'left',
              paddingLeft: 28,
              paddingRight: 16,
            }}>
            (*) Thiết bị sẽ không thể bị điều khiển trong những khung thời gian được kích hoạt
          </Text>
          <ScrollView style={{ paddingHorizontal: 16 }}>
            {Array.isArray(limit_time) &&
              limit_time.map((_limit_time, index) => {
                const { t_start, t_end, enable } = _limit_time;
                const m_t_start = t_start % 60;
                const h_t_start = (t_start - m_t_start) / 60;

                const m_t_end = t_end % 60;
                const h_t_end = (t_end - m_t_end) / 60;
                return (
                  <View>
                    <Text
                      style={{
                        color: theme.colors.textColor,
                        fontFamily: theme.fonts.fontBold,
                        width: '100%',
                        flex: 1,
                        fontSize: 20,
                        lineHeight: 28,
                        maxWidth: '70%',
                        textAlign: 'left',
                        marginLeft: 12,
                        marginTop: 12,
                        fontWeight: '700',
                      }}
                      ellipsizeMode={'tail'}
                      numberOfLines={1}>
                      Giới hạn {index + 1}
                    </Text>
                    <View
                      style={{
                        width: '100%',
                        flex: 1,
                        fontSize: 20,
                        lineHeight: 28,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingHorizontal: 12,
                        marginTop: 6,
                      }}>
                      <Text
                        style={{
                          color: theme.colors.textColor,
                          fontFamily: theme.fonts.fontBold,
                          width: '100%',
                          flex: 1,
                          fontSize: 16,
                          lineHeight: 28,
                          maxWidth: '70%',
                          textAlign: 'left',
                          marginLeft: 15,
                        }}>
                        Kích hoạt
                      </Text>
                      <Switch checked={enable} onChangeValue={(value) => this.onChangeEnable(value, index)} style={{ marginRight: '30px' }} />
                    </View>
                    <View
                      style={{
                        width: '100%',
                        flex: 1,
                        fontSize: 20,
                        lineHeight: 28,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingHorizontal: 12,
                        marginTop: 4,
                      }}>
                      <Text
                        style={{
                          color: theme.colors.textColor,
                          fontFamily: theme.fonts.fontBold,
                          width: '100%',
                          flex: 1,
                          fontSize: 16,
                          lineHeight: 28,
                          maxWidth: '70%',
                          textAlign: 'left',
                          marginLeft: 15,
                        }}>
                        Thời gian bắt đầu
                      </Text>
                      <TimePicker
                        mode={'time'}
                        value={`${h_t_start}:${m_t_start}`}
                        onChange={(event, date) => {
                          if (event !== null) {
                            const hours = parseInt(event.split(':')[0]);
                            const minutes = parseInt(event.split(':')[1]);
                            this.onChangeTime({ t_start: hours * 60 + minutes }, index);
                          }
                        }}
                        disableClock={true}
                        clearIcon={<></>}
                        className='clock-touch-press'
                      />
                    </View>
                    <View
                      style={{
                        width: '100%',
                        flex: 1,
                        fontSize: 20,
                        lineHeight: 28,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingHorizontal: 12,
                        marginTop: 4,
                      }}>
                      <Text
                        style={{
                          color: theme.colors.textColor,
                          fontFamily: theme.fonts.fontBold,
                          width: '100%',
                          flex: 1,
                          fontSize: 16,
                          lineHeight: 28,
                          maxWidth: '70%',
                          textAlign: 'left',
                          marginLeft: 15,
                        }}>
                        Thời gian kết thúc
                      </Text>
                      <TimePicker
                        mode={'time'}
                        value={`${h_t_end}:${m_t_end}`}
                        onChange={(event, date) => {
                          if (event !== null) {
                            const hours = parseInt(event.split(':')[0]);
                            const minutes = parseInt(event.split(':')[1]);
                            this.onChangeTime({ t_end: hours * 60 + minutes }, index);
                          }
                        }}
                        disableClock={true}
                        clearIcon={<></>}
                        className='clock-touch-press'
                      />
                    </View>
                  </View>
                );
              })}
          </ScrollView>
        </View>
      </View>
    );
  }
}

export default connect((state) => {
  return {
    theme: state.theme,
    lang: state.lang,
  };
}, {})(AirConditionLimitTimeUseMobus);

const styles = StyleSheet.create({
  change: {
    fontSize: 18,
    lineHeight: 24,
  },
  btn: {
    paddingVertical: 8,
    paddingHorizontal: 8,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    marginTop: 50,
    width: '100%',
    alignSelf: 'center',
    height: 56,
  },
});
