import React, { Component } from 'react';
import { StyleSheet, View, Text, TouchableOpacity, Animated, PanResponder, Dimensions } from '../../React-Native/types';
import { connect } from 'react-redux';
import { fixLowTen, getDayInWeek, parseDayWeek, types } from '../../../../../../Helper/App/Core/Helper/cron';
import validate from '../../../../../../Helper/App/Core/Helper/validate';

import Switch from './switch';
import Logger from '../../../../../../Helper/Logger';

const width = Dimensions.get('screen').width;

const schema = {
  type: 'object',
  properties: {
    action: {
      type: 'object',
      properties: {
        on_off: {
          type: 'boolean',
        },
      },
      required: ['on_off'],
    },
    str: {
      type: 'string',
    },
    enable: {
      type: 'boolean',
    },
    id: {
      type: 'number',
    },
  },
  required: ['action', 'str', 'enable', 'id'],
};

const makeFullCron = (cron) => {
  if (cron === undefined || cron === null) {
    return '0 0 0 * * *';
  }
  if (cron.split(' ').length === 5) {
    return ['0', ...cron.split(' ')].join(' ');
  }
  return cron;
};

class TimerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: validate(props?.timer, schema)
        ? props?.timer
        : {
            action: {
              on_off: true,
            },
            str: '0 0 * * *',
            enable: true,
          },
      visible: false,
    };

    Logger.terminalInfo('props?.timer', props?.timer, validate(props?.timer, schema));
    this.getTimer = this.getTimer.bind(this);
    // this.pan = new Animated.ValueXY({ x: 0, y: 0 });
    this.startX = 0;
    // this.construct();
    this.panDisplayDelete = -75;
    this.panAutoDelete = -width / 2;
    this.panInfinity = -width;
    this.rootX = 0;
    /**
     * Component has 2 mode: touchable & swipe. Touchable to press & navigate to detail timer screen. Swipe to swipe.
     */
    this.isTouchableMode = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.timer) !== JSON.stringify(this.props.timer)) {
      this.setState({
        item: validate(this.props?.timer, schema)
          ? this.props?.timer
          : {
              action: {
                on_off: true,
              },
              str: '0 0 * * *',
              enable: true,
            },
      });
    }
  }

  onShowDelete = () => {
    this.setState({
      visible: true,
    });
  };

  onHideDelete = () => {
    this.setState({
      visible: false,
    });
  };

  getTimer(cron) {
    if (cron) {
      const { lang } = this.props;
      let seconds, minutes, hours, day, month, day_week;
      const splitCron = cron.split(' ');
      if (splitCron.length === 5) {
        minutes = splitCron[0];
        hours = splitCron[1];
        day = splitCron[2];
        month = splitCron[3];
        day_week = splitCron[4];
      } else {
        seconds = splitCron[0];
        minutes = splitCron[1];
        hours = splitCron[2];
        day = splitCron[3];
        month = splitCron[4];
        day_week = splitCron[5];
      }
      if (!isNaN(parseInt(minutes, 10)) && !isNaN(parseInt(hours, 10))) {
        const parseDayWeekResult = parseDayWeek(day_week);
        switch (parseDayWeekResult.type) {
          case types.ANY:
            return {
              timeInDay: `${fixLowTen(hours)} : ${fixLowTen(minutes)}`,
              dayInWeek: lang.everyDay,
              day_week,
            };

          case types.SEPARATOR:
            let dayInWeek = '';
            parseDayWeekResult.value.forEach((e, index) => {
              dayInWeek += getDayInWeek(e);
              if (index !== parseDayWeekResult.value.length - 1) {
                dayInWeek += ', ';
              }
            });
            return {
              timeInDay: `${fixLowTen(hours)} : ${fixLowTen(minutes)}`,
              dayInWeek,
              day_week,
            };

          case types.VALUE:
            return {
              timeInDay: `${fixLowTen(hours)} : ${fixLowTen(minutes)}`,
              dayInWeek: getDayInWeek(parseDayWeekResult.value),
              day_week,
            };

          default:
            return {
              timeInDay: lang.undefined,
              dayInWeek: lang.undefined,
              day_week: undefined,
            };
        }
      }
      return {
        timeInDay: lang.undefined,
        dayInWeek: lang.undefined,
        day_week: undefined,
      };
    }

    return {};
  }

  onSaveTimer = (_timer) => {
    this.props.onChangeValue?.(_timer);
  };

  changeEnableCron = (_timer, enable) => {
    this.props.editTimer({ ..._timer, enable });
  };

  onDelete = () => {
    this.onHideDelete();
  };

  getAction = () => {
    const { lang } = this.props;
    const { item } = this.state;
    const { action } = item;
    const { on_off } = action;
    if (on_off && on_off.toString() === 'true') {
      return lang.control;
    } else {
      return lang.off;
    }
  };

  render() {
    const { lang, theme } = this.props;
    const { item } = this.state;
    Logger.terminalInfo('item timercomponent', item);
    const { action, str, enable } = item;
    const { timeInDay, dayInWeek } = this.getTimer(str);
    return (
      <View style={{ flexDirection: 'row', marginVertical: 8, borderRadius: 8, border: '1px solid black' }}>
        <View style={{ flexGrow: 1, backgroundColor: '#f9f9f9', borderRadius: 8 }}>
          <View key={item?.id} style={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', ...this.props.style }}>
            <View style={[styles.content, { width: '100%', flex: 1 }]}>
              <TouchableOpacity style={[{ flex: 6 }, styles.infoContainer]} onPress={() => this.props.navigateEditTimer?.(item)}>
                <View style={[{ maxWidth: '100%', flex: 1 }, styles.textContainer]}>
                  <Text style={[{ fontFamily: theme.fonts.fontMedium, color: theme.colors.textColor, fontSize: 14 }, styles.timeInDay]}>{timeInDay}</Text>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                    <Text style={[{ fontFamily: theme.fonts.fontMedium, color: theme.colors.textColor, flexWrap: 'wrap', flex: 2 }, styles.dayInWeek]}>
                      {dayInWeek}
                    </Text>
                    <View style={{ flex: 0.5 }}>
                      <Text style={{ color: theme.colors.textColor }}>--{'>'}</Text>
                    </View>
                    <Text style={[{ flex: 1, fontFamily: theme.fonts.fontMedium, color: theme.colors.textColor }]}>{this.getAction()}</Text>
                  </View>
                </View>
              </TouchableOpacity>
              <View style={[{ flex: 1 }, styles.actionContainer]}>
                <Switch checked={enable} onChangeValue={(active) => this.changeEnableCron(item, active)} />
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

export default connect(
  (state) => {
    return {
      theme: state.theme,
      lang: state.lang,
    };
  },
  // { createObject },
)(TimerComponent);

const styles = StyleSheet.create({
  textOnOff: {
    fontSize: 12,
    lineHeight: 20,
  },
  textAtion: {
    fontSize: 14,
    lineHeight: 24,
  },
  actionContainer: {
    alignItems: 'center',
  },
  textContainer: {},
  timeInDay: {
    fontWeight: '700',
    fontSize: 24,
  },
  dayInWeek: {
    flexWrap: 'wrap',
  },
  content: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderRadius: 20,
    borderWidth: 1,
  },
  infoContainer: {
    flexDirection: 'row',
  },
  iconContainer: {
    paddingVertical: 8,
    paddingHorizontal: 11,
    backgroundColor: '#fff',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    height: 24,
    width: 24,
  },
  title: {
    marginBottom: 5,
    textAlign: 'center',
  },
  flex: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  heading: {
    fontSize: 18,
    marginBottom: 6,
    marginTop: 30,
  },
  input: {
    backgroundColor: '#fff',
  },
  text: {
    fontSize: 15,
    lineHeight: 24,
    marginLeft: 10,
    color: '#fff',
  },
  rename: {
    fontSize: 16,
    lineHeight: 24,
  },
  btnRename: {
    flex: 1,
    height: 44,
    paddingHorizontal: 10,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    borderWidth: 1,
  },
  cancel: {
    fontSize: 16,
    lineHeight: 24,
  },
  btnCancel: {
    flex: 1,
    height: 44,
    paddingHorizontal: 10,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    borderWidth: 1,
    marginRight: 16,
  },
  layoutBtn: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconCancel: {
    width: 18,
    height: 18,
  },
  clearBtn: {
    position: 'absolute',
    right: 8,
  },

  row: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 25,
  },
  container: {
    marginHorizontal: 24,
    padding: 24,
    borderRadius: 8,
  },
});
