import React, { Component } from 'react';
import { Text, View } from '../../UI_ReactNative/React-Native/types';
import { connect } from 'react-redux';
import validate from '../../../../../Helper/App/View/Attribute/validate';
import DynamicComponent from './attributedynamiccomponent';
import _ from 'lodash';
import { extractValue, updateObjectProperty } from '../../../../../Helper/App/View/Attribute/extractor';
import { AttributeSchema } from './Constants/schemas';
import Logger from '../../../../../Helper/Logger';
import { FiliotController } from '../../../../../Controller/FiliotController';

export const actions = {
  update_setting: 'update_setting',
  delete_setting: 'delete_setting',
  update_automation: 'update_automation',
  received: 'received',
};

const getReceivedData = (cEntityAttribute, cEntity) => {
  let receivedData;

  if (['timer', 'timer_hcl'].includes(cEntityAttribute)) {
    receivedData = cEntity?.automation?.rules?.[cEntityAttribute] || [];
  } else {
    receivedData = cEntity?.setting?.[cEntityAttribute];
  }

  return receivedData;
};

class DetailAttribute extends Component {
  constructor(props) {
    super(props);
    this.width = props.width || '492px';
    this.height = props.height || '600px';
    const { cEntityAttribute } = props;

    this.state = {
      data: {},
      [cEntityAttribute]: getReceivedData(cEntityAttribute, props.cEntity),
    };
    this.inputRef = null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextProps.cEntity.state) !== JSON.stringify(this.props.cEntity.state)) {
      return false;
    }
    return true;
  }

  // handle after

  // shouldComponentUpdate(nextProps, nextState) {
  //   //Logger.terminalInfo('shouldComponentUpdate');
  //     const { cEntityAttribute } = this.props;
  //     if (this.state.updateView !== nextState.updateView) {
  //         return true;
  //     }

  //     if (JSON.stringify(this.props.cEntity.info) !== JSON.stringify(nextProps.cEntity.info)) {
  //         return true;
  //     }

  //     if (
  //         JSON.stringify(this.props.cEntity?.setting?.[cEntityAttribute]) !== JSON.stringify(nextProps.cEntity?.setting?.[cEntityAttribute]) ||
  //         JSON.stringify(nextProps.cEntity?.automation?.rules?.[cEntityAttribute]) !==
  //             JSON.stringify(this.props.cEntity?.automation?.rules?.[cEntityAttribute])
  //     ) {
  //         if (['timer', 'timer_hcl'].includes(cEntityAttribute)) {
  //           //Logger.terminalInfo(
  //                 'JSON.stringify(nextProps.cEntity?.automation?.rules?.[cEntityAttribute])',
  //                 JSON.stringify(nextProps.cEntity?.automation?.rules?.[cEntityAttribute]),
  //             );

  //           //Logger.terminalInfo('JSON.stringify(this.state[cEntityAttribute])', this.state[cEntityAttribute]);
  //             if (JSON.stringify(nextProps.cEntity?.automation?.rules?.[cEntityAttribute]) !== JSON.stringify(this.state[cEntityAttribute])) {
  //                 return true;
  //             }
  //         } else {
  //             if (JSON.stringify(nextProps.cEntity?.setting?.[cEntityAttribute]) !== JSON.stringify(this.state[cEntityAttribute])) {
  //                 return true;
  //             }
  //         }
  //     }
  //     return false;
  // }

  componentDidUpdate(prevProps, prevState) {
    const { cEntityAttribute } = this.props;
    if (
      JSON.stringify(prevProps.cEntity?.setting?.[cEntityAttribute]) !== JSON.stringify(this.props.cEntity?.setting?.[cEntityAttribute]) ||
      JSON.stringify(prevProps.cEntity?.automation?.rules?.[cEntityAttribute]) !== JSON.stringify(this.props.cEntity?.automation?.rules?.[cEntityAttribute])
    ) {
      let receivedData;
      if (['timer', 'timer_hcl'].includes(cEntityAttribute)) {
        if (JSON.stringify(this.props.cEntity?.automation?.rules?.[cEntityAttribute]) !== JSON.stringify(this.state[cEntityAttribute])) {
          receivedData = this.props.cEntity?.automation?.rules?.[cEntityAttribute];
          this.setState({
            [cEntityAttribute]: receivedData,
          });
        }
      } else {
        if (JSON.stringify(this.props.cEntity?.setting?.[cEntityAttribute]) !== JSON.stringify(this.state[cEntityAttribute])) {
          receivedData = this.props.cEntity?.setting?.[cEntityAttribute];
          this.setState({
            [cEntityAttribute]: receivedData,
          });
        }
      }
    }
  }

  onChangeState = (pathToState, newValue, index) => {
    if (Array.isArray(pathToState)) {
      const _pathToState = pathToState;
      const currentValue = extractValue(pathToState, this.state);
      if (Array.isArray(currentValue)) {
        _pathToState.push(index);
      }
      const updateState = updateObjectProperty(this.state, _pathToState, newValue);
      this.setState(updateState);
    }
  };

  onChangeStateWithPushSetting = (pathToState, newValue, index) => {
    if (Array.isArray(pathToState)) {
      const _pathToState = [...pathToState];
      const currentValue = extractValue(pathToState, this.state);
      if (Array.isArray(currentValue)) {
        _pathToState.push(index);
      }
      const updateState = updateObjectProperty(this.state, _pathToState, newValue);
      this.setState(updateState, () => {
        this.onSaveSetting(pathToState);
      });
    }
  };

  onChangeStateWithPushAutomation = (pathToState, newValue, index) => {
    if (Array.isArray(pathToState)) {
      const _pathToState = [...pathToState];
      const currentValue = extractValue(pathToState, this.state);
      if (Array.isArray(currentValue)) {
        _pathToState.push(index);
      }
      const updateState = updateObjectProperty(this.state, _pathToState, newValue);
      this.setState(updateState, () => {
        this.onSaveAutomationRule(pathToState);
      });
    }
  };

  onSaveSetting = (pathToState) => {
    if (Array.isArray(pathToState)) {
      const updateState = extractValue(pathToState, this.state);
      const { cEntityAttribute } = this.props;
      this.onUpdateSetting({ [cEntityAttribute]: updateState });
    }
  };

  onSaveAutomationRule = (pathToState) => {
    if (Array.isArray(pathToState)) {
      const updateState = extractValue(pathToState, this.state);
      const { cEntityAttribute } = this.props;
      this.onUpdateAutomationRules({ [cEntityAttribute]: updateState });
    }
  };

  onUpdateSetting = (data = {}) => {
    Logger.terminalInfo('----------onUpdateSetting-------------------');
    const { cEntity, settingConfig, cEntityAttribute } = this.props;
    const parentOriginId = cEntity?.currentParentEntity?.originId;
    const attribute = settingConfig.attributes.find((_attribute) => _attribute.name === cEntityAttribute);
    Logger.terminalInfo(`attribute: ${JSON.stringify(attribute)}`);
    if (attribute) {
      const action = attribute.schema.actions.find((_action) => _action.action === 'update_setting');
      Logger.terminalInfo(`action: ${JSON.stringify(action)}`);
      if (action) {
        Logger.terminalInfo(`validate(data, action.schema): ${validate(data[cEntityAttribute], action.schema)}`);
        Logger.terminalInfo(`data: ${JSON.stringify(data[cEntityAttribute])}`);
        Logger.terminalInfo(`action.schema: ${JSON.stringify(action.schema)}`);
        if (validate(data[cEntityAttribute], action.schema)) {
          FiliotController.updateDeviceSetting({ deviceId: cEntity.originId, data, parentId: parentOriginId });
        }
      } else {
        FiliotController.updateDeviceSetting({ deviceId: cEntity.originId, data, parentId: parentOriginId });
      }
    }
    Logger.terminalInfo('-----------------------------------------------');
  };

  onSaveSettingRetail = (pathToState) => {
    if (Array.isArray(pathToState)) {
      const updateState = extractValue(pathToState, this.state);
      const { cEntityAttribute } = this.props;
      this.onUpdateSettingRetail({ [cEntityAttribute]: updateState });
    }
  };

  onUpdateSettingRetail = (data = {}) => {
    Logger.terminalInfo('----------onUpdateSetting-------------------');
    const { cEntity, settingConfig, cEntityAttribute } = this.props;
    const parentOriginId = cEntity?.currentParentEntity?.originId;
    const attribute = settingConfig.attributes.find((_attribute) => _attribute.name === cEntityAttribute);
    Logger.terminalInfo(`attribute: ${JSON.stringify(attribute)}`);
    if (attribute) {
      const action = attribute.schema.actions.find((_action) => _action.action === 'update_setting');
      Logger.terminalInfo(`action: ${JSON.stringify(action)}`);
      if (action) {
        Logger.terminalInfo(`validate(data, action.schema): ${validate(data[cEntityAttribute], action.schema)}`);
        Logger.terminalInfo(`data: ${JSON.stringify(data[cEntityAttribute])}`);
        Logger.terminalInfo(`action.schema: ${JSON.stringify(action.schema)}`);
        if (validate(data[cEntityAttribute], action.schema)) {
          FiliotController.updateDeviceSettingRetail({ deviceId: cEntity.originId, data, parentId: parentOriginId });
        }
      } else {
        FiliotController.updateDeviceSettingRetail({ deviceId: cEntity.originId, data, parentId: parentOriginId });
      }
    }
    Logger.terminalInfo('-----------------------------------------------');
  };

  onUpdateOta = (data = {}) => {
    const { cEntity } = this.props;
    const parentOriginId = cEntity?.currentParentEntity?.originId;
    data.msg_version = cEntity?.info?.msg_version || '1.0';
    FiliotController.updateDeviceOta({ deviceId: cEntity.originId, data, parentId: parentOriginId });
  };

  onDeleteSetting = (data = {}) => {
    const { cEntity } = this.props;
    const parentOriginId = cEntity?.currentParentEntity?.originId;
    FiliotController.deleteDeviceSetting({ deviceId: cEntity.originId, data, parentId: parentOriginId });
  };

  onUpdateAutomation = (data = {}) => {
    const { cEntity } = this.props;
    const parentOriginId = cEntity?.currentParentEntity?.originId;
    FiliotController.updateDeviceAutomation({ deviceId: cEntity.originId, data: { ...cEntity.automation, ...data }, parentId: parentOriginId });
  };

  onUpdateAutomationRules = (rules = {}) => {
    Logger.terminalInfo('----------onUpdateAutomationRules-------------------');
    const { settingConfig, cEntityAttribute } = this.props;
    const attribute = settingConfig.attributes.find((_attribute) => _attribute.name === cEntityAttribute);
    if (attribute) {
      //Logger.terminalInfo('attribute: ', JSON.stringify(attribute));
      const action = attribute.schema.actions.find((_action) => _action.action === 'update_automation');
      //Logger.terminalInfo('action: ', JSON.stringify(action));
      if (action) {
        //Logger.terminalInfo('validate(rules[cEntityAttribute], action.schema): ', validate(rules[cEntityAttribute], action.schema));
        //Logger.terminalInfo('data: ', JSON.stringify(rules[cEntityAttribute]));
        //Logger.terminalInfo('action.schema: ', JSON.stringify(action.schema));
        if (validate(rules[cEntityAttribute], action.schema)) {
          this.onUpdateAutomation({ rules });
        }
      } else {
        this.onUpdateAutomation({ rules });
      }
    }
    Logger.terminalInfo('-----------------------------------------------');
  };

  onNavigate = (screenName, params) => {
    const { navigation } = this.props;
    if (['detailtimer', 'detailwifi'].includes(screenName)) {
      navigation.navigate(screenName, {
        ...params,
      });
    }
  };

  goBack = () => {
    this.props.closeModal();
  };

  renderDetailAttribute = () => {
    try {
      const { cEntityAttribute, settingConfig, cEntity } = this.props;
      Logger.terminalInfo('cEntity', cEntity);
      Logger.terminalInfo('settingConfig', settingConfig);
      Logger.terminalInfo('cEntityAttribute', cEntityAttribute);
      Logger.terminalInfo('getReceivedData(cEntityAttribute, cEntity)', getReceivedData(cEntityAttribute, cEntity));

      // Logger.terminalInfo(`cEntityAttribute::: ${cEntityAttribute}}`);

      try {
        const attributeConfig = _.cloneDeep(settingConfig.attributes.find((attribute) => attribute.name === cEntityAttribute));
        if (attributeConfig) {
          // // Logger.terminalInfo(`validate(attributeConfig, AttributeSchema)::: ${validate(attributeConfig, AttributeSchema)}`);
          if (validate(attributeConfig, AttributeSchema)) {
            const receivedSchema = attributeConfig.schema.actions.find((action) => action.action === actions.received)?.schema;
            // Logger.terminalInfo(`receivedSchema ::: ${JSON.stringify(receivedSchema)}`);

            if (receivedSchema || attributeConfig.name === 'updateFirmware') {
              // >>TrungTT<< Hiển thị trường hợp bên dưới ko gửi dữ liệu lên.
              const receivedData = getReceivedData(cEntityAttribute, cEntity);
              // Logger.terminalInfo(`receivedData:::${JSON.stringify(receivedData)}`);
              // Logger.terminalInfo(
              //   `validate(receivedData, receivedSchema) || receivedData === undefined:::${
              //     validate(receivedData, receivedSchema) || receivedData === undefined
              //   }`,
              // );
              if (validate(receivedData, receivedSchema) || receivedData === undefined) {
                // Logger.terminalInfo(`attributeConfig:::${JSON.stringify(attributeConfig)}`);

                return this.renderScreen(attributeConfig, settingConfig);
              } else {
                throw new Error('\n\nreceived data is invalid');
              }
            } else {
              throw new Error('received schema is undefined');
            }
          } else {
            throw new Error('attribute config is invalid');
          }
        }
        throw new Error("attribute config isn't found");
      } catch (error) {
        console.log('error', error);
        throw error;
      }
    } catch (error) {
      return <Text style={{ marginTop: 20, color: 'red' }}>{error.message}</Text>;
    }
  };

  renderScreen = (attributeConfig, settingConfig) => {
    try {
      return (
        <DynamicComponent
          display={attributeConfig.display}
          externalData={{
            lang: settingConfig.externalData.lang,
            theme: settingConfig.externalData.theme,
            this: {
              state: this.state,
              goBack: this.goBack,
              onNavigate: this.onNavigate,
              onChangeState: this.onChangeState,
              onSaveAutomationRule: this.onSaveAutomationRule,
              onSaveSetting: this.onSaveSetting,
              onDeleteSetting: this.onDeleteSetting,
              onChangeStateWithPushSetting: this.onChangeStateWithPushSetting,
              onChangeStateWithPushAutomation: this.onChangeStateWithPushAutomation,
              onUpdateOta: this.onUpdateOta,
              onUpdateSetting: this.onUpdateSetting,
              onUpdateSettingRetail: this.onUpdateSettingRetail,
              onSaveSettingRetail: this.onSaveSettingRetail,
              props: {
                cEntity: this.props.cEntity,
                navigation: {
                  goBack: () => {
                    this.goBack();
                  },
                },
              },
            },
          }}
        />
      );
    } catch (error) {
      const { theme } = this.props;
      return <Text style={{ color: theme.colors.textColor, color: 'red' }}>{error.message}</Text>;
    }
  };

  render() {
    const { theme } = this.props;
    return (
      <View
        style={[
          {
            width: this.width,
            height: this.height,
            flex: 1,
            backgroundColor: theme.colors.appColorThird,
          },
        ]}>
        {this.renderDetailAttribute()}
      </View>
    );
    // return this.renderDetailAttribute();
  }
}

export default connect((state) => {
  return {
    theme: state.theme,
    lang: state.lang,
  };
})(DetailAttribute);
