class Navigator {
    constructor() {
        this.navigator = undefined;
        this.rootNavigator = undefined;
    }

    setNavigator(navigator) {
        this.navigator = navigator;
        // this.navigate({screen:"home2"})
    }

    setRootNavigator(rootNavigator) {
        this.rootNavigator = rootNavigator;
    }
}

export default new Navigator();
