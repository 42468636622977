import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BooleanComponent, BooleanModal } from './types/boolean';
import { IntComponent, IntModal } from './types/int';
import { StringComponent, StringModal } from './types/string';
import { TimeComponent } from './types/time';
import { ButtonComponent, ButtonModal } from './types/button';
import { CheckBoxComponent, CheckBoxModal } from './types/checkbox';
import { SelectComponent, SelectModal } from './types/select';
import { DayWeekComponent, DayWeekModal } from './types/dayweek';
import { JsonComponent, JsonModal } from './types/json';
import { StyleSheet, Text, View } from '../../React-Native/types';
import Modal from '../Modal';
import TimePicker from 'react-time-picker';
import Logger from '../../../../../../Helper/Logger';

const types = {
  boolean: 'bool',
  string: 'string',
  time: 'time',
  button: 'btn',
  int: 'int',
  dayweek: 'dayweek',
  json: 'json',
  select: 'select',
  checkbox: 'checkbox',
};

class TouchableOpacityModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      value: undefined,
      isDatePickerVisible: false,
      selectItemHeight: 0,
    };
  }

  componentDidMount() {
    this.setState({
      value: this.props.value,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(this.props.value) !== JSON.stringify(prevProps.value)) {
      this.setState({
        value: this.props.value,
      });
    }
  }

  showModal = () => {
    const { type, edit } = this.props;
    Logger.terminalInfo('showModal', type, edit);
    if (edit) {
      if (type === types.time) {
        this.setState({
          isDatePickerVisible: true,
        });
      } else {
        if (type !== types.boolean) {
          this.setState({
            visible: true,
          });
        }
      }
    }
  };

  closeModal = () => {
    this.setState({
      visible: false,
    });
  };

  renderModalContent = () => {
    const { type } = this.props;
    const { value } = this.state;
    switch (type) {
      case types.boolean:
        return <BooleanModal />;
      case types.int:
        const { min, max, step, useSlide } = this.props;
        return <IntModal min={min} max={max} value={value} step={step} save={this.save} useSlide={useSlide} />;
      case types.string:
        return <StringModal value={value} save={this.save} />;
      case 'str':
        return <StringModal value={value} save={this.save} />;
      case types.button:
        return <ButtonModal value={value} save={this.save} closeModal={this.closeModal} />;
      case types.dayweek:
        return <DayWeekModal value={value} save={this.save} closeModal={this.closeModal} />;

      case types.json:
        return <JsonModal value={value} save={this.save} closeModal={this.closeModal} />;

      case types.select:
        return <SelectModal value={value} save={this.save} data={this.props.data} />;
      case types.checkbox:
        return <CheckBoxModal value={value} save={this.save} data={this.props.data} />;
      default:
        return <></>;
    }
  };

  save = (value) => {
    this.closeModal();
    this.props.onChangeValue?.(value);
  };

  renderItem = () => {
    const { type, title } = this.props;
    const { value } = this.props;
    switch (type) {
      case types.boolean:
        return <BooleanComponent title={title} value={value} onChangeValue={this.props.onChangeValue} />;
      case types.int:
        return <IntComponent title={title} value={value} onChangeValue={this.props.onChangeValue} showModal={this.showModal} />;
      case types.string:
        return <StringComponent title={title} value={value} onChangeValue={this.props.onChangeValue} showModal={this.showModal} />;
      case 'str':
        return <StringComponent title={title} value={value} onChangeValue={this.props.onChangeValue} showModal={this.showModal} />;
      case types.time:
        return <TimeComponent title={title} value={value} onChangeValue={this.props.onChangeValue} showModal={this.showModal} />;
      case types.button:
        return <ButtonComponent title={title} value={value} onChangeValue={this.props.onChangeValue} showModal={this.showModal} />;
      case types.checkbox:
        return <CheckBoxComponent title={title} value={value} onChangeValue={this.props.onChangeValue} showModal={this.showModal} data={this.props.data} />;
      case types.select:
        return <SelectComponent title={title} value={value} onChangeValue={this.props.onChangeValue} showModal={this.showModal} data={this.props.data} />;
      case types.dayweek:
        return <DayWeekComponent title={title} value={value} onChangeValue={this.props.onChangeValue} showModal={this.showModal} />;
      case types.json:
        return <JsonComponent title={title} value={value} onChangeValue={this.props.onChangeValue} showModal={this.showModal} />;
      default:
        return <></>;
    }
  };

  render() {
    const { title } = this.props;
    const titleContent = title?.content;
    const { visible } = this.state;
    const styles = { ...style, ...this.style };
    const height = this.props.windowHeight;
    const width = this.props.windowWidth;
    return (
      <>
        <Modal
          visible={visible}
          onRequestClose={this.closeModal}
          style={{
            content: {
              width: '500px',
              height: '600px',
            },
          }}>
          <View style={[{ backgroundColor: '#fff', marginHorizontal: 20, borderRadius: 8, maxHeight: height - 50 }, styles.modalContainer]}>
            <View style={styles.heading}>
              <Text style={styles.headingText}>{titleContent}</Text>
            </View>
            <View style={[styles.body, { maxHeight: height - 150 }]}>{this.renderModalContent()}</View>
          </View>
        </Modal>
        {this.renderItem()}
      </>
    );
  }
}

export default connect((state) => {
  return {
    theme: state.theme,
    lang: state.lang,
  };
})(TouchableOpacityModal);

const style = StyleSheet.create({
  modalContainer: {
    paddingHorizontal: 20,
    paddingVertical: 18,
  },
  heading: {
    marginBottom: 12,
    alignItems: 'center',
  },
  headingText: {
    fontSize: 24,
    fontWeight: '700',
    color: '#000',
  },
  body: {
    paddingHorizontal: 12,
  },
  bottom: {
    alignItems: 'center',
    paddingBottom: 20,
    marginTop: 20,
  },
});
