import { WarningActions, WarningTypes } from '../../../UI_ReactNative/Components/Warning/constant';
import { actions, componentTypes, operators, regexs, types } from './common';

export const ConfigSchema = {
    type: 'object',
    properties: {
        externalData: {
            type: 'object',
        },
        attributes: {
            type: 'array',
        },
        version: {
            type: 'string',
        },
    },
    required: ['externalData', 'attributes', 'version'],
};

// export const warningItem = {
//     type: types.object,
//     properties: {
//         name: {
//             type: types.string,
//             enum: Object.values(WarningTypes),
//         },
//         op: {
//             type: types.object,
//         },
//         enable: {
//             type: types.object,
//         },
//         action: {
//             type: types.object,
//         },
//         value: {
//             type: types.object,
//         },
//     },
//     required: ['name', 'op', 'enable', 'action', 'value'],
// };

export const warningItem = {
    name: 'voltage',
    items: [
        {
            op: 'lt',
            value: 100,
            action: {
                active_protect: true,
                warning: true,
            },
            enable: false,
        },
        {
            op: 'gt',
            value: 150,
            action: {
                active_protect: true,
                warning: true,
            },
            enable: false,
        },
    ],
    valueSchema: {
        type: 'number',
        min: 100,
        max: 200,
    },
};

export const AttributeSchema = {
    type: 'object',
    properties: {
        name: {
            type: 'string',
        },
        title: {
            type: 'string',
        },
        schema: {
            type: 'object',
            properties: {
                descriptionSetValue: {
                    type: 'object',
                },
                actions: {
                    type: 'array',
                    items: {
                        type: 'object',
                        properties: {
                            action: {
                                type: 'string',
                                enum: Object.values(actions),
                            },
                            schema: {
                                type: 'object',
                            },
                        },
                        required: ['action', 'schema'],
                    },
                },
            },
        },
        display: {
            type: 'object',
        },
    },
    required: ['name', 'title', 'schema', 'display'],
};

export const defaultSchema = [
    {
        name: componentTypes.select,
        dataSchema: {
            type: types.number,
            enum: [0, 1],
            required: true,
        },
        actions: [
            {
                action: actions.update_setting,
                dataSchema: {
                    type: types.object,
                    properties: {
                        select: {
                            type: types.number,
                            enum: [0, 1],
                        },
                    },
                    required: ['select'],
                },
            },
        ],
    },
    {
        name: componentTypes.select,
        dataSchema: {
            type: types.number,
            enum: [0, 1, 2],
            required: true,
        },
        actions: [
            {
                action: actions.update_setting,
                dataSchema: {
                    type: types.object,
                    properties: {
                        select: {
                            type: types.number,
                            enum: [0, 1, 2],
                        },
                    },
                    required: ['select'],
                },
            },
        ],
    },
    {
        name: componentTypes.meter_reading_date,
        dataSchema: {
            type: types.number,
            minimum: 1,
            maximum: 28,
        },
        actions: [
            {
                action: actions.update_setting,
                dataSchema: {
                    type: types.object,
                    properties: {
                        meter_reading_date: {
                            type: types.number,
                            minimum: 1,
                            maximum: 28,
                        },
                    },
                    required: ['meter_reading_date'],
                },
            },
        ],
    },
    {
        name: componentTypes.updateFirmware,
        dataSchema: {},
        actions: [],
    },
    {
        name: componentTypes.wifi,
        dataSchema: {
            type: types.array,
            items: {
                type: types.object,
                properties: {
                    ssid: { type: types.string, minLength: 1 },
                    pass: { type: types.string, minLength: 8 },
                    active: { type: types.boolean },
                },
                required: ['ssid', 'pass', 'active'],
            },
            required: ['wifi'],
        },
        actions: [
            {
                action: actions.update_setting,
                dataSchema: {
                    type: types.object,
                    properties: {
                        wifi: {
                            type: types.object,
                            properties: {
                                ssid: { type: types.string, minLength: 1 },
                                pass: { type: types.string, minLength: 8 },
                            },
                            required: ['ssid', 'pass'],
                        },
                    },
                    required: ['wifi'],
                },
            },
            {
                action: actions.delete_setting,
                dataSchema: {
                    type: types.object,
                    properties: {
                        wifi: {
                            type: types.object,
                            properties: {
                                ssid: { type: types.string, minLength: 1 },
                            },
                            required: ['ssid'],
                        },
                    },
                    required: ['wifi'],
                },
            },
        ],
    },
    {
        name: componentTypes.timer,
        dataSchema: {
            type: types.array,
            items: {
                type: types.object,
                properties: {
                    id: {
                        type: types.string,
                        required: true,
                    },
                    cron: {
                        type: types.string,
                        pattern: regexs.cron,
                        required: true,
                    },
                    action: {
                        type: types.object,
                        properties: {
                            state: {
                                type: types.object,
                                properties: {
                                    on_off: {
                                        type: types.boolean,
                                        required: true,
                                    },
                                },
                                required: ['on_off'],
                            },
                        },
                        required: ['state'],
                    },
                    enable: {
                        type: types.boolean,
                        required: true,
                    },
                },
            },
        },
        actions: [
            {
                action: actions.update_automation,
                dataSchema: {
                    type: types.object,
                    properties: {
                        timer: {
                            type: types.array,
                            items: {
                                type: types.object,
                                properties: {
                                    id: {
                                        type: types.string,
                                        required: true,
                                    },
                                    cron: {
                                        type: types.string,
                                        pattern: regexs.cron,
                                        required: true,
                                    },
                                    action: {
                                        type: types.object,
                                        properties: {
                                            state: {
                                                type: types.object,
                                                properties: {
                                                    on_off: {
                                                        type: types.boolean,
                                                        required: true,
                                                    },
                                                },
                                                required: ['on_off'],
                                            },
                                        },
                                        required: ['state'],
                                    },
                                    enable: {
                                        type: types.boolean,
                                        required: true,
                                    },
                                },
                            },
                        },
                    },
                },
            },
        ],
    },
    {
        name: componentTypes.timer_hcl,
        dataSchema: {
            type: types.object,
            properties: {
                enable: {
                    type: types.boolean,
                    required: true,
                },
                list: {
                    type: types.array,
                    items: {
                        type: types.object,
                        properties: {
                            cron: {
                                type: types.string,
                                required: true,
                                pattern: regexs.cron,
                            },
                            action: {
                                type: types.object,
                                properties: {
                                    state: {
                                        type: types.object,
                                        properties: {
                                            temp: {
                                                type: types.number,
                                                required: true,
                                                min: 17,
                                                max: 32,
                                            },
                                        },
                                        required: ['temp'],
                                    },
                                },
                                required: ['state'],
                            },
                        },
                    },
                },
            },
        },
        actions: [
            {
                action: actions.update_automation,
                dataSchema: {
                    type: types.object,
                    properties: {
                        enable: {
                            type: types.boolean,
                            required: true,
                        },
                        list: {
                            type: types.array,
                            items: {
                                type: types.object,
                                properties: {
                                    cron: {
                                        type: types.string,
                                        required: true,
                                        pattern: regexs.cron,
                                    },
                                    action: {
                                        type: types.object,
                                        properties: {
                                            state: {
                                                type: types.object,
                                                properties: {
                                                    temp: {
                                                        type: types.number,
                                                        required: true,
                                                        min: 17,
                                                        max: 32,
                                                    },
                                                },
                                                required: ['temp'],
                                            },
                                        },
                                        required: ['state'],
                                    },
                                },
                            },
                        },
                    },
                },
            },
        ],
    },
    {
        name: componentTypes.warning,
        dataSchema: {
            type: types.array,
            items: {
                type: types.object,
                properties: {
                    name: {
                        type: types.string,
                        enum: Object.values(WarningTypes),
                        required: true,
                    },
                    op: {
                        type: types.string,
                        enum: Object.values(operators),
                        required: true,
                    },
                    enable: {
                        type: types.boolean,
                        required: true,
                    },
                    action: {
                        type: types.array,
                        items: {
                            type: types.object,
                            properties: {
                                name: {
                                    type: types.string,
                                    enum: Object.values(WarningActions),
                                    required: true,
                                },
                                enable: {
                                    type: types.boolean,
                                    required: true,
                                },
                            },
                            required: ['name', 'enable'],
                        },
                        required: true,
                    },
                    value: {
                        type: [types.number, types.string],
                    },
                    valueSchema: {
                        type: types.object,
                    },
                },
                required: ['name', 'op', 'enable', 'action', 'value'],
            },
        },
        actions: [
            {
                action: actions.update_setting,
                dataSchema: {
                    type: types.object,
                    properties: {
                        name: {
                            type: types.string,
                            enum: Object.values(WarningTypes),
                            required: true,
                        },
                        op: {
                            type: types.string,
                            enum: Object.values(operators),
                            required: true,
                        },
                        enable: {
                            type: types.boolean,
                            required: true,
                        },
                        action: {
                            type: types.array,
                            items: {
                                type: types.object,
                                properties: {
                                    name: {
                                        type: types.string,
                                        enum: Object.values(WarningActions),
                                        required: true,
                                    },
                                    enable: {
                                        type: types.boolean,
                                        required: true,
                                    },
                                },
                                required: ['name', 'enable'],
                            },
                            required: true,
                        },
                        value: {
                            type: [types.number, types.string],
                        },
                        valueSchema: {
                            type: types.object,
                        },
                    },
                    required: ['name', 'op', 'enable', 'action', 'value'],
                },
            },
        ],
    },
];
