import Builder from '..';

export const build = (ArrayLengthConfig, resource) => {
  const array = Builder.build(ArrayLengthConfig.array, resource);

  const arrayValue = Array.isArray(array) ? array : [];
  return arrayValue.length;
};

export const type = 'ArrayLength';
