import { Dimensions } from '../../../React-Native/types';

const theme = {
  type: 'light',
  constants: {
    windowWidth: Dimensions.get('window').width,
    windowHeight: Dimensions.get('window').height,
  },
  fonts: {
    fontLight: 'Roboto-Light',
    fontRegular: 'Roboto-Regular',
    fontMedium: 'Roboto-Medium',
    fontBold: 'Roboto-Bold',
  },
  colors: {
    appColor: '#FFFFFF',
    appColorSecond: '#F0F2F7',
    appColorThird: '#F9F9FC',
    textColor: '#22252D',
    textColorSecond: '#FFFFFF',
    textColorInactive: '#7F8596',
    textColorBtn: '#FFFFFF',
    textInfor: '#494E5B',
    placeholderColor: '#7F8596',
    errorColor: '#E7574E',
    borderColor: '#F0F2F7',
    borderColorSecond: '#E1E3E7',
    borderInputColor: '#23262F',
    btnColor: '#FFFFFF',
    btnColorSecond: '#FB6D23',
    colorSwitch: '#7F8596',
    colorSwitchSecond: '#08CE87',
    activeColor: '#3059DE',
    inactiveColor: '#8492A6',
    linkColor: '#3059DE',
    disableColor: '#B1B5C3',
    modalColor: 'rgba(35, 38, 47, 0.24)',
    onLineColor: '#01AA4F',
  },
  icons: {
    homekitbg: require('./WebP/homekitbg.webp'),
    settings: require('./WebP/settings.webp'),
    homekit: require('./WebP/homekit.webp'),
    '404notfound': require('./WebP/404notfound.webp'),
    warning: require('./WebP/warning.webp'),
    addFour: require('./WebP/addFour.webp'),
    copyRight: require('./WebP/copyright.webp'),
    location2: require('./WebP/location2.webp'),
    sunnyWeather: require('./WebP/Weather/01d.webp'),
    clearWeather: require('./WebP/Weather/01n.webp'),
    partlyCloudyDayWeather: require('./WebP/Weather/02d.webp'),
    partlyCloudyNightWeather: require('./WebP/Weather/02n.webp'),
    cloudyDayWeather: require('./WebP/Weather/03d.webp'),
    cloudyNightWeather: require('./WebP/Weather/03n.webp'),
    overCastDayWeather: require('./WebP/Weather/04d.webp'),
    overCastNightWeather: require('./WebP/Weather/04n.webp'),
    rainDayWeather: require('./WebP/Weather/09d.webp'),
    rainNightWeather: require('./WebP/Weather/09n.webp'),
    patchyRainDayWeather: require('./WebP/Weather/10d.webp'),
    patchyRainNightWeather: require('./WebP/Weather/10n.webp'),
    thunderyRainDayWeather: require('./WebP/Weather/11d.webp'),
    thunderyRainNightWeather: require('./WebP/Weather/11n.webp'),
    snowDayWeather: require('./WebP/Weather/13d.webp'),
    snowNightWeather: require('./WebP/Weather/13n.webp'),
    blackheartoutline: require('./WebP/blackheart.webp'),
    whitehouseoutline: require('./WebP/whitehouseoutline.webp'),
    whiteheartoutline: require('./WebP/heartoutline.webp'),
    whiteheart: require('./WebP/whiteheart.webp'),
    checkAll: require('./WebP/checkAll.webp'),
    edit2: require('./WebP/edit2.webp'),
    failIcon: require('./WebP/failIcon.webp'),
    iconSuccess: require('./WebP/successIcon.webp'),
    loadingGif: require('./WebP/loadingGif.gif'),
    loading: require('./WebP/loading.gif'),
    logo: require('./WebP/logo.webp'),
    back: require('./WebP/back.webp'),
    home: require('./WebP/home.webp'),
    homeA: require('./WebP/homeA.webp'),
    homeASecond: require('./WebP/homeASecond.webp'),
    auto: require('./WebP/auto.webp'),
    autoA: require('./WebP/autoA.webp'),
    automation: require('./WebP/automation.webp'),
    automationA: require('./WebP/automationA.webp'),
    automationSecond: require('./WebP/automationSecond.webp'),
    setting: require('./WebP/setting.webp'),
    settingA: require('./WebP/settingA.webp'),
    settingSecond: require('./WebP/settingSecond.webp'),
    homeIcon: require('./WebP/homeIcon.webp'),
    add: require('./WebP/add.webp'),
    addSecond: require('./WebP/addSecond.webp'),
    addThird: require('./WebP/addThird.webp'),
    down: require('./WebP/down.webp'),
    downSecond: require('./WebP/downSecond.webp'),
    area: require('./WebP/area.webp'),
    hc: require('./WebP/hc.webp'),
    lightBulb: require('./WebP/lightBulb.webp'),
    location: require('./WebP/location.webp'),
    unCheck: require('./WebP/unCheck.webp'),
    check: require('./WebP/check.webp'),
    checkprogress: require('./WebP/Image/check.gif'),
    goTo: require('./WebP/goTo.webp'),
    edit: require('./WebP/edit.webp'),
    calendar: require('./WebP/calendar.webp'),
    cancel: require('./WebP/cancel.webp'),
    manageArea: require('./WebP/manageArea.webp'),
    manageDevice: require('./WebP/manageDevice.webp'),
    manageHc: require('./WebP/manageHc.webp'),
    manageMember: require('./WebP/manageMember.webp'),
    manageNoti: require('./WebP/manageNoti.webp'),
    mangeLangAndTheme: require('./WebP/mangeLangAndTheme.webp'),
    iconUser: require('./WebP/iconUser.webp'),
    line: require('./WebP/line.webp'),
    livingRoom: require('./WebP/livingRoom.webp'),
    chip: require('./WebP/chip.webp'),
    trash: require('./WebP/trash.webp'),
    select: require('./WebP/select.webp'),
    us: require('./WebP/us.webp'),
    vi: require('./WebP/vi.webp'),
    logout: require('./WebP/logout.webp'),
    lightBulbWhite: require('./WebP/lightBulbWhite.webp'),
    share: require('./WebP/share.webp'),
    transfer: require('./WebP/transfer.webp'),
    error: require('./WebP/error.webp'),
    inforDevice: require('./WebP/inforDevice.webp'),
    switchOff: require('./WebP/switchOff.webp'),
    switchOn: require('./WebP/switchOn.webp'),
    inforFan: require('./WebP/inforFan.webp'),
    decreaseOff: require('./WebP/decreaseOff.webp'),
    decreaseOn: require('./WebP/decreaseOn.webp'),
    increaseOff: require('./WebP/increaseOff.webp'),
    increaseOn: require('./WebP/increaseOn.webp'),
    off: require('./WebP/off.webp'),
    on: require('./WebP/on.webp'),
    lowSpeed: require('./WebP/lowSpeed.webp'),
    mediumSpeed: require('./WebP/mediumSpeed.webp'),
    hightSpeed: require('./WebP/hightSpeed.webp'),
    swingDeactive: require('./WebP/swingDeactive.webp'),
    swingActive: require('./WebP/swingActive.webp'),
    inforCurtain: require('./WebP/inforCurtain.webp'),
    inforAircondition: require('./WebP/inforAircondition.webp'),
    wifi: require('./WebP/wifi.webp'),
    wifi1: require('./WebP/wifi1.webp'),
    wifi2: require('./WebP/wifi2.webp'),
    wifi3: require('./WebP/wifi3.webp'),
    unWifi: require('./WebP/unWifi.webp'),
    swap: require('./WebP/swap.webp'),
    swapSecond: require('./WebP/swapSecond.webp'),
    swapThird: require('./WebP/swapThird.webp'),
    droplets: require('./WebP/droplets.webp'),
    loop: require('./WebP/loop.webp'),
    colorTemp: require('./WebP/colorTemp.webp'),
    colorWheel: require('./WebP/colorWheel.webp'),
    picker: require('./WebP/picker.webp'),
    powerOn: require('./WebP/powerOn.webp'),
    mainsc: require('./WebP/mainsc.webp'),
    increase: require('./WebP/increase.webp'),
    decrease: require('./WebP/decrease.webp'),
    btnDown: require('./WebP/btnDown.webp'),
    btnLeft: require('./WebP/btnLeft.webp'),
    btnRight: require('./WebP/btnRight.webp'),
    btnUp: require('./WebP/btnUp.webp'),
    ok: require('./WebP/ok.webp'),
    undo: require('./WebP/undo.webp'),
    mic: require('./WebP/mic.webp'),
    listApp: require('./WebP/listApp.webp'),
    lineSlider: require('./WebP/lineSlider.webp'),
    offSwitch: require('./WebP/offSwitch.webp'),
    onSwitch: require('./WebP/onSwitch.webp'),
    decreaseBtnActive: require('./WebP/decreaseBtnActive.webp'),
    decreaseBtnDeactive: require('./WebP/decreaseBtnDeactive.webp'),
    increaseBtnActive: require('./WebP/increaseBtnActive.webp'),
    increaseBtnDeactive: require('./WebP/increaseBtnDeactive.webp'),
    coolBtnActive: require('./WebP/coolBtnActive.webp'),
    coolBtnDeactive: require('./WebP/coolBtnDeactive.webp'),
    speedBtnActive: require('./WebP/speedBtnActive.webp'),
    speedBtnDeactive: require('./WebP/speedBtnDeactive.webp'),
    swingBtnActive: require('./WebP/swingBtnActive.webp'),
    swingBtnDeactive: require('./WebP/swingBtnDeactive.webp'),
    reverseBtnActive: require('./WebP/reverseBtnActive.webp'),
    reverseBtnDeactive: require('./WebP/reverseBtnDeactive.webp'),
    nextBtnActive: require('./WebP/nextBtnActive.webp'),
    nextBtnDeactive: require('./WebP/nextBtnDeactive.webp'),
    active0: require('./WebP/active0.webp'),
    deactive0: require('./WebP/deactive0.webp'),
    active20: require('./WebP/active20.webp'),
    deactive20: require('./WebP/deactive20.webp'),
    active40: require('./WebP/active40.webp'),
    deactive40: require('./WebP/deactive40.webp'),
    active60: require('./WebP/active60.webp'),
    deactive60: require('./WebP/deactive60.webp'),
    active80: require('./WebP/active80.webp'),
    deactive80: require('./WebP/deactive80.webp'),
    active100: require('./WebP/active100.webp'),
    deactive100: require('./WebP/deactive100.webp'),
    key: require('./WebP/key.webp'),
    controls: require('./WebP/controls.webp'),
    trackProgress: require('./WebP/trackProgress.webp'),
    phone: require('./WebP/phone.webp'),
    lock: require('./WebP/lock.webp'),
    show: require('./WebP/show.webp'),
    hide: require('./WebP/hide.webp'),
    mail: require('./WebP/mail.webp'),
    user: require('./WebP/user.webp'),
    addDevice: require('./WebP/addDevice.webp'),
    iconManageHome: require('./WebP/iconManageHome.webp'),
    iconManageArea: require('./WebP/iconManageArea.webp'),
    iconAddArea: require('./WebP/iconAddArea.webp'),
    iconAddDevice: require('./WebP/iconAddDevice.webp'),
    iconAddHome: require('./WebP/iconAddHome.webp'),
    iconAddMember: require('./WebP/iconAddMember.webp'),
    iconAirCondition: require('./WebP/iconAirCondition.webp'),
    iconElectricMeter: require('./WebP/iconElectricMeter.webp'),
    iconWaterPurifier: require('./WebP/iconWaterPurifier.webp'),
    avatarDefault: require('./WebP/avatarDefault.webp'),
    photo: require('./WebP/photo.webp'),
    mediaLibary: require('./WebP/mediaLibary.webp'),
    iconElectricMeterSecond: require('./WebP/iconElectricMeterSecond.webp'),
    logoNotiDefault: require('./WebP/logoNotiDefault.webp'),
    logoNotiElectricMeter: require('./WebP/logoNotiElectricMeter.webp'),
    editWaterPurifier: require('./WebP/editWaterPurifier.webp'),
    iconRiceTech: require('./WebP/iconRiceTech.webp'),
    agree: require('./WebP/agree.webp'),
    unAgree: require('./WebP/unAgree.webp'),
    iconVerifyCode: require('./WebP/iconVerifyCode.webp'),
    iconLocation: require('./WebP/iconLocation.webp'),
    menu: require('./WebP/menu.webp'),
    cancelSecond: require('./WebP/cancelSecond.webp'),
    iconForDefault: require('./WebP/iconForDefault.webp'),
    manageDeviceSecond: require('./WebP/manageDeviceSecond.webp'),
    areaSecond: require('./WebP/areaSecond.webp'),
    sort: require('./WebP/sort.webp'),
    search: require('./WebP/search.webp'),
    changeArea: require('./WebP/changeArea.webp'),
    shareMember: require('./WebP/shareMember.webp'),
    successIconSecond: require('./WebP/successIconSecond.webp'),
    warningIcon: require('./WebP/warningIcon.webp'),
    backSecond: require('./WebP/backSecond.webp'),
    cancelThird: require('./WebP/cancelThird.webp'),
    manageUser: require('./WebP/manageUser.webp'),
    camera: require('./WebP/camera.webp'),
    cancelFour: require('./WebP/cancelFour.webp'),
    iconTdsFirst: require('./WebP/iconTdsFirst.webp'),
    logoConfirmConnect: require('./WebP/logoConfirmConnect.webp'),
    avartarFirst: require('./WebP/avartarFirst.webp'),
    dotSlide: require('./WebP/dotSlide.webp'),
    dotSlideA: require('./WebP/dotSlideA.webp'),
    scan: require('./WebP/scan.webp'),
    connectWifi: require('./WebP/connectWifi.webp'),
    wifiRouter: require('./WebP/wifiRouter.webp'),
    fail: require('./WebP/fail.webp'),
    success: require('./WebP/success.webp'),
    backThird: require('./WebP/backThird.webp'),
    sortSecond: require('./WebP/sortSecond.webp'),
    menuSecond: require('./WebP/menuSecond.webp'),
    warningSecond: require('./WebP/warningSecond.webp'),
    clock: require('./WebP/clock.webp'),
    plus: require('./WebP/plus.webp'),
    trashDelete: require('./WebP/trashDelete.webp'),
    hot: require('./WebP/hot.webp'),
    dry: require('./WebP/dry.webp'),
    autoMode: require('./WebP/autoMode.webp'),
    fan: require('./WebP/fan.webp'),
    speedlow: require('./WebP/speedlow.webp'),
    speedmax: require('./WebP/speedmax.webp'),
    speedmed: require('./WebP/speedmed.webp'),
    autoModeActive: require('./WebP/autoModeActive.webp'),
    avatarDefaultSecond: require('./WebP/avatarDefaultSecond.webp'),
    iconDeleteAccount: require('./WebP/iconDeleteAccount.webp'),
    iconRgb: require('./WebP/iconRgb.webp'),
    iconWhiteLight: require('./WebP/iconWhiteLight.webp'),
    iconCurtain: require('./WebP/iconCurtain.webp'),
    reloadSecond: require('./WebP/reloadSecond.webp'),
    doorClose: require('./WebP/doorClose.webp'),
    doorOpen: require('./WebP/doorOpen.webp'),
    iconHc: require('./WebP/iconHc.webp'),
    iconSwitch: require('./WebP/iconSwitch.webp'),
    downKey: require('./WebP/downKey.webp'),
    upKey: require('./WebP/upKey.webp'),
    verticalLine: require('./WebP/verticalLine.webp'),
    loadingSuccess: require('./WebP/loadingSuccess.gif'),
    timer: require('./WebP/timer.webp'),
    remove: require('./WebP/remove.webp'),
    acept: require('./WebP/acept.webp'),
    deny: require('./WebP/deny.webp'),
    updateFirmwareIcon: require('./WebP/updateFirmwareIcon.webp'),
    warningNoti: require('./WebP/warningNoti.webp'),
    inviteNoti: require('./WebP/inviteNoti.webp'),
    star: require('./WebP/star.webp'),
    starA: require('./WebP/starA.webp'),
    adminNoti: require('./WebP/adminNoti.webp'),
    rule: require('./WebP/rule.webp'),
    scene: require('./WebP/scene.webp'),
    play: require('./WebP/play.webp'),
    device: require('./WebP/device.webp'),
    deviceSecond: require('./WebP/deviceSecond.webp'),

    // ===========================================================================
    // Aircondition
    coolActive: require('./WebP/Aircondition/coolActive.webp'),
    coolDeactive: require('./WebP/Aircondition/coolDeactive.webp'),
    hotActive: require('./WebP/Aircondition/hotActive.webp'),
    fanActive: require('./WebP/Aircondition/fanActive.webp'),
    dryActive: require('./WebP/Aircondition/dryActive.webp'),
    // Curtain
    closeOff: require('./WebP/Curtain/closeOff.webp'),
    closeOn: require('./WebP/Curtain/closeOn.webp'),
    closeBtnActive: require('./WebP/Curtain/closeBtnActive.webp'),
    closeBtnDeactive: require('./WebP/Curtain/closeBtnDeactive.webp'),
    openOff: require('./WebP/Curtain/openOff.webp'),
    openOn: require('./WebP/Curtain/openOn.webp'),
    openBtnActive: require('./WebP/Curtain/openBtnActive.webp'),
    openBtnDeactive: require('./WebP/Curtain/openBtnDeactive.webp'),
    playOff: require('./WebP/Curtain/playOff.webp'),
    playOn: require('./WebP/Curtain/playOn.webp'),
    curtainBar: require('./WebP/Curtain/curtainBar.webp'),
    curtainFabricLeft: require('./WebP/Curtain/curtainFabricLeft.webp'),
    curtainFabricRight: require('./WebP/Curtain/curtainFabricRight.webp'),
    // Electricmeter
    totalPower: require('./WebP//Electricmeter/totalPower.webp'),
    transMoney: require('./WebP/Electricmeter/transMoney.webp'),
    // Waterpurifier
    pump: require('./WebP/Waterpurifier/pump.webp'),
    suppliedWater: require('./WebP/Waterpurifier/suppliedWater.webp'),
    waterCondition: require('./WebP/Waterpurifier/waterCondition.webp'),
    fillterFirst: require('./WebP/Waterpurifier/fillterFirst.webp'),
    fillterSecond: require('./WebP/Waterpurifier/fillterSecond.webp'),
    fillterThird: require('./WebP/Waterpurifier/fillterThird.webp'),
    reload: require('./WebP/Waterpurifier/reload.webp'),
    // Fan
    rhythm: require('./WebP/Fan/rhythm.webp'),
    rhythmA: require('./WebP/Fan/rhythmA.webp'),
    // DoorSensor
    doorBaseLeft: require('./WebP/DoorSensor/doorBaseLeft.webp'),
    doorBarLeft: require('./WebP/DoorSensor/doorBarLeft.webp'),
    latchLeft: require('./WebP/DoorSensor/latchLeft.webp'),
    doorBaseRight: require('./WebP/DoorSensor/doorBaseRight.webp'),
    doorBarRight: require('./WebP/DoorSensor/doorBarRight.webp'),
    latchRight: require('./WebP/DoorSensor/latchRight.webp'),

    suceess: require('./WebP/Image/suceess.webp'),
    bg1: require('./WebP/Image/bg1.webp'),
    bg2: require('./WebP/Image/bg2.webp'),
    bg3: require('./WebP/Image/bg3.webp'),
    bg4: require('./WebP/Image/bg4.webp'),
    bg5: require('./WebP/Image/bg5.webp'),
    bg6: require('./WebP/Image/bg6.webp'),
    bg7: require('./WebP/Image/bg7.webp'),
    bg8: require('./WebP/Image/bg8.webp'),
    bg9: require('./WebP/Image/bg9.webp'),
    bg10: require('./WebP/Image/bg10.webp'),
    bg11: require('./WebP/Image/bg11.webp'),
    bannerDeleteArea: require('./WebP/Image/bannerDeleteArea.webp'),
    linearColor: require('./WebP/Image/linearColor.webp'),
    bgDefault: require('./WebP/Image/bgDefault.webp'),
    bannerDeleteHome: require('./WebP/Image/bannerDeleteHome.webp'),
    signupSuccess: require('./WebP/Image/signupSuccess.webp'),
    changpassSuccess: require('./WebP/Image/changpassSuccess.webp'),
    bannerElectricMeter: require('./WebP/Image/bannerElectricMeter.webp'),
    bannerVerifyCode: require('./WebP/Image/bannerVerifyCode.webp'),
    bannerForgotPass: require('./WebP/Image/bannerForgotPass.webp'),
    emptyBox: require('./WebP/Image/emptyBox.webp'),
    bgAuth: require('./WebP/Image/bgAuth.webp'),
    bannerAuth: require('./WebP/Image/bannerAuth.webp'),
    imgOnboarding: require('./WebP/Image/imgOnboarding.webp'),
    bannerDeleteDevice: require('./WebP/Image/bannerDeleteDevice.webp'),
    imgOnboarding1: require('./WebP/Image/imgOnboarding1.webp'),
    imgOnboarding2: require('./WebP/Image/imgOnboarding2.webp'),
    imgOnboarding3: require('./WebP/Image/imgOnboarding3.webp'),
    imgOnboarding4: require('./WebP/Image/imgOnboarding4.webp'),
    bannerWaterpurifier: require('./WebP/Image/bannerWaterpurifier.webp'),
    radar: require('./WebP/Image/radar.webp'),
    scanWave: require('./WebP/Image/scanWave.webp'),
    linearChart: require('./WebP/Image/linearChart.webp'),
    bannerDeleteAccount: require('./WebP/Image/bannerDeleteAccount.webp'),
    curtainFabric: require('./WebP/Image/curtainFabric.webp'),
    curtainFabric2: require('./WebP/Image/curtainFabric2.webp'),
    barCurtain: require('./WebP/Image/barCurtain.webp'),
    ble: require('./WebP/bluetooth.webp'),
    zigbee: require('./WebP/zigbeeicon.webp'),
    eth: require('./WebP/ethernetIcon.webp'),
  },

  bgHome: [],

  bgArea: [
    require('./WebP/Image/bg1.webp'),
    require('./WebP/Image/bg2.webp'),
    require('./WebP/Image/bg3.webp'),
    require('./WebP/Image/bg4.webp'),
    require('./WebP/Image/bg5.webp'),
    require('./WebP/Image/bg6.webp'),
    require('./WebP/Image/bg7.webp'),
    require('./WebP/Image/bg8.webp'),
    require('./WebP/Image/bg9.webp'),
    require('./WebP/Image/bg10.webp'),
    require('./WebP/Image/bg11.webp'),
  ],
};

export default theme;
