import Text from './Text';
import UIBuilder from '../BaseComponent';
import { UI_COMPONENTS_TYPE_FIELD } from '../../../../Constant/Constant';

export default class TextBuilder extends UIBuilder {
  constructor() {
    super();
    this.schema = {
      fontSize: UI_COMPONENTS_TYPE_FIELD.NUMBER,
      
    };
  }

  getType = () => {
    return 'text';
  };

  getComponent = () => {
    return Text;
  };
}
