export const getStyle = (_x, _y, _width, _height, zIndex, style = {}, resource) => {
  const widthBase = resource?.widthBase ?? window.screen.width;
  const heightBase = resource?.heightBase ?? window.screen.heightBase;
  const col = resource?.col ?? 50;
  const row = resource?.row ?? 150;

  if (typeof _x === 'undefined' || typeof _y === 'undefined') {
    let baseStyle = {
      position: 'relative',
      display: 'flex',
    };
    // baseStyle = Object.assign(baseStyle, style);
    baseStyle = { ...baseStyle, ...style };
    baseStyle.width = (_width / col) * widthBase;
    baseStyle.height = (_height / row) * heightBase;
    return baseStyle;
  } else {
    let baseStyle = {
      position: 'absolute',
      display: 'flex',
      zIndex: zIndex,
    };
    // baseStyle = Object.assign(baseStyle, style);
    baseStyle = { ...baseStyle, ...style };
    baseStyle.width = (_width / col) * widthBase;
    baseStyle.height = (_height / row) * heightBase;
    baseStyle.left = (_x / col) * widthBase;
    baseStyle.top = (_y / row) * heightBase;
    return baseStyle;
  }
};
