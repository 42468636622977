import React from 'react';
import { connect } from 'react-redux';
import { Animated, Dimensions, FastImage, StyleSheet, Text, TouchableOpacity, View } from '../../React-Native/types';

const windowWidth = Dimensions.get('window').width;

const processes = {
  INIT: 'INIT',
  START: 'START',
  PENDING: 'PENDING',
  STOP_FAIL: 'STOP_FAIL',
  STOP_SUCCESS: 'STOP_SUCCESS',
};

const getProcess = (state) => {
  if ([0, 2].includes(state)) {
    return processes.START;
  } else if ([3, 5].includes(state)) {
    return processes.PENDING;
  } else if ([1, 4, 6].includes(state)) {
    return processes.STOP_FAIL;
  } else if ([7].includes(state)) {
    return processes.STOP_SUCCESS;
  } else {
    return processes.INIT;
  }
};

class FirmwareUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      err: null,
      process: false,
    };
  }
  translateX = new Animated.Value(-windowWidth + 48);
  progressWidth = new Animated.Value(0);
  timeOut = -1;

  async componentDidMount() {
    const { cEntity, lang } = this.props;
    const { info } = cEntity;
    this.timeOut = setTimeout(() => {
      this.setState({ isLoading: false, err: lang.notConnectServer });
    }, 20000);
    if (typeof info !== 'undefined') {
      this.setState({ isLoading: false });
      clearTimeout(this.timeOut);
    }
    Animated.loop(
      Animated.timing(this.translateX, {
        toValue: windowWidth - 48,
        duration: 1500,
        useNativeDriver: true,
      }),
      {
        useNativeDriver: true,
      },
    ).start();
  }

  componentDidUpdate(prevProps) {
    const { cEntity, lang } = this.props;
    //Logger.terminalInfo('cEntity', JSON.stringify(cEntity));
    const { info, lastestVersion } = cEntity;
    if (info && lastestVersion) {
      const currentVersion = info.version.firmware;

      if (lastestVersion && lastestVersion === currentVersion && this.state.isLoading) {
        this.setState({ isLoading: false, err: lang.noNewUpdateFound });
        clearTimeout(this.timeOut);
      } else if (lastestVersion && lastestVersion !== currentVersion && this.state.isLoading) {
        this.setState({ isLoading: false });
        clearTimeout(this.timeOut);
      }
      const { upgrade } = info;
      if (upgrade) {
        const { state, download } = upgrade;
        //Logger.terminalInfo('getProcess(state) === processes.PENDING', getProcess(state) === processes.PENDING);
        if (getProcess(state) === processes.PENDING) {
          Animated.timing(this.progressWidth, {
            toValue: (download / 100) * (windowWidth - 48),
            duration: 500,
            useNativeDriver: false,
          }).start();
        }
      }
    }
  }

  componentWillUnmount() {
    if (this.timeOut !== -1) {
      clearTimeout(this.timeOut);
      this.timeOut = -1;
    }
    Animated.loop(Animated.timing(this.translateX), {
      useNativeDriver: true,
    }).stop();
  }

  goBack = () => {
    const { navigation } = this.props;
    navigation.goBack();
  };

  onPressInstall = () => {
    this.progressWidth.setValue(0);
    const { cEntity } = this.props;
    const { info, originId, lastestVersion } = cEntity;
    const { model } = info;
    //Logger.terminalInfo('-------------lastestVersion-----------', lastestVersion);
    this.props.onUpdateEntityInfoInHome?.({ deviceId: originId, info: { ...info, upgrade: { download: 0, state: 0 } } });
    this.props.onListEntityChecked([{ ...cEntity, info: { ...cEntity.info, upgrade: { download: 0, state: 0 } } }]);

    //  uri: '',
    //  md5: '',
    //  force: true,
    //  sha256: '',
    //  model_id: '',
    //  region: '',
    //  vendor: '',
    //  version: '',

    this.props.onUpdateOta?.({
      force: true,
      md5: lastestVersion?.info.md5sum,
      uri: lastestVersion?.url,
      vendor: model?.vendor,
      model_id: model?.model_id,
      region: model?.region,
      version: lastestVersion?.version,
    });
  };

  renderContent = () => {
    const { cEntity, lang, theme } = this.props;
    const { info, lastestVersion } = cEntity;
    const { model, upgrade, version } = info || {};
    const { isLoading } = this.state;
    const process = getProcess(upgrade?.state);
    //Logger.terminalInfo('--------process-------', process);
    if (isLoading) {
      return (
        <View style={styles.loading}>
          <FastImage source={theme.icons.loading} />
        </View>
      );
    } else {
      switch (process) {
        case processes.INIT:
          const { err } = this.state;
          if (err) {
            return <Text style={[styles.textHead, { fontFamily: theme.fonts.fontLight, color: theme.colors.errorColor, marginTop: '5%' }]}>{err}</Text>;
          } else {
            return (
              <>
                {lastestVersion?.version && lastestVersion?.version !== version?.firmware ? (
                  <>
                    <Text style={[styles.textHeadz, { color: theme.colors.textColor, marginTop: '5%', fontFamily: theme.fonts.fontLight }]}>
                      {lang.newFirmware}
                    </Text>
                    <Text style={[styles.textHead, { color: theme.colors.textColor, marginTop: '5%' }]}>
                      New firmware version: <Text style={{ fontFamily: theme.fonts.fontBold }}>{lastestVersion?.version || lang.undefined}</Text>
                    </Text>
                    <Text style={[styles.textHead, { color: theme.colors.textColor }]}>
                      Region: <Text style={{ fontFamily: theme.fonts.fontBold }}>{model?.region || lang.undefined}</Text>
                    </Text>
                    <TouchableOpacity style={[styles.rowz, { backgroundColor: theme.colors.appColor }]} onPress={this.onPressInstall}>
                      <Text ellipsizeMode={'tail'} numberOfLines={1} style={[styles.textHeadz, { color: theme.colors.linkColor }]}>
                        {lang.installNow}
                      </Text>
                      <View style={styles.displayLocation}>
                        <FastImage source={theme.icons.rightThin} style={styles.iconRightThin} />
                      </View>
                    </TouchableOpacity>
                  </>
                ) : (
                  <Text style={[styles.textHeadz, { color: theme.colors.textColor, marginTop: '5%', fontFamily: theme.fonts.fontLight }]}>
                    {lang.firmwareUpdated}
                  </Text>
                )}
              </>
            );
          }

        case processes.START:
          return (
            <>
              <Text style={[styles.textHead, { color: theme.colors.textColor, marginTop: '5%', fontFamily: theme.fonts.fontBold }]}>
                {this.renderStateOTA?.(upgrade?.state)} - {upgrade?.download}%
              </Text>
              <View style={[styles.progressBg, { backgroundColor: 'rgb(229, 231, 233)' }]}>
                <Animated.View style={[styles.progress, { width: this.progressWidth, backgroundColor: theme.colors.activeColor }]}>
                  <Animated.Image source={theme.icons.trackProgress} style={[styles.track, { transform: [{ translateX: this.translateX }] }]} />
                </Animated.View>
              </View>
            </>
          );
        case processes.PENDING:
          return (
            <>
              <Text style={[styles.textHead, { color: theme.colors.textColor, marginTop: '5%', fontFamily: theme.fonts.fontBold }]}>
                {this.renderStateOTA?.(upgrade?.state)} - {upgrade?.download}%
              </Text>
              <View style={[styles.progressBg, { backgroundColor: 'rgb(229, 231, 233)' }]}>
                <Animated.View style={[styles.progress, { width: this.progressWidth, backgroundColor: theme.colors.activeColor }]}>
                  <Animated.Image source={theme.icons.trackProgress} style={[styles.track, { transform: [{ translateX: this.translateX }] }]} />
                </Animated.View>
              </View>
            </>
          );
        case processes.STOP_FAIL:
          return (
            <>
              <Text style={[styles.textHead, { color: theme.colors.textColor, marginTop: '5%', fontFamily: theme.fonts.fontBold }]}>
                {this.renderStateOTA?.(upgrade?.state)}
              </Text>
              <TouchableOpacity style={[styles.rowz, { backgroundColor: theme.colors.appColor }]} onPress={this.onPressInstall}>
                <Text ellipsizeMode={'tail'} numberOfLines={1} style={[styles.textHeadz, { color: theme.colors.textColorSave }]}>
                  {lang.reTry}
                </Text>
                <View style={styles.displayLocation}>
                  <FastImage source={theme.icons.rightThin} style={styles.iconRightThin} />
                </View>
              </TouchableOpacity>
            </>
          );
        case processes.STOP_SUCCESS:
          return (
            <Text style={[styles.textHead, { color: theme.colors.textColor, marginTop: '5%', fontFamily: theme.fonts.fontBold }]}>
              {this.renderStateOTA?.(upgrade?.state)}
            </Text>
          );
        default:
          return <></>;
      }
    }
  };

  renderStateOTA = (state) => {
    switch (state) {
      case 0:
        return 'Device start OTA module successfully ';
      case 1:
        return 'Device start OTA module failed';
      case 2:
        return 'Device started download firmware';
      case 3:
        return 'Device is downloading firmware';
      case 4:
        return 'Device download firmware failed';
      case 5:
        return 'Device download firmware finished';
      case 6:
        return 'Device verify firmware failed';
      case 7:
        return 'Update firmware successfully';
      case 8:
        return 'Update OTA finally fail. Stop update OTA progress.';

      default:
        return 'Device started download firmware';
    }
  };

  render() {
    const { lang, theme, cEntity } = this.props;
    const { info } = cEntity;
    const { version, model } = info || {};
    return (
      <>
        <View style={{ ...styles.body, ...this.props.style }}>
          <View style={styles.row}>
            <FastImage source={theme.icons.controls} style={styles.icon} />
            <View style={styles.infoComp}>
              <Text style={[styles.textHead, { color: theme.colors.textColor }]}>
                <Text> Firmware version: </Text>
                <Text style={{ fontFamily: theme.fonts.fontBold }}>{version?.firmware || lang.undefined}</Text>
              </Text>
              <Text style={[styles.textHead, { color: theme.colors.textColor }]}>
                Hardware version: <Text style={{ fontFamily: theme.fonts.fontBold }}>{version?.hardware || lang.undefined}</Text>
              </Text>
              <Text style={[styles.textHead, { color: theme.colors.textColor }]}>
                Vendor: <Text style={{ fontFamily: theme.fonts.fontBold }}>{model?.vendor || lang.undefined}</Text>
              </Text>
            </View>
          </View>
          {this.renderContent()}
        </View>
      </>
    );
  }
}

export default connect(
  (state) => {
    return {
      lang: state.lang,
      theme: state.theme,
    };
  },
  // { onUpdateEntityInfoInHome, onListEntityChecked },
)(FirmwareUpdate);

export const BaseFirmwareUpdate = FirmwareUpdate;
export const _connect = (cp) =>
  connect(
    (state) => {
      return {
        lang: state.lang,
        theme: state.theme,
      };
    },
    // { onUpdateEntityInfoInHome, onListEntityChecked },
  )(cp);

const styles = StyleSheet.create({
  loading: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 50,
  },
  progressBg: {
    width: '100%',
    marginTop: 20,
    borderRadius: 100,
  },
  contentModal: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
  },
  track: {
    width: windowWidth,
    height: 18,
  },
  progress: {
    width: '100%',
    height: 16,
    borderRadius: 10,
    overflow: 'hidden',
  },
  iconRightThin: {
    width: 16,
    height: 16,
  },
  displayLocation: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  rowz: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 16,
    justifyContent: 'space-between',
    borderRadius: 8,
    marginVertical: 20,
  },
  textHeadz: {
    fontSize: 17,
    lineHeight: 24,
  },
  textHead: {
    fontSize: 16,
    lineHeight: 23,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  icon: {
    width: 80,
    height: 80,
  },
  infoComp: {
    flex: 1,
    paddingLeft: 20,
    justifyContent: 'center',
  },
  row: {
    width: '100%',
    flexDirection: 'row',
  },
  body: {
    paddingHorizontal: 24,
    paddingTop: '5%',
  },
  iconAdd: {
    width: 24,
    height: 24,
  },
  btnCreate: {
    position: 'absolute',
    right: 15,
  },
  title: {
    fontSize: 16,
    lineHeight: 24,
    textAlign: 'center',
  },
  iconBack: {
    width: 24,
    height: 24,
  },
  btnBack: {
    position: 'absolute',
    left: -16,
    padding: 16,
  },
  rowHeader: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    width: '100%',
    paddingBottom: 20,
    paddingHorizontal: 24,
    paddingTop: 50,
  },
  root: {
    width: '100%',
    flex: 1,
  },
});
