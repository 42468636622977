import Builder from '..';

export const build = (SetTimeOutConfig, resource) => {
  try {
    const setTimeoutFunction = SetTimeOutConfig.function;
    const setTimeoutFunctionBuilder = Builder.build(setTimeoutFunction, resource);

    const duration = Builder.build(SetTimeOutConfig.duration, resource);

    return setTimeout(() => {
      typeof setTimeoutFunctionBuilder === 'function' && setTimeoutFunctionBuilder();
    }, duration);
  } catch (error) {
    return undefined;
  }
};

export const type = 'SetTimeOut';
