import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Text, TouchableOpacity, View } from '../../../../React-Native/types';
import TimePicker from 'react-time-picker';

class Time extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  render() {
    const { title, theme } = this.props;
    const { value } = this.state;
    const titleContent = title?.content;
    const titleStyle = title?.style;
    const styles = { ...style, ...this.style };
    const minutes = isNaN(parseInt(value)) ? 0 : parseInt(value) % 60;
    const hours = isNaN(parseInt(value)) ? 0 : (parseInt(value) - minutes) / 60;
    const _minutes = minutes < 10 ? '0' + minutes : minutes;
    const _hours = hours < 10 ? '0' + hours : hours;
    return (
      <TouchableOpacity style={{ backgroundColor: theme.colors.appColor, ...styles.titleContainer, ...titleStyle?.container }} onPress={this.props.showModal}>
        <Text
          style={{
            color: theme.colors.textColor,
            fontFamily: theme.fonts.fontBold,
            ...styles.title,
            ...titleStyle?.title,
          }}
          numberOfLines={1}
          ellipsizeMode={'tail'}>
          {titleContent}
        </Text>
        <View style={{ marginRight: -15 }}>
          <TimePicker
            mode={'time'}
            value={`${_hours}:${_minutes}`}
            onChange={(event, date) => {
              const hours = parseInt(event.split(':')[0]);
              const minutes = parseInt(event.split(':')[1]);
              const newValue = hours * 60 + minutes;
              this.props.onChangeValue(newValue);
            }}
            disableClock={true}
            clearIcon={<></>}
            className='clock-touch-press'
          />
        </View>
      </TouchableOpacity>
    );
  }
}

const style = {
  title: {
    flex: 1,
    fontSize: 16,
    lineHeight: 24,
  },
  titleContainer: {
    flex: 1,
    height: 56,
    borderRadius: 16,
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginHorizontal: 20,
  },
};

export default connect((state) => {
  return {
    theme: state.theme,
    lang: state.lang,
  };
})(Time);
