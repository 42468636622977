import { Redirect } from 'react-router-dom';

import React, { Component } from 'react';
import Builder from '../..';
import { withRouter } from 'react-router-dom';
import * as Page from '../Components/Page';
import Logger from '../../../Helper/Logger';

const Element = withRouter(
  class extends Component {
    render() {
      const { resource, RouteConfig, path } = this.props;
      const searchParams = new URLSearchParams(this.props.location.search);
      const _resource = {
        ...resource,
        appResource: {
          ...resource.appResource,
          uriSearch: Object.fromEntries(searchParams.entries()),
          RouteConfig,
          path,
        },
      };
      const { secure, redirect } = RouteConfig;
      const { typeWindow } = _resource.appResource;

      Logger.terminalInfo('typeWindow', typeWindow, RouteConfig);

      if (secure === true) {
        const viewElement = Builder.build(
          RouteConfig[typeWindow.name],
          { ..._resource, appResource: { ..._resource.appResource, typeWindow: { ..._resource.appResource.typeWindow, use: typeWindow.name } } },
          RouteConfig[typeWindow.name].type === Page.type,
        );

        Logger.terminalInfo('viewElement', viewElement, RouteConfig[typeWindow.name]);

        if (React.isValidElement(viewElement)) {
          return viewElement;
        } else {
          const _viewElement = Builder.build(
            RouteConfig[typeWindow.secondName],
            { ..._resource, appResource: { ..._resource.appResource, typeWindow: { ..._resource.appResource.typeWindow, use: typeWindow.secondName } } },
            RouteConfig[typeWindow.name].type === Page.type,
          );
          Logger.terminalInfo('_viewElement', _viewElement, RouteConfig[typeWindow.secondName]);
          if (React.isValidElement(_viewElement)) {
            return _viewElement;
          } else {
            return <></>;
          }
        }

        // switch (typeWindow) {
        //   case TYPE_WINDOWS.LARGE:
        //     const viewLargeElement = Builder.build(viewLarge, { ..._resource, typeView: 'viewLarge' }, true);
        //     if (React.isValidElement(viewLargeElement)) {
        //       return viewLargeElement;
        //     } else {
        //       const viewElement = Builder.build(view, { ..._resource, typeView: 'view' }, true);
        //       if (React.isValidElement(viewElement)) {
        //         return viewElement;
        //       } else {
        //         return <></>;
        //       }
        //     }
        //   case TYPE_WINDOWS.MEDIUM:
        //     const viewMediumElement = Builder.build(viewMedium, { ..._resource, typeView: 'viewMedium' }, true);
        //     if (React.isValidElement(viewMediumElement)) {
        //       return viewMediumElement;
        //     } else {
        //       const viewElement = Builder.build(view, { ..._resource, typeView: 'view' }, true);
        //       if (React.isValidElement(viewElement)) {
        //         return viewElement;
        //       } else {
        //         return <></>;
        //       }
        //     }
        //   case TYPE_WINDOWS.SMALL:
        //     const viewSmallElement = Builder.build(viewSmall, { ..._resource, typeView: 'viewSmall' }, true);
        //     if (React.isValidElement(viewSmallElement)) {
        //       return viewSmallElement;
        //     } else {
        //       const viewElement = Builder.build(view, { ..._resource, typeView: 'view' }, true);
        //       if (React.isValidElement(viewElement)) {
        //         return viewElement;
        //       } else {
        //         return <></>;
        //       }
        //     }
        //   default:
        //     return <div style={{ width: '100%', height: '100%' }}></div>;
        // }
      } else {
        return <Redirect to={redirect} replace />;
      }
    }
  },
);

export const build = (RouteConfig, resource) => {
  const path = RouteConfig.uri ?? '/';

  return (
    // <Route path={path}>
    <Element resource={resource} path={path} RouteConfig={RouteConfig} />
    // </Route>
  );
};

export const type = 'Route';
