import React, { Component } from 'react';
import BaseComponent from '../BaseComponent/BaseComponent';
import Builder from '../../..';
import deepEqual from 'deep-equal';
import shallowCompare from 'react-addons-shallow-compare';

export default class Table extends BaseComponent {
  // shouldComponentUpdate(nextProps, nextState) {
  //   Logger.terminalInfo('shouldComponentUpdate Table shallowCompare(this, nextProps, nextState)', shallowCompare(this, nextProps, nextState), nextProps, this.props);
  //   if (!shallowCompare(this, nextProps, nextState)) {
  //     return false;
  //   }

  //   Logger.terminalInfo('findKeyDifferences', findKeyDifferences(this.props, nextProps));
  //   Logger.terminalInfo(
  //     'findKeyDifferences onChange',
  //     deepEqual(this.props.heading[0].props.onChange, nextProps.heading[0].props.onChange),
  //     this.props.heading[0].props.onChange.toString() === nextProps.heading[0].props.onChange.toString(),
  //   );
  //   Logger.terminalInfo('this.props.heading.0.props.onChange', this.props.heading[0].props.onChange.toString());
  //   Logger.terminalInfo('nextProps.heading.0.props.onChange', nextProps.heading[0].props.onChange.toString());

  //   return true;
  // }

  getTableClass = () => {
    let _className = 'table';
    const mode = this.props.mode;
    switch (mode) {
      case 'dark':
        _className += ' table-dark';
        break;
      case 'default':
        break;
      default:
        break;
    }

    const striped = this.props.striped;
    if (striped === true) {
      _className += ' table-striped';
    }

    const border = this.props.border;
    if (border === true) {
      _className += ' table-bordered';
    }

    const borderless = this.props.borderless;
    if (borderless === true) {
      _className += ' table-borderless';
    }

    const hover = this.props.hover;
    if (hover === true) {
      _className += ' table-hover';
    }

    const sm = this.props.sm;
    if (sm === true) {
      _className += ' table-sm';
    }

    return _className;
  };

  renderComponent(x, y, width, height) {
    const resource = this.props.resource;
    const showIndex = this.props.showIndex;
    const _heading = Builder.build(this.props.heading, resource);
    const heading = Array.isArray(_heading) ? _heading : [];

    const _body = Builder.build(this.props.body, resource);
    const body = Array.isArray(_body) ? _body : [];
    const tableClass = this.getTableClass();
    const rowWidth = this.props.rowWidth;
    const cellStyle = this.props.cellStyle;
    const cellHeight =
      typeof this.props.cellHeight === 'number' ? this.props.cellHeight * (resource.appResource.heightBase / resource.appResource.row) : this.props.cellHeight;

    return (
      <div class='table-responsive' style={{ width: '100%', height: '100%' }}>
        <table class={tableClass}>
          <thead class={'thead-dark'}>
            <tr>
              {showIndex ? <th>#</th> : null}

              {heading.map((head, index) => {
                const width = typeof rowWidth[index] === 'number' && rowWidth[index] < 1 ? `${rowWidth[index] * 100}%` : 0;
                return (
                  <th scope='col' key={index} style={{ position: 'relative', width }}>
                    {Builder.build(head, resource)}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {body?.map((item, index) => {
              return (
                <tr>
                  {showIndex ? <td>{index}</td> : null}

                  {item?.map((value) => {
                    return <td style={{ ...cellStyle, height: cellHeight, position: 'relative' }}>{Builder.build(value, resource)}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}
