import React, { Component } from 'react';
import Modal from 'react-modal';
import BaseComponent from '../BaseComponent/BaseComponent';
import { Spring, animated } from '@react-spring/web';
class FModal extends BaseComponent {
  render() {
    const modal = this.props.modal;
    const visible = this.props.visible;
    const style = this.style;
    // const _style = this.getStyle(this.props?.x, this.props?.y, this.props?.width, this.props?.height, this.props?.style);
    const isOpen = visible === true;
    const { pxWidth, pxHeight, blockStyle } = this.props;

    return (
      <Modal
        {...this.props}
        isOpen={isOpen}
        style={{
          ...style,
          position: 'fixed',
          overlay: {
            zIndex: 9999,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
            outline: 'none',
            textAlign: 'center',
          },
          content: {
            ...style?.content,
            borderRadius: '6px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
            width: blockStyle.width,
            height: blockStyle.height,
            margin: 'auto',
            padding: 0,
            borderWidth: '0px',
          },
        }}
        onRequestClose={() => {
          typeof this.props.onRequestClose === 'function' && this.props.onRequestClose();
        }}>
        <Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
          {(styleProps) => <animated.div style={styleProps}>{React.isValidElement(modal) ? modal : <></>}</animated.div>}
        </Spring>
      </Modal>
    );
  }
}

export default FModal;
