import { updateObjectProperty } from '../../Helper/utils';
import _ from 'lodash';
import { ingoreReduxNames } from '../../Constant/ingore';
import { CHANGE_LANG } from '../type';
import vi from '../../Resource/Lang/vi.json';
import en from '../../Resource/Lang/en.json';
import DecorationController from '../../Controller/Decoration';
import { THEME_SELECTION } from '../type';
import DefaultTheme from '../../Resource/Theme/Default';
import DarkTheme from '../../Resource/Theme/Dark';
import Logger from '../../Helper/Logger';

const defaultState = {
  Toast: {
    visible: false,
  },
  FirebaseNotification: {
    visible: false,
    screen: {},
  },

  AdaptiveScreen: {
    visible: false,
    isWebView: false,
    uri: '',
    title: '',
    subTitle: '',
    config: {},
  },

  CommonModal: {
    visible: false,
    modal: {},
  },
  lang: {
    ...vi,
    __type: 'vi',
  },
  theme: {
    ...DefaultTheme,
    __type: 'default',
  },
};

const getObjectByType = (type) => {
  let arr = type.split('@');
  return arr;
};

const filiotReducer = (state = defaultState, action) => {
  if (action.type === CHANGE_LANG) {
    const { __type } = action.value;
    switch (__type) {
      case 'vi':
        return {
          ...state,
          lang: _.cloneDeep({
            ..._.merge(vi, DecorationController.getLang()[__type]),
            __type: 'vi',
          }),
        };
      case 'en':
        return {
          ...state,
          lang: _.cloneDeep({
            ..._.merge(en, DecorationController.getLang()[__type]),
            __type: 'en',
          }),
        };
      default:
        return {
          ...state,
          lang: _.cloneDeep({
            ..._.merge(vi, DecorationController.getLang()[__type]),
            __type: 'vi',
          }),
        };
    }
  }

  if (action.type === THEME_SELECTION) {
    const { __type } = action.value;
    switch (__type) {
      case 'dark':
        return {
          ...state,
          theme: _.cloneDeep({
            ..._.merge(DarkTheme, DecorationController.getTheme()[__type]),
            __type: 'dark',
          }),
        };

      default:
        return {
          ...state,
          theme: _.cloneDeep({
            ..._.merge(DefaultTheme, DecorationController.getTheme()[__type]),
            __type: 'default',
          }),
        };
    }
  }

  let arr = getObjectByType(action.type);
  let data = undefined;
  try {
    let path = undefined;
    switch (arr[0]) {
      case 'create':
        // state[arr[1]] = action.value;
        path = arr[1].split('.');
        return { ...updateObjectProperty(state, path, action.value) };
      case 'update':
        // state[arr[1]] = action.value;
        path = arr[1].split('.');
        Logger.terminalInfo('update', path, action.value);
        if (Array.isArray(action.value)) {
          data = [...action.value];
        } else if (_.isObject(action.value)) {
          data = { ...action.value };
        } else {
          data = action.value;
        }

        if (!Object.values(ingoreReduxNames).includes(path[0])) {
          let updateState = {
            ...updateObjectProperty(state, path, data),
          };

          updateState = { ...updateState, updatePaths: [path] };

          return updateState;
        }

        return state;

      case 'updates':
        let newState = { ...state };
        const paths = arr[1]?.split('____')?.map((dotPath) => dotPath?.split('.'));

        data = [...action.value];
        // Logger.terminalInfo('action.value', action.value);

        paths?.forEach((path, index) => {
          if (!Object.values(ingoreReduxNames).includes(path[0])) {
            newState = { ...updateObjectProperty(newState, path, data?.[index]) };
          }
        });

        newState = { ...newState, updatePaths: paths };

        Logger.terminalInfo('paths', paths);
        // Logger.terminalInfo('newState', newState);
        // state[arr[1]] = action.value;
        return newState;
      case 'delete':
        path = arr[1].split('.');
        state[arr[1]] = null;
        // return { ...state };
        return { ...updateObjectProperty(state, path, undefined) };
      default:
        return { ...state };
    }
  } catch {
    return state;
  }
};

export default filiotReducer;
