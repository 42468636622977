import TextInput from './TextInput';
import UIBuilder from '../BaseComponent';
import { UI_COMPONENTS_TYPE_FIELD } from '../../../../Constant/Constant';

export default class TextInputBuilder extends UIBuilder {
  constructor() {
    super();
    this.schema = {
      fontSize: UI_COMPONENTS_TYPE_FIELD.NUMBER,
      placeholder: UI_COMPONENTS_TYPE_FIELD.STRING,
      onChangeText: UI_COMPONENTS_TYPE_FIELD.JSON,
    };
  }

  getType = () => {
    return 'text_input';
  };

  getComponent = () => {
    return TextInput;
  };
}
