import AWS from '../AWS';
import _ from 'lodash';
import * as App from '../../Builder/UI/App';
import * as RouteManager from '../../Builder/UI/RouteManager';
import { isJson } from '../../Helper/utils';
import Logger from '../../Helper/Logger';

class FAWS {
  constructor() {
    this.accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY;
    this.secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
    this.bucketName = process.env.REACT_APP_AWS_BUCKET_NAME;
  }

  getConfiguration = async (appCode) => {
    const secretData = {
      accessKeyId: this.accessKeyId,
      secretAccessKey: this.secretAccessKey,
    };

    const query = {
      bucketName: this.bucketName,
      prefix: appCode,
    };

    const listObjectsResponse = await AWS.listObjects(secretData, query);
    Logger.terminalInfo('listObjectsResponse', listObjectsResponse);
    if (listObjectsResponse?.status === true) {
      let configuration = {
        type: App.type,
        manifest: {
          fAuth: true,
        },
        auth: {
          preprocessing: {},
          main: {
            type: RouteManager.type,
            pages: [],
          },
          mainStyle: {},
        },
        container: {
          preprocessing: {},
          main: {
            type: RouteManager.type,
            pages: [],
          },
          mainStyle: {},
        },
      };
      const objects = listObjectsResponse.result;
      if (Array.isArray(objects)) {
        const manifestObject = objects.find((object) => object.Key === `${appCode}/manifest.json`);
        if (typeof manifestObject !== 'undefined') {
          if (isJson(manifestObject.value)) {
            configuration['manifest'] = { ...configuration['manifest'], ...JSON.parse(manifestObject.value) };
          }
        }

        const authPreprocessing = objects.find((object) => object.Key === `${appCode}/auth/preprocessing.json`);
        if (typeof authPreprocessing !== 'undefined') {
          if (isJson(authPreprocessing.value)) {
            configuration['auth']['preprocessing'] = JSON.parse(authPreprocessing.value);
          } else {
            configuration['auth']['preprocessing'] = {};
          }
        }

        const containerPreprocessing = objects.find((object) => object.Key === `${appCode}/container/preprocessing.json`);
        if (typeof containerPreprocessing !== 'undefined' && isJson(containerPreprocessing.value)) {
          if (isJson(containerPreprocessing.value)) {
            configuration['container']['preprocessing'] = JSON.parse(containerPreprocessing.value);
          } else {
            configuration['container']['preprocessing'] = {};
          }
        }

        const authPageObjects = objects.filter(
          (object) =>
            JSON.stringify(object.Key.split('/').slice(0, 4)) === JSON.stringify(`${appCode}/auth/main/pages`.split('/')) &&
            object.Key.split('/').length === 5 &&
            object.Key.split('/')[4] !== '',
        );

        configuration.auth.main.pages = authPageObjects.map((authPageObject) => {
          try {
            let page = {};
            if (isJson(authPageObject.value)) {
              page = JSON.parse(authPageObject.value);
            }
            const pageName = authPageObject.Key.split('/')[4].split('.')[0];
            return { ...page, uri: typeof page.uri === 'string' ? page.uri : `/${pageName}` };
          } catch (error) {
            return null;
          }
        });

        const containerSidebar = objects.find((object) => object.Key === `${appCode}/container/sidebar.json`);
        if (typeof containerSidebar !== 'undefined' && isJson(containerSidebar.value)) {
          if (isJson(containerSidebar.value)) {
            configuration['container']['sidebar'] = JSON.parse(containerSidebar.value);
          }
        }

        const containerHeader = objects.find((object) => object.Key === `${appCode}/container/header.json`);
        if (typeof containerHeader !== 'undefined' && isJson(containerHeader.value)) {
          if (isJson(containerHeader.value)) {
            configuration['container']['header'] = JSON.parse(containerHeader.value);
          }
        }

        const containerPages = objects.filter(
          (object) =>
            JSON.stringify(object.Key.split('/').slice(0, 4)) === JSON.stringify(`${appCode}/container/main/pages`.split('/')) &&
            object.Key.split('/').length === 5 &&
            object.Key.split('/')[4] !== '',
        );

        Logger.terminalInfo('containerPages', containerPages);

        configuration.container.main.pages = containerPages.map((containerPage) => {
          try {
            let page = {};
            if (isJson(containerPage.value)) {
              page = JSON.parse(containerPage.value);
            }
            const pageName = containerPage.Key.split('/')[4].split('.')[0];
            return { ...page, uri: typeof page.uri === 'string' ? page.uri : `/${pageName}` };
          } catch (error) {
            return null;
          }
        });
      }

      Logger.terminalInfo('configuration', configuration);

      return configuration;
    }
    return null;
  };

  pushConfiguration = async ({ appCode, configuration }) => {
    Logger.terminalInfo('pushConfiguration', configuration);
    if (_.isObject(configuration)) {
      const objects = [];
      const manifest = configuration.manifest;
      objects.push({
        key: `${appCode}/manifest.json`,
        value: manifest,
        bucketName: this.bucketName,
      });

      const authPreprocessing = configuration.auth?.preprocessing || {};
      objects.push({
        key: `${appCode}/auth/preprocessing.json`,
        value: authPreprocessing,
        bucketName: this.bucketName,
      });

      const containerPreprocessing = configuration.container?.preprocessing || {};
      objects.push({
        key: `${appCode}/container/preprocessing.json`,
        value: containerPreprocessing,
        bucketName: this.bucketName,
      });

      const authPages = configuration.auth?.main?.pages || [];
      if (Array.isArray(authPages)) {
        authPages.forEach((authPage) => {
          const uri = authPage.uri;
          if (typeof uri === 'string' && uri[0] === '/') {
            const pageName = uri.slice(1, uri.length);
            const key = `${appCode}/auth/main/pages/${pageName}.json`;
            const value = authPage;
            objects.push({
              key,
              value,
              bucketName: this.bucketName,
            });
          }
        });
      }

      const containerPages = configuration.container?.main?.pages || [];
      if (Array.isArray(containerPages)) {
        containerPages.forEach((containerPage) => {
          const uri = containerPage.uri;
          if (typeof uri === 'string' && uri[0] === '/') {
            const pageName = uri.slice(1, uri.length);
            const key = `${appCode}/container/main/pages/${pageName}.json`;
            const value = containerPage;
            objects.push({
              key,
              value,
              bucketName: this.bucketName,
            });
          }
        });
      }
      const secretData = {
        accessKeyId: this.accessKeyId,
        secretAccessKey: this.secretAccessKey,
      };

      Logger.terminalInfo('this.bucketNamethis.bucketName', this.bucketName);

      const query = {
        bucketName: this.bucketName,
        prefix: appCode,
      };

      return AWS.uploadObjects(secretData, query, objects);
    }
    return null;
  };
}

export default new FAWS();
