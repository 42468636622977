import { Dimensions } from "../../../../../../Builder/UI/Components/UI_ReactNative/React-Native/types";

const { height } = Dimensions.get('window');

const infoTextComponent = (
    data = {
        label: 'attribute 1',
        parameter: 'A1',
        value: 1,
        type: 'number',
        min: 0, // option
        max: 10, // option
        marginHorizontal: 0,
    },
) => {
    return {
        type: 'View',
        children: [
            {
                type: 'Modal',
                children: [
                    {
                        type: 'View',
                        children: [
                            {
                                type: 'View',
                                children: [
                                    {
                                        type: 'Text',
                                        children: data.label,
                                        props: {
                                            style: {
                                                fontSize: 24,
                                                color: '{{theme.colors.textColor}}',
                                                fontFamily: '{{theme.fonts.fontBold}}',
                                                lineHeight: 24,
                                                fontWeight: '700',
                                            },
                                        },
                                    },
                                ],
                                props: {
                                    style: {
                                        marginBottom: 12,
                                        alignItems: 'center',
                                    },
                                },
                            },
                            {
                                type: 'View',
                                children: [
                                    {
                                        type: 'View',
                                        children: [
                                            {
                                                type: 'Text',
                                                children: data.value || '---',
                                                props: {
                                                    style: {
                                                        color: '{{theme.colors.textInfor}}',
                                                        fontFamily: '{{theme.fonts.fontMedium}}',
                                                        textAlign: 'center',
                                                        fontSize: 16,
                                                        lineHeight: 24,
                                                    },
                                                },
                                            },
                                        ],
                                        props: {
                                            style: {
                                                width: '100%',
                                                borderRadius: 16,
                                                paddingVertical: 5,
                                                paddingHorizontal: 16,
                                                marginTop: 8,
                                                backgroundColor: '{{theme.colors.appColor}}',
                                            },
                                        },
                                    },
                                ],
                                props: {
                                    style: {
                                        paddingHorizontal: 12,
                                        maxHeight: height - 150,
                                    },
                                },
                            },
                        ],
                        props: {
                            style: {
                                backgroundColor: '#fff',
                                marginHorizontal: 20,
                                borderRadius: 8,
                                maxHeight: height - 50,
                                paddingHorizontal: 20,
                                paddingVertical: 18,
                            },
                        },
                    },
                ],
                props: {
                    visible: '{{this.state.visible}}',
                    onCloseModal: [
                        {
                            functionName: 'onChangeState',
                            args: [['visible'], false],
                        },
                    ],
                },
            },
            {
                type: 'TouchableOpacity',
                children: [
                    {
                        type: 'Text',
                        children: data.label,
                        props: {
                            style: {
                                fontSize: 16,
                                color: '{{theme.colors.textColor}}',
                                fontFamily: '{{theme.fonts.fontBold}}',
                                lineHeight: 24,
                            },
                        },
                    },
                ],
                props: {
                    style: {
                        backgroundColor: '{{theme.colors.appColor}}',
                        flex: 1,
                        height: 56,
                        borderRadius: 16,
                        paddingHorizontal: 20,
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginHorizontal: 20,
                    },
                    onPress: [
                        {
                            functionName: 'onChangeState',
                            args: [['visible'], true],
                        },
                    ],
                },
            },
        ],
        props: {},
    };
};

export default infoTextComponent;
