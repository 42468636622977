// import MultiSlider from '@ptomasroos/react-native-multi-slider';
import React, { Component } from 'react';

export default class ValueNumberSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props?.value,
        };
    }

    componentDidMount() {
        this.setState({
            value: isNaN(parseInt(this.props.value, 10)) ? 0 : parseInt(this.props.value, 10),
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(prevProps.value) !== JSON.stringify(this.props.value)) {
            this.setState({
                value: isNaN(parseInt(this.props.value, 10)) ? 0 : parseInt(this.props.value, 10),
            });
        }
    }

    onChangeValue = (value) => {
        this.props.onChangeValue?.(value);
    };

    render() {
        const { min, max } = this.props;
        const _min = isNaN(parseInt(min, 10)) ? 0 : parseInt(min, 10);
        const _max = !isNaN(parseInt(max, 10)) && parseInt(max, 10) >= min ? parseInt(max, 10) : _min + 100;
        return null

        // return (
        //     // <MultiSlider
        //     //     enabledOne={true}
        //     //     min={_min}
        //     //     max={_max}
        //     //     onValuesChange={(e) => {
        //     //       //Logger.terminalInfo('e', e);
        //     //         this.setState({ value: e[0] });
        //     //     }}
        //     //     valuePrefix={_min.toString()}
        //     //     valueSuffix={_max.toString()}
        //     //     values={[this.state.value]}
        //     //     step={this.props.step ?? 1}
        //     //     sliderLength={200}
        //     //     onValuesChangeFinish={(e) => {
        //     //         this.onChangeValue(e[0] || _min);
        //     //     }}
        //     // />
        // );
    }
}
