import Builder from '..';

export const build = (ClearTimeOutConfig, resource) => {
  try {
    const timeout = ClearTimeOutConfig.timeout;
    const timeoutBuilder = Builder.build(timeout, resource);

    return clearTimeout(timeoutBuilder);
  } catch (error) {
    return undefined;
  }
};

export const type = 'ClearTimeOut';
