import Builder from '..';

export const build = (ObjectKeysConfig, resource) => {
  const object = Builder.build(ObjectKeysConfig.object, resource);
  if (typeof object === 'object' && !Array.isArray(object) && object !== null) {
    return Object.keys(object);
  }
  return [];
};

export const type = 'ObjectKeys';
