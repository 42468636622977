import React, { Component } from 'react';
import Builder from '../../..';
// import { getConfigFromSize } from '../../../../Helper/utils';
import { getConfigFromSize, getSidebarState, getSidebarWidth } from '../../../../Helper/responsive';
import { v4 as uuidv4 } from 'uuid';
import { TYPE_WINDOWS } from '../../../../Constant/Constant';
import { connect } from 'react-redux';
import { fRedux } from '../../../../Controller/fRedux';
import Logger from '../../../../Helper/Logger';

class main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: undefined,
      width: undefined,
      heightBase: undefined,
      widthBase: undefined,
      fontSizeRatio: undefined,
      col: undefined,
      row: undefined,
      key: uuidv4(),
      typeWindow: TYPE_WINDOWS.LARGE,
      init: false,
    };
    this.ref = React.createRef();
  }

  componentDidMount() {
    // window.addEventListener('resize', () => {
    //   const width = document.documentElement.clientWidth - getSidebarWidth(this.props.sidebarState);
    //   const height = window.innerHeight;
    //   const config = getConfigFromSize(width, height);

    //   this.setState({
    //     widthBase: config.widthBase,
    //     heightBase: config.heightBase,
    //     width: width,
    //     height,
    //     col: config.col,
    //     row: config.row,
    //     typeWindow: config.type,
    //     fontSizeRatio: config.fontSizeRatio,
    //     key: uuidv4(),
    //   });
    // });
    Logger.terminalInfo('auth componentDidMount', this.props.resource);

    const initSidebarState = getSidebarState(this.props.resource?.appResource?.width, typeof this.props.sidebar !== 'undefined');

    const width = this.props.resource?.appResource?.width - getSidebarWidth(initSidebarState);
    const height = this.props.resource?.appResource?.height;
    const resolutionRatio = this.props.resource?.appResource?.resolutionRatio ?? 1;

    const config = getConfigFromSize(width, height, resolutionRatio);

    fRedux.updateObject({ path: 'main.page.pageHeight', data: config.heightBase });

    this.setState({
      widthBase: config.widthBase,
      typeWindow: config.type,
      width: width,
      height,
      heightBase: config.heightBase,
      fontSizeRatio: config.fontSizeRatio,
      col: config.col,
      row: config.row,
      key: uuidv4(),
      init: true,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    Logger.terminalInfo('auth componentDidUpdate', this.props.resource);

    if (this.props.sidebarState !== prevProps.sidebarState) {
      Logger.terminalInfo('componentDidUpdate sidebarState', this.props.resource);
      const width = this.props.resource?.appResource?.width - getSidebarWidth(this.props.sidebarState);
      const height = this.props.resource?.appResource?.height;
      const resolutionRatio = this.props.resource?.appResource?.resolutionRatio ?? 1;

      const config = getConfigFromSize(width, height, resolutionRatio);

      this.setState({
        widthBase: config.widthBase,
        typeWindow: config.type,
        width: width,
        height,
        heightBase: config.heightBase,
        fontSizeRatio: config.fontSizeRatio,
        col: config.col,
        row: config.row,
        key: uuidv4(),
      });
    }

    if (this.props.pageViewHeight !== prevProps.pageViewHeight) {
      const { heightBase } = this.state;
      if (
        (heightBase < this.props.pageViewHeight && heightBase >= prevProps.pageViewHeight) ||
        (heightBase >= this.props.pageViewHeight && heightBase < prevProps.pageViewHeight)
      ) {
        Logger.terminalInfo('componentDidUpdate pageViewHeight', this.props.resource);
        const width = this.props.resource?.appResource?.width - getSidebarWidth(this.props.sidebarState);
        const height = this.props.resource?.appResource?.height;
        const resolutionRatio = this.props.resource?.appResource?.resolutionRatio ?? 1;

        const config = getConfigFromSize(width, height, resolutionRatio);

        this.setState({
          widthBase: config.widthBase,
          typeWindow: config.type,
          width: width,
          height,
          heightBase: config.heightBase,
          fontSizeRatio: config.fontSizeRatio,
          col: config.col,
          row: config.row,
          key: uuidv4(),
        });
      }
    }

    if (this.props.resource?.appResource?.width !== prevProps.resource?.appResource?.width || this.props.resource?.appResource?.height !== prevProps.resource?.appResource?.height) {
      Logger.terminalInfo('componentDidUpdate resolution', this.props.resource);

      const width = this.props.resource?.appResource?.width - getSidebarWidth(this.props.sidebarState);
      const height = this.props.resource?.appResource?.height;
      const resolutionRatio = this.props.resource?.appResource?.resolutionRatio ?? 1;

      const config = getConfigFromSize(width, height, resolutionRatio);

      this.setState({
        widthBase: config.widthBase,
        typeWindow: config.type,
        width: width,
        height,
        heightBase: config.heightBase,
        fontSizeRatio: config.fontSizeRatio,
        col: config.col,
        row: config.row,
        key: uuidv4(),
      });
    }
  }

  render() {
    const main = this.props.main;
    const resource = this.props.resource;
    Logger.terminalInfo('render auth');
    if (this.state.init) {
      return (
        <div
          ref={this.ref}
          style={{
            // ...mainStyle,
            // width: typeof this.state.width !== 'undefined' ? this.state.width : '100%',
            // height: typeof this.state.height !== 'undefined' ? this.state.height : '100%',
            width: this.state.width,
            position: 'relative',
            justifyContent: this.state.widthBase < this.state.width ? 'center' : undefined,
            display: this.state.widthBase < this.state.width ? 'flex' : undefined,
          }}
          key={this.state.key}>
          {Builder.build(main, {
            ...resource,
            appResource: {
              ...resource?.appResource,
              widthBase: this.state.widthBase,
              heightBase: this.state.heightBase,
              col: this.state.col,
              row: this.state.row,
              typeWindow: this.state.typeWindow,
              fontSizeRatio: this.state.fontSizeRatio,
              containerWidth: this.state.width,
              containerHeight: this.state.height,
            },
          })}
        </div>
      );
    }
    return <></>;
  }
}

export default connect((state) => {
  return {
    sidebarState: state.filiot?.sidebar?.state,
    pageViewHeight: state.filiot?.main?.page?.pageHeight,
  };
}, {})(main);
