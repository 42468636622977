import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Text, TouchableOpacity, View } from '../../../../React-Native/types';

class CheckBoxModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  render() {
    const { theme, lang } = this.props;
    const styles = { ...style, ...this.style };
    const { value } = this.state;
    const _value = Array.isArray(value) ? value : [];

    return (
      <View style={[styles.contentModal, { backgroundColor: theme.colors.appColor }]}>
        {/* <FlatList
                    style={{ maxHeight: '100%', width: '100%' }}
                    contentContainerStyle={{ height: 57 * this.props.data?.length }}
                    data={this.props.data}
                    showsVerticalScrollIndicator={false}
                    renderItem={({ item, index }) => {
                        return (
                            <TouchableOpacity
                                style={[
                                    styles.row,
                                    {
                                        borderTopWidth: index !== 0 ? 1 : 0,
                                        borderTopColor: theme.colors.borderColor,
                                        width: '100%',
                                        justifyContent: 'space-between',
                                    },
                                ]}
                                key={item?.id || 'key' + index}
                                onPress={() => {
                                    const currentIndex = _value.indexOf(item.value);
                                    if (currentIndex > -1) {
                                        _value.splice(index, 1);
                                    } else {
                                        _value.push(item.value);
                                    }
                                    this.setState({
                                        value: _value,
                                    });
                                }}>
                                <View style={{ maxWidth: '93%' }}>
                                    <Text style={[styles.textSelect, { color: theme.colors.textColor, fontFamily: theme.fonts.fontBold }]}>{item.label}</Text>
                                </View>
                                {_value.includes(item.value) && <FastImage source={theme.icons.check} style={{ width: 20, height: 20 }} />}
                            </TouchableOpacity>
                        );
                    }}
                /> */}
        <TouchableOpacity style={[styles.btn, { backgroundColor: theme.colors.activeColor }]} onPress={() => this.props.save(value)}>
          <Text style={[styles.textBtn, { color: theme.colors.textColorBtn, fontFamily: theme.fonts.fontBold }]}>{lang.save}</Text>
        </TouchableOpacity>
      </View>
    );
  }
}

const style = {
  textBtn: {
    fontSize: 14,
    lineHeight: 24,
  },
  btn: {
    width: '100%',
    height: 56,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 16,
    marginTop: 50,
    marginBottom: 16,
  },
  textSelect: {
    fontSize: 14,
    lineHeight: 24,
  },
  contentModal: {
    width: '100%',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  row: {
    width: '100%',
    paddingVertical: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
};

export default connect((state) => {
  return {
    theme: state.theme,
    lang: state.lang,
  };
})(CheckBoxModal);
