import sidebar from './sidebar.json';
import preprocessing from './preprocessing.json';
// import header from './header.json';
// import main from './main.json';
import main from './Main';
// import main2 from "./main2.json";

const container = {
  preprocessing,
  sidebar,
  // header,
  main: main,
  mainStyle: {
    backgroundColor: '#ededed',
  },
  // mainBackgroundImage: "url(https://upload.wikimedia.org/wikipedia/commons/thumb/1/1a/24701-nature-natural-beauty.jpg/800px-24701-nature-natural-beauty.jpg?20160607144903)",
};

export default container;
