import Builder from '..';
import React, { Component, useEffect } from 'react';
import { extractValue } from '../../Helper/utils';
import { connect } from 'react-redux';
import Logger from '../../Helper/Logger';

class FiliotComponent extends Component {
  constructor(props) {
    super(props);
    this.reduxResource = {};
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { FiliotComponentConfig, reduxState, item } = nextProps;
    const { redux } = FiliotComponentConfig;
    let reduxResource = {};
    if (redux) {
      Object.keys(redux).forEach((key) => {
        reduxResource[key] = extractValue(redux[key], reduxState);
      });
    }

    if (JSON.stringify(this.reduxResource) !== JSON.stringify(reduxResource) || JSON.stringify(item) !== JSON.stringify(this.props.item)) {
      this.reduxResource = { ...reduxResource };
      return true;
    }
    return false;
  }

  render() {
    const { FiliotComponentConfig, resource, index, item } = this.props;

    return (
      <div key={index}>
        {Builder.build(FiliotComponentConfig.view, {
          ...resource,
          appResource: { ...resource.appResource, item: { ...item, index }, reduxItem: this.reduxResource },
        })}
      </div>
    );
  }
}

const ConnectedFiliotComponent = connect((state) => {
  return {
    reduxState: state,
  };
}, {})(FiliotComponent);

export const build = (FiliotComponentConfig, resource) => {
  Logger.terminalInfo('build FiliotComponent');
  return (props) => <ConnectedFiliotComponent {...props} FiliotComponentConfig={FiliotComponentConfig} resource={resource} />;
};

export const type = 'FiliotComponent';
