import Switch from './switch';
import UIBuilder from '../BaseComponent';
import { UI_COMPONENTS_TYPE_FIELD } from '../../../../Constant/Constant';

export default class SwitchBuilder extends UIBuilder {
  constructor() {
    super();
    this.schema = {
      value: UI_COMPONENTS_TYPE_FIELD.BOOLEAN,
    };
  }

  getType = () => {
    return 'switch';
  };

  getComponent = () => {
    return Switch;
  };
}
