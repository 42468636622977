import React from 'react';
import { Tree, Menu, Dropdown, Input, Modal, ConfigProvider, theme, Button } from 'antd';
import { FileAddOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import _ from 'lodash';
import Logger from '../../../../Helper/Logger';

const { DirectoryTree } = Tree;

const makeTreeDataFromTreeFile = (treeFile, prePath) => {
  if (Array.isArray(treeFile)) {
    return treeFile.map((child, index) => makeTreeDataFromTreeFile(child, `${prePath}.children.${index}`));
  }
  if (_.isObject(treeFile)) {
    return {
      title: treeFile.name,
      key: `${prePath}`,
      children: Array.isArray(treeFile.children) ? makeTreeDataFromTreeFile(treeFile.children, `${prePath}`) : [],
      isLeaf: treeFile.isFile ?? false,
    };
  }
};

const getSelectFile = (TreeFile, pos) => {
  const nodePos = pos.slice(1, pos.length);
  const block = TreeFile[nodePos[0]];
  const getNode = (Node, childPos) => {
    if (childPos.length === 0) {
      return Node;
    }
    return getNode(Node.children[childPos[0]], childPos.slice(1, childPos.length));
  };

  return getNode(block, nodePos.slice(1, nodePos.length));
};

const FileExplorer = (props) => {
  const [rightClickedNode, setRightClickedNode] = React.useState(null);
  const [visible, setVisible] = React.useState(false);
  const [menuPosition, setMenuPosition] = React.useState({ top: 0, left: 0 });
  const { treeFile, currentFile, setSelectFile, setTreeFile, closeFile } = props;
  const treeData = makeTreeDataFromTreeFile(treeFile, '$');
  const [newFileName, setNewFileName] = React.useState('');
  const [isModalOpen, setModalOpen] = React.useState(null);

  const onSelect = (keys, info) => {
    if (info.node.isLeaf) {
      const nodePos = info.node.pos.split('-');
      const selectFile = getSelectFile(treeFile, nodePos);
      Logger.terminalInfo('Trigger Select', keys, info);
      setSelectFile({ ...selectFile });
    }
  };

  const onExpand = (keys, info) => {
    Logger.terminalInfo('Trigger Expand', keys, info);
  };

  const onRightClick = ({ event, node }) => {
    event?.preventDefault();
    setVisible(true);
    setRightClickedNode(node);
    const position = { top: event.clientY, left: event.clientX };
    setMenuPosition(position);
  };

  const handleMenuClick = (e) => {
    if (e.key === 'new') {
      setModalOpen(e.key);
    } else if (e.key === 'del') {
      handleDelete();
    } else if (e.key === 'rename') {
      setNewFileName(rightClickedNode.title);
      setModalOpen(e.key);
    }
  };

  const handleInputChange = (e) => {
    setNewFileName(e.target.value);
  };

  const menu = (
    <Menu
      onClick={handleMenuClick}
      style={{
        backgroundColor: 'rgb(26 26 26)',
        position: 'relative',
        top: menuPosition.top,
        left: menuPosition.left,
      }}>
      <Menu.Item key='new' style={{ color: 'rgb(151 151 151)' }} icon={<FileAddOutlined />} disabled={rightClickedNode?.isLeaf}>
        New file
      </Menu.Item>
      <Menu.Item key='rename' style={{ color: 'rgb(151 151 151)' }} icon={<EditOutlined />} disabled={!rightClickedNode?.isLeaf}>
        {Logger.terminalInfo('RightClickedNode: ', rightClickedNode)}
        Rename
      </Menu.Item>
      <Menu.Item key='del' style={{ color: 'rgb(151 151 151)' }} icon={<DeleteOutlined />} danger={true}>
        Delete
      </Menu.Item>
    </Menu>
  );

  const handleCreate = () => {
    const newTreeFile = treeFile.map((elm, ind) => {
      if (elm.name === rightClickedNode.title) {
        return {
          ...elm,
          children: [
            ...elm.children,
            {
              name: newFileName,
              isFile: true,
              value: {
                abc: 'def',
              },
              path: `${rightClickedNode.title} > ${newFileName}`,
            },
          ],
        };
      }
      return elm;
    });
    setTreeFile(newTreeFile);
    handleClose();
  };

  const handleClose = () => {
    setMenuPosition({ top: 0, left: 0 });
    setNewFileName('');
    setRightClickedNode(null);
    setModalOpen(null);
  };

  const handleRename = () => {
    const nodePos = rightClickedNode.pos.split('-');
    const newTreeFile = treeFile.map((elm, index) => {
      if (index == nodePos[1]) {
        return {
          ...elm,
          children: [
            ...elm.children.filter((item, ind) => item.name !== rightClickedNode.title),
            {
              name: newFileName,
              value: elm.children[nodePos[1]].value,
              path: `${elm.name} > ${newFileName}`,
              isFile: true,
            },
          ],
        };
      }
      return elm;
    });
    setTreeFile(newTreeFile);
    handleClose();
  };

  const handleDelete = () => {
    const nodePos = rightClickedNode.pos.split('-');
    Logger.terminalInfo('pos: ', nodePos);
    const newTreeFile = treeFile.map((elm, index) => {
      if (index == nodePos[1]) {
        return {
          ...elm,
          children: [...elm.children.filter((item) => item.name !== rightClickedNode.title)],
        };
      }
      return elm;
    });
    closeFile({
      ...treeFile[nodePos[1]].children[nodePos[2]],
      select: rightClickedNode.selected,
    });
    setTreeFile(newTreeFile);
  };

  const showAlertWithInput = () => {
    var userInput = prompt('Please enter password:', ''); // Prompt user for input
    if (userInput === '123456') {
      props.onPublish?.();
    } else {
      alert('Password is incorrect !!'); // Inform the user that they canceled
    }
  };

  Logger.terminalInfo('FileExplorer', treeFile);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: 'rgb(26 26 26)',
        color: 'rgb(151 151 151)',
        position: 'relative',
      }}>
      <div
        style={{
          color: 'rgb(151 151 151)',
          height: '35px',
          fontSize: '16px',
          marginBottom: '20px',
          alignItems: 'center',
          paddingLeft: '20px',
          display: 'flex',
        }}>
        EXPLORER
      </div>
      <DirectoryTree
        defaultExpandAll
        selectedKeys={[currentFile.name]}
        onSelect={onSelect}
        onExpand={onExpand}
        treeData={treeData}
        onRightClick={onRightClick}
        style={{
          width: '100%',
          height: 'auto',
          backgroundColor: 'rgb(26 26 26)',
          color: 'rgb(151 151 151)',
          maxHeight: 800,
          overflow: 'auto',
        }}
      />
      {rightClickedNode && (
        <Dropdown overlay={menu} open={visible} trigger={['contextMenu']} onOpenChange={() => setVisible(!visible)}>
          <div></div>
        </Dropdown>
      )}
      <ConfigProvider
        theme={{
          algorithm: theme.darkAlgorithm,
        }}>
        <Modal
          title={isModalOpen === 'new' ? `Create new file in ${rightClickedNode?.title}` : 'Rename file'}
          open={isModalOpen ? true : false}
          onOk={() => {
            if (isModalOpen === 'new') {
              handleCreate();
            } else {
              handleRename();
            }
          }}
          mask={false}
          onCancel={() => setModalOpen(null)}>
          <Input value={newFileName} placeholder='File name ...' onChange={handleInputChange} />
        </Modal>
      </ConfigProvider>
      <div
        style={{
          position: 'absolute',
          bottom: '20px',
          margin: 'auto',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}>
        <Button type='primary' onClick={showAlertWithInput}>
          Publish
        </Button>
      </div>
    </div>
  );
};
export default FileExplorer;
