export const updateObjectProperty = (obj, path, newValue) => {
  const head = path[0];
  const rest = path.slice(1, path.length);
  if (Array.isArray(obj)) {
    let newArray = [];
    // if (typeof head === 'undefined') {
    if (typeof head === 'undefined') {
      newArray = newValue;
    } else if (typeof head === -1) {
      newArray = [...obj];
      if (typeof newValue !== 'undefined') {
        newArray.push(newValue);
      }
    } else {
      obj.forEach((element, index) => {
        if (index == head) {
          if (rest.length) {
            newArray.push(updateObjectProperty(obj[head], rest, newValue));
          } else {
            if (typeof newValue !== 'undefined' && newValue !== null) {
              newArray.push(newValue);
            }
          }
        } else {
          newArray.push(element);
        }
      });
    }

    return [...newArray];
  } else {
    if (rest.length) {
      return {
        ...obj,
        [head]: updateObjectProperty(obj[head] || {}, rest, newValue),
      };
    } else {
      if (typeof newValue !== 'undefined') {
        return {
          ...obj,
          [head]: newValue,
        };
      } else {
        return { ...obj };
      }
    }
  }
};

export const extractValue = (array, object) => {
  try {
    if (array.length === 0) {
      if (object === undefined || object === null) {
        return null;
      }
      return object;
    } else {
      const key = array[0];
      const newObject = object[key];
      return extractValue(array.slice(1, array.length), newObject);
    }
  } catch (error) {
    return null;
  }
};

export const getData = (component) => {
  if (component?.map_data) {
    let index = Object.keys(component.map_data).findIndex((item) => item === component.value);
    if (index >= 0) return Object.values(component.map_data)?.[index];
    return component.map_data.default || null;
  }
  return component?.value;
};

export const makeViewNumber = (number) => {
  if (Math.abs(number) >= 0 && Math.abs(number) < 10) {
    return Math.round(number * 100) / 100;
  } else if (Math.abs(number) >= 10 && Math.abs(number) < 100) {
    return Math.round(number * 10) / 10;
  } else if (Math.abs(number) >= 100) {
    return Math.round(number);
  }
};

export const validateEmail = (mail) => {
  try {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail);
    // return /^[a-zA-Z0-9-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail);
  } catch {
    return false;
  }
};

export const makeMaxNumber = (number) => {
  try {
    if (Math.abs(number) >= 0 && Math.abs(number) <= 0.5) {
      return 0.5;
    } else if (Math.abs(number) > 0.5 && Math.abs(number) <= 1) {
      return 1;
    } else if (Math.abs(number) > 1 && Math.abs(number) < 5) {
      return Math.ceil(number % 5);
    } else if (Math.abs(number) >= 5 && Math.abs(number) < 10) {
      return 5 + Math.ceil(number % 5);
    } else if (Math.abs(number) >= 10) {
      return Math.ceil(number / 5) * 5;
    }
  } catch {
    return number;
  }
};

export const electricityCalculator = (electricNumber, priceInfo) => {
  try {
    // if (isNaN(parseInt(price, 10)) || price === 'default') {
    //     const number = lodash.cloneDeep(electricNumber);
    //     const range = [1678, 1734, 2014, 2536, 2834, 2927];

    //     if (number >= 0 && number <= 50) {
    //         return number * range[0];
    //     } else if (number > 50 && number <= 100) {
    //         return 50 * range[0] + (number - 50) * range[1];
    //     } else if (number > 100 && number <= 200) {
    //         return 50 * range[0] + 50 * range[1] + (number - 100) * range[2];
    //     } else if (number > 200 && number <= 300) {
    //         return 50 * range[0] + 50 * range[1] + 100 * range[2] + (number - 200) * range[3];
    //     } else if (number > 300 && number <= 400) {
    //         return 50 * range[0] + 50 * range[1] + 100 * range[2] + 100 * range[3] + (number - 300) * range[4];
    //     } else if (number > 400) {
    //         return 50 * range[0] + 50 * range[1] + 100 * range[2] + 100 * range[3] + 100 * range[4] + (number - 400) * range[5];
    //     }
    // } else {
    //     return electricNumber * price;
    // }

    if (electricNumber > 0) {
      let cost = 0;
      priceInfo.map((item) => {
        if (item.from > electricNumber) {
          return;
        }
        cost = cost + item.price * (electricNumber < item.to ? electricNumber - item.from : item.to - item.from);
      });
      return cost;
    } else if (electricNumber === 0) {
      return 0;
    } else {
      return '---';
    }
  } catch {
    return false;
  }
};

export const ratioToColorTemp = (_ratio, _min, _max) => {
  const ratio = _ratio ?? 0.5;
  const min = _min ?? 2700;
  const max = _max ?? 6500;
  return Math.abs(Math.round(min + ratio * (max - min)));
};

export const colorTempToRatio = (_temp, _min, _max) => {
  const temp = _temp ?? 0.5;
  const min = _min ?? 2700;
  const max = _max ?? 6500;
  return (temp - min) / (max - min);
};

export const getAmountParams = (func) => {
  const functionString = func.toString();
  const match = functionString.match(/\(([^)]*)\)/);

  // If there is a match, count the number of parameters
  const numberOfParams = match
    ? match[1].split(',').reduce((acc, param) => {
        const parts = param.split('=');
        return acc + (parts.length === 1 ? 1 : 0);
      }, 0)
    : 0;

  return numberOfParams;
};

export const isJson = (str) => {
  try {
    JSON.parse(str);
    return true;
  } catch (error) {
    return false;
  }
};

export const ProjectConfigToTreeFile = (project) => {
  return [
    {
      name: 'manifest',
      isFile: true,
      children: [],
      value: project.manifest ?? {},
      path: 'manifest',
      key: `manifest`,
    },
    {
      name: 'auth',
      isFile: false,
      children: [
        {
          name: 'preprocessing',
          isFile: true,
          children: [],
          value: project.auth?.preprocessing ?? {},
          path: 'auth > preprocessing',
          key: `auth.preprocessing`,
        },
        {
          name: 'main',
          isFile: false,
          children: [
            {
              name: 'pages',
              isFile: false,
              children: (Array.isArray(project?.auth?.main?.pages) ? project?.auth?.main?.pages : []).map((page, index) => {
                return {
                  name: page.uri ?? 'Unknown',
                  isFile: true,
                  children: [],
                  value: page,
                  path: `auth > main > pages > ${page.uri ?? 'Unknown'}`,
                  key: `auth.main.pages.${index}`,
                };
              }),
            },
          ],
          value: project.auth?.preprocessing ?? {},
          path: 'auth > main',
        },
      ],
    },
    {
      name: 'container',
      isFile: false,
      children: [
        {
          name: 'preprocessing',
          isFile: true,
          children: [],
          value: project.container?.preprocessing ?? {},
          path: 'container > preprocessing',
          key: `container.preprocessing`,
        },
        {
          name: 'main',
          isFile: false,
          children: [
            {
              name: 'pages',
              isFile: false,
              children: (Array.isArray(project?.container?.main?.pages) ? project?.container?.main?.pages : []).map((page, index) => {
                return {
                  name: page.uri ?? 'Unknown',
                  isFile: true,
                  children: [],
                  value: page,
                  path: `container > main > pages > ${page.uri ?? 'Unknown'}`,
                  key: `container.main.pages.${index}`,
                };
              }),
              path: 'container > main > pages',
            },
          ],
          path: 'container > main',
        },
      ],
    },
  ];
};
