import Logger from "../../Helper/Logger";
import fAPI from "../fAPI";
import { BASE_URL, API } from "../fAPI/constants";

const BASE_URI = BASE_URL.prod;

/**
 * @param { email: string }: email of user.
 * @param { password: string }: password of user.
 * @param { appCode: string }: appCode of app.
 * @param { fcmToken: string }: firebase token of user.
 * @param { deviceUID: string }: deviceUID of app.
 * @param { os: string }: os of app.
 * @param { callback: json }: callback of function.
 * @param { callback.success: function }: function will call when sign in success.
 * @param { callback.fail: function }: function will call when sign in fail.
 */

async function createInvitationDetail({
  userId,
  memberEmail,
  appCode,
  spaceId,
  message,
  entityIds,
  token,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.INVITATION_DETAIL.CREATE_INVITATION_DETAIL}`;
    Logger.terminalInfo("uri", uri);
    const response = await fAPI.post(
      uri,
      {
        Authorization: token,
        "x-user": userId
      },
      {
        memberEmail,
        appCode,
        message,
        spaceId,
        entityIds
      }
    );

    typeof callback?.success === "function" && callback.success(response);

    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo("error", error);
    typeof callback.fail === "function" &&
      callback.fail({ status: false, error });
    return { status: false, error };
  }
}

async function updateInvitationDetailInfo({
  userId,
  invitationId,
  memberEmail,
  message,
  entityIds,
  token,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.INVITATION_DETAIL.UPDATE_INVITATION_DETAIL_INFO(invitationId)}`;
    Logger.terminalInfo("uri", uri);
    const response = await fAPI.put(
      uri,
      {
        Authorization: token,
        "x-user": userId
      },
      {
        memberEmail,
        message,
        entityIds
      }
    );

    typeof callback?.success === "function" && callback.success(response);

    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo("error", error);
    typeof callback.fail === "function" &&
      callback.fail({ status: false, error });
    return { status: false, error };
  }
}

async function updateInvitationDetailStatus({
  userId,
  invitationId,
  status,
  token,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.INVITATION_DETAIL.UPDATE_INVITATION_DETAIL_STATUS(invitationId)}`;
    Logger.terminalInfo("uri", uri);
    const response = await fAPI.put(
      uri,
      {
        Authorization: token,
        "x-user": userId
      },
      {
        status
      }
    );

    typeof callback?.success === "function" && callback.success(response);

    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo("error", error);
    typeof callback.fail === "function" &&
      callback.fail({ status: false, error });
    return { status: false, error };
  }
}

async function deleteInvitationDetail({
  userId,
  invitationId,
  token,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.INVITATION_DETAIL.DELETE_INVITATION_DETAIL(invitationId)}`;
    Logger.terminalInfo("uri", uri);
    const response = await fAPI.delete(
      uri,
      {
        Authorization: token,
        "x-user": userId
      },
      {}
    );

    typeof callback?.success === "function" && callback.success(response);

    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo("error", error);
    typeof callback.fail === "function" &&
      callback.fail({ status: false, error });
    return { status: false, error };
  }
}

export const fInvitationDetail = {
  createInvitationDetail,
  updateInvitationDetailInfo,
  updateInvitationDetailStatus,
  deleteInvitationDetail
};
