import DefaultTheme from '../../Resource/Theme/Default';
import DarkTheme from '../../Resource/Theme/Dark';
import En from '../../Resource/Lang/en.json';
import Vi from '../../Resource/Lang/vi.json';
import { Storage } from '../../Controller/Storage';

const defaultLang = {
  en: En,
  vi: Vi,
};

const defaultTheme = {
  default: DefaultTheme,
  dark: DarkTheme,
};

const baseUri = 'https://image-app-public.s3.ap-southeast-1.amazonaws.com';

class DecorationController {
  constructor() {
    this.componentDecoration = {};
    this.themeDecoration = defaultTheme;
    this.langDecoration = defaultLang;
    this.favouriteBanner = {
      type: 'weather',
    };
    this.currentPublishVersion = '';
    this.dictionary = {};
    this.convert = [];
    this.obj = {};
  }

  async init(appCode, decorationDirectory, currentPublishVersion) {
    let decorationConfiguration;
    const themeUri = `${baseUri}/${decorationDirectory}/${appCode}/${currentPublishVersion}/config/theme.json`;
    const langUri = `${baseUri}/${decorationDirectory}/${appCode}/${currentPublishVersion}/config/lang.json`;
    const componentUri = `${baseUri}/${decorationDirectory}/${appCode}/${currentPublishVersion}/config/component.json`;
    const deviceCPNUri = `${baseUri}/${decorationDirectory}/${appCode}/${currentPublishVersion}/config/deviceCPN.json`;
    try {
      const themeResponse = await fetch(themeUri, {
        headers: {
          'Cache-Control': 'no-cache',
        },
      });
      const theme = await themeResponse.json();
      decorationConfiguration.theme = theme;
      this.setTheme(theme);
    } catch (error) {
      //Logger.terminalInfo('Load theme decoration from CMS error ::: ', error);
    }
    try {
      const langResponse = await fetch(langUri, {
        headers: {
          'Cache-Control': 'no-cache',
        },
      });
      const lang = await langResponse.json();
      decorationConfiguration.lang = lang;
      this.setLang(lang);
    } catch (error) {
      //Logger.terminalInfo('Load lang decoration from CMS error ::: ', error);
    }
    try {
      const componentResponse = await fetch(componentUri, {
        headers: {
          'Cache-Control': 'no-cache',
        },
      });
      const component = await componentResponse.json();
      decorationConfiguration.component = component;
      this.setComponent(component);
    } catch (error) {
      //Logger.terminalInfo('Load component decoration from CMS error ::: ', error);
    }

    try {
      const deviceCPNResponse = await fetch(deviceCPNUri, {
        headers: {
          'Cache-Control': 'no-cache',
        },
      });
      const deviceCPN = await deviceCPNResponse.json();
      decorationConfiguration.deviceCPN = deviceCPN;
      this.setObject('deviceCPN', deviceCPN);
    } catch (error) {
      //Logger.terminalInfo('Load deviceCPN decoration from CMS error ::: ', error);
    }

    const favouriteBannerUri = `${baseUri}/${decorationDirectory}/${appCode}/${currentPublishVersion}/favourite/banner.json`;
    // Logger.terminalInfo('FavouriteBannerUri uri ::: ', favouriteBannerUri);
    try {
      const favouriteBannerResponse = await fetch(favouriteBannerUri, {
        headers: {
          'Cache-Control': 'no-cache',
        },
      });
      const favouriteBanner = await favouriteBannerResponse.json();
      decorationConfiguration.favouriteBanner = favouriteBanner;
      this.setFavouriteBanner(favouriteBanner);
    } catch (error) {
      //Logger.terminalInfo('Load component decoration from CMS error ::: ', error);
    }
    const discoveryUri = `${baseUri}/${decorationDirectory}/${appCode}/${currentPublishVersion}/favourite/discoveries.json`;
    try {
      const discoveriesResponse = await fetch(discoveryUri, {
        headers: {
          'Cache-Control': 'no-cache',
        },
      });
      const discoveries = await discoveriesResponse.json();
      decorationConfiguration.discoveries = discoveries;
      this.setDiscoveries(discoveries);
    } catch (error) {
      // Logger.terminalInfo('Load component decoration from CMS error ::: ', error);
    }

    Storage.set({ name: 'decoration', value: { ...decorationConfiguration } });

    const _discoveryUri = `${baseUri}/${decorationDirectory}/${appCode}/${currentPublishVersion}/favourite/discoveries.json`;
    try {
      const discoveriesResponse = await fetch(_discoveryUri, {
        headers: {
          'Cache-Control': 'no-cache',
        },
      });
      const discoveries = await discoveriesResponse.json();
      decorationConfiguration.discoveries = discoveries;
      this.setDiscoveries(discoveries);
    } catch (error) {
      // Logger.terminalInfo('Load component decoration from CMS error ::: ', error);
    }

    try {
      const { lang, theme, component, favouriteBanner } = decorationConfiguration;

      let preLoadList = [];
      preLoadList = [...theme?.default?.bgArea];
      favouriteBanner.data.forEach((item) => {
        preLoadList.push({ uri: item.uri });
      });
      this.discoveries?.discoveries?.forEach((item) => {
        preLoadList.push({ uri: item.thumnail });
      });
      this.setComponent(component);
      this.setLang(lang);
      this.setTheme(theme);
      this.setFavouriteBanner(favouriteBanner);
    } catch (error) {
      // Logger.terminalInfo('Load component decoration from local error ::: ', error);
    }
  }

  getObject(objectName) {
    //get from config
    //get storage
    //get cloud
    return this.obj?.[objectName];
  }
  setObject(objectName, data) {
    this.obj[objectName] = data;
  }

  getTheme() {
    return this.themeDecoration;
  }

  setTheme(theme) {
    this.themeDecoration = theme;
  }

  getLang() {
    return this.langDecoration;
  }

  setLang(lang) {
    this.langDecoration = lang;
  }

  getComponent() {
    return this.componentDecoration;
  }

  setComponent(component) {
    this.componentDecoration = component;
  }

  getFavouriteBanner() {
    return this.favouriteBanner;
  }

  setFavouriteBanner(favouriteBanner) {
    this.favouriteBanner = favouriteBanner;
  }

  getDiscoveries() {
    return this.discoveries;
  }

  setDiscoveries(discoveries) {
    this.discoveries = discoveries;
  }

  getDecorationByComponentId(componentId) {
    return this.getComponent()[componentId] ?? { redux: {}, style: {} };
  }

  getCurrentPublishVersion() {
    return this.currentPublishVersion;
  }

  setCurrentPublishVersion(currentPublishVersion) {
    this.currentPublishVersion = currentPublishVersion;
  }

  getDictionary() {
    return this.dictionary;
  }

  setDictionary(dictionary) {
    this.dictionary = dictionary;
  }

  getConvert() {
    return this.convert;
  }

  setConvert(convert) {
    this.convert = convert;
  }
}

export default new DecorationController();
