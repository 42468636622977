import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { buildReactNode } from '../../../Helpers/Attribute/builder';
import { extractQuoteWithoutThis, extractQuoteWithThis } from '../../../../../Helper/App/View/Attribute/extractor';
import Dynamic from '../../UI_ReactNative/DynamicComponent';
import Logger from '../../../../../Helper/Logger';

/**
 * A dynamic component which build by a json file & existed mini components.
 * Một số vấn đề cần giải quyết của component
 * 1. Dữ liệu động ( theme, lang, this ): trích xuất trước khi build react node bằng cách tìm và thay dữ liệu được đánh dấu biến {} bằng dữ liệu được truyền vào từ props.externalData.
 *
 * 2. Hàm động: Mỗi component đều có props của riêng nó. Props có thể là dữ liệu như ( theme, lang ) bên trên, cũng có thể là một hàm số.
 * Với hàm số, ta cũng truyền vào 1 số hàm số có trong component cha chứa dynamic component ( truyền trong externalData ).
 * Hàm số trong file json được đánh dấu bằng 1 mảng, mỗi phần tử trong mảng là 1 hàm số được thể hiện bằng 1 đối tượng có 2 trường: functionName là tên hàm và args là list tham số truyền vào.
 * Ví dụ trong file json của {"src/View/BaseComponent/Common/Attributes/Samples/Attributes/timer.json"}
 * với dynamiccomponent có trong {"src/View/BaseComponent/Common/Attributes/detailattribute.js"} :
 *          "onDeleteTimer": [
                { "functionName": "onChangeState", "args": [["timer"]] },
                { "functionName": "onSaveAutomationRule", "args": [["timer"]] }
            ].
 * Với props được truyền vào là onDeleteTimer như trên, onDeleteTimer sẽ gồm 2 hàm:
            - onChangeState với tham số truyền vào là ["timer"]
            - onSaveAutomationRule với tham số truyền vào là ["timer"].
    Trong đó onChangeState và onSaveAutomationRule lại là hai hàm được truyền vào {{externalData.this}} có trong detailattribute
 *
 * 3. Hàm trong item của flatlist. Vì flatlist không đánh dấu index component item của nó nên không thể biết được là item ở số thứ tự nào đang gọi hàm của flatlist.
 * Vì vậy ta sẽ can thiệp vào phần render của flatlist để chèn thêm index vào trong các hàm của item khi nó gọi.
 * Lại 1 ví dụ khác, ta có 1 flatlist json như sau:
 *  {
        "type": "FlatList",
        "children": [],
        "props": {
            "itemComponent": {
                "type": "TimerComponent",
                "props": {
                    "onNavigateDetailTimer": [
                        {
                            "functionName": "navigate",
                            "args": [
                                "detailtimer",
                                {
                                    "onSaveTimer": [
                                        { "functionName": "onChangeState", "args": [["timer"]] },
                                        { "functionName": "onSaveAutomationRule", "args": [["timer"]] }
                                    ],
                                    "onDeleteTimer": [
                                        { "functionName": "onChangeState", "args": [["timer"]] },
                                        { "functionName": "onSaveAutomationRule", "args": [["timer"]] }
                                    ]
                                }
                            ]
                        }
                    ],
                    "onChangeValue": [
                        { "functionName": "onChangeState", "args": [["timer"]] },
                        { "functionName": "onSaveAutomationRule", "args": [["timer"]] }
                    ]
                },
                "children": []
            }
        }
    }
 *  Ta thấy itemcomponent trong flatlist có props "onChangeValue" là 1 hàm khi thực thi sẽ chạy 2 hàm "onChangeState" và "onSaveAutomationRule" trong component cha chứa dynamiccomponent.
 *  Tuy nhiên do nó nằm trong flatlist, khi chạy hàm của component cha nó không chỉ truyền tham số ["timer"] vào mà còn truyền thêm vị trí của nó trong flatlist.
 *  Ví dụ với item ở vị trí 2, hàm được gọi sẽ kiểu this.onSaveAutomationRule(["timer"], 2)
 */

class DynamicComponent extends Component {
  fillData(dest) {
    const { externalData, components } = this.props;
    extractQuoteWithThis(dest, {
      ...externalData,
      this: externalData.this,
      components: { ...components },
    });

    return dest;
  }

  fillEnviroment(dest) {
    const { lang, theme, externalData } = this.props;
    const currentLanguage = lang.__type;
    const currentTheme = theme.__type;
    const mergeLanguage = { ...lang, ...externalData.lang?.[currentLanguage] };
    const mergeTheme = { ...theme, ...externalData.theme?.[currentTheme] };
    extractQuoteWithoutThis(dest, { ...externalData, lang: mergeLanguage, theme: mergeTheme });

    return dest;
  }

  fill(dest) {
    this.fillEnviroment(dest);
    this.fillData(dest);
    return dest;
  }

  render() {
    const { display, components } = this.props;
    const noMarkDisplay = this.fill(display);
    Logger.terminalInfo('noMarkDisplay', noMarkDisplay, this.props.externalData);
    return <Dynamic jsonData={noMarkDisplay} __components={{ ...components }} />;
    // buildReactNode(noMarkDisplay, { ...components, ...Components });
    // return React.isValidElement(noMarkDisplay.component) ? noMarkDisplay.component : <></>;
  }
}

export default connect((state) => {
  return {
    lang: state.lang,
    theme: state.theme,
  };
})(DynamicComponent);
