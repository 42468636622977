import { extractValue } from '../../Helper/utils';
import store from '../../Redux';
import { fRedux } from '../fRedux';

const getGlobal = (path, resource) => {
  let _path = path;
  if (typeof path === 'string') {
    _path = path.split('.');
  }
  return extractValue(_path, store.getState()?.filiot);
};

const setGlobal = ({ path, data }) => {
  fRedux.updateObject({ path, data });
};

const getLocal = (path, resource) => {
  let _path = path;
  if (typeof path === 'string') {
    _path = path.split('.');
  }
  return extractValue(_path, resource?.variableEnvironment);
};

const get = (path, resource) => {
  let _path = path;
  if (typeof path === 'string') {
    _path = path.split('.');
  }

  const extractByVariable = extractValue(_path, resource?.variableEnvironment);
  if (extractByVariable === null || typeof extractByVariable === 'undefined') {
    return extractValue(_path, resource);
  }
  return extractByVariable;
};

export const Variable = {
  getGlobal,
  setGlobal,
  getLocal,
  get,
};
