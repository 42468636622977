import Builder from '..';

const operators = {
  eq: 'eq',
  neq: 'neq',
  lt: 'lt',
  le: 'le',
  gt: 'gt',
  ge: 'ge',
};

export const build = (ConditionConfig, resource) => {
  const { left, right, operator } = ConditionConfig;

  const leftValue = Builder.traverseBuild(left, resource);
  const rightValue = Builder.traverseBuild(right, resource);
  switch (operator) {
    case operators.eq:
      return leftValue === rightValue;
    case operators.neq:
      return leftValue !== rightValue;
    case operators.lt:
      return leftValue < rightValue;
    case operators.le:
      return leftValue <= rightValue;
    case operators.gt:
      return leftValue > rightValue;
    case operators.ge:
      return leftValue >= rightValue;
    default:
      return 'unknown';
  }
};

export const type = 'Condition';
