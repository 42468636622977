// import FModal from '../DynamicComponent/Components/Modal';
// import FSwitch from '../DynamicComponent/Components/Switch';
import Logger from '../../../../../Helper/Logger';
import { makeStyles } from './utils';

export const View = (props) => {
  return (
    <div {...props} style={makeStyles(props.style)}>
      {props.children}
    </div>
  );
};

// export const Modal = FModal;

export const Text = (props) => {
  return (
    <div {...props} style={{ textAlign: 'left', ...makeStyles(props.style) }}>
      {props.children}
    </div>
  );
};

export const TouchableOpacity = (props) => {
  const newProps = { ...props, onClick: props.onPress };
  return (
    <div {...newProps} style={{ ...makeStyles(props.style), cursor: 'pointer' }}>
      {props.children}
    </div>
  );
};

// export const Switch = FSwitch;

export const Button = (props) => {
  const newProps = { ...props, onClick: props.onPress };
  return (
    <button {...newProps} style={makeStyles(props.style)}>
      {props.children}
    </button>
  );
};

export const TextInput = (props) => {
  return (
    <input
      {...props}
      style={{
        backgroundColor: 'unset',
        ...makeStyles(props.style),
      }}
      onChange={(e) => {
        const type = props.secureTextEntry === true ? 'password' : props.type;
        switch (type) {
          case 'number':
            if (e.target.value === '') {
              props.onChangeText?.('');
            } else if (e.target.value === '' || !isNaN(parseFloat(e.target.value))) {
              props.onChangeText?.(parseFloat(e.target.value));
            }
            break;

          default:
            props.onChangeText?.(e.target.value);
            break;
        }
      }}
      type={props.secureTextEntry === true ? 'password' : props.type}
    />
  );
};

export const CheckBox = (props) => {
  return (
    <input
      {...props}
      style={{
        backgroundColor: 'unset',
        ...makeStyles(props.style),
      }}
      checked={props.checked}
      onChange={(e) => props.onChangeValue?.(e.target.checked)}
      type={'checkbox'}
    />
  );
};

export const Image = (props) => {
  Logger.terminalInfo('props', props);
  return <img {...props} style={makeStyles(props.style)} alt={props.alt} src={props.source} />;
};

export const FastImage = (props) => {
  return <img {...props} style={makeStyles(props.style)} alt={props.alt} src={props.source} />;
};

export const FlatList = (props) => {
  return <></>;
};

export const StyleSheet = {
  create: (style) => style,
};

let DimensionsClass = undefined;

export const setEnvironment = ({ windowWidth, windowHeight, screenWidth, screenHeight }) => {
  DimensionsClass = class _Dimensions {
    constructor() {
      this.windowWidth = windowWidth;
      this.windowHeight = windowHeight;
      this.screenWidth = screenWidth;
      this.screenHeight = screenHeight;
    }

    get(type) {
      if (type === 'window') {
        return {
          width: this.windowWidth,
          height: this.windowHeight,
        };
      } else if (type === 'screen') {
        return {
          width: this.screenWidth,
          height: this.screenHeight,
        };
      } else {
        return {};
      }
    }
  };

  Logger.terminalInfo('DimensionsClass', DimensionsClass);
};

export const Dimensions = {
  get: (type) => {
    if (type === 'window') {
      return {
        width: 390,
        height: 844,
      };
    } else if (type === 'screen') {
      return {
        width: 390,
        height: 844,
      };
    } else {
      return {};
    }
  },
};

export const DeviceEventEmitter = {
  removeAllListeners: () => {},
  addEventListener: () => {},
};

export const ScrollView = (props) => {
  return (
    <div {...props} style={{ ...makeStyles(props.style), flex: 1 }}>
      {props.children}
    </div>
  );
};

export const Platform = {
  OS: 'android',
};

export const NativeModules = {};

export const NativeEventEmitter = {};

export const Clipboard = {};

export const ImageBackground = (props) => {
  return (
    <>
      <img
        {...props}
        style={{ ...makeStyles(props.style), width: '100%', height: '100%', position: 'absolute', zIndex: 1 }}
        alt={props.alt}
        src={props.source}
      />
      {props.children}
    </>
  );
};

export const Animated = {
  Value: class Value {
    constructor(value) {
      this.value = value;
    }
  },
  timing: () => {},
  loop: () => {
    return {
      start: () => {},
      stop: () => {},
    };
  },
};
