import { Dimensions } from '../../../../Builder/UI/Components/UI_ReactNative/React-Native/types';

export const COMPONENT_STATUS = {
  PENDING: 'PENDING',
  CREATING: 'CREATING',
  DELETING: 'DELETING',
  EDITING: 'EDITING',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};

export const MEMBER_ROLE = {
  OWNER: 'owner',
  MEMBER: 'member',
  WAITING: 'waiting',
};

export const INVITATION_STATUS = {
  WAITING: 'waiting',
  DENIED: 'denied',
  ACCEPTED: 'accepted',
};

export const DIMENTION = {
  windowWidth: Dimensions.get('window').width,
  windowHeight: Dimensions.get('window').height,
  fontScale: Dimensions.get('window').fontScale,
  scale: Dimensions.get('window').scale,
  screenWidth: Dimensions.get('screen').width,
  screenHeight: Dimensions.get('screen').height,
  screenFontScale: Dimensions.get('screen').fontScale,
  screenScale: Dimensions.get('screen').scale,
};
