import validate from '../../../../View/Attribute/validate';

const rightButtonArgumentsSchema = {
  type: 'object',
  properties: {
    parameter: {
      type: ['integer', 'string', 'number'],
    },
    value: {
      type: ['integer', 'string', 'number'],
    },
  },
  required: ['parameter', 'value'],
};
const headingComponent = (
  data = {
    title: 'Tiêu đề',
    rightButton: {
      component: 'add',
      onPress: {},
    },
  },
) => {
  let rightButtonComponent = {
    type: 'View',
    children: [],
  };
  if (data.rightButton && data.rightButton.component !== 'none') {
    let rightFunction = [];
    if (data.rightButton.onPress) {
      const onPress = data.rightButton.onPress;
      if (Array.isArray(onPress)) {
        rightFunction = onPress;
      }

      if (validate(onPress, rightButtonArgumentsSchema)) {
        rightFunction = [
          { functionName: 'onChangeState', args: [['temp', 'par'], onPress.parameter] },
          { functionName: 'onChangeState', args: [['temp', 'val'], onPress.value] },
          { functionName: 'onSaveSetting', args: [['temp']] },
        ];
      }
    }

    switch (data.rightButton.component) {
      case 'add':
        rightButtonComponent = {
          type: 'TouchableOpacity',
          children: [
            {
              type: 'Image',
              props: {
                source: '{{theme.icons.add}}',
                style: { width: 32, height: 32 },
              },
            },
          ],
          props: {
            style: {
              position: 'absolute',
              right: -16,
              padding: 16,
            },
            onPress: rightFunction,
          },
        };
        break;

      case 'save':
        rightButtonComponent = {
          type: 'TouchableOpacity',
          children: [
            {
              type: 'Image',
              props: {
                source: '{{theme.icons.save}}',
                style: { width: 32, height: 32 },
              },
            },
          ],
          props: {
            style: {
              position: 'absolute',
              right: -16,
              padding: 16,
            },
            onPress: rightFunction,
          },
        };
        break;

      default:
        break;
    }
  }

  return {
    type: 'View',
    children: [
      {
        type: 'View',
        children: [
          {
            type: 'TouchableOpacity',
            children: [
              {
                type: 'Image',
                props: {
                  source: '{{theme.icons.back}}',
                  style: { width: 32, height: 32 },
                },
              },
            ],
            props: {
              style: {
                position: 'absolute',
                left: -16,
                padding: 16,
              },
              onPress: [{ functionName: 'goBack', args: [] }],
            },
          },
          {
            type: 'Text',
            children: data.title || 'EX ATT',
            props: {
              style: {
                color: '{{theme.colors.textColor}}',
                fontFamily: '{{theme.fonts.fontBold}}',
                width: '100%',
                flex: 1,
                fontSize: 20,
                lineHeight: 28,
                maxWidth: '70%',
                textAlign: 'center',
              },
              ellipsizeMode: 'tail',
              numberOfLines: 1,
            },
          },
          { ...rightButtonComponent },
        ],
        props: {
          style: {
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          },
        },
      },
    ],
    props: {
      style: {
        width: '100%',
        paddingBottom: 16,
        paddingHorizontal: 24,
        paddingTop: 50,
      },
    },
  };
};

export default headingComponent;
