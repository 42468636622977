import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import Builder from '../..';
import ErrorPage from '../App/Component/ErrorPage';

const RouteManager = class RouteManager extends Component {
  render() {
    const { pages } = this.props.RouteManagerConfig;
    const { resource } = this.props;
    if (Array.isArray(pages) && pages.length > 0) {
      return (
        <Switch>
          {Array.isArray(pages) &&
            pages.map((page) => {
              const FiliotPageJSX = Builder.build(page, resource);
              if (React.isValidElement(FiliotPageJSX)) {
                return Builder.build(page, resource);
              }
              return <ErrorPage accessDenied={resource?.reduxResource?.theme.icons.accessDenied} />;
            })}
        </Switch>
      );
    }

    return <ErrorPage accessDenied={resource?.reduxResource?.theme.icons.accessDenied} />;
  }
};

export const build = (RouteManagerConfig, resource) => {
  return <RouteManager resource={resource} RouteManagerConfig={RouteManagerConfig} />;
};

export const type = 'RouteManager';
