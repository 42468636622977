import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Text, TextInput, View } from '../../../../React-Native/types';
import Logger from '../../../../../../../../Helper/Logger';

class IntModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  render() {
    const { min, max, useSlide } = this.props;
    const _min = isNaN(parseInt(min, 10)) ? 0 : parseInt(min, 10);
    const _max = !isNaN(parseInt(max, 10)) && parseInt(max, 10) >= min ? parseInt(max, 10) : _min + 100;
    const { theme, lang } = this.props;
    const styles = { ...style, ...this.style };
    const { value } = this.state;

    Logger.terminalInfo('useSlide', useSlide);

    if (useSlide === false) {
      return (
        <View>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text style={{ fontFamily: theme.fonts.fontMedium, fontSize: 18, color: theme.colors.textInfor }}>{'Giá trị: '}</Text>
            <TextInput
              style={{ fontFamily: theme.fonts.fontMedium, fontSize: 20, fontWeight: '700', color: theme.colors.textColor }}
              value={this.state.value?.toString?.()}
              onChangeText={(text) => {
                this.setState({ value: text !== '' ? parseFloat(text) : '' });
              }}
              inputMode='decimal'
              type={'number'}
            />
          </View>
          <View style={styles.bottom}>
            <Button
              title={lang.save}
              style={{ color: 'white', backgroundColor: 'black' }}
              color={'#000'}
              onPress={() => this.props.save(isNaN(parseFloat(value)) ? 0 : parseFloat(value))}
            />
          </View>
        </View>
      );
    } else {
      return (
        <View>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text style={{ fontFamily: theme.fonts.fontMedium, fontSize: 18, color: theme.colors.textInfor }}>{'Giá trị: '}</Text>
            <Text style={{ fontFamily: theme.fonts.fontMedium, fontSize: 20, fontWeight: '700', color: theme.colors.textColor }}>{value}</Text>
          </View>
          <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Text style={{ fontFamily: theme.fonts.fontMedium, fontSize: 18, color: theme.colors.textColor, marginRight: 10, marginBottom: 4 }}>{_min}</Text>
            {/* <MultiSlider
                            enabledOne={true}
                            min={_min}
                            max={_max}
                            onValuesChange={(e) => {
                                this.setState({ value: e[0] });
                            }}
                            valuePrefix={_min.toString()}
                            valueSuffix={_max.toString()}
                            values={[value]}
                            step={this.props.step ?? 1}
                            sliderLength={200}
                        /> */}
            <Text style={{ fontFamily: theme.fonts.fontMedium, fontSize: 18, color: theme.colors.textColor, marginLeft: 10, marginBottom: 4 }}>{_max}</Text>
          </View>
          <Text style={{ color: theme.colors.textInfor }}>{`Lưu ý: Giá trị nằm trong khoảng ${_min} -> ${_max}`}</Text>
          <View style={styles.bottom}>
            <Button
              title={lang.save}
              style={{ color: 'white', backgroundColor: 'black' }}
              color={'#000'}
              onPress={() => this.props.save(isNaN(parseFloat(value)) ? 0 : parseFloat(value))}
            />
          </View>
        </View>
      );
    }
  }
}

const style = {
  bottom: {
    alignItems: 'center',
    paddingBottom: 20,
    marginTop: 20,
  },
};

export default connect((state) => {
  return {
    theme: state.theme,
    lang: state.lang,
  };
})(IntModal);
