import React, { Component } from 'react';
import ReactModal from 'react-modal';
import BaseComponent from '../BaseComponent/BaseComponent';
import { Spring, animated } from '@react-spring/web';
class Modal extends Component {
  render() {
    const visible = this.props.visible;
    const style = this.props.style;
    const isOpen = visible === true;
    return (
      <ReactModal
        isOpen={isOpen}
        style={{
          ...style,
          position: 'fixed',
          overlay: {
            zIndex: 9999,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
            outline: 'none',
            textAlign: 'center',
          },
          content: {
            ...style?.content,
            borderRadius: '6px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
            margin: 'auto',
            padding: 0,
            borderWidth: '0px',
            background: undefined,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        }}
        onRequestClose={() => {
          typeof this.props.onRequestClose === 'function' && this.props.onRequestClose();
        }}>
        <Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
          {(styleProps) => (
            <animated.div style={{ ...styleProps, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {React.isValidElement(this.props.children) ? this.props.children : <></>}
            </animated.div>
          )}
        </Spring>
      </ReactModal>
    );
  }
}

export default Modal;
