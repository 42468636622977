import Table from './Table';
import UIBuilder from '../BaseComponent';
import { UI_COMPONENTS_TYPE_FIELD } from '../../../../Constant/Constant';

export default class TableBuilder extends UIBuilder {
  constructor() {
    super();
    this.schema = {
      rowWidth: UI_COMPONENTS_TYPE_FIELD.JSON,
      heading: UI_COMPONENTS_TYPE_FIELD.JSON,
      body: UI_COMPONENTS_TYPE_FIELD.JSON,
      striped: UI_COMPONENTS_TYPE_FIELD.BOOLEAN,
      hover: UI_COMPONENTS_TYPE_FIELD.BOOLEAN,
      border: UI_COMPONENTS_TYPE_FIELD.BOOLEAN,
    };
  }

  getType = () => {
    return 'table';
  };

  getComponent = () => {
    return Table;
  };
}
