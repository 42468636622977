import Builder from '..';

export const build = (ConditionConfig, resource) => {
  const { trueBranch, falseBranch, test } = ConditionConfig;
  const testValue = Builder.traverseBuild(test, resource);

  if (testValue === true) {
    return Builder.traverseBuild(trueBranch, resource);
  } else {
    return Builder.traverseBuild(falseBranch, resource);
  }
};

export const type = 'ConditionExpression';
