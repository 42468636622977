import Builder from '..';

export const build = (FindIndexInArrayExpressionConfig, resource) => {
  const array = Builder.build(FindIndexInArrayExpressionConfig.array, resource);

  if (Array.isArray(array)) {
    return array.findIndex((element, index) => {
      const checkFunction = Builder.build(FindIndexInArrayExpressionConfig.checkFunction, resource);
      return checkFunction(element, index, array) === true;
    });
  } else {
    return -1;
  }
};

export const type = 'FindIndexInArrayExpression';
