import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Text, TextInput, TouchableOpacity, View } from '../../../../React-Native/types';

class Int extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
    };
  }

  setIsEdit = (value) => {
    this.setState({
      isEdit: value,
    });
  };

  render() {
    const { title, theme } = this.props;
    const titleContent = title?.content;
    const titleStyle = title?.style;
    const styles = { ...style, ...this.style };
    const { value } = this.props;

    return (
      <TouchableOpacity
        style={{ backgroundColor: theme.colors.appColor, ...styles.titleContainer, ...titleStyle?.container }}
        onPress={() => this.setIsEdit(true)}>
        <Text
          style={{
            color: theme.colors.textColor,
            fontFamily: theme.fonts.fontBold,
            ...styles.title,
            ...titleStyle?.title,
          }}
          numberOfLines={1}
          ellipsizeMode={'tail'}>
          {titleContent}
        </Text>
        {this.state.isEdit ? (
          <View
            style={{
              position: 'absolute',
              right: '20px',
              top: '30px',
            }}>
            <View>
              <Text>Giá trị</Text>
              <TextInput
                style={{
                  backgroundColor: 'unset',
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'relative',
                  color: 'rgb(34, 37, 45)',
                  fontFamily: 'RobotoBold',
                  marginRight: '8px',
                  width: '60px',
                  borderWidth: '1px',
                  borderColor: '#e8e8e8',
                  borderStyle: 'solid',
                  justifyContent: 'center',
                  padding: '2px 7px',
                  borderRadius: '8px',
                }}
                value={value}
              />
            </View>
            <View>
              <View>Hủy</View>
              <View>Xác nhận</View>
            </View>
          </View>
        ) : (
          <Text
            style={{
              color: theme.colors.textColor,
              fontFamily: theme.fonts.fontBold,
              marginRight: 8,
            }}
            numberOfLines={1}
            ellipsizeMode={'tail'}>
            {`${value?.toString()}`}
          </Text>
        )}
      </TouchableOpacity>
    );
  }
}

const style = {
  title: {
    flex: 1,
    fontSize: 16,
    lineHeight: 24,
  },
  titleContainer: {
    flex: 1,
    height: 56,
    borderRadius: 16,
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginHorizontal: 20,
  },
};

export default connect((state) => {
  return {
    theme: state.theme,
    lang: state.lang,
  };
})(Int);
