import { ControlSource } from '../../Constant/ControlFlow';
import ControlEnv from '../env';
import * as methods from '../../Redux/action';
import Store from '../../Redux/index';
/**
 * Update a field in state of redux.
 * @param { path: string }: path to field. Example: "env.os",
 * @param { data: any }: data will be updated.
 */

const IGNORE_PATH_PREVIEW = ['accessToken', 'refreshToken'];

function updateObject({ path, data }) {
  const env = ControlEnv.getEnv();
  if (env.preview === true && env.source === ControlSource.SYSTEM) {
    if (!IGNORE_PATH_PREVIEW.includes(path)) {
      Store.dispatch(methods.updateObject(path, data));
    }
  } else {
    Store.dispatch(methods.updateObject(path, data));
  }
}

function updateObjects(object = [{ path: '', data: '' }]) {
  const env = ControlEnv.getEnv();
  if (env.preview === true && env.source === ControlSource.SYSTEM) {
    if (Array.isArray(object)) {
      Store.dispatch(
        methods.updateObjects(
          object.filter((_object) => {
            return !IGNORE_PATH_PREVIEW.includes(_object.path);
          }),
        ),
      );
    }
  } else {
    Store.dispatch(methods.updateObjects(object));
  }
}

const createObject = ({ path, data }) => {
  methods.createObject(path, data);
};

const deleteObject = ({ path, data }) => {
  methods.deleteObject(path, data);
};

const getObject = ({ path, data }) => {
  methods.getObject(path, data);
};

export const fRedux = {
  updateObject,
  createObject,
  deleteObject,
  getObject,
  updateObjects,
};
