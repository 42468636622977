import { LOG_LEVEL } from '../../Constant/Constant';
import { Platform } from '../../../../../Builder/UI/Components/UI_ReactNative/React-Native/types';
import { RELEASE_MODE } from '../../Constant/Constant';

class Logger {
    constructor(scope) {
        this.scope = scope || 'app';
    }

    terminalInfo(message) {
        if (!RELEASE_MODE.active)
            switch (LOG_LEVEL) {
                case 'INFO':
                case 'DEBUG':
                case 'ERROR':
                    Logger.terminalInfo('\x1b[36m%s\x1b[0m', `[${Platform.OS}_${Platform.Version}][INFO] : ${message}`);
                    break;
            }
    }

    terminalDebug(message) {
        if (!RELEASE_MODE.active)
            switch (LOG_LEVEL) {
                case 'DEBUG':
                case 'ERROR':
                    Logger.terminalInfo('\x1b[33m%s\x1b[0m', `[${Platform.OS}_${Platform.Version}][DEBUG] : ${message}`);
                    break;
            }
    }

    terminalError(message) {
        if (!RELEASE_MODE.active)
            switch (LOG_LEVEL) {
                case 'ERROR':
                    Logger.terminalInfo('\x1b[31m%s\x1b[0m', `[${Platform.OS}_${Platform.Version}][ERROR] : ${message}`);
                    break;
            }
    }
}

export default new Logger();
