export const mixDeviceResource = (DeviceTypes = []) => {
    let deviceCategories = [];
    DeviceTypes.forEach((deviceType) => {
        if (deviceCategories.findIndex((e) => e.id === deviceType.deviceCategoryId) === -1) {
            let deviceFamilies = [];
            DeviceTypes.forEach((deviceType2) => {
                if (
                    deviceFamilies.findIndex((e) => e.id === deviceType2.deviceFamilyId) === -1 &&
                    deviceType2.deviceCategoryId === deviceType.deviceCategoryId
                ) {
                    let deviceConnections = [];
                    DeviceTypes.forEach((deviceType3) => {
                        if (
                            deviceConnections.findIndex((e) => e.id === deviceType3.deviceConnectionId) === -1 &&
                            deviceType3.deviceFamilyId === deviceType2.deviceFamilyId &&
                            deviceType3.deviceCategoryId === deviceType.deviceCategoryId
                        ) {
                            let deviceTypes = [];
                            DeviceTypes.forEach((deviceType4) => {
                                if (
                                    deviceTypes.findIndex((e) => e.id === deviceType4.id) === -1 &&
                                    deviceType4.deviceConnectionId === deviceType3.deviceConnectionId &&
                                    deviceType4.deviceFamilyId === deviceType2.deviceFamilyId &&
                                    deviceType4.deviceCategoryId === deviceType.deviceCategoryId
                                ) {
                                    deviceTypes.push({
                                        id: deviceType4.id,
                                        name: deviceType4.name,
                                        code: deviceType4.deviceTypeCode,
                                    });
                                }
                            });
                            if (deviceTypes.length !== 0) {
                                deviceConnections.push({
                                    id: deviceType3.deviceConnectionId,
                                    name: deviceType3.deviceConnection.name,
                                    deviceTypes,
                                });
                            }
                        }
                    });
                    if (deviceConnections.length !== 0) {
                        deviceFamilies.push({
                            id: deviceType2.deviceFamilyId,
                            name: deviceType2.deviceFamily.name,
                            deviceConnections,
                        });
                    }
                }
            });
            if (deviceFamilies.length !== 0) {
                deviceCategories.push({
                    id: deviceType.deviceCategoryId,
                    name: deviceType.deviceCategory.name,
                    deviceFamilies,
                });
            }
        }
    });

    return deviceCategories;
};

export const getDeviceFamilyByDeviceType = (deviceType) => {
    let result = null;
    if (deviceType.length === 11) {
        result = deviceType.slice(5, 8);
    }
    return result;
};

export const getDeviceConnectionByDeviceType = (deviceType) => {
    let result = null;
    if (deviceType.length === 11) {
        result = deviceType.slice(3, 5);
    }
    return result;
};
