const theme = {
  __type: 'light',
  fonts: {
    fontLight: 'Roboto-Light',
    fontRegular: 'Roboto-Regular',
    fontMedium: 'Roboto-Medium',
    fontBold: 'Roboto-Bold',
  },
  colors: {
    appColor: '#FFFFFF',
    appColorSecond: '#F0F2F7',
    appColorThird: '#F9F9FC',
    textColor: '#22252D',
    textColorSecond: '#FFFFFF',
    textColorInactive: '#7F8596',
    textColorBtn: '#FFFFFF',
    textInfor: '#494E5B',
    placeholderColor: '#7F8596',
    errorColor: '#E7574E',
    borderColor: '#F0F2F7',
    borderColorSecond: '#E1E3E7',
    borderInputColor: '#23262F',
    btnColor: '#FFFFFF',
    btnColorSecond: '#FB6D23',
    colorSwitch: '#7F8596',
    colorSwitchSecond: '#08CE87',
    activeColor: '#3059DE',
    inactiveColor: '#8492A6',
    linkColor: '#3059DE',
    disableColor: '#B1B5C3',
    modalColor: 'rgba(35, 38, 47, 0.24)',
    onLineColor: '#01AA4F',
  },
  icons: {
    home: require('./Image/home.png'),
    folders: require('./Image/folders.png'),
    pictures: require('./Image/pictures.png'),
    plane: require('./Image/plane.png'),
    tags: require('./Image/tags.png'),
    users: require('./Image/users.png'),
    previous: require('./Image/previous.png'),
    news: require('./Image/news.png'),
    map: require('./Image/map.png'),
    data: require('./Image/data.png'),
    building: require('./Image/building.png'),
    apartment: require('./Image/apartment.png'),
    money: require('./Image/money.png'),
    fee: require('./Image/fee.png'),
    notification: require('./Image/notification.png'),
    task: require('./Image/task.png'),
    report: require('./Image/report.png'),
    settings: require('./Image/settings.png'),
    form: require('./Image/contact-form.png'),
    edit: require('./Image/edit.png'),
    delete: require('./Image/delete.png'),
    info: require('./Image/ic_info.png'),
    analysis: require('./Image/data-analysis.png'),
    detail: require('./Image/detail.png'),
    history: require('./Image/history.png'),
    user: require('./Image/ic_user.png'),
    device: require('./Image/device.png'),
    delete2: require('./Image/delete2.png'),
    add: require('./Image/add.png'),
    accessDenied: require('./Image/access-denied.png'),
    spin: require('./Image/Spin.gif'),
    up: require('./Image/up-arrow.png'),
    down: require('./Image/down.png'),
    mode: require('./Image/mode.png'),
    notification2: require('./Image/notification2.png'),
    setting2: require('./Image/setting2.png'),
    airCondition: require('./Image/air-conditioner.png'),
    power: require('./Image/power.png'),
    flat: require('./Image/flat.png'),
    locker: require('./Image/locker.png'),
    calendar: require('./Image/calendar.png'),
    facebook: require('./Image/facebook.png'),
    youtube: require('./Image/youtube.png'),
    email: require('./Image/email.png'),
    search: require('./Image/search.png'),
    cart: require('./Image/shopping-cart.png'),
    menu: require('./Image/menu.png'),
    heatmode: require('./Image/air.png'),
    drymode: require('./Image/alcohol.png'),
    automode: require('./Image/auto.png'),
    fanmode: require('./Image/cooler.png'),
    coolmode: require('./Image/snowflake.png'),
    on: require('./Image/on.png'),
    off: require('./Image/off.png'),
    swingBtnActive: require('./Image/swingBtnActive.png'),
    swingBtnDeactive: require('./Image/swingBtnDeactive.png'),
    invitation: require('./Image/invitation.png'),
    checked: require('./Image/checked.png'),
    fan: require('./Image/fan.png'),
    lowSpeed: require('./Image/lowSpeed.png'),
    mediumSpeed: require('./Image/mediumSpeed.png'),
    highSpeed: require('./Image/highSpeed.png'),
    radioButton: require('./Image/radio-button.png'),
    blackLoading: require('./Image/blackLoading.gif'),
    checkMark: require('./Image/check-mark.png'),
  },
};

export default theme;
