import ReactNativeListAttribute from './ReactNativeListAttribute';
import UIBuilder from '../BaseComponent';
import { UI_COMPONENTS_TYPE_FIELD } from '../../../../Constant/Constant';

export default class ReactNativeListAttributeBuilder extends UIBuilder {
  constructor() {
    super();
    this.schema = {
      currentEntityId: UI_COMPONENTS_TYPE_FIELD.STRING,
      report_setting: UI_COMPONENTS_TYPE_FIELD.JSON,
    };
  }

  getType = () => {
    return 'react_native_list_attribute';
  };

  getComponent = () => {
    return ReactNativeListAttribute;
  };
}
