// import appInfo from "../../../app_config.json";

export const BASE_URL = {
  qa: 'https://api.filiot.com/',
  test: 'https://test-api.filiot.com/',
  dev: 'https://dev-api.knxbee.com/',
  prod: 'https://api.filiot.com/',
};

export const APP_NAME = {
  qa: '18026f5df9f',
  test: '18165543096',
  dev: '181717966d8',
  // dev: 'filiot_mzkynjyynze3',
  //   prod: appInfo.app_code,
  // prod: '18092d1c231',
};

export const MQTT_SERVER = {
  qa: 'mqtt.filiot.com',
  test: 'test-mqtt.filiot.com',
  dev: 'dev-mqtt.knxbee.com',
  prod: 'mqtt.filiot.com',
};

export const OTHER_FLOW = {
  is: false,
};

export const RELEASE_MODE = {
  //   active: appInfo.release_mode || false,
};

export const ACCESS_TOKEN_EXPIRE_TIME = 60; // min

export const LOG_LEVEL = 'ERROR'; // INFO | DEBUG | ERROR

export const API = {
  AREA: {
    GET_AREAS_BY_SPACEID: (spaceId) => `casa/v1/area/${spaceId}`,
    GET_ENTITIES_BY_SPACEID_AND_AREAID: (id, spaceId) => `casa/v1/area/${spaceId}/${id}/entities`,
    CREATE_AREA: 'casa/v1/area/',
    UPDATE_AREA: (id) => `casa/v1/area/${id}`,
    UPDATE_AREAS: 'casa/v1/area/areas',
    DELETE_AREA: (id) => `casa/v1/area/${id}`,
  },
  AUTH: {
    SIGN_UP: 'casa/v1/auth/sign-up',
    SIGN_IN: 'casa/v1/auth/sign-in',
    SIGN_OUT: 'casa/v1/auth/log-out',
    REFRESH_TOKEN: 'casa/v1/auth/refresh',
    VERIFY_TOKEN: 'casa/v1/auth/verify-token',
    FORGOT_PASSWORD: 'casa/v1/auth/forgot-password',
    VERIFY_USER: 'casa/v1/auth/verify-user',
    UPDATE_PASSWORD: 'casa/v1/auth/update-password',
    SET_PASSWORD: 'casa/v1/auth/set-password',
  },
  ENTITY: {
    GET_ENTITIES: 'casa/v1/entity/',
    CREATE_ENTITY: 'casa/v1/entity/',
    SHARE_ENTITIES: 'casa/v1/entity/share',
    UNSHARE_ENTITIES: 'casa/v1/entity/unshare',
    UPDATE_ENTITY: (id) => `casa/v1/entity/${id}`,
    DELETE_ENTITY: (id) => `casa/v1/entity/${id}`,
  },
  INVITATION: {
    GET_INVITATIONS: 'casa/v1/invitation/',
    CREATE_INVITATION: 'casa/v1/invitation/',
    UPDATE_INVITATION: (id) => `casa/v1/invitation/${id}`,
    DELETE_INVITATION: (id) => `casa/v1/invitation/${id}`,
  },
  INVITATION_DETAIL: {
    CREATE_INVITATION_DETAIL: 'casa/v1/invitation-detail/',
    UPDATE_INVITATION_DETAIL_STATUS: (id) => `casa/v1/invitation-detail/${id}/status`,
    DELETE_INVITATION_DETAIL: (id) => `casa/v1/invitation-detail/${id}`,
    UPDATE_INVITATION_DETAIL_INFO: (id) => `casa/v1/invitation-detail/${id}/info`,
  },
  RULE: {
    GET_RULES: 'casa/v1/rule/',
    CREATE_RULE: 'casa/v1/rule/',
    UPDATE_RULE: (id) => `casa/v1/rule/${id}`,
    DELETE_RULE: (id) => `casa/v1/rule/${id}`,
  },
  SPACE: {
    GET_SPACES: 'casa/v1/space/',
    CREATE_SPACE: 'casa/v1/space/',
    GET_MEMBERS_BY_SPACEID: (spaceId) => `casa/v1/space/${spaceId}/members`,
    GET_AREAS_BY_SPACEID: (spaceId) => `casa/v1/space/${spaceId}/areas`,
    GET_ENTITIES_BY_SPACEID: (spaceId) => `casa/v1/space/${spaceId}/entities`,
    UPDATE_SPACE: (id) => `casa/v1/space/${id}`,
    DELETE_SPACE: (id) => `casa/v1/space/${id}`,
    DELETE_MEMBER: (id) => `casa/v1/space/${id}/member`,
    GET_SHARE_ENTITIES: (spaceId, memberId) => `casa/v1/space/${spaceId}/member-entities/${memberId}`,
  },
  DEFAULT: {
    PING: 'casa/v1/ping',
  },
  USER: {
    GET_USERS: 'casa/v1/user',
    UPDATE_USER: 'casa/v1/user',
    DELETE_USER: 'casa/v1/user',
  },
  DEVICERESOURCE: {
    GET_DEVICE_FAMILY: 'casa/v1/device-resource/device-family',
    GET_DEVICE_CATEGORY: 'casa/v1/device-resource/device-category',
    GET_DEVICE_CONNECTION: 'casa/v1/device-resource/device-connection',
    GET_DEVICE_TYPE: 'casa/v1/device-resource/device-type',
  },
  OTA: {
    GET_OTA: 'device/v1/firmware/latest-version',
  },
  NOTIFICATION: {
    GET_NOTIFICATION: 'notification/v1/notification',
    SEEN_NOTIFICATION: (id) => `notification/v1/notification/${id}/seen`,
    DELETE_NOTIFICATION: (id) => `notification/v1/notification/${id}`, // replace by casa after
  },
};

export const network = {
  wan: 'WAN',
  lan: 'LAN',
  none: 'NONE',
};

export const rule = {
  io: {
    input: 'input',
    output: 'output',
  },
  type: {
    rule: 'rule',
    schedule: 'schedule',
    scene: 'scene',
    group: 'group',
  },
  logic: {
    or: 'or',
    and: 'and',
  },
  operator: {
    eq: 'eq',
    ne: 'ne',
    lt: 'lt',
    le: 'le',
    gt: 'gt',
    ge: 'ge',
  },
};

export const notification = {
  type: {
    warning: 'warning',
    invitation: 'invitation',
    'admin-notification': 'admin-notification',
  },
};

export const RSSI = -80;

export const TYPE_WINDOWS = {
  LARGE: { type: 'large', name: 'viewLarge', secondName: 'view' },
  MEDIUM: { type: 'medium', name: 'viewMedium', secondName: 'view' },
  SMALL: { type: 'small', name: 'viewSmall', secondName: 'view' },
};

export const UI_COMPONENTS_TYPE_FIELD = {
  NUMBER: 'number',
  STRING: 'string',
  BOOLEAN: 'boolean',
  JSON: 'json',
  MARKDOWN: 'MARKDOWN',
  ANY: 'any',
};
