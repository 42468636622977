import { regexTextMarkdown } from './constant';

export const validateTextMarkdown = (data) => {
    try {
        const regex = new RegExp(regexTextMarkdown, 'g');
        if (typeof data === 'string' && regex.test(data)) {
            return true;
        }
        return false;
    } catch (error) {
        return false;
    }
};
