import Builder from '..';
import { ControlSource } from '../../Constant/ControlFlow';
import Controllers from '../../Controller';

export const build = (CalleeConfig, resource) => {
  const { name, args } = CalleeConfig;

  const serviceName = name.split('.')[0];
  const methodName = name.split('.')[1];
  const _args = Builder.traverseBuild(args, resource);

  return Controllers.execute({
    serviceName,
    methodName,
    args: _args,
    resource,
    source: ControlSource.USER,
  });
};

export const type = 'Callee';
