export const WIDTH_LARGE_DEFAULT_MIN = 1000;
export const WIDTH_LARGE_DEFAULT_MAX = 1920 + (1920 * 2) / 16;
export const WIDTH_LARGE_DEFAULT = 1920;
export const HEIGHT_LARGE_DEFAULT = 926;

export const WIDTH_MEDIUM_DEFAULT_MIN = 600;
export const WIDTH_MEDIUM_DEFAULT_MAX = 1366;
export const WIDTH_MEDIUM_DEFAULT = 820;
export const HEIGHT_MEDIUM_DEFAULT = 1180;

export const WIDTH_SMALL_DEFAULT_MIN = 292.5;
export const WIDTH_SMALL_DEFAULT_MAX = 780;
export const WIDTH_SMALL_DEFAULT = 390;
export const HEIGHT_SMALL_DEFAULT = 844;

export const LARGE_RATIO_MIN = 12 / 9;
export const LARGE_RATIO_MAX = 19 / 9;

export const MEDIUM_RATIO_MIN = 4.5 / 9;
export const MEDIUM_RATIO_MAX = 10 / 9;

export const SMALL_RATIO_MIN = 3 / 9;
export const SMALL_RATIO_MAX = 6 / 9;
export const SMALL_RATIO = 390 / 844;

export const LARGE_COL = 320;
export const LARGE_ROW = 180;
export const MEDIUM_COL = 180;
export const MEDIUM_ROW = 180;
export const SMALL_COL = 100;
export const SMALL_ROW = 180;
