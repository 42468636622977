import * as prettier from 'prettier/standalone';
import * as parserBabel from 'prettier/parser-babel';
import ast from 'prettier/plugins/estree';
import Logger from './Logger';

export const formatCode = async (code) => {
  try {
    Logger.terminalInfo('formattedCodeformattedCode code', code);

    const formattedCode = await prettier?.format?.(code, {
      semi: false,
      parser: 'json',
      plugins: [parserBabel, ast],
    });

    Logger.terminalInfo('formattedCodeformattedCode', formattedCode);

    return formattedCode;
  } catch (error) {
    console.error('Error formatting code:', error);
    // Optionally, notify the user about the error
  }
};
