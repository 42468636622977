import Ajv from 'ajv';
const ajv = new Ajv({ allErrors: true });

const validate = (data, schema) => {
    try {
        const _validate = ajv.compile(schema);
        const valid = _validate(data);
        return valid;
    } catch (error) {
        // Logger.terminalError(`Validate error: ${JSON.stringify(error.message)}`);
        // Logger.terminalError(`Schema: ${JSON.stringify(schema)}`);
        // Logger.terminalError(`data: ${JSON.stringify(data)}`);
        // Logger.terminalDebug('--------------------------------------');
        return false;
    }
};

export default validate;
