import { createComponentbyData } from '../extensionattribute';

const accordionComponent = (
    data = {
        title: 'Tiêu đề',
        body: [],
    },
) => {
    return {
        type: 'ScrollView',
        children: [
            {
                type: 'Text',
                children: (data.title || 'Group name').toUpperCase(),
                props: {
                    style: {
                        fontSize: 20,
                        fontFamily: '{{theme.fonts.fontBold}}',
                        color: '{{theme.colors.textColorInactive}}',
                        marginTop: 12,
                        marginBottom: 6,
                    },
                },
            },
            {
                type: 'View',
                children: Array.isArray(data.body) ? data.body.map((cpn) => createComponentbyData(cpn)) : [],
            },
        ],
        props: {
            style: {
                paddingHorizontal: 24,
            },
        },
    };
};

export default accordionComponent;
