import BaseComponent from '../BaseComponent/BaseComponent';

class Text extends BaseComponent {
  adjustStyle() {
    const { value } = this.props;
    // const fitContent = this.props.fitContent ?? true;
    const fitContent = this.props.fitContent ?? false;

    const baseStyle = this.style;
    let fontSize = this.style.fontSize;

    const widthContent = value?.toString().length * 0.6 * fontSize;

    return {
      ...baseStyle,
      fontSize,
      height: baseStyle.height < 1.5 * fontSize ? 1.5 * fontSize : baseStyle.height,
      width: fitContent && baseStyle.width < widthContent ? widthContent : baseStyle.width,
      WebkitLineClamp: 1,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    };
  }

  renderComponent(x, y, width, height) {
    const { value } = this.props;

    return (
      <div {...this.restProps} style={this.style}>
        {value}
      </div>
    );
  }
}

export default Text;
