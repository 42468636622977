import Modal from './fmodal';
import UIBuilder from '../BaseComponent';
import { UI_COMPONENTS_TYPE_FIELD } from '../../../../Constant/Constant';

export default class ModalBuilder extends UIBuilder {
  constructor() {
    super();
    this.schema = {
      modal: UI_COMPONENTS_TYPE_FIELD.JSON,
      onRequestClose: UI_COMPONENTS_TYPE_FIELD.JSON,
      visible: UI_COMPONENTS_TYPE_FIELD.BOOLEAN,
    };
  }

  getType = () => {
    return 'modal';
  };

  getComponent = () => {
    return Modal;
  };
}
