import container from './Container';
import auth from './Auth';
import manifest from './manifest.json';
const app = {
  type: 'App',
  appCode: 'appCode',
  manifest,
  auth,
  container,
};

export default app;
