export const createObject = (objectName = [], data) => {
  return {
    type: `create@${objectName}`,
    value: data,
  };
};

export const updateObject = (objectName, data) => {
  return {
    type: `update@${objectName}`,
    value: data,
  };
};

export const updateObjects = (objects = [{ path: '', data: '' }]) => {
  const path = objects.map(({ path }) => path).join('____');
  // Logger.terminalInfo('updateObjects', objects);
  return {
    type: `updates@${path}`,
    value: objects.map(({ data }) => data),
  };
};

export const getObject = (objectName, data) => {
  return {
    type: `get@${objectName}`,
    value: data,
  };
};

export const deleteObject = (objectName, data) => {
  return {
    type: `delete@${objectName}`,
    value: data,
  };
};
