import React, { Component } from 'react';
import { connect } from 'react-redux';
import { WarningActions } from './constant';
import validate from '../../../../../../Helper/App/View/Attribute/validate';
import { checkAllActionsOnOff } from '../../../../../../Helper/App/View/utils'
import Modal from '../Modal';
import Switch from '../Switch';

import { FastImage, FlatList, StyleSheet, Text, TextInput, TouchableOpacity, View } from '../../React-Native/types';

const getValue = (schemaValue, value) => {
    if (schemaValue) {
        if (validate(value, schemaValue)) {
            return value;
        }
        switch (schemaValue.type) {
            case 'number':
                if (schemaValue.minimum && value <= schemaValue.minimum) {
                    return schemaValue.minimum;
                }
                if (schemaValue.maximum && value >= schemaValue.maximum) {
                    return schemaValue.maximum;
                }
                if (schemaValue.enum) {
                    return schemaValue.enum[0];
                }
                return 0;
            case 'boolean':
                return true;
            default:
                return value;
        }
    }
    return value;
};

const schema = {
    type: 'object',
    properties: {
        action: {
            type: 'object',
        },
        value: {},
        enable: {
            type: 'boolean',
        },
    },
    required: ['action', 'value', 'enable'],
};
class ConditionComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: validate(props.data.modal, schema)
                ? props.data.modal
                : {
                      enable: true,
                      value: 12,
                      action: {},
                  },
            visible: false,
            isErrorValue: false,
        };
        this.timeoutErrorDisplay = null;
    }

    getActionFullName = (action) => {
        switch (action) {
            case WarningActions.warning:
                return 'Cảnh báo';
            case WarningActions.active_protect:
                return 'Bảo vệ tự động';
        }
    };

    openModal = () => {
        this.setState({
            visible: true,
        });
    };

    closeModal = () => {
        this.setState({ modal: this.props.data?.modal, visible: false });
    };

    onSaveWarning = () => {
        try {
            let { modal } = this.state;
            const { data, theme } = this.props;
            const schemaValue = data.schemaValue || {};
            const value = modal.value;
            const type = schemaValue?.type || typeof value;
            if (type === 'number') {
                const { minimum, maximum } = schemaValue;
                if (typeof maximum !== 'number' && typeof minimum === 'number') {
                    if (value < minimum) {
                        throw Error('value is invalid');
                    }
                }

                if (typeof maximum === 'number' && typeof minimum !== 'number') {
                    if (value > maximum) {
                        throw Error('value is invalid');
                    }
                }

                if (typeof maximum === 'number' && typeof minimum === 'number') {
                    if (value > maximum || value < minimum) {
                        throw Error('value is invalid');
                    }
                }
            }

            const actions = Object.values(this.state.modal.action || {});
            const getCheckAllActionsOnOff = checkAllActionsOnOff(actions);
            if (getCheckAllActionsOnOff.result) {
                modal = { ...modal, enable: getCheckAllActionsOnOff.value };
            }
            this._onSaveWarning(modal);
            this.setState({ visible: false, modal });
        } catch (error) {
            this.setState(
                {
                    isErrorValue: true,
                },
                () => {
                    clearTimeout(this.timeoutErrorDisplay);
                    this.timeoutErrorDisplay = setTimeout(() => {
                        this.setState({
                            isErrorValue: false,
                        });
                    }, 2000);
                },
            );
        }
    };

    _onSaveWarning = (modal) => {
        const value = modal.value;
        const schemaValue = this.props.data?.schemaValue;
        this.props.onChangeValue?.({ ...modal, value: getValue(schemaValue, value) });
    };

    onChangeValue = (value) => {
        this.setState({
            modal: {
                ...this.state.modal,
                value: parseFloat(value) || 0,
            },
        });
    };

    onChangeEnable = (enable) => {
        this.setState({
            modal: {
                ...this.state.modal,
                enable: typeof enable === 'boolean' ? enable : true,
                action: { ...this.state.modal.action, warning: enable },
            },
        });

        this._onSaveWarning({ ...this.state.modal, enable, action: { ...this.state.modal.action, warning: enable } });
    };

    onChangeAction = (action, value) => {
        this.setState({
            modal: {
                ...this.state.modal,
                action: { ...this.state.modal.action, [action]: value },
            },
        });
    };

    renderValue = () => {
        const { data, theme } = this.props;
        const { modal, isErrorValue } = this.state;
        const schemaValue = data.schemaValue || {};
        const type = schemaValue?.type || typeof modal.value;
        switch (type) {
            case 'number':
                const { minimum, maximum } = schemaValue;
                return (
                    <>
                        <TextInput
                            value={modal.value?.toString() || '0'}
                            placeholder={''}
                            style={[styles.valueInput, { backgroundColor: theme.colors.appColorThird, color: theme.colors.textColor }]}
                            onChangeText={(text) => this.onChangeValue(text)}
                        />
                    </>
                );
            case 'boolean':
                return (
                    <>
                        <Switch active={modal.value} onChange={() => this.onChangeEnable(!modal.value)} width={40} height={22} />
                    </>
                );
            default:
                break;
        }
    };

    renderConditionDetail = () => {
        const { data, theme } = this.props;
        const { modal, isErrorValue } = this.state;
        const schemaValue = data.schemaValue || {};
        const type = schemaValue?.type || typeof modal.value;
        switch (type) {
            case 'number':
                const { minimum, maximum } = schemaValue;
                switch (true) {
                    case typeof minimum === 'number' && typeof maximum === 'number':
                        return (
                            <View>
                                <Text style={{ color: isErrorValue ? 'red' : theme.colors.textColor }}>
                                    ( Giá trị từ {minimum} đến {maximum} )
                                </Text>
                            </View>
                        );

                    case typeof minimum === 'number' && typeof maximum !== 'number':
                        return (
                            <View>
                                <Text style={{ color: isErrorValue ? 'red' : theme.colors.textColor }}>( Giá trị lớn hơn {minimum} )</Text>
                            </View>
                        );

                    case typeof minimum !== 'number' && typeof maximum === 'number':
                        return (
                            <View>
                                <Text style={{ color: isErrorValue ? 'red' : theme.colors.textColor }}>( Giá trị nhỏ hơn {maximum} )</Text>
                            </View>
                        );

                    case typeof minimum !== 'number' && typeof maximum !== 'number':
                    default:
                        return <></>;
                }
            default:
                return <></>;
        }
    };

    render() {
        const { data, lang, theme } = this.props;
        const { title } = data;
        const { modal } = this.state;
        const { enable, action } = modal;
        return (
            <>
                <Modal visible={this.state.visible} onCloseModal={this.closeModal}>
                    <View style={[styles.modalContainer, { backgroundColor: theme.colors.appColor }]}>
                        <Text style={[styles.modalTitle, { color: theme.colors.textColor }]}>
                            {lang.editWarning} {title}
                        </Text>
                        <View>
                            {/* <View style={[styles.flexRow, styles.marginVerticalHeading]}>
                                <Text style={[styles.modalHeading, { color: theme.colors.textColor }]}>Trạng thái</Text>
                                <Switch active={enable} onChange={() => this.onChangeEnable(!enable)} width={40} height={22} />
                            </View> */}
                            <View style={[styles.marginVerticalHeading, styles.flexRow]}>
                                <View>
                                    <Text style={[styles.modalHeading, { color: theme.colors.textColor }]}>Giá trị điều kiện</Text>
                                    {this.renderConditionDetail()}
                                </View>
                                {this.renderValue()}
                            </View>
                            <View>
                                <Text style={[styles.modalHeading, styles.marginVerticalHeading, { color: theme.colors.textColor }]}>Hành động</Text>
                                <View style={[styles.flexRow, { marginLeft: 15 }]}>
                                    {
                                        <FlatList
                                            data={Object.keys(action || {})}
                                            renderItem={({ item }) => {
                                                return (
                                                    <View style={styles.flexRow}>
                                                        <Text style={[styles.actionFullName, { color: theme.colors.textColorInactive }]}>
                                                            {this.getActionFullName(item)}
                                                        </Text>
                                                        <Switch
                                                            active={action[item]}
                                                            onChange={() => this.onChangeAction(item, !action[item])}
                                                            width={40}
                                                            height={22}
                                                        />
                                                    </View>
                                                );
                                            }}
                                        />
                                    }
                                </View>
                            </View>
                            <TouchableOpacity style={[styles.btn, { backgroundColor: theme.colors.activeColor }]} onPress={this.onSaveWarning}>
                                <Text style={[styles.change, { color: theme.colors.textColorBtn, fontFamily: theme.fonts.fontBold }]}>{lang.save}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </Modal>
                <TouchableOpacity style={[styles.content, { backgroundColor: theme.colors.appColor }]} onPress={this.openModal}>
                    <View style={[styles.infoContainer]}>
                        <View style={[styles.iconContainer, { backgroundColor: theme.colors.appColorThird }]}>
                            <FastImage source={theme.icons.warning} style={styles.icon} />
                        </View>
                        <View style={[{ flex: 3 }, styles.textContainer]}>
                            <Text style={styles.timeInDay}>{title}</Text>
                        </View>
                        <View style={{ flex: 1, justifyContent: 'flex-end', flexDirection: 'row' }}>
                            <Switch
                                active={enable}
                                onChange={() => {
                                    this.onChangeEnable(!enable);
                                }}
                                width={40}
                                height={22}
                            />
                        </View>
                    </View>
                </TouchableOpacity>
            </>
        );
    }
}

export default connect((state) => {
    return {
        theme: state.theme,
        lang: state.lang,
    };
})(ConditionComponent);

export const BaseConditionComponent = ConditionComponent;
export const _connect = (cp) =>
    connect((state) => {
        return {
            theme: state.theme,
            lang: state.lang,
        };
    })(cp);

const styles = StyleSheet.create({
    actionFullName: {
        fontSize: 14,
        lineHeight: 24,
    },
    status: {
        fontSize: 14,
        lineHeight: 24,
    },
    valueInput: {
        paddingVertical: 8,
        paddingHorizontal: 8,
        borderRadius: 8,
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 14,
    },
    btn: {
        height: 56,
        paddingHorizontal: 8,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 10,
        marginTop: 50,
        width: '100%',
        alignSelf: 'center',
    },
    marginVerticalHeading: {
        marginVertical: 15,
    },
    modalHeading: {
        fontWeight: '700',
        fontSize: 16,
    },
    modalTitle: {
        textAlign: 'center',
        fontSize: 18,
        marginBottom: 18,
    },
    flexRow: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingHorizontal: 6,
    },
    modalContainer: {
        marginHorizontal: 20,
        borderRadius: 20,
        padding: 20,
    },
    actionContainer: {
        alignItems: 'center',
    },
    textContainer: {
        marginLeft: 16,
    },
    timeInDay: {
        color: '#131D67',
        fontWeight: '700',
        fontSize: 16,
    },
    dayInWeek: {
        color: '#707599',
        flexWrap: 'wrap',
        flex: 1,
        width: 150,
    },
    content: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingVertical: 20,
        paddingHorizontal: 16,
        marginTop: 20,
        borderRadius: 20,
    },
    infoContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    iconContainer: {
        paddingVertical: 8,
        paddingHorizontal: 11,
        backgroundColor: '#fff',
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {
        height: 24,
        width: 24,
    },
    title: {
        marginBottom: 5,
    },
    flex: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    heading: {
        fontSize: 18,
        marginBottom: 6,
        marginTop: 30,
    },
    input: {
        backgroundColor: '#fff',
    },
});
