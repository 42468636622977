const RELEASE_MODE = {
  active: true,
};

const LOG_LEVELS = {
  INFO: 'INFO',
  DEBUG: 'DEBUG',
  ERROR: 'ERROR',
};

const LOG_LEVEL = LOG_LEVELS.INFO;

class Logger {
  constructor(scope) {
    this.scope = scope || 'app';
  }

  terminalInfo(...message) {
    if (!RELEASE_MODE.active)
      switch (LOG_LEVEL) {
        case 'INFO':
        case 'DEBUG':
        case 'ERROR':
          console.log('\x1b[36m%s\x1b[0m', `[INFO] : ${message}`);
          break;
        default:
          break;
      }
  }

  terminalDebug(...message) {
    if (!RELEASE_MODE.active)
      switch (LOG_LEVEL) {
        case 'DEBUG':
        case 'ERROR':
          console.log('\x1b[33m%s\x1b[0m', `[DEBUG] : ${message}`);
          break;
        default:
          break;
      }
  }

  terminalError(...message) {
    if (!RELEASE_MODE.active)
      switch (LOG_LEVEL) {
        case 'ERROR':
          console.log('\x1b[31m%s\x1b[0m', `[ERROR] : ${message}`);
          break;
        default:
          break;
      }
  }
}

export default new Logger();
