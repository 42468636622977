import React, { Component } from 'react';
import { StyleSheet, Text, TouchableOpacity, View, FastImage, TextInput } from '../../React-Native/types';
import { connect } from 'react-redux';
import { fixLowTen, getDayInWeek, parseDayWeek, types } from '../../../../../../Helper/App/Core/Helper/cron';
// import DatePicker from '@react-native-community/datetimepicker';
import Switch from './switch';

import TimePicker from 'react-time-picker';
import { MOSBUS_ROLES } from './constants';
import Logger from '../../../../../../Helper/Logger';

// import FastImage from 'react-native-fast-image';
// import { createObject, deleteObject } from '../../Redux/actions/filiot';

const makeShortCron = (cron) => {
  if (cron === undefined || cron === null) {
    return '0 0 * * *';
  }
  if (cron.split(' ').length === 6) {
    return cron.split(' ').slice(1, 6).join(' ');
  }
  return cron;
};

const makeFullCron = (cron) => {
  if (cron === undefined || cron === null) {
    return '0 0 0 * * *';
  }
  if (cron.split(' ').length === 5) {
    return ['0', ...cron.split(' ')].join(' ');
  }
  return cron;
};

const modes = ['Gió thoảng', 'Làm lạnh', 'Làm khô', 'Làm nóng', 'Tự động'];

class DetailTimer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      str: '0 0 * * 1,2,3',
      action: {
        on_off: true,
      },
      id: '',
      enable: false,
      timePick: false,
      selectAction: false,
      actionSelected: 0,
    };
    this.navigationSubscriber = null;
  }

  componentDidMount() {
    const { navigation } = this.props;

    // this.navigationSubscriber = navigation.addListener('focus', () => {
    let detailTimer = this.props.currentTimer;

    Logger.terminalInfo('detailTimer', detailTimer);

    if (detailTimer?.isNew) {
      const currentDate = new Date();
      const currentMinutes = currentDate.getMinutes();
      const currentHours = currentDate.getHours();
      detailTimer = {
        action: {
          on_off: false,
        },
        str: `0 ${currentMinutes} ${currentHours} * * *`,
        enable: true,
        id: '',
      };
      //   this.props.createObject('detailTimer', detailTimer);
    }
    const actionState = detailTimer.action;
    const str = detailTimer?.str;
    const enable = detailTimer?.enable;
    const id = detailTimer?.id;
    this.setState({
      str: makeShortCron(str),
      action: actionState,
      enable,
      id,
    });
    // });
  }

  onDeleteTimer = (_timer) => {
    const { timer } = this.state;
    this.props.onUpdateAutomationRules?.({
      timer: [...timer, _timer]
        .filter((e) => e.id !== _timer.id)
        .map((e) => {
          delete e.id;
          const { str } = e;
          if (str.split(' ').length === 5) {
            return { ...e, ['str']: ['0', ...str.split(' ')].join(' ') };
          }
          return e;
        }),
    });
  };

  changeEnableCron = (_timer) => {
    this.onSaveTimer({ ..._timer, enable: !_timer.enable });
  };

  goBack = () => {
    this.props.navigateItems();
  };

  onSelectDayInWeek = (day) => {
    const { str } = this.state;
    const parseDayWeekResult = parseDayWeek(str.split(' ')[4]);
    if (parseDayWeekResult.type === types.SEPARATOR) {
      if (parseDayWeekResult.value.includes(day)) {
        const newDayWeek = parseDayWeekResult.value.filter((e) => e !== day).join(',');
        this.onChangeDayWeek(newDayWeek);
      } else {
        if (parseDayWeekResult.value.length === 6) {
          this.onChangeDayWeek('*');
        } else {
          const newDayWeek = [...parseDayWeekResult.value, day].join(',');
          this.onChangeDayWeek(newDayWeek);
        }
      }
    }

    if (parseDayWeekResult.type === types.VALUE) {
      if (!parseDayWeekResult.value.includes(day)) {
        const newDayWeek = [...parseDayWeekResult.value, day].join(',');
        this.onChangeDayWeek(newDayWeek);
      }
    }
  };

  onSelectAnyDay = () => {
    const { str } = this.state;
    const parseDayWeekResult = parseDayWeek(str.split(' ')[4]);
    if (parseDayWeekResult.type === types.ANY) {
      this.onChangeDayWeek('1');
    } else {
      this.onChangeDayWeek('*');
    }
  };

  onChangeDayWeek = (dayWeek) => {
    const { str } = this.state;
    this.onChangeCron([...str.split(' ').slice(0, 4), dayWeek].join(' '));
  };

  onChangeCron = (str) => {
    this.setState({ str });
  };

  onSelectAction = () => {
    this.setState({
      action: {
        state: { on_off: !this.state.action.state.on_off },
      },
    });
  };

  onChangeTimePick = (timePick) => {
    this.setState({ timePick });
  };

  onChangeTimeInDay = (hours, minutes) => {
    const { str } = this.state;
    this.onChangeTimePick(false);
    this.onChangeCron([minutes, hours, ...str.split(' ').slice(2, 5)].join(' '));
  };

  onSaveTimer = () => {
    const { action, enable, id } = this.state;
    let { str } = this.state;
    const { currentTimer } = this.props;

    // sort before save. 5,4,6 => 4,5,6
    if (parseDayWeek(str.split(' ')[4]).type === types.SEPARATOR) {
      str = [
        ...str.split(' ').slice(0, 4),
        parseDayWeek(str.split(' ')[4])
          .value.sort(function (a, b) {
            return a - b;
          })
          .join(','),
      ].join(' ');
    }

    if (currentTimer.isNew) {
      this.props.addTimer({ str: makeFullCron(str), action, enable, id });
    } else {
      this.props.editTimer({ str: makeFullCron(str), action, enable, id });
    }

    this.goBack();
  };

  navigationToDetail = () => {
    this.props.navigation.navigate('devicecontroloutput', this.state.action || {});
  };

  onDeleteTimer = () => {
    const { id } = this.state;
    this.props.deleteTimer({ id });
    this.goBack();
  };

  getAction = () => {
    // const { action } = this.state;
    // const on_off = action?.state?.on_off;
    // const { lang } = this.props;
    // if (on_off && on_off.toString() === 'true') {
    //   return lang.control;
    // } else {
    //   return lang.off;
    // }

    switch (this.state.actionSelected) {
      case 0:
        return 'Swing';
      case 1:
        return 'Mode';
      case 2:
        return 'On-off';
      case 3:
        return 'Nhiệt độ';

      default:
        return 'Swing';
    }
  };

  handleSelectAction = () => {
    this.setState({ selectAction: !this.state.selectAction });
    Logger.terminalInfo('selectAction', this.state.selectAction);
  };

  onSelectActions = (item) => {
    this.setState({ actionSelected: item.id });
    Logger.terminalInfo('actionSelected', this.state.actionSelected);
  };

  render() {
    const { theme, lang, selectedDevices, currentTimer, mosbusRole } = this.props;
    const { str, timePick, action } = this.state;

    const [minutes, hours] = str.split(' ').slice(0, 3);
    const parseDayWeekResult = parseDayWeek(str.split(' ')[4]);
    const _minutes = minutes < 10 ? '0' + minutes : minutes;
    const _hours = hours < 10 ? '0' + hours : hours;
    Logger.terminalInfo('parseDayWeekResult', parseDayWeekResult);
    return (
      <View style={[styles.root, { backgroundColor: theme.colors.appColorThird }]}>
        <View style={[styles.header, { backgroundColor: theme.colors.appColor }]}>
          <View style={styles.rowHeader}>
            <TouchableOpacity style={styles.btnBack} onPress={this.goBack}>
              <FastImage source={'https://image-app-public.s3.ap-southeast-1.amazonaws.com/giangha/my-icons/arrow-back.png'} style={styles.back} />
            </TouchableOpacity>
            <Text style={[styles.title, { color: theme.colors.textColor, fontFamily: theme.fonts.fontBold }]}>{'Điều kiện thời gian'}</Text>

            <TouchableOpacity style={[styles.btnSave]} onPress={this.onSaveTimer}>
              <Text style={[styles.save, { color: theme.colors.textColor, fontFamily: theme.fonts.fontBold }]}>{currentTimer?.isNew && 'Thêm'}</Text>
            </TouchableOpacity>
          </View>
        </View>
        <View style={styles.body}>
          <View style={[styles.container, styles.flexRow, { backgroundColor: theme.colors.appColor }]}>
            <Text style={styles.heading}>{'Thời gian'}</Text>
            <View style={[styles.timeBtn, { backgroundColor: theme.colors.appColorThird }]}>
              <TimePicker
                mode={'time'}
                value={`${_hours}:${_minutes}`}
                onChange={(event, date) => {
                  if (event !== null && currentTimer?.isNew) {
                    const hours = parseInt(event.split(':')[0]);
                    const minutes = parseInt(event.split(':')[1]);
                    this.onChangeTimeInDay(hours, minutes);
                  }
                }}
                disableClock={true}
                clearIcon={<></>}
                className='clock-touch-press'
              />
            </View>
          </View>
          {(parseDayWeekResult.type === types.SEPARATOR || parseDayWeekResult.type === types.VALUE) && (
            <View style={[styles.container, { backgroundColor: theme.colors.appColor }]}>
              <Text style={[styles.heading, { color: theme.colors.textColor }]}>Lặp lại</Text>
              <View style={[styles.flexRow, { flexWrap: 'wrap', justifyContent: 'flex-start' }]}>
                {['1', '2', '3', '4', '5', '6', '7'].map((day) => {
                  return (
                    <TouchableOpacity
                      key={day}
                      onPress={() => {
                        if (currentTimer.isNew) {
                          this.onSelectDayInWeek(day);
                        }
                      }}
                      style={[
                        styles.dayInWeek,
                        {
                          backgroundColor: parseDayWeekResult.value.includes(day) ? theme.colors.activeColor : theme.colors.appColor,
                          borderColor: theme.colors.activeColor,
                        },
                      ]}>
                      <Text
                        style={{
                          color: parseDayWeekResult.value.includes(day) ? theme.colors.textColorBtn : theme.colors.activeColor,
                        }}>
                        {getDayInWeek(day)}
                      </Text>
                    </TouchableOpacity>
                  );
                })}
              </View>
            </View>
          )}

          <View style={[styles.container, { backgroundColor: theme.colors.appColor }]}>
            <View style={[styles.flexRow, { marginBottom: 10 }]}>
              <Text style={[styles.heading, { color: theme.colors.textColor }]}>Mỗi lần một ngày</Text>
              {/* <Switch active={parseDayWeekResult.type === types.ANY} onChange={() => this.onSelectAnyDay()} width={40} height={22} /> */}
              <Switch
                checked={parseDayWeekResult.type === types.ANY}
                onChangeValue={() => {
                  if (currentTimer.isNew) {
                    this.onSelectAnyDay();
                  }
                }}
              />
            </View>
            <Text style={[styles.subTitle, { color: theme.colors.textColorInactive }]}>
              Nếu điều kiện xuất hiện lần thứ hai trong cùng một ngày, kịch bản này sẽ không xuất hiện nữa
            </Text>
          </View>

          <View style={[styles.container, { backgroundColor: theme.colors.appColor }]}>
            <View>
              <Text style={[styles.heading, { color: theme.colors.textColor }]}>Hành động</Text>
            </View>

            <View style={{ width: '100%', backgroundColor: '#fff', flexWrap: 'wrap', marginTop: 10, paddingVertical: 10 }}>
              <View style={[styles.actionContainer]}>
                <Text style={[styles.actionTitle, { color: theme.colors.textInfor }]}>Bật/tắt</Text>
                <Switch
                  checked={action?.on_off}
                  onChangeValue={(value) => {
                    if (currentTimer.isNew) {
                      this.setState({
                        action: { ...this.state.action, on_off: value },
                      });
                    }
                  }}
                />
              </View>
              {/* <View style={[styles.actionContainer]}>
                <Text style={[styles.actionTitle, { color: theme.colors.textInfor }]}>Nhiệt độ</Text>
                <TextInput
                  style={{ width: 100, borderRadius: 8, border: '1px solid #e8e8e8', paddingHorizontal: 12, fontFamily: theme.fonts.fontBold }}
                  type={'number'}
                  placeholder='°C'
                  min='18'
                  max='32'
                  value={action?.temp}
                  onChangeText={(text) => {
                    this.setState({
                      action: { ...this.state.action, temp: parseInt(text) },
                    });
                  }}
                  onKeyDown={(event) => event.preventDefault()}
                />
              </View>
              <View style={[styles.actionContainer]}>
                <Text style={[styles.actionTitle, { color: theme.colors.textInfor }]}>Mode</Text>
                <View style={[styles.flexRow]}>
                  {modes.map((mode, index) => {
                    return (
                      <TouchableOpacity
                        style={[
                          styles.mode,
                          {
                            backgroundColor: action?.mode === index ? theme.colors.activeColor : theme.colors.appColor,
                            borderColor: theme.colors.activeColor,
                            borderWidth: 1,
                            borderStyle: 'solid',
                          },
                        ]}
                        onPress={() => {
                          this.setState({
                            action: { ...this.state.action, mode: index },
                          });
                        }}>
                        <Text
                          style={[
                            {
                              color: action?.mode === index ? theme.colors.textColorBtn : theme.colors.activeColor,
                              fontSize: 12,
                            },
                          ]}>
                          {mode}
                        </Text>
                      </TouchableOpacity>
                    );
                  })}
                </View>
              </View>
              <View style={[styles.actionContainer]}>
                <Text style={[styles.actionTitle, { color: theme.colors.textInfor }]}>Swing</Text>
                <Switch
                  checked={action?.swing}
                  onChangeValue={(value) => {
                    this.setState({
                      action: { ...this.state.action, swing: value },
                    });
                  }}
                />
              </View> */}
            </View>
          </View>
          {mosbusRole === MOSBUS_ROLES.GATEWAY && (
            <View style={[styles.container, { backgroundColor: theme.colors.appColor }]}>
              <View style={[styles.flexRow]}>
                <Text style={[styles.heading, { color: theme.colors.textColor }]}>Mobus</Text>
                <TouchableOpacity onPress={this.props.navigateSelectDevices}>
                  <Text style={[styles.heading, { color: theme.colors.textColor }]}>
                    {Array.isArray(selectedDevices) ? selectedDevices.length : 0} thiết bị
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          )}
        </View>
      </View>
    );
  }
}

export default connect(
  (state) => {
    return {
      theme: state.theme,
      lang: state.lang,
      filiot: state.filiot,
    };
  },
  // { deleteObject, createObject },
)(DetailTimer);

const styles = StyleSheet.create({
  body: {
    width: '100%',
    height: '100%',
    paddingHorizontal: 16,
  },
  subTitle: {
    fontSize: 12,
    lineHeight: 20,
  },
  actionTitle: {
    fontSize: 14,
    marginLeft: 12,
    marginVertical: 5,
  },
  actionContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 8,
  },
  textTime: {
    fontSize: 14,
    lineHeight: 24,
  },
  btn: {
    paddingVertical: 10,
    paddingHorizontal: 8,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    width: '100%',
    alignSelf: 'center',
    marginVertical: 25,
    borderWidth: 1,
    borderColor: 'red',
    height: 56,
  },
  timeBtn: {
    padding: 10,
    borderRadius: 10,
  },
  heading: {
    color: '#131D67',
    fontSize: 16,
    fontWeight: '700',
  },
  mode: {
    marginRight: 6,
    paddingHorizontal: 6,
    paddingVertical: 6,
    borderWidth: 1,
    borderRadius: 8,
    marginTop: 0,
  },
  dayInWeek: {
    marginRight: 6,
    paddingHorizontal: 16,
    paddingVertical: 5,
    borderWidth: 1,
    borderRadius: 40,
    marginTop: 16,
  },
  flexRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  container: {
    marginTop: 24,
    padding: 16,
    borderRadius: 6,
  },
  btnSave: {
    position: 'absolute',
    right: -16,
    padding: 16,
  },
  title: {
    fontSize: 18,
    lineHeight: 24,
    minWidth: '70%',
    textAlign: 'center',
  },
  back: {
    width: 16,
    height: 16,
  },
  btnBack: {
    position: 'absolute',
    left: -16,
    padding: 16,
  },
  rowHeader: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    width: '100%',
    paddingBottom: 20,
    paddingHorizontal: 24,
    paddingTop: 50,
  },
  root: {
    width: '100%',
    height: '100%',
  },
});
