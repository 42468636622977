import { fRedux } from './fRedux';
import fAPI from './fAPI';
import API from './API';
import { fAuth } from './fAuth';
import { Navigator, Stator } from './Screen';
import { getAmountParams, updateObjectProperty } from '../Helper/utils';
import { AdaptiveScreenController } from './AdaptiveScreen';
import { CommonModalController } from './CommonModal';
import { fArea } from './fArea';
import fMqtt from './fMqtt';
import { fEntity } from './fEntity';
import { fSpace } from './fSpace';
import { Variable } from './Variable';
import { Modal } from './Modal';
import { history } from '../index';
import { fInvitation } from './fInvitation';
import { fInvitationDetail } from './fInvitationDetail';
import { fNotification } from './fNotification';
import { fUsers } from './fUser';
import { Storage } from './Storage';
import FAWS from './FAWS';
import { fLog } from './fLog';
import { fRules } from './fRule';
import ControlEnv from './env';
import Logger from '../Helper/Logger';

const cr = {
  fRedux,
  fAPI,
  fAuth,
  AdaptiveScreenController,
  CommonModalController,
  console,
  API,
  fArea,
  fMqtt,
  fEntity,
  fSpace,
  var: Variable,
  Modal,
  fInvitation,
  fNotification,
  FAWS,
  Storage,
  fLog,
  fUsers,
  fRules,
  fInvitationDetail,
};

class Controller {
  constructor() {
    this.services = {};
    this.navigator = Navigator;
    this.env = {};
    this.isPreviewMode = false;
    this.appId = null;
    this.init();
  }

  init() {
    Object.keys(cr).forEach((serviceName) => {
      this.addServices({ serviceName });
      const methodNames = Object.getOwnPropertyNames(cr[serviceName])
        .concat(Object.getOwnPropertyNames(Object.getPrototypeOf(cr[serviceName])))
        .concat(Object.keys(cr[serviceName]));

      methodNames.forEach((methodName) => {
        if (methodName !== 'constructor' && typeof cr[serviceName][methodName] === 'function') {
          this.addMethodToService({
            serviceName: serviceName,
            method: {
              name: methodName,
              params: {},
              execute: cr[serviceName][methodName],
            },
          });
        }
      });
    });
    this.addServices({ serviceName: 'navigator' });
    this.addMethodToService({
      serviceName: 'navigator',
      method: {
        name: 'navigate',
        params: {},
        execute: ({ uri }) => {
          try {
            new URL(uri);
            history.push(uri);
          } catch (error) {
            try {
              const uriParse = new URL(window.location.origin + uri);
              const searchParams = uriParse.searchParams;

              const env = ControlEnv.getEnv();
              if (env.preview === true) {
                searchParams.append('preview', 'true');
                searchParams.append('editorid', this.editorid ?? 'null');

                history.push(`${uri}?${searchParams.toString()}`);
              } else {
                history.push(uri);
              }
            } catch (error) {
              Logger.terminalInfo('uri error');
            }
          }
        },
      },
    });

    this.addServices({ serviceName: 'state' });
    this.addMethodToService({
      serviceName: 'state',
      method: {
        name: 'updateState',
        params: {},
        execute: ({ path, data }) => {
          Logger.terminalInfo('path, data', path, data);
          Logger.terminalInfo('Stator.state', Stator.state);
          let _path = path;
          if (typeof path === 'string') {
            _path = path.split('.');
          }
          const newState = updateObjectProperty(Stator.state || {}, _path, data);
          Stator.StateActor.setState({ ...newState });
        },
      },
    });

    // this.addMethodToService({
    //   serviceName: "var",
    //   method: {
    //     name: "setGlobal",
    //     params: {},
    //     execute: (path, value) => {
    //       let _path = path;
    //       if (typeof path === "string") {
    //         _path = path.split(".");
    //       }
    //       fRedux.updateObject({ path: _path, data: value });
    //     },
    //   },
    // });

    // this.addMethodToService({
    //   serviceName: "var",
    //   method: {
    //     name: "setLocal",
    //     params: {},
    //     execute: (path, value) => {
    //       let _path = path;
    //       if (typeof path === "string") {
    //         _path = path.split(".");
    //       }
    //       fRedux.updateObject({ path: _path, data: value });
    //     },
    //   },
    // });

    // this.addMethodToService({
    //   serviceName: "var",
    //   method: {
    //     name: "setLocal",
    //     params: {},
    //     execute: (path, value) => {
    //       let _path = path;
    //       if (typeof path === "string") {
    //         _path = path.split(".");
    //       }
    //       fRedux.updateObject({ path: _path, data: value });
    //     },
    //   },
    // });
  }

  openPreviewMode({ editorid }) {
    this.isPreviewMode = true;
    this.editorid = editorid;
  }

  addServices({ serviceName, methods = { init: { name: '', execute: () => {} } } }) {
    if (typeof this.services[serviceName] === 'undefined') {
      this.services[serviceName] = { serviceName, methods: methods };
    }
    // this.services.push({ serviceName, methods: [] });
  }

  addMethodToService({ serviceName, method = { name: '', execute: () => {} } }) {
    if (typeof this.services[serviceName] === 'undefined') {
      this.services[serviceName] = { serviceName, methods: {} };
    }

    this.services[serviceName].methods[method.name] = method;
  }

  execute({ serviceName, methodName, args, resource, source }) {
    // const service = this.services.find((service) => service.serviceName === serviceName);
    const service = this.services[serviceName];
    let result = undefined;
    if (service) {
      // const method = service.methods.find((method) => method.name === methodName);
      const method = service.methods[methodName];
      if (method && method.execute && typeof method.execute === 'function') {
        Logger.terminalInfo('\n');
        Logger.terminalInfo('----------- Controller Executing -----------');
        Logger.terminalInfo(`Execute function: ${serviceName}.${methodName}`);
        Logger.terminalInfo(`Amount params: ${getAmountParams(method.execute)}`);
        Logger.terminalInfo(`Args: ${args}, type: ${typeof args}`);
        ControlEnv.setEnv({
          source,
          preview: this.isPreviewMode,
          editorid: this.editorid,
        });
        if (serviceName === 'var') {
          const { path, data } = args;
          if (methodName.slice(0, 3) === 'get') {
            result = method.execute(path, resource);
          } else if (methodName.slice(0, 3) === 'set') {
            result = method.execute({ path, data }, { source });
          }
        } else {
          if (Array.isArray(args) && getAmountParams(method.execute) !== 1) {
            result = method.execute(...args, { source });
          } else {
            result = method.execute(args, { source });
          }
        }

        Logger.terminalInfo(`result: ${result}`);
        Logger.terminalInfo('--------------------------------------------');
        Logger.terminalInfo('\n');
      }
    }
    return result;
  }

  getControlEnv() {
    return this.env;
  }

  setControlEnv(env) {
    this.env = env;
  }
}

export default new Controller();
