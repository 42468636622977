import Logger from '../../Helper/Logger';
import fAPI from '../fAPI';
import { BASE_URL, API } from '../fAPI/constants';

const BASE_URI = BASE_URL.prod;

/**
 * @param { email: string }: email of user.
 * @param { password: string }: password of user.
 * @param { appCode: string }: appCode of app.
 * @param { fcmToken: string }: firebase token of user.
 * @param { deviceUID: string }: deviceUID of app.
 * @param { os: string }: os of app.
 * @param { callback: json }: callback of function.
 * @param { callback.success: function }: function will call when sign in success.
 * @param { callback.fail: function }: function will call when sign in fail.
 */

async function getEntities({
  userId,
  page,
  page_size,
  token,
  add_device_info,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.ENTITY.GET_ENTITIES}`;
    const response = await fAPI.get(
      uri,
      {
        Authorization: token,
        'x-user': userId,
      },
      {
        page,
        page_size,
        add_device_info,
      },
    );

    typeof callback?.success === 'function' && callback.success(response);

    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

async function createEntity({
  spaceId,
  areaId,
  name,
  deviceId,
  parientId,
  type,
  deviceToken,
  deviceInfoCode,
  token,
  options,
  userId,
  appCode,
  favorite,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.ENTITY.CREATE_ENTITY}`;
    Logger.terminalInfo('uri', uri);
    const response = await fAPI.post(
      uri,
      {
        Authorization: token,
        'x-user': userId,
        'x-appCode': appCode,
      },
      {
        spaceId,
        areaId,
        name,
        token: deviceToken,
        deviceInfoCode,
        deviceId,
        parientId,
        type,
        options,
        favorite,
      },
    );

    typeof callback?.success === 'function' && callback.success(response);

    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

async function shareEntities({
  userId,
  spaceId,
  memberId,
  entityIds,
  token,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.ENTITY.SHARE_ENTITIES}`;
    Logger.terminalInfo('uri', uri);
    const response = await fAPI.post(
      uri,
      {
        Authorization: token,
        'x-user': userId,
      },
      {
        spaceId,
        memberId,
        entityIds,
      },
    );

    typeof callback?.success === 'function' && callback.success(response);

    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

async function updateEntity({
  id,
  userId,
  spaceId,
  areaId,
  name,
  favorite,
  token,
  options,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.ENTITY.UPDATE_ENTITY(id)}`;
    Logger.terminalInfo('uri', uri);
    const response = await fAPI.put(
      uri,
      {
        Authorization: token,
        'x-user': userId,
      },
      {
        name,
        spaceId,
        areaId,
        favorite,
        options,
      },
    );

    typeof callback?.success === 'function' && callback.success(response);

    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

async function deleteEntity({
  userId,
  id,
  spaceId,
  token,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.ENTITY.DELETE_ENTITY(id)}`;
    Logger.terminalInfo('uri', uri);
    const response = await fAPI.delete(
      uri,
      {
        Authorization: token,
        'x-user': userId,
      },
      {
        spaceId,
      },
    );

    typeof callback?.success === 'function' && callback.success(response);

    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

async function deleteEntities({
  devices = [{ userId: '', id: '', spaceId: '', token: '' }],
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    return Promise.all(
      devices.map(({ userId, id, spaceId, token }) => {
        const uri = `${BASE_URI}${API.ENTITY.DELETE_ENTITY(id)}`;
        return fAPI.delete(
          uri,
          {
            Authorization: token,
            'x-user': userId,
          },
          {
            spaceId,
          },
        );
      }),
    ).then((responses) => {
      typeof callback?.success === 'function' && callback.success(responses);
      return { status: true, result: responses };
    });
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

async function unshareEntities({
  memberId,
  userId,
  entityIds,
  spaceId,
  token,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.ENTITY.UNSHARE_ENTITIES}`;
    Logger.terminalInfo('uri', uri);
    const response = await fAPI.delete(
      uri,
      {
        Authorization: token,
        'x-user': userId,
      },
      {
        spaceId,
        entityIds,
        memberId,
      },
    );

    typeof callback?.success === 'function' && callback.success(response);

    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

export const fEntity = {
  getEntities,
  createEntity,
  updateEntity,
  deleteEntity,
  shareEntities,
  unshareEntities,
  deleteEntities,
};
