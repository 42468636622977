import React, { Component } from 'react';
import Editor from './Editor';
import Sidebar from './Sidebar';
import Logger from '../Helper/Logger';

const getFileInTreeFileByPath = (node, path) => {
  if (node.path === path) {
    return node; // Return the value if found
  }

  // If the current node doesn't match, recursively search its children
  if (node.children) {
    for (const child of node.children) {
      const result = getFileInTreeFileByPath(child, path);
      if (result !== undefined) {
        return result; // If found in children, return the value
      }
    }
  }

  // Return undefined if the key is not found in the current node or its children
  return undefined;
};

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      treeFile: props.treeFile || [
        {
          name: 'Auth',
          isFile: false,
          children: [],
        },
        {
          name: 'Container',
          isFile: false,
          children: [],
        },
      ],
      currentFile: {},
      openedFiles: [],
    };
  }

  componentDidMount() {
    // this.downloadJSON();
  }

  downloadJSON = () => {
    // Your JSON data
    const jsonData = this.state.treeFile;

    // Convert JSON data to a string
    const jsonString = JSON.stringify(jsonData, null, 2);

    // Create a Blob object from the JSON string
    const blob = new Blob([jsonString], { type: 'application/json' });

    // Create an anchor element
    const link = document.createElement('a');

    // Set the href attribute of the anchor to the Blob object
    link.href = URL.createObjectURL(blob);

    // Specify that the file should be downloaded when clicked
    link.setAttribute('download', 'data.json');

    // Append the anchor to the body
    document.body.appendChild(link);

    // Programmatically click the anchor to trigger the download
    link.click();

    // Remove the anchor from the body
    document.body.removeChild(link);
  };

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(this.props.treeFile) !== JSON.stringify(prevProps.treeFile)) {
      const currentFileNewValue = getFileInTreeFileByPath({ children: this.props.treeFile }, this.state.currentFile.path)?.value;
      this.setState({
        treeFile: this.props.treeFile,
        currentFile: {
          ...this.state.currentFile,
          value: currentFileNewValue,
          reFormat: JSON.stringify(currentFileNewValue) !== JSON.stringify(this.state.currentFile?.value),
        },
      });
      // this.setTreeFile(this.props.treeFile);
    }
    if (prevProps.url !== this.props.url) {
      this.setState({});
    }
  }

  onChangeUrl = (url) => {
    Logger.terminalInfo('url', url);
  };

  setTreeFile = (treeFile) => {
    this.setState({
      treeFile,
    });
  };

  setSelectFile = (currentFile) => {
    const opened = this.state.openedFiles.some((elm) => elm.name === currentFile.name);
    if (!opened) {
      const newArray = this.state.openedFiles.map((elm) => ({
        ...elm,
        select: false,
      }));
      newArray.push({
        ...currentFile,
        select: true,
      });
      this.setState({
        ...this.state,
        currentFile,
        openedFiles: newArray,
      });
    } else {
      this.setState({
        ...this.state,
        currentFile,
        openedFiles: this.state.openedFiles.map((elm) => {
          if (elm.name === currentFile.name) {
            return { ...elm, select: true };
          }
          return { ...elm, select: false };
        }),
      });
    }
  };

  setCloseFile = (openedFile) => {
    let currentFile = this.state.currentFile;
    let openedFiles = this.state.openedFiles.filter((elm) => elm.path !== openedFile.path);
    let index = this.state.openedFiles.findIndex((elm) => elm.path === openedFile.path);
    if (openedFile.select && openedFiles.length > 0) {
      let newIndex = index > 0 ? index - 1 : Math.min(0, index + 1);
      openedFiles[newIndex].select = true;
      currentFile = openedFiles[newIndex];
    }
    if (openedFiles.length < 1) {
      currentFile = {};
    }
    this.setState({
      ...this.state,
      openedFiles,
      currentFile,
    });
  };

  onChangeValue = (value, currentFile) => {
    this.setState(
      {
        currentFile: { ...currentFile, value },
      },
      () => {
        this.props.onChangeValue(value, currentFile);
      },
    );
  };

  render() {
    const { treeFile, currentFile } = this.state;
    const { editorid } = this.props;
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}>
        <Sidebar
          setTreeFile={this.setTreeFile}
          treeFile={treeFile}
          setSelectFile={this.setSelectFile}
          currentFile={currentFile}
          openedFiles={[currentFile]}
          closeFile={this.setCloseFile}
          onPublish={this.props.onPublish}
        />
        {typeof currentFile.name === 'undefined' ? (
          <iframe src={`${window.location.origin}?preview=true&&editorid=${editorid}`} title='description' width={'100%'}></iframe>
        ) : (
          <Editor
            onChangeUrl={this.onChangeUrl}
            setTreeFile={this.setTreeFile}
            treeFile={treeFile}
            setSelectFile={this.setSelectFile}
            currentFile={currentFile}
            openedFiles={[currentFile]}
            closeFile={this.setCloseFile}
            onChangeValue={this.onChangeValue}
            editorid={this.props.editorid}
          />
        )}
      </div>
    );
  }
}
