import React from 'react';
import Builder from '../../..';
import BaseUI from './BaseComponent';
import Block from './BlockComponent';
import { UI_COMPONENTS_TYPE_FIELD } from '../../../../Constant/Constant';
import { getStyle } from './style';
import { blockStyleChecked } from './util';

class UIBuilder {
  constructor() {
    this.type = this.getType();
    this.isUI = true;
  }

  getSchema = () => {
    return {
      x: UI_COMPONENTS_TYPE_FIELD.NUMBER,
      y: UI_COMPONENTS_TYPE_FIELD.NUMBER,
      width: UI_COMPONENTS_TYPE_FIELD.NUMBER,
      height: UI_COMPONENTS_TYPE_FIELD.NUMBER,
      style: UI_COMPONENTS_TYPE_FIELD.JSON,
      onClick: UI_COMPONENTS_TYPE_FIELD.JSON,
      ...this.schema,
    };
  };

  setSchema = (schema) => {
    this.schema = schema;
  };

  getType = () => {
    return undefined;
  };

  getComponent = () => {
    return BaseUI;
  };

  build = (Config, resource) => {
    const component = Config;

    // const compiledMarkdownComponent = Builder.traverseBuildTextMarkdown(component, resource);
    const blockcompiledcomponent = Object.fromEntries(
      Object.keys(component)
        .filter((key) => {
          return key.slice(0, 5) === 'block' || blockStyleChecked(component).includes(key);
        })
        .map((key) => {
          if (key.slice(0, 5) === 'block') {
            return [key.slice(5, key.length).toLowerCase(), Builder.traverseBuild(component[key], resource)];
          }
          return [key, Builder.traverseBuild(component[key], resource)];
        }),
    );

    if (blockcompiledcomponent) {
      const defaultBlockStyle = getStyle(
        blockcompiledcomponent.x,
        blockcompiledcomponent.y,
        blockcompiledcomponent.width,
        blockcompiledcomponent.height,
        blockcompiledcomponent.style,
        blockcompiledcomponent.zIndex,
        resource.appResource,
      );

      const TypeComponent = this.getComponent();
      const children = React.createElement(TypeComponent, {});

      return React.createElement(Block, {
        resource: {
          ...resource,
          appResource: {
            ...resource.appResource,
            blockcompiledcomponent,
            rawcomponent: Config,
            key: blockcompiledcomponent.key,
            defaultBlockStyle,
          },
        },
        children,
        key: blockcompiledcomponent.key,
      });
      // }
    } else {
      return <></>;
    }
  };

  buildProps = (compiledcomponent, resource) => {
    return {};
  };
}

export default UIBuilder;
