import { TYPE_WINDOWS } from '../Constant/Constant';
import {
  HEIGHT_LARGE_DEFAULT,
  HEIGHT_MEDIUM_DEFAULT,
  HEIGHT_SMALL_DEFAULT,
  LARGE_RATIO_MAX,
  LARGE_RATIO_MIN,
  MEDIUM_RATIO_MAX,
  MEDIUM_RATIO_MIN,
  SMALL_RATIO,
  SMALL_RATIO_MAX,
  SMALL_RATIO_MIN,
  WIDTH_LARGE_DEFAULT,
  WIDTH_LARGE_DEFAULT_MAX,
  WIDTH_LARGE_DEFAULT_MIN,
  WIDTH_MEDIUM_DEFAULT,
  WIDTH_MEDIUM_DEFAULT_MAX,
  WIDTH_MEDIUM_DEFAULT_MIN,
  WIDTH_SMALL_DEFAULT,
  WIDTH_SMALL_DEFAULT_MAX,
  WIDTH_SMALL_DEFAULT_MIN,
  LARGE_COL,
  LARGE_ROW,
  MEDIUM_COL,
  MEDIUM_ROW,
  SMALL_COL,
  SMALL_ROW,
} from '../Constant/Responsive';
import { COLLAPSED_WIDTH, FULL_WIDTH, SIDEBAR_STATES } from '../Constant/Sidebar';
import Logger from './Logger';

export const getSidebarState = (windowWidth, useSidebar = true) => {
  if (useSidebar) {
    if (windowWidth >= WIDTH_LARGE_DEFAULT_MIN) {
      return SIDEBAR_STATES.FULL;
    } else if (windowWidth < WIDTH_LARGE_DEFAULT_MIN && windowWidth >= WIDTH_MEDIUM_DEFAULT_MIN) {
      return SIDEBAR_STATES.COLLAPSED;
    } else {
      return SIDEBAR_STATES.BREAK;
    }
  }
  return SIDEBAR_STATES.NONE;
};

export const getSidebarWidth = (sidebarState, resolutionRatio = 1) => {
  switch (sidebarState) {
    case SIDEBAR_STATES.BREAK:
      return 0;
    case SIDEBAR_STATES.COLLAPSED:
      return COLLAPSED_WIDTH * resolutionRatio;
    case SIDEBAR_STATES.FULL:
      return FULL_WIDTH * resolutionRatio;
    case SIDEBAR_STATES.NONE:
      return 0;
    default:
      return 0;
  }
};

const makeConfig = (
  data = { type: '', resolutionRatio: 1 },
  env = {
    LARGE_COL,
    LARGE_ROW,
    MEDIUM_COL,
    MEDIUM_ROW,
    SMALL_COL,
    SMALL_ROW,
  },
) => {
  const resolutionRatio = data.resolutionRatio ?? 1;
  Logger.terminalInfo('makeConfigmakeConfig', data);
  switch (data.type) {
    case TYPE_WINDOWS.LARGE.type:
      return {
        ...data,
        widthBase: data.widthBase * resolutionRatio,
        heightBase: data.heightBase * resolutionRatio,
        fontSizeRatio: data.fontSizeRatio * resolutionRatio * resolutionRatio,
        col: env.LARGE_COL,
        row: env.LARGE_ROW,
        type: TYPE_WINDOWS.LARGE,
      };
    case TYPE_WINDOWS.MEDIUM.type:
      return {
        ...data,
        widthBase: data.widthBase * resolutionRatio,
        heightBase: data.heightBase * resolutionRatio,
        fontSizeRatio: data.fontSizeRatio * resolutionRatio * resolutionRatio,
        col: env.MEDIUM_COL,
        row: env.MEDIUM_ROW,
        type: TYPE_WINDOWS.MEDIUM,
      };
    case TYPE_WINDOWS.SMALL.type:
      return {
        ...data,
        widthBase: data.widthBase * resolutionRatio,
        heightBase: data.heightBase * resolutionRatio,
        fontSizeRatio: data.fontSizeRatio * resolutionRatio * resolutionRatio,
        col: env.SMALL_COL,
        row: env.SMALL_ROW,
        type: TYPE_WINDOWS.SMALL,
      };

    default:
      break;
  }
};

export const getConfigFromSize = (
  _width,
  _height,
  resolutionRatio = 1,
  env = {
    HEIGHT_LARGE_DEFAULT,
    HEIGHT_MEDIUM_DEFAULT,
    HEIGHT_SMALL_DEFAULT,
    LARGE_RATIO_MAX,
    LARGE_RATIO_MIN,
    MEDIUM_RATIO_MAX,
    MEDIUM_RATIO_MIN,
    SMALL_RATIO,
    SMALL_RATIO_MAX,
    SMALL_RATIO_MIN,
    WIDTH_LARGE_DEFAULT,
    WIDTH_LARGE_DEFAULT_MAX,
    WIDTH_LARGE_DEFAULT_MIN,
    WIDTH_MEDIUM_DEFAULT,
    WIDTH_MEDIUM_DEFAULT_MAX,
    WIDTH_MEDIUM_DEFAULT_MIN,
    WIDTH_SMALL_DEFAULT,
    WIDTH_SMALL_DEFAULT_MAX,
    WIDTH_SMALL_DEFAULT_MIN,
    LARGE_COL,
    LARGE_ROW,
    MEDIUM_COL,
    MEDIUM_ROW,
    SMALL_COL,
    SMALL_ROW,
  },
) => {
  const width = _width / resolutionRatio;
  const height = _height / resolutionRatio;
  const ratio = width / height;
  const {
    HEIGHT_LARGE_DEFAULT,
    HEIGHT_MEDIUM_DEFAULT,
    HEIGHT_SMALL_DEFAULT,
    LARGE_RATIO_MAX,
    LARGE_RATIO_MIN,
    MEDIUM_RATIO_MAX,
    MEDIUM_RATIO_MIN,
    SMALL_RATIO,
    SMALL_RATIO_MAX,
    SMALL_RATIO_MIN,
    WIDTH_LARGE_DEFAULT,
    WIDTH_LARGE_DEFAULT_MAX,
    WIDTH_LARGE_DEFAULT_MIN,
    WIDTH_MEDIUM_DEFAULT,
    WIDTH_MEDIUM_DEFAULT_MAX,
    WIDTH_MEDIUM_DEFAULT_MIN,
    WIDTH_SMALL_DEFAULT,
    WIDTH_SMALL_DEFAULT_MAX,
    WIDTH_SMALL_DEFAULT_MIN,
    LARGE_COL,
    LARGE_ROW,
    MEDIUM_COL,
    MEDIUM_ROW,
    SMALL_COL,
    SMALL_ROW,
  } = env;
  Logger.terminalInfo('getConfigFromSize width height', width, height, env, ratio, LARGE_RATIO_MIN, LARGE_RATIO_MAX);
  switch (true) {
    case ratio >= LARGE_RATIO_MAX:
      switch (true) {
        // width = 2300, height = 1100, ratio = 2300/1100 > 18/9
        // large_ratio_max = 18/9
        // width_large_default_max = 2160, height_large_default = 1080
        // => widthBase = 2200, heightBase = 1100, fontSizeRatio = 1100/1080
        case width > WIDTH_LARGE_DEFAULT_MAX:
          Logger.terminalInfo('getConfigFromSize', 1);
          return makeConfig(
            {
              type: TYPE_WINDOWS.LARGE.type,
              widthBase: height * LARGE_RATIO_MAX,
              heightBase: height,
              fontSizeRatio: height / HEIGHT_LARGE_DEFAULT,
              resolutionRatio,
            },
            {
              LARGE_COL,
              LARGE_ROW,
              MEDIUM_COL,
              MEDIUM_ROW,
              SMALL_COL,
              SMALL_ROW,
            },
          );

        // width = 1600, height = 727, ratio = 1600/727 > 18/9
        // large_ratio_max = 18/9
        // width_large_default_min = 1000, width_large_default_max = 2160, height_large_default = 1080
        // => widthBase = 1454, heightBase = 727, fontSizeRatio = 727/1080 *  1454/1920
        case width >= WIDTH_LARGE_DEFAULT_MIN && width < WIDTH_LARGE_DEFAULT_MAX:
          Logger.terminalInfo('getConfigFromSize', 2);
          return makeConfig(
            {
              type: TYPE_WINDOWS.LARGE.type,
              widthBase: height * LARGE_RATIO_MAX,
              heightBase: height,
              fontSizeRatio: (height / HEIGHT_LARGE_DEFAULT) * ((height * LARGE_RATIO_MAX) / WIDTH_LARGE_DEFAULT_MAX),
              resolutionRatio,
            },
            {
              LARGE_COL,
              LARGE_ROW,
              MEDIUM_COL,
              MEDIUM_ROW,
              SMALL_COL,
              SMALL_ROW,
            },
          );

        case width < WIDTH_LARGE_DEFAULT_MIN:
          switch (true) {
            // width = 1400, height = 600, ratio = 1366/600 > 18/9
            // large_ratio_max = 18/9
            // width_medium_default_max = 1366, width_large_default_min = 1000, height_medium_default_max = 1180
            // => widthBase = 1366, heightBase = 1180, fontSizeRatio = 1366 / width_medium_default
            case width >= WIDTH_MEDIUM_DEFAULT_MAX:
              Logger.terminalInfo('getConfigFromSize', 3);
              return makeConfig(
                {
                  type: TYPE_WINDOWS.MEDIUM.type,
                  widthBase: WIDTH_MEDIUM_DEFAULT_MAX,
                  heightBase: HEIGHT_MEDIUM_DEFAULT,
                  fontSizeRatio: WIDTH_MEDIUM_DEFAULT_MAX / WIDTH_MEDIUM_DEFAULT,
                  resolutionRatio,
                },
                {
                  LARGE_COL,
                  LARGE_ROW,
                  MEDIUM_COL,
                  MEDIUM_ROW,
                  SMALL_COL,
                  SMALL_ROW,
                },
              );

            // width = 800, height = 300, ratio = 800/300 > 18/9
            // large_ratio_max = 18/9
            // width_medium_default_min = 600, width_medium_default_max = 1366
            // => widthBase = 800, heightBase = 1080, fontSizeRatio = 800 / width_medium_default
            case width >= WIDTH_MEDIUM_DEFAULT_MIN && width < WIDTH_MEDIUM_DEFAULT_MAX:
              Logger.terminalInfo('getConfigFromSize', 4);
              return makeConfig(
                {
                  type: TYPE_WINDOWS.MEDIUM.type,
                  widthBase: width,
                  heightBase: HEIGHT_MEDIUM_DEFAULT,
                  fontSizeRatio: width / WIDTH_MEDIUM_DEFAULT,
                  resolutionRatio,
                },
                {
                  LARGE_COL,
                  LARGE_ROW,
                  MEDIUM_COL,
                  MEDIUM_ROW,
                  SMALL_COL,
                  SMALL_ROW,
                },
              );

            // width = 800, height = 300, ratio = 780/300 > 18/9
            // large_ratio_max = 18/9
            // width_small_default_max = 780, width_medium_default_min = 600
            // => widthBase = 780, heightBase = 844, fontSizeRatio = 780 / width_small_default
            case width >= WIDTH_SMALL_DEFAULT_MAX && width < WIDTH_MEDIUM_DEFAULT_MIN:
              Logger.terminalInfo('getConfigFromSize', 5);
              return makeConfig(
                {
                  type: TYPE_WINDOWS.SMALL.type,
                  widthBase: WIDTH_SMALL_DEFAULT_MAX,
                  heightBase: HEIGHT_SMALL_DEFAULT,
                  fontSizeRatio: WIDTH_SMALL_DEFAULT_MAX / WIDTH_SMALL_DEFAULT,
                  resolutionRatio,
                },
                {
                  LARGE_COL,
                  LARGE_ROW,
                  MEDIUM_COL,
                  MEDIUM_ROW,
                  SMALL_COL,
                  SMALL_ROW,
                },
              );

            // width = 300 height = 100, ratio = 300/100 > 18/9
            // large_ratio_max = 18/9
            // width_small_default_max = 780, width_small_default_min = 292.5 = 292.5
            // => widthBase = 300, heightBase = 844, fontSizeRatio = 300 / width_small_default
            case width >= WIDTH_SMALL_DEFAULT_MIN && width < WIDTH_SMALL_DEFAULT_MAX:
              Logger.terminalInfo('getConfigFromSize', 6);
              return makeConfig(
                {
                  type: TYPE_WINDOWS.SMALL.type,
                  widthBase: width,
                  heightBase: HEIGHT_SMALL_DEFAULT,
                  fontSizeRatio: width / WIDTH_SMALL_DEFAULT,
                  resolutionRatio,
                },
                {
                  LARGE_COL,
                  LARGE_ROW,
                  MEDIUM_COL,
                  MEDIUM_ROW,
                  SMALL_COL,
                  SMALL_ROW,
                },
              );

            // width = 270, height = 120, ratio = 270/120 > 18/9
            // large_ratio_max = 18/9
            // width_small_default_min = 292.5, height_small_default = 844
            // => widthBase = 270, heightBase = 270 / small_ratio, fontSizeRatio = (270 * (270 / small_ratio)) / (width_small_default * height_small_default)
            case width < WIDTH_SMALL_DEFAULT_MIN:
              Logger.terminalInfo('getConfigFromSize', 7);
              return makeConfig(
                {
                  type: TYPE_WINDOWS.SMALL.type,
                  widthBase: width,
                  heightBase: width / SMALL_RATIO,
                  fontSizeRatio: (width * (width / SMALL_RATIO)) / (WIDTH_SMALL_DEFAULT * HEIGHT_SMALL_DEFAULT),
                  resolutionRatio,
                },
                {
                  LARGE_COL,
                  LARGE_ROW,
                  MEDIUM_COL,
                  MEDIUM_ROW,
                  SMALL_COL,
                  SMALL_ROW,
                },
              );
            default:
              break;
          }
          break;
        default:
          break;
      }
      break;

    case ratio >= LARGE_RATIO_MIN && ratio < LARGE_RATIO_MAX:
      switch (true) {
        // width = 1200, height = 700, ratio = 1200/700 > 12/9 && 1200/700 < 18/9
        // large_ratio_min = 12/9, large_ratio_max = 18/9
        // width_large_default_min = 1000
        // => widthBase = 1200, heightBase = 700, fontSizeRatio = ( width * height ) / ( width_large_default * height_large_default )
        case width >= WIDTH_LARGE_DEFAULT_MIN:
          Logger.terminalInfo('getConfigFromSize', 8);
          return makeConfig(
            {
              type: TYPE_WINDOWS.LARGE.type,
              widthBase: width,
              heightBase: height,
              fontSizeRatio: (width * height) / (HEIGHT_LARGE_DEFAULT * WIDTH_LARGE_DEFAULT),
              resolutionRatio,
            },
            {
              LARGE_COL,
              LARGE_ROW,
              MEDIUM_COL,
              MEDIUM_ROW,
              SMALL_COL,
              SMALL_ROW,
            },
          );

        case width < WIDTH_LARGE_DEFAULT_MIN:
          switch (true) {
            // width = 1400, height = 800, ratio = 1400/800 > 12/9 && 1400/800 < 18/9
            // large_ratio_min = 12/9, large_ratio_max = 18/9
            // width_large_default_min = 1000, width_medium_default_max = 1366
            // => widthBase = 1366, heightBase = 1180, fontSizeRatio = 1366/820
            case width >= WIDTH_MEDIUM_DEFAULT_MAX:
              Logger.terminalInfo('getConfigFromSize', 9);
              return makeConfig(
                {
                  type: TYPE_WINDOWS.MEDIUM.type,
                  widthBase: WIDTH_MEDIUM_DEFAULT_MAX,
                  heightBase: HEIGHT_MEDIUM_DEFAULT,
                  fontSizeRatio: WIDTH_MEDIUM_DEFAULT_MAX / WIDTH_MEDIUM_DEFAULT,
                  resolutionRatio,
                },
                {
                  LARGE_COL,
                  LARGE_ROW,
                  MEDIUM_COL,
                  MEDIUM_ROW,
                  SMALL_COL,
                  SMALL_ROW,
                },
              );

            // width = 900, height = 540, ratio = 900/540 > 12/9 && 900/540 < 18/9
            // large_ratio_min = 12/9, large_ratio_max = 18/9
            // width_medium_default_min = 600, width_medium_default_max = 1366, width_large_default_min = 1000
            // => widthBase = 900, heightBase = 1180, fontSizeRatio = (900/820)
            case width >= WIDTH_MEDIUM_DEFAULT_MIN && width < WIDTH_MEDIUM_DEFAULT_MAX:
              Logger.terminalInfo('getConfigFromSize', 10);
              return makeConfig(
                {
                  type: TYPE_WINDOWS.MEDIUM.type,
                  widthBase: width,
                  heightBase: HEIGHT_MEDIUM_DEFAULT,
                  fontSizeRatio: width / WIDTH_MEDIUM_DEFAULT,
                  resolutionRatio,
                },
                {
                  LARGE_COL,
                  LARGE_ROW,
                  MEDIUM_COL,
                  MEDIUM_ROW,
                  SMALL_COL,
                  SMALL_ROW,
                },
              );

            // width = 700, height = 420, ratio = 700/420 > 12/9 && 700/420 < 18/9
            // large_ratio_min = 12/9, large_ratio_max = 18/9
            // width_small_default_max = 780, width_medium_default_min = 600, width_large_default_min = 1000
            // => widthBase = 780, heightBase = 844, fontSizeRatio = 780/390
            case width >= WIDTH_SMALL_DEFAULT_MAX && width < WIDTH_MEDIUM_DEFAULT_MIN:
              Logger.terminalInfo('getConfigFromSize', 11);
              return makeConfig(
                {
                  type: TYPE_WINDOWS.SMALL.type,
                  widthBase: WIDTH_SMALL_DEFAULT_MAX,
                  heightBase: HEIGHT_SMALL_DEFAULT,
                  fontSizeRatio: WIDTH_SMALL_DEFAULT_MAX / WIDTH_SMALL_DEFAULT,
                  resolutionRatio,
                },
                {
                  LARGE_COL,
                  LARGE_ROW,
                  MEDIUM_COL,
                  MEDIUM_ROW,
                  SMALL_COL,
                  SMALL_ROW,
                },
              );

            // width = 360, height = 216, ratio = 360/216 > 12/9 && 360/216 < 18/9
            // large_ratio_min = 12/9, large_ratio_max = 18/9
            // width_small_default_max = 780, width_small_default_min = 292.5, width_large_default_min = 1000
            // => widthBase = 360, heightBase = 844, fontSizeRatio = 360 / 292.5
            case width >= WIDTH_SMALL_DEFAULT_MIN && width < WIDTH_SMALL_DEFAULT_MAX:
              Logger.terminalInfo('getConfigFromSize', 12);
              return makeConfig(
                {
                  type: TYPE_WINDOWS.SMALL.type,
                  widthBase: width,
                  heightBase: HEIGHT_SMALL_DEFAULT,
                  fontSizeRatio: width / WIDTH_SMALL_DEFAULT,
                  resolutionRatio,
                },
                {
                  LARGE_COL,
                  LARGE_ROW,
                  MEDIUM_COL,
                  MEDIUM_ROW,
                  SMALL_COL,
                  SMALL_ROW,
                },
              );

            // width = 270, height = 162, ratio = 270/162 > 12/9 && 270/162 < 18/9
            // large_ratio_min = 12/9, large_ratio_max = 18/9
            // width_small_default_min = 292.5, width_large_default_min = 1000
            // => widthBase = 270, heightBase = 270/(4/9), fontSizeRatio = widthBase * heightBase / (WIDTH_SMALL_DEFAULT_MIN * HEIGHT_SMALL_DEFAULT_MIN)
            case width < WIDTH_SMALL_DEFAULT_MIN:
              Logger.terminalInfo('getConfigFromSize', 13);
              return makeConfig(
                {
                  type: TYPE_WINDOWS.SMALL.type,
                  widthBase: width,
                  heightBase: width / SMALL_RATIO,
                  fontSizeRatio: (width * width) / SMALL_RATIO / (HEIGHT_SMALL_DEFAULT * WIDTH_SMALL_DEFAULT),
                  resolutionRatio,
                },
                {
                  LARGE_COL,
                  LARGE_ROW,
                  MEDIUM_COL,
                  MEDIUM_ROW,
                  SMALL_COL,
                  SMALL_ROW,
                },
              );
            default:
              break;
          }
          break;

        default:
          break;
      }
      break;

    case ratio >= MEDIUM_RATIO_MAX && ratio < LARGE_RATIO_MIN:
      switch (true) {
        // width = 1600, height = 1310, ratio = 1600/1310 > 10/9 && 1600/1310 < 12/9
        // large_ratio_min = 12/9, medium_ratio_max = 10/9
        // width_medium_default_max = 1366
        // => widthBase = height * medium_ratio_max, heightBase = height, fontSizeRatio = widthBase * heightBase / (WIDTH_MEDIUM_DEFAULT_MAX * HEIGHT_MEDIUM_DEFAULT_MAX)
        case width >= WIDTH_MEDIUM_DEFAULT_MAX:
          Logger.terminalInfo('getConfigFromSize', 14);
          return makeConfig(
            {
              type: TYPE_WINDOWS.MEDIUM.type,
              widthBase: height * MEDIUM_RATIO_MAX,
              heightBase: height,
              fontSizeRatio: (height * height * MEDIUM_RATIO_MAX) / (HEIGHT_MEDIUM_DEFAULT * WIDTH_LARGE_DEFAULT_MAX),
              resolutionRatio,
            },
            {
              LARGE_COL,
              LARGE_ROW,
              MEDIUM_COL,
              MEDIUM_ROW,
              SMALL_COL,
              SMALL_ROW,
            },
          );

        // width = 1000, height = 818, ratio = 1000/818 > 10/9 && 1000/818 < 12/9
        // large_ratio_min = 12/9, medium_ratio_max = 10/9
        // width_medium_default_min = 600, width_medium_default_max = 1366
        // => widthBase = width, heightBase = HEIGHT_MEDIUM_DEFAULT, fontSizeRatio = width / WIDTH_MEDIUM_DEFAULT
        case width >= WIDTH_MEDIUM_DEFAULT_MIN && width < WIDTH_MEDIUM_DEFAULT_MAX:
          Logger.terminalInfo('getConfigFromSize', 15);
          return makeConfig(
            {
              type: TYPE_WINDOWS.MEDIUM.type,
              widthBase: width,
              heightBase: HEIGHT_MEDIUM_DEFAULT,
              fontSizeRatio: width / WIDTH_MEDIUM_DEFAULT,
              resolutionRatio,
            },
            {
              LARGE_COL,
              LARGE_ROW,
              MEDIUM_COL,
              MEDIUM_ROW,
              SMALL_COL,
              SMALL_ROW,
            },
          );

        // width = 800, height = 654, ratio = 800/654 > 10/9 && 800/654 < 12/9
        // large_ratio_min = 12/9, medium_ratio_max = 10/9
        // width_small_default_max = 780, width_medium_default_min = 600
        // => widthBase = WIDTH_SMALL_DEFAULT_MAX, heightBase = HEIGHT_SMALL_DEFAULT, fontSizeRatio = WIDTH_SMALL_DEFAULT_MAX / WIDTH_SMALL_DEFAULT
        case width >= WIDTH_SMALL_DEFAULT_MAX && width < WIDTH_MEDIUM_DEFAULT_MIN:
          Logger.terminalInfo('getConfigFromSize', 16);
          return makeConfig(
            {
              type: TYPE_WINDOWS.SMALL.type,
              widthBase: WIDTH_SMALL_DEFAULT_MAX,
              heightBase: HEIGHT_SMALL_DEFAULT,
              fontSizeRatio: WIDTH_SMALL_DEFAULT_MAX / WIDTH_SMALL_DEFAULT,
              resolutionRatio,
            },
            {
              LARGE_COL,
              LARGE_ROW,
              MEDIUM_COL,
              MEDIUM_ROW,
              SMALL_COL,
              SMALL_ROW,
            },
          );

        // width = 300, height = 245, ratio = 300/245 > 10/9 && 300/245 < 12/9
        // large_ratio_min = 12/9, medium_ratio_max = 10/9
        // width_small_default_max = 780, width_small_default_min = 292.5
        // => widthBase = width, heightBase = HEIGHT_SMALL_DEFAULT, fontSizeRatio = width / WIDTH_SMALL_DEFAULT
        case width >= WIDTH_SMALL_DEFAULT_MIN && width < WIDTH_SMALL_DEFAULT_MAX:
          Logger.terminalInfo('getConfigFromSize', 17);
          return makeConfig(
            {
              type: TYPE_WINDOWS.SMALL.type,
              widthBase: width,
              heightBase: HEIGHT_SMALL_DEFAULT,
              fontSizeRatio: width / WIDTH_SMALL_DEFAULT,
              resolutionRatio,
            },
            {
              LARGE_COL,
              LARGE_ROW,
              MEDIUM_COL,
              MEDIUM_ROW,
              SMALL_COL,
              SMALL_ROW,
            },
          );

        // width = 250, height = 205, ratio = 250/205 > 11/9 && 250/205 < 14/9
        // large_ratio_min = 12/9, medium_ratio_max = 10/9
        // width_small_default_min = 292.5
        // => widthBase = width, heightBase = width * SMALL_RATIO, fontSizeRatio = width * height / HEIGHT_SMALL_DEFAULT * WIDTH_SMALL_DEFAULT
        case width < WIDTH_SMALL_DEFAULT_MIN:
          Logger.terminalInfo('getConfigFromSize', 18);
          return makeConfig(
            {
              type: TYPE_WINDOWS.SMALL.type,
              widthBase: width,
              heightBase: width / SMALL_RATIO,
              fontSizeRatio: (width * width) / SMALL_RATIO / (HEIGHT_SMALL_DEFAULT * WIDTH_SMALL_DEFAULT),
              resolutionRatio,
            },
            {
              LARGE_COL,
              LARGE_ROW,
              MEDIUM_COL,
              MEDIUM_ROW,
              SMALL_COL,
              SMALL_ROW,
            },
          );
        default:
          break;
      }
      break;

    case ratio >= MEDIUM_RATIO_MIN && ratio < MEDIUM_RATIO_MAX:
      switch (true) {
        // width = 700, height = 1050, ratio = 700/1050 > 4.5/9 && 700/1050 < 10/9
        // medium_ratio_min = 4.5/9, medium_ratio_max = 10/9
        // width_medium_default_min = 600
        // => widthBase = width, heightBase = height, fontSizeRatio = width * height / WIDTH_MEDIUM_DEFAULT * HEIGHT_MEDIUM_DEFAULT
        case width >= WIDTH_MEDIUM_DEFAULT_MIN:
          Logger.terminalInfo('getConfigFromSize', 19);
          return makeConfig(
            {
              type: TYPE_WINDOWS.MEDIUM.type,
              widthBase: width,
              heightBase: height,
              fontSizeRatio: (width * height) / (WIDTH_MEDIUM_DEFAULT * HEIGHT_MEDIUM_DEFAULT),
              resolutionRatio,
            },
            {
              LARGE_COL,
              LARGE_ROW,
              MEDIUM_COL,
              MEDIUM_ROW,
              SMALL_COL,
              SMALL_ROW,
            },
          );

        // width = 800, height = 1200, ratio = 7/6.3 > 4.5/9 && 7/6.3 < 10/9
        // medium_ratio_min = 4.5/9, medium_ratio_max = 10/9
        // width_medium_default_min = 600, width_small_default_max = 780
        // => widthBase = WIDTH_SMALL_DEFAULT_MAX, heightBase = HEIGHT_SMALL_DEFAULT, fontSizeRatio = WIDTH_SMALL_DEFAULT_MAX / WIDTH_SMALL_DEFAULT
        case width >= WIDTH_SMALL_DEFAULT_MAX && width < WIDTH_MEDIUM_DEFAULT_MIN:
          Logger.terminalInfo('getConfigFromSize', 20);
          return makeConfig(
            {
              type: TYPE_WINDOWS.SMALL.type,
              widthBase: WIDTH_SMALL_DEFAULT_MAX,
              heightBase: HEIGHT_SMALL_DEFAULT,
              fontSizeRatio: WIDTH_SMALL_DEFAULT_MAX / WIDTH_SMALL_DEFAULT,
              resolutionRatio,
            },
            {
              LARGE_COL,
              LARGE_ROW,
              MEDIUM_COL,
              MEDIUM_ROW,
              SMALL_COL,
              SMALL_ROW,
            },
          );

        // width = 300, height = 450, ratio = 300/450 > 4.5/9 && 300/450 < 10/9
        // medium_ratio_min = 4.5/9, medium_ratio_max = 10/9
        // width_small_default_min = 292.5 = 292.5, width_small_default_max = 780
        // => widthBase = width, heightBase = HEIGHT_SMALL_DEFAULT, fontSizeRatio = width / WIDTH_SMALL_DEFAULT
        case width >= WIDTH_SMALL_DEFAULT_MIN && width < WIDTH_SMALL_DEFAULT_MAX:
          Logger.terminalInfo('getConfigFromSize', 21);
          return makeConfig(
            {
              type: TYPE_WINDOWS.SMALL.type,
              widthBase: width,
              heightBase: HEIGHT_SMALL_DEFAULT,
              fontSizeRatio: width / WIDTH_SMALL_DEFAULT,
              resolutionRatio,
            },
            {
              LARGE_COL,
              LARGE_ROW,
              MEDIUM_COL,
              MEDIUM_ROW,
              SMALL_COL,
              SMALL_ROW,
            },
          );

        // width = 200, height = 300, ratio = 200/300 > 4.5/9 && 200/300 < 10/9
        // medium_ratio_min = 4.5/9, medium_ratio_max = 10/9
        // width_small_default_min = 292.5
        // => widthBase = width, heightBase = width / SMALL_RATIO, fontSizeRatio = (widthBase * heightBase)/(WIDTH_SMALL_DEFAULT * HEIGHT_SMALL_DEFAULT)
        case width < WIDTH_SMALL_DEFAULT_MIN:
          Logger.terminalInfo('getConfigFromSize', 22);
          return makeConfig(
            {
              type: TYPE_WINDOWS.SMALL.type,
              widthBase: width,
              heightBase: width / SMALL_RATIO,
              fontSizeRatio: (width * (width / SMALL_RATIO)) / (WIDTH_SMALL_DEFAULT * HEIGHT_SMALL_DEFAULT),
              resolutionRatio,
            },
            {
              LARGE_COL,
              LARGE_ROW,
              MEDIUM_COL,
              MEDIUM_ROW,
              SMALL_COL,
              SMALL_ROW,
            },
          );
        default:
          break;
      }
      break;
    case ratio >= SMALL_RATIO_MAX && ratio < MEDIUM_RATIO_MIN:
      switch (true) {
        // width = 800, height = 1028, ratio = 800/1028 > 6/9 && 800/1028 < 4.5/9
        // medium_ratio_min = 4.5/9, small_ratio_max = 6/9
        // width_small_default_max = 780
        // => widthBase = height * small_ratio_max , heightBase = height, fontSizeRatio = (widthBase * heightBase)/(WIDTH_SMALL_DEFAULT_MAX * HEIGHT_SMALL_DEFAULT)
        case width >= WIDTH_SMALL_DEFAULT_MAX:
          Logger.terminalInfo('getConfigFromSize', 23);
          return makeConfig(
            {
              type: TYPE_WINDOWS.SMALL.type,
              widthBase: height * SMALL_RATIO_MAX,
              heightBase: height,
              fontSizeRatio: (height * height * SMALL_RATIO_MAX) / (WIDTH_SMALL_DEFAULT_MAX * HEIGHT_SMALL_DEFAULT),
              resolutionRatio,
            },
            {
              LARGE_COL,
              LARGE_ROW,
              MEDIUM_COL,
              MEDIUM_ROW,
              SMALL_COL,
              SMALL_ROW,
            },
          );

        // width = 500, height = 642, ratio = 500/642 > 6/9 && 500/642 < 8/9
        // medium_ratio_min = 4.5/9, small_ratio_max = 6/9
        // width_small_default_max = 780, width_small_default_min = 292.5 = 292.5
        // => widthBase = width, heightBase = HEIGHT_SMALL_DEFAULT, fontSizeRatio = width / WIDTH_SMALL_DEFAULT
        case width >= WIDTH_SMALL_DEFAULT_MIN && width < WIDTH_SMALL_DEFAULT_MAX:
          Logger.terminalInfo('getConfigFromSize', 24);
          return makeConfig(
            {
              type: TYPE_WINDOWS.SMALL.type,
              widthBase: width,
              heightBase: HEIGHT_SMALL_DEFAULT,
              fontSizeRatio: width / WIDTH_SMALL_DEFAULT,
              resolutionRatio,
            },
            {
              LARGE_COL,
              LARGE_ROW,
              MEDIUM_COL,
              MEDIUM_ROW,
              SMALL_COL,
              SMALL_ROW,
            },
          );

        // width = 250, height = 321, ratio = 250/321 > 6/9 && 250/321 < 8/9
        // medium_ratio_min = 4.5/9, small_ratio_max = 6/9
        //  width_small_default_min = 292.5 = 292.5
        // => widthBase = width, heightBase = width /(6/9), fontSizeRatio = (widthBase * heightBase) / ( WIDTH_SMALL_DEFAULT_MIN * HEIGHT_SMALL_DEFAULT )
        case width < WIDTH_SMALL_DEFAULT_MIN:
          Logger.terminalInfo('getConfigFromSize', 25);
          return makeConfig(
            {
              type: TYPE_WINDOWS.SMALL.type,
              widthBase: width,
              heightBase: width / SMALL_RATIO_MAX,
              fontSizeRatio: (width * (width / SMALL_RATIO_MAX)) / (WIDTH_SMALL_DEFAULT_MIN * HEIGHT_SMALL_DEFAULT),
              resolutionRatio,
            },
            {
              LARGE_COL,
              LARGE_ROW,
              MEDIUM_COL,
              MEDIUM_ROW,
              SMALL_COL,
              SMALL_ROW,
            },
          );
        default:
          break;
      }
      break;
    case ratio >= SMALL_RATIO_MIN && ratio < SMALL_RATIO_MAX:
      switch (true) {
        // width = 3500, height = 6300, ratio = 3500/6300 > 3/9 && 3500/6300 < 6/9
        // small_ratio_min = 3/9, small_ratio_max = 6/9
        // width_small_default_min = 292.5 = 292.5
        // => widthBase = width, heightBase = height, fontSizeRatio = (width * height)/ (WIDTH_SMALL_DEFAULT * HEIGHT_SMALL_DEFAULT)
        case width >= WIDTH_SMALL_DEFAULT_MIN:
          Logger.terminalInfo('getConfigFromSize', 26);
          return makeConfig(
            {
              type: TYPE_WINDOWS.SMALL.type,
              widthBase: width,
              heightBase: height,
              fontSizeRatio: (width * height) / (WIDTH_SMALL_DEFAULT * HEIGHT_SMALL_DEFAULT),
              resolutionRatio,
            },
            {
              LARGE_COL,
              LARGE_ROW,
              MEDIUM_COL,
              MEDIUM_ROW,
              SMALL_COL,
              SMALL_ROW,
            },
          );

        // width = 200, height = 360, ratio = 200/360 > 3/9 && 200/360 < 6/9
        // small_ratio_min = 3/9, small_ratio_max = 6/9
        // width_small_default_min = 292.5 = 292.5
        // => widthBase = width, heightBase = width / SMALL_RATIO, fontSizeRatio = (width * height)/ (WIDTH_SMALL_DEFAULT * HEIGHT_SMALL_DEFAULT)
        case width < WIDTH_SMALL_DEFAULT_MIN:
          Logger.terminalInfo('getConfigFromSize', 27);
          return makeConfig(
            {
              type: TYPE_WINDOWS.SMALL.type,
              widthBase: width,
              heightBase: width / SMALL_RATIO,
              fontSizeRatio: (width * (width / SMALL_RATIO_MIN)) / (WIDTH_SMALL_DEFAULT * HEIGHT_SMALL_DEFAULT),
              resolutionRatio,
            },
            {
              LARGE_COL,
              LARGE_ROW,
              MEDIUM_COL,
              MEDIUM_ROW,
              SMALL_COL,
              SMALL_ROW,
            },
          );
        default:
          break;
      }
      break;
    case ratio < SMALL_RATIO_MIN:
      Logger.terminalInfo('getConfigFromSize', 28);
      // width = 1000, height = 4500, ratio = 1000/4500 < 3/9
      // small_ratio_min = 3/9
      // width_small_default_min = 292.5 = 292.5
      // => widthBase = width, heightBase = width / SMALL_RATIO_MIN, fontSizeRatio = (width * height)/ (WIDTH_SMALL_DEFAULT_MIN * HEIGHT_SMALL_DEFAULT)
      return makeConfig(
        {
          type: TYPE_WINDOWS.SMALL.type,
          widthBase: width,
          heightBase: width / SMALL_RATIO_MIN,
          fontSizeRatio: (width * (width / SMALL_RATIO_MIN)) / (WIDTH_SMALL_DEFAULT_MIN * HEIGHT_SMALL_DEFAULT),
          resolutionRatio,
        },
        {
          LARGE_COL,
          LARGE_ROW,
          MEDIUM_COL,
          MEDIUM_ROW,
          SMALL_COL,
          SMALL_ROW,
        },
      );
    default:
      break;
  }
};

export const getConfigHeaderFromSize = (
  _width,
  _height,
  resolutionRatio = 1,
  env = {
    HEIGHT_LARGE_DEFAULT,
    HEIGHT_MEDIUM_DEFAULT,
    HEIGHT_SMALL_DEFAULT,
    LARGE_RATIO_MAX,
    LARGE_RATIO_MIN,
    MEDIUM_RATIO_MAX,
    MEDIUM_RATIO_MIN,
    SMALL_RATIO,
    SMALL_RATIO_MAX,
    SMALL_RATIO_MIN,
    WIDTH_LARGE_DEFAULT,
    WIDTH_LARGE_DEFAULT_MAX,
    WIDTH_LARGE_DEFAULT_MIN,
    WIDTH_MEDIUM_DEFAULT,
    WIDTH_MEDIUM_DEFAULT_MAX,
    WIDTH_MEDIUM_DEFAULT_MIN,
    WIDTH_SMALL_DEFAULT,
    WIDTH_SMALL_DEFAULT_MAX,
    WIDTH_SMALL_DEFAULT_MIN,
    LARGE_COL,
    LARGE_ROW,
    MEDIUM_COL,
    MEDIUM_ROW,
    SMALL_COL,
    SMALL_ROW,
  },
) => {
  const width = _width / resolutionRatio;
  const height = _height / resolutionRatio;
  const ratio = width / height;
  const {
    HEIGHT_LARGE_DEFAULT,
    HEIGHT_MEDIUM_DEFAULT,
    HEIGHT_SMALL_DEFAULT,
    LARGE_RATIO_MAX,
    LARGE_RATIO_MIN,
    MEDIUM_RATIO_MAX,
    MEDIUM_RATIO_MIN,
    SMALL_RATIO,
    SMALL_RATIO_MAX,
    SMALL_RATIO_MIN,
    WIDTH_LARGE_DEFAULT,
    WIDTH_LARGE_DEFAULT_MAX,
    WIDTH_LARGE_DEFAULT_MIN,
    WIDTH_MEDIUM_DEFAULT,
    WIDTH_MEDIUM_DEFAULT_MAX,
    WIDTH_MEDIUM_DEFAULT_MIN,
    WIDTH_SMALL_DEFAULT,
    WIDTH_SMALL_DEFAULT_MAX,
    WIDTH_SMALL_DEFAULT_MIN,
    LARGE_COL,
    LARGE_ROW,
    MEDIUM_COL,
    MEDIUM_ROW,
    SMALL_COL,
    SMALL_ROW,
  } = env;
  Logger.terminalInfo('getConfigHeaderFromSize width height', width, height, env, ratio, LARGE_RATIO_MIN, LARGE_RATIO_MAX);
  switch (true) {
    case ratio >= LARGE_RATIO_MAX:
      switch (true) {
        // width = 2300, height = 1100, ratio = 2300/1100 > 18/9
        // large_ratio_max = 18/9
        // width_large_default_max = 2160, height_large_default = 1080
        // => widthBase = 2200, heightBase = 1100, fontSizeRatio = 1100/1080
        case width > WIDTH_LARGE_DEFAULT_MAX:
          Logger.terminalInfo('getConfigHeaderFromSize', 1);
          return makeConfig(
            {
              type: TYPE_WINDOWS.LARGE.type,
              widthBase: height * LARGE_RATIO_MAX,
              heightBase: height,
              fontSizeRatio: height / HEIGHT_LARGE_DEFAULT,
              resolutionRatio,
            },
            {
              LARGE_COL,
              LARGE_ROW,
              MEDIUM_COL,
              MEDIUM_ROW,
              SMALL_COL,
              SMALL_ROW,
            },
          );

        // width = 1600, height = 727, ratio = 1600/727 > 18/9
        // large_ratio_max = 18/9
        // width_large_default_min = 1000, width_large_default_max = 2160, height_large_default = 1080
        // => widthBase = 1454, heightBase = 727, fontSizeRatio = 727/1080 *  1454/1920
        case width >= WIDTH_LARGE_DEFAULT_MIN && width < WIDTH_LARGE_DEFAULT_MAX:
          Logger.terminalInfo('getConfigHeaderFromSize', 2);
          return makeConfig(
            {
              type: TYPE_WINDOWS.LARGE.type,
              widthBase: height * LARGE_RATIO_MAX,
              heightBase: height,
              fontSizeRatio: (height / HEIGHT_LARGE_DEFAULT) * ((height * LARGE_RATIO_MAX) / WIDTH_LARGE_DEFAULT_MAX),
              resolutionRatio,
            },
            {
              LARGE_COL,
              LARGE_ROW,
              MEDIUM_COL,
              MEDIUM_ROW,
              SMALL_COL,
              SMALL_ROW,
            },
          );

        case width < WIDTH_LARGE_DEFAULT_MIN:
          switch (true) {
            // width = 1400, height = 600, ratio = 1366/600 > 18/9
            // large_ratio_max = 18/9
            // width_medium_default_max = 1366, width_large_default_min = 1000, height_medium_default_max = 1180
            // => widthBase = 1366, heightBase = 1180, fontSizeRatio = 1366 / width_medium_default
            case width >= WIDTH_MEDIUM_DEFAULT_MAX:
              Logger.terminalInfo('getConfigHeaderFromSize', 3);
              return makeConfig(
                {
                  type: TYPE_WINDOWS.MEDIUM.type,
                  widthBase: height * MEDIUM_RATIO_MAX,
                  heightBase: height,
                  fontSizeRatio: WIDTH_MEDIUM_DEFAULT_MAX / WIDTH_MEDIUM_DEFAULT,
                  resolutionRatio,
                },
                {
                  LARGE_COL,
                  LARGE_ROW,
                  MEDIUM_COL,
                  MEDIUM_ROW,
                  SMALL_COL,
                  SMALL_ROW,
                },
              );

            // width = 800, height = 300, ratio = 800/300 > 18/9
            // large_ratio_max = 18/9
            // width_medium_default_min = 600, width_medium_default_max = 1366
            // => widthBase = 800, heightBase = 1080, fontSizeRatio = 800 / width_medium_default
            case width >= WIDTH_MEDIUM_DEFAULT_MIN && width < WIDTH_MEDIUM_DEFAULT_MAX:
              Logger.terminalInfo('getConfigHeaderFromSize', 4);
              return makeConfig(
                {
                  type: TYPE_WINDOWS.MEDIUM.type,
                  widthBase: width,
                  heightBase: height,
                  fontSizeRatio: width / WIDTH_MEDIUM_DEFAULT,
                  resolutionRatio,
                },
                {
                  LARGE_COL,
                  LARGE_ROW,
                  MEDIUM_COL,
                  MEDIUM_ROW,
                  SMALL_COL,
                  SMALL_ROW,
                },
              );

            // width = 800, height = 300, ratio = 780/300 > 18/9
            // large_ratio_max = 18/9
            // width_small_default_max = 780, width_medium_default_min = 600
            // => widthBase = 780, heightBase = 844, fontSizeRatio = 780 / width_small_default
            case width >= WIDTH_SMALL_DEFAULT_MAX && width < WIDTH_MEDIUM_DEFAULT_MIN:
              Logger.terminalInfo('getConfigHeaderFromSize', 5);
              return makeConfig(
                {
                  type: TYPE_WINDOWS.SMALL.type,
                  widthBase: height * SMALL_RATIO_MAX,
                  heightBase: height,
                  fontSizeRatio: WIDTH_SMALL_DEFAULT_MAX / WIDTH_SMALL_DEFAULT,
                  resolutionRatio,
                },
                {
                  LARGE_COL,
                  LARGE_ROW,
                  MEDIUM_COL,
                  MEDIUM_ROW,
                  SMALL_COL,
                  SMALL_ROW,
                },
              );

            // width = 300 height = 100, ratio = 300/100 > 18/9
            // large_ratio_max = 18/9
            // width_small_default_max = 780, width_small_default_min = 292.5 = 292.5
            // => widthBase = 300, heightBase = 844, fontSizeRatio = 300 / width_small_default
            case width >= WIDTH_SMALL_DEFAULT_MIN && width < WIDTH_SMALL_DEFAULT_MAX:
              Logger.terminalInfo('getConfigHeaderFromSize', 6);
              return makeConfig(
                {
                  type: TYPE_WINDOWS.SMALL.type,
                  widthBase: width,
                  heightBase: height,
                  fontSizeRatio: width / WIDTH_SMALL_DEFAULT,
                  resolutionRatio,
                },
                {
                  LARGE_COL,
                  LARGE_ROW,
                  MEDIUM_COL,
                  MEDIUM_ROW,
                  SMALL_COL,
                  SMALL_ROW,
                },
              );

            // width = 270, height = 120, ratio = 270/120 > 18/9
            // large_ratio_max = 18/9
            // width_small_default_min = 292.5, height_small_default = 844
            // => widthBase = 270, heightBase = 270 / small_ratio, fontSizeRatio = (270 * (270 / small_ratio)) / (width_small_default * height_small_default)
            case width < WIDTH_SMALL_DEFAULT_MIN:
              Logger.terminalInfo('getConfigHeaderFromSize', 7);
              return makeConfig(
                {
                  type: TYPE_WINDOWS.SMALL.type,
                  widthBase: width,
                  heightBase: height,
                  fontSizeRatio: (width * (width / SMALL_RATIO)) / (WIDTH_SMALL_DEFAULT * HEIGHT_SMALL_DEFAULT),
                  resolutionRatio,
                },
                {
                  LARGE_COL,
                  LARGE_ROW,
                  MEDIUM_COL,
                  MEDIUM_ROW,
                  SMALL_COL,
                  SMALL_ROW,
                },
              );
            default:
              break;
          }
          break;
        default:
          break;
      }
      break;

    case ratio >= LARGE_RATIO_MIN && ratio < LARGE_RATIO_MAX:
      switch (true) {
        // width = 1200, height = 700, ratio = 1200/700 > 12/9 && 1200/700 < 18/9
        // large_ratio_min = 12/9, large_ratio_max = 18/9
        // width_large_default_min = 1000
        // => widthBase = 1200, heightBase = 700, fontSizeRatio = ( width * height ) / ( width_large_default * height_large_default )
        case width >= WIDTH_LARGE_DEFAULT_MIN:
          Logger.terminalInfo('getConfigHeaderFromSize', 8);
          return makeConfig(
            {
              type: TYPE_WINDOWS.LARGE.type,
              widthBase: width,
              heightBase: height,
              fontSizeRatio: (width * height) / (HEIGHT_LARGE_DEFAULT * WIDTH_LARGE_DEFAULT),
              resolutionRatio,
            },
            {
              LARGE_COL,
              LARGE_ROW,
              MEDIUM_COL,
              MEDIUM_ROW,
              SMALL_COL,
              SMALL_ROW,
            },
          );

        case width < WIDTH_LARGE_DEFAULT_MIN:
          switch (true) {
            // width = 1400, height = 800, ratio = 1400/800 > 12/9 && 1400/800 < 18/9
            // large_ratio_min = 12/9, large_ratio_max = 18/9
            // width_large_default_min = 1000, width_medium_default_max = 1366
            // => widthBase = 1366, heightBase = 1180, fontSizeRatio = 1366/820
            case width >= WIDTH_MEDIUM_DEFAULT_MAX:
              Logger.terminalInfo('getConfigHeaderFromSize', 9);
              return makeConfig(
                {
                  type: TYPE_WINDOWS.MEDIUM.type,
                  widthBase: height * MEDIUM_RATIO_MAX,
                  heightBase: height,
                  fontSizeRatio: WIDTH_MEDIUM_DEFAULT_MAX / WIDTH_MEDIUM_DEFAULT,
                  resolutionRatio,
                },
                {
                  LARGE_COL,
                  LARGE_ROW,
                  MEDIUM_COL,
                  MEDIUM_ROW,
                  SMALL_COL,
                  SMALL_ROW,
                },
              );

            // width = 900, height = 540, ratio = 900/540 > 12/9 && 900/540 < 18/9
            // large_ratio_min = 12/9, large_ratio_max = 18/9
            // width_medium_default_min = 600, width_medium_default_max = 1366, width_large_default_min = 1000
            // => widthBase = 900, heightBase = 1180, fontSizeRatio = (900/820)
            case width >= WIDTH_MEDIUM_DEFAULT_MIN && width < WIDTH_MEDIUM_DEFAULT_MAX:
              Logger.terminalInfo('getConfigHeaderFromSize', 10);
              return makeConfig(
                {
                  type: TYPE_WINDOWS.MEDIUM.type,
                  widthBase: width,
                  heightBase: height,
                  fontSizeRatio: width / WIDTH_MEDIUM_DEFAULT,
                  resolutionRatio,
                },
                {
                  LARGE_COL,
                  LARGE_ROW,
                  MEDIUM_COL,
                  MEDIUM_ROW,
                  SMALL_COL,
                  SMALL_ROW,
                },
              );

            // width = 700, height = 420, ratio = 700/420 > 12/9 && 700/420 < 18/9
            // large_ratio_min = 12/9, large_ratio_max = 18/9
            // width_small_default_max = 780, width_medium_default_min = 600, width_large_default_min = 1000
            // => widthBase = 780, heightBase = 844, fontSizeRatio = 780/390
            case width >= WIDTH_SMALL_DEFAULT_MAX && width < WIDTH_MEDIUM_DEFAULT_MIN:
              Logger.terminalInfo('getConfigHeaderFromSize', 11);
              return makeConfig(
                {
                  type: TYPE_WINDOWS.SMALL.type,
                  widthBase: height * SMALL_RATIO_MAX,
                  heightBase: height,
                  fontSizeRatio: WIDTH_SMALL_DEFAULT_MAX / WIDTH_SMALL_DEFAULT,
                  resolutionRatio,
                },
                {
                  LARGE_COL,
                  LARGE_ROW,
                  MEDIUM_COL,
                  MEDIUM_ROW,
                  SMALL_COL,
                  SMALL_ROW,
                },
              );

            // width = 360, height = 216, ratio = 360/216 > 12/9 && 360/216 < 18/9
            // large_ratio_min = 12/9, large_ratio_max = 18/9
            // width_small_default_max = 780, width_small_default_min = 292.5, width_large_default_min = 1000
            // => widthBase = 360, heightBase = 844, fontSizeRatio = 360 / 292.5
            case width >= WIDTH_SMALL_DEFAULT_MIN && width < WIDTH_SMALL_DEFAULT_MAX:
              Logger.terminalInfo('getConfigHeaderFromSize', 12);
              return makeConfig(
                {
                  type: TYPE_WINDOWS.SMALL.type,
                  widthBase: width,
                  heightBase: height,
                  fontSizeRatio: width / WIDTH_SMALL_DEFAULT,
                  resolutionRatio,
                },
                {
                  LARGE_COL,
                  LARGE_ROW,
                  MEDIUM_COL,
                  MEDIUM_ROW,
                  SMALL_COL,
                  SMALL_ROW,
                },
              );

            // width = 270, height = 162, ratio = 270/162 > 12/9 && 270/162 < 18/9
            // large_ratio_min = 12/9, large_ratio_max = 18/9
            // width_small_default_min = 292.5, width_large_default_min = 1000
            // => widthBase = 270, heightBase = 270/(4/9), fontSizeRatio = widthBase * heightBase / (WIDTH_SMALL_DEFAULT_MIN * HEIGHT_SMALL_DEFAULT_MIN)
            case width < WIDTH_SMALL_DEFAULT_MIN:
              Logger.terminalInfo('getConfigHeaderFromSize', 13);
              return makeConfig(
                {
                  type: TYPE_WINDOWS.SMALL.type,
                  widthBase: width,
                  heightBase: height,
                  fontSizeRatio: (width * width) / SMALL_RATIO / (HEIGHT_SMALL_DEFAULT * WIDTH_SMALL_DEFAULT),
                  resolutionRatio,
                },
                {
                  LARGE_COL,
                  LARGE_ROW,
                  MEDIUM_COL,
                  MEDIUM_ROW,
                  SMALL_COL,
                  SMALL_ROW,
                },
              );
            default:
              break;
          }
          break;

        default:
          break;
      }
      break;

    case ratio >= MEDIUM_RATIO_MAX && ratio < LARGE_RATIO_MIN:
      switch (true) {
        // width = 1600, height = 1310, ratio = 1600/1310 > 10/9 && 1600/1310 < 12/9
        // large_ratio_min = 12/9, medium_ratio_max = 10/9
        // width_medium_default_max = 1366
        // => widthBase = height * medium_ratio_max, heightBase = height, fontSizeRatio = widthBase * heightBase / (WIDTH_MEDIUM_DEFAULT_MAX * HEIGHT_MEDIUM_DEFAULT_MAX)
        case width >= WIDTH_MEDIUM_DEFAULT_MAX:
          Logger.terminalInfo('getConfigHeaderFromSize', 14);
          return makeConfig(
            {
              type: TYPE_WINDOWS.MEDIUM.type,
              widthBase: height * MEDIUM_RATIO_MAX,
              heightBase: height,
              fontSizeRatio: (height * height * MEDIUM_RATIO_MAX) / (HEIGHT_MEDIUM_DEFAULT * WIDTH_LARGE_DEFAULT_MAX),
              resolutionRatio,
            },
            {
              LARGE_COL,
              LARGE_ROW,
              MEDIUM_COL,
              MEDIUM_ROW,
              SMALL_COL,
              SMALL_ROW,
            },
          );

        // width = 1000, height = 818, ratio = 1000/818 > 10/9 && 1000/818 < 12/9
        // large_ratio_min = 12/9, medium_ratio_max = 10/9
        // width_medium_default_min = 600, width_medium_default_max = 1366
        // => widthBase = width, heightBase = HEIGHT_MEDIUM_DEFAULT, fontSizeRatio = width / WIDTH_MEDIUM_DEFAULT
        case width >= WIDTH_MEDIUM_DEFAULT_MIN && width < WIDTH_MEDIUM_DEFAULT_MAX:
          Logger.terminalInfo('getConfigHeaderFromSize', 15);
          return makeConfig(
            {
              type: TYPE_WINDOWS.MEDIUM.type,
              widthBase: width,
              heightBase: height,
              fontSizeRatio: width / WIDTH_MEDIUM_DEFAULT,
              resolutionRatio,
            },
            {
              LARGE_COL,
              LARGE_ROW,
              MEDIUM_COL,
              MEDIUM_ROW,
              SMALL_COL,
              SMALL_ROW,
            },
          );

        // width = 800, height = 654, ratio = 800/654 > 10/9 && 800/654 < 12/9
        // large_ratio_min = 12/9, medium_ratio_max = 10/9
        // width_small_default_max = 780, width_medium_default_min = 600
        // => widthBase = WIDTH_SMALL_DEFAULT_MAX, heightBase = HEIGHT_SMALL_DEFAULT, fontSizeRatio = WIDTH_SMALL_DEFAULT_MAX / WIDTH_SMALL_DEFAULT
        case width >= WIDTH_SMALL_DEFAULT_MAX && width < WIDTH_MEDIUM_DEFAULT_MIN:
          Logger.terminalInfo('getConfigHeaderFromSize', 16);
          return makeConfig(
            {
              type: TYPE_WINDOWS.SMALL.type,
              widthBase: height * SMALL_RATIO_MAX,
              heightBase: height,
              fontSizeRatio: WIDTH_SMALL_DEFAULT_MAX / WIDTH_SMALL_DEFAULT,
              resolutionRatio,
            },
            {
              LARGE_COL,
              LARGE_ROW,
              MEDIUM_COL,
              MEDIUM_ROW,
              SMALL_COL,
              SMALL_ROW,
            },
          );

        // width = 300, height = 245, ratio = 300/245 > 10/9 && 300/245 < 12/9
        // large_ratio_min = 12/9, medium_ratio_max = 10/9
        // width_small_default_max = 780, width_small_default_min = 292.5
        // => widthBase = width, heightBase = HEIGHT_SMALL_DEFAULT, fontSizeRatio = width / WIDTH_SMALL_DEFAULT
        case width >= WIDTH_SMALL_DEFAULT_MIN && width < WIDTH_SMALL_DEFAULT_MAX:
          Logger.terminalInfo('getConfigHeaderFromSize', 17);
          return makeConfig(
            {
              type: TYPE_WINDOWS.SMALL.type,
              widthBase: width,
              heightBase: height,
              fontSizeRatio: width / WIDTH_SMALL_DEFAULT,
              resolutionRatio,
            },
            {
              LARGE_COL,
              LARGE_ROW,
              MEDIUM_COL,
              MEDIUM_ROW,
              SMALL_COL,
              SMALL_ROW,
            },
          );

        // width = 250, height = 205, ratio = 250/205 > 11/9 && 250/205 < 14/9
        // large_ratio_min = 12/9, medium_ratio_max = 10/9
        // width_small_default_min = 292.5
        // => widthBase = width, heightBase = width * SMALL_RATIO, fontSizeRatio = width * height / HEIGHT_SMALL_DEFAULT * WIDTH_SMALL_DEFAULT
        case width < WIDTH_SMALL_DEFAULT_MIN:
          Logger.terminalInfo('getConfigHeaderFromSize', 18);
          return makeConfig(
            {
              type: TYPE_WINDOWS.SMALL.type,
              widthBase: width,
              heightBase: height,
              fontSizeRatio: (width * width) / SMALL_RATIO / (HEIGHT_SMALL_DEFAULT * WIDTH_SMALL_DEFAULT),
              resolutionRatio,
            },
            {
              LARGE_COL,
              LARGE_ROW,
              MEDIUM_COL,
              MEDIUM_ROW,
              SMALL_COL,
              SMALL_ROW,
            },
          );
        default:
          break;
      }
      break;

    case ratio >= MEDIUM_RATIO_MIN && ratio < MEDIUM_RATIO_MAX:
      switch (true) {
        // width = 700, height = 1050, ratio = 700/1050 > 4.5/9 && 700/1050 < 10/9
        // medium_ratio_min = 4.5/9, medium_ratio_max = 10/9
        // width_medium_default_min = 600
        // => widthBase = width, heightBase = height, fontSizeRatio = width * height / WIDTH_MEDIUM_DEFAULT * HEIGHT_MEDIUM_DEFAULT
        case width >= WIDTH_MEDIUM_DEFAULT_MIN:
          Logger.terminalInfo('getConfigHeaderFromSize', 19);
          return makeConfig(
            {
              type: TYPE_WINDOWS.MEDIUM.type,
              widthBase: width,
              heightBase: height,
              fontSizeRatio: (width * height) / (WIDTH_MEDIUM_DEFAULT * HEIGHT_MEDIUM_DEFAULT),
              resolutionRatio,
            },
            {
              LARGE_COL,
              LARGE_ROW,
              MEDIUM_COL,
              MEDIUM_ROW,
              SMALL_COL,
              SMALL_ROW,
            },
          );

        // width = 800, height = 1200, ratio = 7/6.3 > 4.5/9 && 7/6.3 < 10/9
        // medium_ratio_min = 4.5/9, medium_ratio_max = 10/9
        // width_medium_default_min = 600, width_small_default_max = 780
        // => widthBase = WIDTH_SMALL_DEFAULT_MAX, heightBase = HEIGHT_SMALL_DEFAULT, fontSizeRatio = WIDTH_SMALL_DEFAULT_MAX / WIDTH_SMALL_DEFAULT
        case width >= WIDTH_SMALL_DEFAULT_MAX && width < WIDTH_MEDIUM_DEFAULT_MIN:
          Logger.terminalInfo('getConfigHeaderFromSize', 20);
          return makeConfig(
            {
              type: TYPE_WINDOWS.SMALL.type,
              widthBase: height * SMALL_RATIO_MAX,
              heightBase: height,
              fontSizeRatio: WIDTH_SMALL_DEFAULT_MAX / WIDTH_SMALL_DEFAULT,
              resolutionRatio,
            },
            {
              LARGE_COL,
              LARGE_ROW,
              MEDIUM_COL,
              MEDIUM_ROW,
              SMALL_COL,
              SMALL_ROW,
            },
          );

        // width = 300, height = 450, ratio = 300/450 > 4.5/9 && 300/450 < 10/9
        // medium_ratio_min = 4.5/9, medium_ratio_max = 10/9
        // width_small_default_min = 292.5 = 292.5, width_small_default_max = 780
        // => widthBase = width, heightBase = HEIGHT_SMALL_DEFAULT, fontSizeRatio = width / WIDTH_SMALL_DEFAULT
        case width >= WIDTH_SMALL_DEFAULT_MIN && width < WIDTH_SMALL_DEFAULT_MAX:
          Logger.terminalInfo('getConfigHeaderFromSize', 21);
          return makeConfig(
            {
              type: TYPE_WINDOWS.SMALL.type,
              widthBase: width,
              heightBase: height,
              fontSizeRatio: width / WIDTH_SMALL_DEFAULT,
              resolutionRatio,
            },
            {
              LARGE_COL,
              LARGE_ROW,
              MEDIUM_COL,
              MEDIUM_ROW,
              SMALL_COL,
              SMALL_ROW,
            },
          );

        // width = 200, height = 300, ratio = 200/300 > 4.5/9 && 200/300 < 10/9
        // medium_ratio_min = 4.5/9, medium_ratio_max = 10/9
        // width_small_default_min = 292.5
        // => widthBase = width, heightBase = width / SMALL_RATIO, fontSizeRatio = (widthBase * heightBase)/(WIDTH_SMALL_DEFAULT * HEIGHT_SMALL_DEFAULT)
        case width < WIDTH_SMALL_DEFAULT_MIN:
          Logger.terminalInfo('getConfigHeaderFromSize', 22);
          return makeConfig(
            {
              type: TYPE_WINDOWS.SMALL.type,
              widthBase: width,
              heightBase: height,
              fontSizeRatio: (width * (width / SMALL_RATIO)) / (WIDTH_SMALL_DEFAULT * HEIGHT_SMALL_DEFAULT),
              resolutionRatio,
            },
            {
              LARGE_COL,
              LARGE_ROW,
              MEDIUM_COL,
              MEDIUM_ROW,
              SMALL_COL,
              SMALL_ROW,
            },
          );
        default:
          break;
      }
      break;
    case ratio >= SMALL_RATIO_MAX && ratio < MEDIUM_RATIO_MIN:
      switch (true) {
        // width = 800, height = 1028, ratio = 800/1028 > 6/9 && 800/1028 < 4.5/9
        // medium_ratio_min = 4.5/9, small_ratio_max = 6/9
        // width_small_default_max = 780
        // => widthBase = height * small_ratio_max , heightBase = height, fontSizeRatio = (widthBase * heightBase)/(WIDTH_SMALL_DEFAULT_MAX * HEIGHT_SMALL_DEFAULT)
        case width >= WIDTH_SMALL_DEFAULT_MAX:
          Logger.terminalInfo('getConfigHeaderFromSize', 23);
          return makeConfig(
            {
              type: TYPE_WINDOWS.SMALL.type,
              widthBase: height * SMALL_RATIO_MAX,
              heightBase: height,
              fontSizeRatio: (height * height * SMALL_RATIO_MAX) / (WIDTH_SMALL_DEFAULT_MAX * HEIGHT_SMALL_DEFAULT),
              resolutionRatio,
            },
            {
              LARGE_COL,
              LARGE_ROW,
              MEDIUM_COL,
              MEDIUM_ROW,
              SMALL_COL,
              SMALL_ROW,
            },
          );

        // width = 500, height = 642, ratio = 500/642 > 6/9 && 500/642 < 8/9
        // medium_ratio_min = 4.5/9, small_ratio_max = 6/9
        // width_small_default_max = 780, width_small_default_min = 292.5 = 292.5
        // => widthBase = width, heightBase = HEIGHT_SMALL_DEFAULT, fontSizeRatio = width / WIDTH_SMALL_DEFAULT
        case width >= WIDTH_SMALL_DEFAULT_MIN && width < WIDTH_SMALL_DEFAULT_MAX:
          Logger.terminalInfo('getConfigHeaderFromSize', 24);
          return makeConfig(
            {
              type: TYPE_WINDOWS.SMALL.type,
              widthBase: width,
              heightBase: height,
              fontSizeRatio: width / WIDTH_SMALL_DEFAULT,
              resolutionRatio,
            },
            {
              LARGE_COL,
              LARGE_ROW,
              MEDIUM_COL,
              MEDIUM_ROW,
              SMALL_COL,
              SMALL_ROW,
            },
          );

        // width = 250, height = 321, ratio = 250/321 > 6/9 && 250/321 < 8/9
        // medium_ratio_min = 4.5/9, small_ratio_max = 6/9
        //  width_small_default_min = 292.5 = 292.5
        // => widthBase = width, heightBase = width /(6/9), fontSizeRatio = (widthBase * heightBase) / ( WIDTH_SMALL_DEFAULT_MIN * HEIGHT_SMALL_DEFAULT )
        case width < WIDTH_SMALL_DEFAULT_MIN:
          Logger.terminalInfo('getConfigHeaderFromSize', 25);
          return makeConfig(
            {
              type: TYPE_WINDOWS.SMALL.type,
              widthBase: width,
              heightBase: height,
              fontSizeRatio: (width * (width / SMALL_RATIO_MAX)) / (WIDTH_SMALL_DEFAULT_MIN * HEIGHT_SMALL_DEFAULT),
              resolutionRatio,
            },
            {
              LARGE_COL,
              LARGE_ROW,
              MEDIUM_COL,
              MEDIUM_ROW,
              SMALL_COL,
              SMALL_ROW,
            },
          );
        default:
          break;
      }
      break;
    case ratio >= SMALL_RATIO_MIN && ratio < SMALL_RATIO_MAX:
      switch (true) {
        // width = 3500, height = 6300, ratio = 3500/6300 > 3/9 && 3500/6300 < 6/9
        // small_ratio_min = 3/9, small_ratio_max = 6/9
        // width_small_default_min = 292.5 = 292.5
        // => widthBase = width, heightBase = height, fontSizeRatio = (width * height)/ (WIDTH_SMALL_DEFAULT * HEIGHT_SMALL_DEFAULT)
        case width >= WIDTH_SMALL_DEFAULT_MIN:
          Logger.terminalInfo('getConfigHeaderFromSize', 26);
          return makeConfig(
            {
              type: TYPE_WINDOWS.SMALL.type,
              widthBase: width,
              heightBase: height,
              fontSizeRatio: (width * height) / (WIDTH_SMALL_DEFAULT * HEIGHT_SMALL_DEFAULT),
              resolutionRatio,
            },
            {
              LARGE_COL,
              LARGE_ROW,
              MEDIUM_COL,
              MEDIUM_ROW,
              SMALL_COL,
              SMALL_ROW,
            },
          );

        // width = 200, height = 360, ratio = 200/360 > 3/9 && 200/360 < 6/9
        // small_ratio_min = 3/9, small_ratio_max = 6/9
        // width_small_default_min = 292.5 = 292.5
        // => widthBase = width, heightBase = width / SMALL_RATIO, fontSizeRatio = (width * height)/ (WIDTH_SMALL_DEFAULT * HEIGHT_SMALL_DEFAULT)
        case width < WIDTH_SMALL_DEFAULT_MIN:
          Logger.terminalInfo('getConfigHeaderFromSize', 27);
          return makeConfig(
            {
              type: TYPE_WINDOWS.SMALL.type,
              widthBase: width,
              heightBase: height,
              fontSizeRatio: (width * (width / SMALL_RATIO_MIN)) / (WIDTH_SMALL_DEFAULT * HEIGHT_SMALL_DEFAULT),
              resolutionRatio,
            },
            {
              LARGE_COL,
              LARGE_ROW,
              MEDIUM_COL,
              MEDIUM_ROW,
              SMALL_COL,
              SMALL_ROW,
            },
          );
        default:
          break;
      }
      break;
    case ratio < SMALL_RATIO_MIN:
      Logger.terminalInfo('getConfigHeaderFromSize', 28);
      // width = 1000, height = 4500, ratio = 1000/4500 < 3/9
      // small_ratio_min = 3/9
      // width_small_default_min = 292.5 = 292.5
      // => widthBase = width, heightBase = width / SMALL_RATIO_MIN, fontSizeRatio = (width * height)/ (WIDTH_SMALL_DEFAULT_MIN * HEIGHT_SMALL_DEFAULT)
      return makeConfig(
        {
          type: TYPE_WINDOWS.SMALL.type,
          widthBase: width,
          heightBase: height,
          fontSizeRatio: (width * (width / SMALL_RATIO_MIN)) / (WIDTH_SMALL_DEFAULT_MIN * HEIGHT_SMALL_DEFAULT),
          resolutionRatio,
        },
        {
          LARGE_COL,
          LARGE_ROW,
          MEDIUM_COL,
          MEDIUM_ROW,
          SMALL_COL,
          SMALL_ROW,
        },
      );
    default:
      break;
  }
};
