import React, { Component } from 'react';

class File extends Component {
  renderIcon = () => {
    const { icon } = this.props.file;
    switch (icon) {
      case 'json':
        return (
          <img
            src={require('../Resource/Image/json.png')}
            alt='json'
            style={{
              width: '18px',
              height: '18px',
              contain: 'content',
            }}
          />
        );

      default:
        return (
          <img
            src={require('../Resource/Image/json.png')}
            alt='json'
            style={{
              width: '18px',
              height: '18px',
              contain: 'content',
            }}
          />
        );
    }
  };

  render() {
    const { name, select } = this.props.file;
    const { closeFile, setSelectFile } = this.props;
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          borderBottomWidth: '1px',
          borderBottomColor: 'yellow',
          borderBottomStyle: select ? 'solid' : 'none',
          paddingLeft: '15px',
          paddingRight: '15px',
          cursor: 'pointer',
        }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => setSelectFile(this.props.file)}>
          {this.renderIcon()}
          <div
            style={{
              fontSize: '14px',
              fontFamily: `"Droid Sans Mono", "monospace", monospace`,
              color: 'rgb(151 151 151)',
              marginLeft: '8px',
            }}>
            {name}
          </div>
        </div>
        <button
          onClick={() => closeFile(this.props.file)}
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            position: 'relative',
            top: '-2px',
          }}>
          <img
            src={require('../Resource/Image/close.png')}
            alt='json'
            style={{
              width: '14px',
              height: '14px',
              contain: 'content',
              borderRadius: '2px',
            }}
            onMouseOver={(event) => {
              event.target.style.backgroundColor = '#363634';
            }}
            onMouseOut={(event) => (event.target.style.backgroundColor = 'transparent')}
          />
        </button>
      </div>
    );
  }
}

class More extends Component {
  render() {
    return (
      <img
        src={require('../Resource/Image/more.png')}
        alt='more'
        style={{
          width: '25px',
          height: '25px',
          contain: 'content',
          borderRadius: '6px',
          padding: '5px',
        }}
        onMouseOver={(event) => {
          event.target.style.backgroundColor = '#363634';
        }}
        onMouseOut={(event) => (event.target.style.backgroundColor = 'transparent')}
        onClick={(event) => {}}
      />
    );
  }
}

class Upload extends Component {
  render() {
    return (
      <img
        src={require('../Resource/Image/upload-show.png')}
        alt='more'
        style={{
          width: '25px',
          height: '25px',
          contain: 'content',
          borderRadius: '6px',
          padding: '5px',
        }}
        onMouseOver={(event) => {
          event.target.style.backgroundColor = '#363634';
        }}
        onMouseOut={(event) => (event.target.style.backgroundColor = 'transparent')}
        onClick={(event) => {}}
      />
    );
  }
}

class Preview extends Component {
  render() {
    return (
      <img
        src={require('../Resource/Image/eye.png')}
        alt='more'
        style={{
          width: '25px',
          height: '25px',
          contain: 'content',
          borderRadius: '6px',
          padding: '5px',
        }}
        onMouseOver={(event) => {
          event.target.style.backgroundColor = '#363634';
        }}
        onMouseOut={(event) => (event.target.style.backgroundColor = 'transparent')}
        onClick={this.props.openPreview}
      />
    );
  }
}

class Code extends Component {
  render() {
    return (
      <img
        src={require('../Resource/Image/code.png')}
        alt='more'
        style={{
          width: '25px',
          height: '25px',
          contain: 'content',
          borderRadius: '6px',
          padding: '5px',
        }}
        onMouseOver={(event) => {
          event.target.style.backgroundColor = '#363634';
        }}
        onMouseOut={(event) => (event.target.style.backgroundColor = 'transparent')}
        onClick={this.props.openCode}
      />
    );
  }
}

class OpenNewTab extends Component {
  render() {
    return (
      <img
        src={require('../Resource/Image/screen.png')}
        alt='more'
        style={{
          width: '25px',
          height: '25px',
          contain: 'content',
          borderRadius: '6px',
          padding: '5px',
        }}
        onMouseOver={(event) => {
          event.target.style.backgroundColor = '#363634';
        }}
        onMouseOut={(event) => (event.target.style.backgroundColor = 'transparent')}
        onClick={this.props.openPreviewInNewTab}
      />
    );
  }
}

class ListOptions extends Component {
  render() {
    const { isPreview, openPreview, openCode, openPreviewInNewTab } = this.props;

    return (
      <div
        style={{
          position: 'absolute',
          right: '10px',
          top: '0px',
          cursor: 'pointer',
          padding: '5px 5px',
        }}>
        <OpenNewTab openPreviewInNewTab={openPreviewInNewTab} />
        {!isPreview ? <Preview openPreview={openPreview} /> : <Code openCode={openCode} />}
        <Upload />
        <More />
      </div>
    );
  }
}

export default class Files extends Component {
  render() {
    const files = this.props.openedFiles || [];
    const { setSelectFile, closeFile, isPreview, openPreview, openCode, openPreviewInNewTab } = this.props;
    return (
      <div
        style={{
          height: '35px',
          width: '100%',
          backgroundColor: '#1e1e1e',
          display: 'flex',
        }}>
        {files.map((file) => {
          return <File file={file} closeFile={closeFile} setSelectFile={setSelectFile} />;
        })}
        <ListOptions isPreview={isPreview} openPreview={openPreview} openCode={openCode} openPreviewInNewTab={openPreviewInNewTab} />
      </div>
    );
  }
}
