import { Family } from '../Core/Constant/DeviceResource';
import { MEMBER_ROLE } from './constants/general';
import _ from 'lodash';
import { isSubDevice } from '../Core/Helper/device';
import { getDeviceFamilyByDeviceType } from '../Core/Helper/deviceresource';

export const getDisplayRoleText = (role, lang) => {
    if (role === MEMBER_ROLE.OWNER) {
        return lang.owner;
    }
    if (role === MEMBER_ROLE.MEMBER) {
        return lang.member;
    }
    if (role === MEMBER_ROLE.WAITING) {
        return lang.waitingToAccept;
    }
    return 'unknown';
};

export const ratioToColorTemp = (_ratio, _min, _max) => {
    const ratio = _ratio ?? 0.5;
    const min = _min ?? 2700;
    const max = _max ?? 6500;
    return Math.abs(Math.round(min + ratio * (max - min)));
};

export const colorTempToRatio = (_temp, _min, _max) => {
    const temp = _temp ?? 0.5;
    const min = _min ?? 2700;
    const max = _max ?? 6500;
    return (temp - min) / (max - min);
};

export const checkAllActionsOnOff = (actions) => {
    if (Array.isArray(actions)) {
        if (actions.every((action) => action === true)) {
            return { result: true, value: true };
        }
        if (actions.every((action) => action === false)) {
            return { result: true, value: false };
        }
        return { result: false };
    }
    return { result: false };
};

// NOTE: will change when use device with attribute.
export const defaultStateByDeviceType = (entity) => {
    if (entity && entity?.originId) {
        let deviceType;
        if (isSubDevice(entity?.originId)) {
            deviceType = entity?.originId.split('_')[3].toString();
        } else {
            deviceType = entity?.originId.split('_')[1].toString();
        }

        const deviceFamily = getDeviceFamilyByDeviceType(deviceType);

        switch (deviceFamily) {
            case Family.air_conditioning:
                return { on_off: true };
            case Family.smart_electric_meter:
                return { on_off: true };

            default:
                return { on_off: true };
        }
    }
    return { on_off: true };
};

// NOTE: will change when use device with attribute.
export const filterOutputDevice = (entity) => {
    if (entity && entity?.originId) {
        let deviceType;
        if (isSubDevice(entity?.originId)) {
            deviceType = entity?.originId.split('_')[3].toString();
        } else {
            deviceType = entity?.originId.split('_')[1].toString();
        }

        const deviceFamily = getDeviceFamilyByDeviceType(deviceType);
        if ([Family.water_purifier].includes(deviceFamily)) {
            return false;
        }

        return true;
    }
    return false;
};

export const time2String = (time) => {
    const date = new Date(time);

    let d = date.getDate();
    let m = date.getMonth() + 1;
    let y = date.getFullYear();

    let h = date.getHours();
    let min = date.getMinutes();
    let s = date.getSeconds();

    d = d <= 9 ? '0' + d : d;
    m = m <= 9 ? '0' + m : m;
    h = h <= 9 ? '0' + h : h;
    min = min <= 9 ? '0' + min : min;
    s = s <= 9 ? '0' + s : s;

    return d + '/' + m + '/' + y + ' - ' + h + ':' + min + ':' + s;
};

export function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
