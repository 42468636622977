const externalData = {
    lang: {
        en: {
            buttonSetting: 'Setting Button',
            timer: 'Timer',
            selectWifi: 'Update wifi',
            meterReadingDate: 'Closing date',
            adaptiveTemperature: 'Adaptive temperature',
            relaySetting: 'Setting Relay',
            warning: 'Warning',
            updateFirmware: 'Firmware update',
            maintainStatus: 'Maintain status',
            alwaysOn: 'Always on',
            alwaysOff: 'Always off',
            startRelayStatus: 'Relay starting status',
            enableToggling: 'Enable toggling',
            onlyOff: 'Only off',
            timerhcl: 'Adaptive temperature',
            tds: 'Total dissolved Solids',
            water_in: 'Water supply status',
            filter: 'Filter',
        },
        vi: {
            buttonSetting: 'Cài đặt nút nhấn',
            timer: 'Hẹn giờ',
            meterReadingDate: 'Ngày chốt số điện',
            adaptiveTemperature: 'Nhiệt độ thích ứng',
            relaySetting: 'Cài đặt relay',
            warning: 'Cảnh báo',
            updateFirmware: 'Cập nhật firmware',
            buttonSetting1: 'Cài đặt nút nhấn 1',
            maintainStatus: 'Giữ nguyên trạng thái',
            alwaysOn: 'Luôn bật',
            alwaysOff: 'Luôn tắt',
            startRelayStatus: 'Trạng thái relay khởi động',
            enableToggling: 'Cho phép bật tắt',
            onlyOff: 'Chỉ tắt',
            timerhcl: 'Nhiệt độ thích ứng',
            tds: 'Chỉ số tds',
            water_in: 'Trạng thái nước cấp',
            filter: 'Lõi lọc',
        },
    },
    theme: {},
};

export default externalData;
