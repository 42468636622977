import React, { Component } from 'react';
import EditArea from './EditArea';
import CurrentFiles from './Files';
import PathFile from './PathFile';
import Frame from 'react-frame-component';
import Logger from '../../Helper/Logger';

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPreview: false,
    };
    this.iframeRef = React.createRef();
  }

  renderEditArea = () => {
    const { isPreview } = this.state;
    const { currentFile } = this.props;

    if (isPreview) {
      return (
        <iframe
          ref={this.iframeRef}
          src={`${window.location.origin}${currentFile.value.uri}?preview=true&&editorid=${this.props.editorid}`}
          title='description'
          width={'100%'}
          style={{
            width: '100%',
            height: 'calc(100vh - 65px)',
          }}></iframe>
      );
    } else {
      return <EditArea currentFile={this.props.currentFile} onChangeValue={this.props.onChangeValue} />;
    }
  };

  openPreview = () => {
    Logger.terminalInfo('openPreview');
    this.setState({
      isPreview: true,
    });
  };

  openCode = () => {
    Logger.terminalInfo('openCode');
    this.setState({
      isPreview: false,
    });
  };

  openPreviewInNewTab = () => {
    const { currentFile } = this.props;
    const url = `${window.location.origin}${currentFile.value.uri}?preview=true&&editorid=${this.props.editorid}`;
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  render() {
    const { isPreview } = this.state;
    const { currentFile } = this.props;
    Logger.terminalInfo('isPreview', isPreview, currentFile);
    return (
      <div
        style={{
          flexGrow: 1,
        }}>
        <CurrentFiles
          openCode={this.openCode}
          openPreview={this.openPreview}
          isPreview={isPreview}
          openedFiles={this.props.openedFiles}
          setSelectFile={this.props.setSelectFile}
          closeFile={this.props.closeFile}
          openPreviewInNewTab={this.openPreviewInNewTab}
        />
        <PathFile currentFile={this.props.currentFile} />
        {this.renderEditArea()}
      </div>
    );
  }
}
