import React, { Component } from 'react';

export default class QrCode extends Component {
    render() {
        const { size, value } = this.props;
        if (value) {
            return null
            // return (
            //     // <View style={{ flexDirection: 'row', justifyContent: 'center', marginBottom: 50 }}>
            //     // <QRCode value={typeof value === 'string' ? value : JSON.stringify(value)} logoBackgroundColor='red' size={size || 200} />
            //     // </View>
            // );
        } else {
            return <></>;
        }
    }
}
