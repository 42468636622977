import axios from "axios";
import Logger from "../../Helper/Logger";

const instance = (requestIn, responseIn) => {
  const axiosClient = axios.create({
    baseURL: "",
    timeout: 10000,
  });

  axiosClient.interceptors.request.use(
    typeof requestIn?.success !== "function"
      ? (param) => {
          return {
            ...param,
            headers: {
              ...param.headers,
            },
          };
        }
      : requestIn?.success,
    typeof requestIn?.error !== "function"
      ? (error) => {
          Logger.terminalInfo("inteceptor error", error);
        }
      : requestIn?.error
  );

  axiosClient.interceptors.response.use(
    typeof responseIn?.success !== "function"
      ? (res) => {
          return res;
        }
      : responseIn?.success,
    typeof responseIn?.error !== "function"
      ? (error) => {
          Logger.terminalInfo("inteceptor error", error);
        }
      : responseIn?.error
  );

  return axiosClient;
};

export default instance;
