import React, { Component } from "react";
import Tab from "./tab";
import Logger from "../../../Helper/Logger";

const tabs = [
  {
    name: "File",
    select: true,
    iconHide: require("../../Resource/Image/folder-hide.png"),
    iconShow: require("../../Resource/Image/folder-show.png"),
    onClick: () => {},
  },
  {
    name: "Upload",
    select: false,
    iconHide: require("../../Resource/Image/upload-hide.png"),
    iconShow: require("../../Resource/Image/upload-show.png"),
    onClick: () => {},
  },
  {
    name: "Download",
    select: false,
    iconHide: require("../../Resource/Image/download-hide.png"),
    iconShow: require("../../Resource/Image/download-show.png"),
    onClick: () => {},
  },
];

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: props.tabs || tabs,
      currentTab: props.currentTab || tabs[0],
    };
  }

  render() {
    const tabs = [...this.state.tabs].map((tab) => {
      return {
        ...tab,
        onClick: (e) => {
          Logger.terminalInfo("click tab", tab);
          this.setState({
            currentTab: tab,
          });
          typeof tab.onClick === "function" && tab.onClick?.(e);
        },
        select: this.state.currentTab.name === tab.name,
      };
    });
    return (
      <div
        style={{
          width: "50px",
          height: "100%",
          backgroundColor: "rgb(20,20,20)",
        }}
      >
        {tabs.map((tab) => {
          return <Tab tab={tab} />;
        })}
      </div>
    );
  }
}
