import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Text, TouchableOpacity } from '../../../../React-Native/types';

class CheckBox extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { title, theme } = this.props;
        const titleContent = title?.content;
        const titleStyle = title?.style;
        const styles = { ...style, ...this.style };
        return (
            <TouchableOpacity
                style={{ backgroundColor: theme.colors.appColor, ...styles.titleContainer, ...titleStyle?.container }}
                onPress={this.props.showModal}>
                <Text
                    style={{
                        color: theme.colors.textColor,
                        fontFamily: theme.fonts.fontBold,
                        ...styles.title,
                        ...titleStyle?.title,
                    }}
                    numberOfLines={1}
                    ellipsizeMode={'tail'}>
                    {titleContent}
                </Text>
            </TouchableOpacity>
        );
    }
}

const style = {
    title: {
        flex: 1,
        fontSize: 16,
        lineHeight: 24,
    },
    titleContainer: {
        flex: 1,
        height: 56,
        borderRadius: 16,
        paddingHorizontal: 20,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginHorizontal: 20,
    },
};

export default connect((state) => {
    return {
        theme: state.theme,
        lang: state.lang,
    };
})(CheckBox);
