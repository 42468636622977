const LoadingPage = (props) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <img
        src={props.image}
        alt='spin'
        style={{
          width: '80px',
          height: '80px',
        }}
      />
    </div>
  );
};

export default LoadingPage;
