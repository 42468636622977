import React, { Component } from 'react';

export default class Switch extends Component {
  render() {
    return (
      <div {...this.props}>
        <div class='form-check form-switch'>
          <input
            class='form-check-input'
            type='checkbox'
            role='switch'
            id='flexSwitchCheckDefault'
            defaultChecked={this.props.checked}
            checked={this.props.checked}
            onChange={(e) => {
              typeof this.props.onChangeValue === 'function' && this.props.onChangeValue(e.target.checked);
            }}
          />
        </div>
      </div>
    );
  }
}
