import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CheckBox, FastImage, StyleSheet, Text, TouchableOpacity, View } from '../../React-Native/types';
import Logger from '../../../../../../Helper/Logger';

class Select extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSelectedAll:
        Array.isArray(props.selectedDevices) &&
        Array.isArray(props.devices) &&
        props.selectedDevices.length > 0 &&
        props.devices.length > 0 &&
        props.selectedDevices.length === props.devices.length,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.selectedDevices) !== JSON.stringify(this.props.selectedDevices) ||
      JSON.stringify(prevProps.devices) !== JSON.stringify(this.props.devices)
    ) {
      this.setState({
        isSelectedAll:
          Array.isArray(this.props.selectedDevices) &&
          Array.isArray(this.props.devices) &&
          this.props.selectedDevices.length > 0 &&
          this.props.devices.length > 0 &&
          this.props.selectedDevices.length === this.props.devices.length,
      });
    }
  }

  selectAll = () => {
    this.props.setSelectedDevices?.(this.props.devices);
  };

  unSelectAll = () => {
    this.props.setSelectedDevices?.([]);
  };

  onSelectDevice = (device) => {
    const { setSelectedDevices } = this.props;
    const selectedDevices = Array.isArray(this.props.selectedDevices) ? this.props.selectedDevices : [];
    setSelectedDevices?.([...selectedDevices, device]);
  };

  onUnSelectDevice = (device) => {
    const { setSelectedDevices } = this.props;
    const selectedDevices = Array.isArray(this.props.selectedDevices) ? this.props.selectedDevices : [];
    setSelectedDevices?.([...selectedDevices].filter((selectedDevice) => selectedDevice.id !== device.id));
  };

  goBack = () => {
    this.props.backDetail();
  };

  render() {
    const { devices, selectedDevices, setSelectedDevices, navigateItems, theme } = this.props;
    const { isSelectedAll } = this.state;
    Logger.terminalInfo('');
    return (
      <View style={[styles.root, { backgroundColor: theme.colors.appColorThird }]}>
        <View style={[styles.header, { backgroundColor: theme.colors.appColor }]}>
          <View style={styles.rowHeader}>
            <TouchableOpacity style={styles.btnBack} onPress={this.goBack}>
              <FastImage source={'https://image-app-public.s3.ap-southeast-1.amazonaws.com/giangha/my-icons/arrow-back.png'} style={styles.back} />
            </TouchableOpacity>
            <Text style={[styles.title, { color: theme.colors.textColor, fontFamily: theme.fonts.fontBold }]}>{'Chọn thiết bị'}</Text>
        
          </View>
        </View>
        <View style={styles.body}>
          <View style={styles.rowContainer}>
            <CheckBox
              style={{ flex: 0.2, width: 12, height: 12, borderRight: '1px solid #e8e8e8', paddingHorizontal: 12 }}
              checked={isSelectedAll}
              onChangeValue={(value) => {
                if (value) {
                  this.selectAll();
                } else {
                  this.unSelectAll();
                }
              }}
            />
            <Text
              style={{ flex: 0.4, color: theme.colors.textColor, fontFamily: theme.fonts.fontBold, borderRight: '1px solid #e8e8e8', paddingHorizontal: 12 }}>
              Tên thiết bị
            </Text>
            <Text style={{ flex: 0.4, color: theme.colors.textColor, fontFamily: theme.fonts.fontBold, paddingHorizontal: 12 }}>Id</Text>
          </View>
          {Array.isArray(devices) && devices.length > 0 ? (
            devices.map((device) => {
              return (
                <View style={styles.rowContainer}>
                  <CheckBox
                    style={{ flex: 0.2, width: 12, height: 12, borderRight: '1px solid #e8e8e8', paddingHorizontal: 12 }}
                    checked={Array.isArray(selectedDevices) && selectedDevices.findIndex((selectedDevice) => selectedDevice.id === device.id) !== -1}
                    onChangeValue={(value) => {
                      if (value) {
                        this.onSelectDevice(device);
                      } else {
                        this.onUnSelectDevice(device);
                      }
                    }}
                  />
                  <Text
                    style={{
                      flex: 0.4,
                      color: theme.colors.textColor,
                      fontFamily: theme.fonts.fontBold,
                      borderRight: '1px solid #e8e8e8',
                      paddingHorizontal: 12,
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    }}>
                    {device.name}
                  </Text>
                  <Text
                    style={{
                      flex: 0.4,
                      color: theme.colors.textColor,
                      fontFamily: theme.fonts.fontBold,
                      paddingHorizontal: 12,
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    }}>
                    {device.originId}
                  </Text>
                </View>
              );
            })
          ) : (
            <></>
          )}
        </View>
      </View>
    );
  }
}

export default connect((state) => {
  return {
    theme: state.theme,
    lang: state.lang,
    filiot: state.filiot,
  };
})(Select);

const styles = StyleSheet.create({
  rowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 8,
    borderBottom: '1px solid #e8e8e8',
  },
  body: {
    width: '100%',
    height: '100%',
    paddingHorizontal: 16,
  },
  subTitle: {
    fontSize: 12,
    lineHeight: 20,
  },
  actionTitle: {
    fontSize: 14,
    marginLeft: 12,
    marginVertical: 5,
  },
  actionContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 8,
  },
  textTime: {
    fontSize: 14,
    lineHeight: 24,
  },
  btn: {
    paddingVertical: 10,
    paddingHorizontal: 8,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    width: '100%',
    alignSelf: 'center',
    marginVertical: 25,
    borderWidth: 1,
    borderColor: 'red',
    height: 56,
  },
  timeBtn: {
    padding: 10,
    borderRadius: 10,
  },
  heading: {
    color: '#131D67',
    fontSize: 16,
    fontWeight: '700',
  },
  mode: {
    marginRight: 6,
    paddingHorizontal: 6,
    paddingVertical: 6,
    borderWidth: 1,
    borderRadius: 8,
    marginTop: 0,
  },
  dayInWeek: {
    marginRight: 6,
    paddingHorizontal: 16,
    paddingVertical: 5,
    borderWidth: 1,
    borderRadius: 40,
    marginTop: 16,
  },
  flexRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  container: {
    marginTop: 24,
    padding: 16,
    borderRadius: 6,
  },
  btnSave: {
    position: 'absolute',
    right: -16,
    padding: 16,
  },
  title: {
    fontSize: 18,
    lineHeight: 24,
    minWidth: '70%',
    textAlign: 'center',
  },
  back: {
    width: 16,
    height: 16,
  },
  btnBack: {
    position: 'absolute',
    left: -16,
    padding: 16,
  },
  rowHeader: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    width: '100%',
    paddingBottom: 20,
    paddingHorizontal: 24,
    paddingTop: 50,
  },
  root: {
    width: '100%',
    height: '100%',
  },
});
