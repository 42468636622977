import React, { useState } from 'react';
import ReactJson from 'react-json-view';
import { Rnd } from 'react-rnd';
import Logger from '../../../../Helper/Logger';
import { useSelector } from 'react-redux';

const Debugger = (props) => {
  const defaultWidth = isNaN(parseFloat(localStorage.getItem('debuggerWidth'))) ? 300 : parseFloat(localStorage.getItem('debuggerWidth'));
  const defaultHeight = isNaN(parseFloat(localStorage.getItem('debuggerHeight'))) ? 400 : parseFloat(localStorage.getItem('debuggerHeight'));
  const defaultX = isNaN(parseFloat(localStorage.getItem('debuggerX'))) ? props?.resource?.widthBase - 300 : parseFloat(localStorage.getItem('debuggerX'));
  const defaultY = isNaN(parseFloat(localStorage.getItem('debuggerY'))) ? 0 : parseFloat(localStorage.getItem('debuggerY'));

  const [width, setWidth] = useState(defaultWidth);
  const [height, setHeight] = useState(defaultHeight);
  const [x, setX] = useState(defaultX);
  const [y, setY] = useState(defaultY);
  const filiotResource = useSelector((state) => state.filiot);

  const { isDebug, debuggerTheme } = props;
  const resource = { ...props.resource, reduxResource: { ...props.resource.reduxResource, ...filiotResource } };
  if (isDebug.toString() === 'true') {
    Logger.terminalInfo('debugResource:::', resource);
    return (
      <Rnd
        style={{
          zIndex: 999999,
        }}
        // bounds='parent'
        default={{
          x,
          y,
          width,
          height,
        }}
        onDragStop={(e, d) => {
          setX(d.x);
          localStorage.setItem('debuggerX', d.x);
          setY(d.y);
          localStorage.setItem('debuggerY', d.y);
        }}
        onResize={(e, direction, ref, delta, position) => {
          setWidth(ref.style.width);
          localStorage.setItem('debuggerWidth', ref.style.width);
          setHeight(ref.style.height);
          localStorage.setItem('debuggerHeight', ref.style.height);
        }}>
        <ReactJson
          src={resource}
          theme={debuggerTheme}
          collapsed={1}
          collapseStringsAfterLength={15}
          indentWidth={4}
          displayObjectSize={true}
          style={{
            fontSize: '12px',
            height: height,
            overflow: 'auto',
            width: width,
          }}
          name='resource'
        />
      </Rnd>
    );
  } else {
    return <></>;
  }
};

export default Debugger;
