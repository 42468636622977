import React from "react";
import BaseComponent from "../BaseComponent/BaseComponent";

export default class Switch extends BaseComponent {
  renderResult = () => {
    const defaultComponent = React.isValidElement(this.props.default) ? (
      this.props.default
    ) : (
      <div>Default Switch Component is invalid</div>
    );
    const switchComponents = this.props.switch;
    const test = this.props.test;
    if (typeof test !== "number") {
      return <div>Test is invalid</div>;
    }
    if (test === -1) {
      return defaultComponent;
    } else {
      if (Array.isArray(switchComponents)) {
        if (React.isValidElement(switchComponents[test])) {
          return switchComponents[test];
        } else {
          return <div>Switch Switch Component {test + 1} is invalid</div>;
        }
      } else {
        return <div>Switch Component is invalid</div>;
      }
    }
  };

  renderComponent(x, y, width, height) {
    return (
      <div
        style={{
          flex: 1,
        }}
      >
        {this.renderResult()}
      </div>
    );
  }
}
