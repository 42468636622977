import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Text, TextInput, View } from '../../../../React-Native/types';

class StringModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  render() {
    const { theme, lang } = this.props;
    const styles = { ...style, ...this.style };
    const { value } = this.state;
    return (
      <View>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text style={{ fontFamily: theme.fonts.fontMedium, fontSize: 18, color: theme.colors.textInfor }}>{'Giá trị: '}</Text>
          <TextInput
            style={{
              fontFamily: theme.fonts.fontMedium,
              fontSize: 16,
              fontWeight: '700',
              color: theme.colors.textColor,
              borderWidth: 1,
              paddingHorizontal: 12,
              paddingVertical: 8,
              borderColor: '#ABABAB',
              borderRadius: 4,
              width: 200,
              marginLeft: 12,
            }}
            value={this.state.value?.toString?.()}
            onChangeText={(text) => this.setState({ value: text })}
          />
        </View>
        <View style={styles.bottom}>
          <Button
            title={lang.save}
            style={{ color: 'white', backgroundColor: 'black', paddingVertical: 8, paddingHorizontal: 10, borderRadius: 8 }}
            color={'#000'}
            onPress={() => this.props.save(value)}>
            {lang.save}
          </Button>{' '}
        </View>
      </View>
    );
  }
}

const style = {
  bottom: {
    alignItems: 'center',
    paddingBottom: 20,
    marginTop: 20,
  },
};

export default connect((state) => {
  return {
    theme: state.theme,
    lang: state.lang,
  };
})(StringModal);
