import { fRedux } from '../fRedux';

/**
 * Navigate to adaptive screen.
 * @param { title: string }: title of screen
 * @param { config: json }: json config data
 */
const navigate = ({ title = '', config = {} }) => {
    fRedux.updateObject({
        path: 'AdaptiveScreen',
        data: {
            visible: true,
            title,
            config,
        },
    });
};

/**
 * Back to previous screen.
 * @param ()
 */
const back = () => {
    fRedux.updateObject({
        path: 'AdaptiveScreen.visible',
        data: false,
    });
};

export const AdaptiveScreenController = {
    navigate,
    back,
};

export const names = {};
