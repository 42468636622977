const ErrorPage = (props) => {
  const { image } = props;

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontWeight: '700',
        color: '#00005f',
      }}>
      <div>
        <img
          src={image}
          alt='accessDenied'
          style={{
            width: '200px',
            height: '200px',
            marginBottom: '20px',
          }}
        />
        <h3>Ooopps!</h3>
        <h6>We cannot find the page you want</h6>
      </div>
    </div>
  );
};

export default ErrorPage;
