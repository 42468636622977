import Builder from '..';
import * as ExeBlock from '../ExeBlock';

export const build = (FunctionConfig, resource) => {
  const { name, execute } = FunctionConfig;
  const params = FunctionConfig.params ?? {};
  return (...funcargs) => {
    if (execute && Array.isArray(execute)) {
      if (typeof resource === 'undefined') {
        resource = {};
      }

      const newResource = {
        ...resource,
        variableEnvironment: Object.fromEntries(
          Object.keys(resource?.variableEnvironment ?? {})
            .map((_key) => [_key, resource?.variableEnvironment[_key]])
            .concat(Object.keys(params).map((key, index) => [key, funcargs[index] ?? params[key]])),
        ),
      };

      return Builder.build(
        {
          type: ExeBlock.type,
          execute,
        },
        newResource,
      );
    }
  };
};

export const type = 'Function';
