import BaseComponent, { ignoreProps, removeObjectKeys } from '../BaseComponent/BaseComponent';

export default class Input extends BaseComponent {
  adjustStyle() {
    const { value } = this.props;
    const baseStyle = this.style;
    let fontSize = this.style?.fontSize;
    // const fitContent = this.props.fitContent ?? true;
    const fitContent = this.props.fitContent ?? false;

    const widthContent = value?.toString().length * 0.6 * fontSize;

    return {
      ...baseStyle,
      fontSize,
      height: baseStyle.height < 1.5 * fontSize ? 1.5 * fontSize : baseStyle.height,
      width: fitContent && baseStyle.width < widthContent ? widthContent : baseStyle.width,
    };
  }

  renderComponent(x, y, width, height) {
    const { value } = this.props;

    return (
      <input
        {...this.restProps}
        style={this.style}
        // style={this.style}
        onChange={this.props.onChange}
        type={this.props.inputType ?? 'text'}
        value={value}
      />
    );
  }
}
