import React, { Component } from 'react';

class Coordinate extends Component {
  constructor(props) {
    super(props);
    this.coordinatesCanvas = React.createRef();
    this.mouseCanvas = React.createRef();

    this.width = props.width;
    this.height = props.height;

    this.widthBase = props.pageResource?.appResource?.widthBase;
    this.heightBase = props.pageResource?.appResource?.heightBase;
    this.col = props.pageResource?.appResource?.col;
    this.row = props.pageResource?.appResource?.row;

    this.widthByFilUnit = props.widthByFilUnit;
    this.heightByFilUnit = props.heightByFilUnit;

    this.widthUnit = this.widthBase / this.col;
    this.heightUnit = this.heightBase / this.row;

    this.prevXMouse = 0;
    this.prevYMouse = 0;
  }

  componentDidMount() {
    const coordinatesCanvas = this.coordinatesCanvas.current;

    const mouseCanvas = this.mouseCanvas.current;

    const coordinatesCanvasContext = coordinatesCanvas.getContext('2d');
    const mouseCanvasContext = mouseCanvas.getContext('2d');

    coordinatesCanvasContext.beginPath();
    coordinatesCanvasContext.lineWidth = 0.1;
    for (let i = 0; i < (this.width - (this.width % this.widthUnit)) / this.widthUnit; i++) {
      coordinatesCanvasContext.moveTo(this.widthUnit * i, 0);
      coordinatesCanvasContext.lineTo(this.widthUnit * i, this.height);
    }

    for (let i = 0; i < (this.height - (this.height % this.heightUnit)) / this.heightUnit; i++) {
      coordinatesCanvasContext.moveTo(0, this.heightUnit * i);
      coordinatesCanvasContext.lineTo(this.width, this.heightUnit * i);
    }
    coordinatesCanvasContext.stroke();

    var box = { x: 0, y: 0, width: 60, height: 20, color: '#e8e8e8' };

    mouseCanvas.fillStyle = box.color;

    mouseCanvas.addEventListener('mousemove', (event) => {
      mouseCanvasContext.clearRect(0, 0, mouseCanvas.width, mouseCanvas.height);

      const x = event.clientX - mouseCanvas.getBoundingClientRect().left;
      const y = event.clientY - mouseCanvas.getBoundingClientRect().top;
      box.x = x;
      box.y = y;

      if (document.documentElement.clientWidth - event.clientX <= 60 + 6) {
        box.x = box.x - box.width;
      } else {
        box.x = box.x + 6;
      }

      if (event.clientY < 20) {
        box.y = box.y + 15;
      } else if (document.documentElement.clientHeight - event.clientY <= 15 + 20) {
        box.y = box.y - box.height;
      } else {
        box.y = box.y + 15;
      }

      const xFil = Math.round((x / this.widthUnit) * 10) / 10;
      const yFil = Math.round((y / this.heightUnit) * 10) / 10;
      const text = `${xFil} x ${yFil}`;

      mouseCanvasContext.fillStyle = box.color;
      mouseCanvasContext.fillRect(box.x, box.y, box.width, box.height);
      mouseCanvasContext.roundRect(box.x, box.y, box.width, box.height, 0);
      // Draw the text inside the rectangle
      mouseCanvasContext.fillStyle = 'rgb(94, 88, 115)'; // Text color
      mouseCanvasContext.font = '10px Arial'; // Font style

      var textWidth = mouseCanvasContext.measureText(text).width;
      var textHeight = parseInt(mouseCanvasContext.font, 10); // Assuming the font size is specified in pixels

      // Calculate the coordinates for center alignment
      var centerX = box.x + (box.width - textWidth) / 2;
      var centerY = box.y + (box.height + textHeight) / 2 - 15;

      mouseCanvasContext.fillText(text, centerX, centerY + 12);
    });
  }

  render() {
    const { width, height } = this.props;
    return (
      <>
        <canvas
          ref={this.coordinatesCanvas}
          width={width}
          height={height}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 999,
          }}
        />
        <canvas
          ref={this.mouseCanvas}
          width={width}
          height={height}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 999,
          }}
        />
      </>
    );
  }
}

export default class Coordinates extends Component {
  render() {
    const { isCoordinate } = this.props;
    if (isCoordinate?.toString() === 'true') {
      return <Coordinate {...this.props} />;
    }
    return null;
  }
}
