import _ from 'lodash';
import * as Define from '../../../Define';
import * as VariableDeclaration from '../../../VariableDeclaration';
import * as FiliotComponent from '../../../FiliotComponent';
import * as Function from '../../../Function';

class _Node {
  constructor() {
    this.start = 0;
    this.end = 0;
    this.value = '';
    this.children = [];
    this.parent = null;
  }
}

function extractExpressions(input) {
  let root = new _Node();
  root.value = '';
  root.start = 0;
  root.end = input.length;
  let currentNode = root;

  for (let i = 0; i < input.length; i++) {
    const currentChar = input.charAt(i);
    const preChar = input.charAt(i - 1);
    const nextChar = input.charAt(i + 1);

    if (`${currentChar}${nextChar}` === '{{' && `${preChar}${currentChar}` !== '{{') {
      const newNode = new _Node();
      newNode.start = i;
      newNode.parent = currentNode;
      currentNode.children.push(newNode);
      currentNode = newNode;
    }

    if (`${currentChar}${nextChar}` === '}}' && `${preChar}${currentChar}` !== '}}') {
      currentNode.end = i + 2;
      currentNode.value = input.slice(currentNode.start, currentNode.end);
      currentNode = currentNode.parent;
    }
  }

  if (root.children.length === 1) {
    root = root.children[0];
    root.parent = null;
  }

  return root;
}

export const getRedux = (config) => {
  let result = [];

  const extractTree = (node, ignoreNames = []) => {
    const children = node.children;
    const value = node.value;
    if (value !== '' && !result.includes(node.value)) {
      const unMarkValue = value.slice(2, value.length - 2);
      const firstField = unMarkValue.split('.')?.[0];
      if (ignoreNames.length === 0) {
        result.push(unMarkValue.split('.'));
      } else {
        if (!ignoreNames.includes(firstField)) {
          result.push(unMarkValue.split('.'));
        }
      }
    }

    children.forEach((_node) => {
      extractTree(_node, ignoreNames);
    });
  };

  const traverseGetTextMarkdown = (pageConfig, _ignoreNames = []) => {
    if (Array.isArray(pageConfig)) {
      return pageConfig.map((element) => traverseGetTextMarkdown(element, _ignoreNames));
    } else if (_.isObject(pageConfig)) {
      if (pageConfig.type === Function.type) {
        const ignoreNames = _ignoreNames.concat(Object.keys(pageConfig?.params || {}));
        const execute = pageConfig.execute;
        if (Array.isArray(execute)) {
          execute.forEach((_exe) => {
            if (_exe.type === Define.type || _exe.type === VariableDeclaration.type) {
              ignoreNames.push(_exe.name);
            }
          });
        }
        return Object.fromEntries(
          Object.keys(pageConfig).map((key) => {
            return [key, traverseGetTextMarkdown(pageConfig[key], ignoreNames)];
          }),
        );
      } else if (pageConfig.type === FiliotComponent.type) {
        const ignoreNames = _ignoreNames.concat(['item']);
        return Object.fromEntries(
          Object.keys(pageConfig).map((key) => {
            return [key, traverseGetTextMarkdown(pageConfig[key], ignoreNames)];
          }),
        );
      } else {
        return Object.fromEntries(
          Object.keys(pageConfig).map((key) => {
            return [key, traverseGetTextMarkdown(pageConfig[key], _ignoreNames)];
          }),
        );
      }
    } else if (typeof pageConfig === 'string') {
      const treeText = extractExpressions(pageConfig);
      // Logger.terminalInfo("_ignoreNames", _ignoreNames);
      extractTree(treeText, _ignoreNames);
    } else {
      return pageConfig;
    }
  };

  traverseGetTextMarkdown(config, []);

  return result;
};

export const normalizeRedux = (redux) => {
  const merged = [];
  const groupedArrays = {};

  for (const arr of redux) {
    const key = arr.slice(0, 3).join('-');
    groupedArrays[key] = groupedArrays[key] || [];
    groupedArrays[key].push(arr);
  }

  for (const key in groupedArrays) {
    if (groupedArrays[key].length >= 2) {
      const mergedArray = key.split('-');
      merged.push(mergedArray);
    } else {
      merged.push(groupedArrays[key][0]);
    }
  }

  return merged;
};
