import Builder from '..';

export const build = (VariableDeclarationConfig, resource) => {
    try {
        const variableName = VariableDeclarationConfig.name;
        const variableValue = Builder.traverseBuild(VariableDeclarationConfig.initializer, resource);

        if (typeof resource === 'undefined') {
            resource = {};
        }

        if (typeof resource.variableEnvironment === 'undefined') {
            resource.variableEnvironment = {};
        }

        resource.variableEnvironment[variableName] = variableValue;
        // return resource;
        return variableValue;
    } catch (error) {
        return undefined;
    }
};

export const type = 'VariableDeclaration';
