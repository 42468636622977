import { combineReducers } from "redux";
import filiotReducer from "./filiot";
import themeReducer from "./theme";
import langReducer from "./lang";

export default combineReducers({
  filiot: filiotReducer,
  theme: themeReducer,
  lang: langReducer,
});
