import { THEME_SELECTION } from '../type.js';
import DefaultTheme from '../../Theme/Default/theme.js';
import DarkTheme from '../../Theme/DarkTheme/theme.js';

const DefaultState = {
  ...DefaultTheme,
  __type: 'default',
};

const themeSelectionReducer = (state = DefaultState, action) => {
  if (action.type === THEME_SELECTION) {
    const { __type } = action.value;
    switch (__type) {
      case 'dark':
        return { DarkTheme, __type: 'dark' };

      default:
        return { DefaultTheme, __type: 'default' };
    }
  }
  return state;
};

export default themeSelectionReducer;
