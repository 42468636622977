export const hostname = 'wss://mqtt.filiot.com/mqtt';

export const actions = {
  update_state: 'update_state',
  update_setting: 'update_setting',
  update_automation: 'update_automation',
  update_ota: 'update_ota',

  scan: 'scan',

  request_state: 'request_state',
  request_setting: 'request_setting',
  request_automation: 'request_automation',

  report_setting: 'report_setting',
  report_state: 'report_state',
  report_info: 'report_info',
  report_automation: 'report_automation',

  delete_setting: 'delete_setting',

  get_list: 'get_list',
  delete_device: 'delete_device',
};

export const types = {
  command: 'command',
  setting: 'setting',
  heartbeat: 'heartbeat',
  info: 'info',
  ota: 'ota',
  automation: 'automation',
};

export const services = {
  device: 'device',
  OTA: 'OTA',
  setting: 'setting',
  app: 'app',
};
