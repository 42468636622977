class State {
    constructor() {
        this.state = undefined;
    }

    setStateActor(stateActor) {
        this.StateActor = stateActor;
    }

    setCurrentState(state) {
        this.state = state;
    }
}

export default new State();
