export const MOSBUS_ROLES = {
  GATEWAY: 'gateway',
  SLAVE: 'slave',
};

export const cmd = {
  add: 'add',
  edit: 'edit',
  del: 'del',
};
