import { fRedux } from '../fRedux';

/**
 * Display common modal.
 * @param { area: arrayElement }: array of element, that be displayed in children of modal
 * @param { animation: string }: animation opening. Inlcude "slideUp" | "slideDown" | "scale" | "slideLeft" | "slideRight"
 * @param { onCloseModal: function }: A function is called when closing modal.
 */
const display = (commonmodal = { area: [], animation: '', onCloseModal: () => {} }) => {
    fRedux.updateObject({
        path: 'CommonModal',
        data: {
            visible: true,
            ...commonmodal,
        },
    });
};

/**
 * Hide firebase notification.
 * @param()
 */
const hide = () => {
    fRedux.updateObject({
        path: 'CommonModal.visible',
        data: false,
    });
};

export const CommonModalController = {
    display,
    hide,
};
