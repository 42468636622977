import React, { Component } from 'react';
import Block from './Block';
import { Rnd } from 'react-rnd';
import Logger from '../../../../../Helper/Logger';

class BlockComponent extends Component {
  render() {
    const { resource } = this.props;
    const { blockcompiledcomponent } = resource;

    if (resource?.appResource?.visualPreviewMode) {
      if (blockcompiledcomponent.key === resource?.appResource?.selectedPreviewView?.key) {
        return (
          <Rnd
            bounds={'parent'}
            style={{
              zIndex: this.zIndex,
              // border: '2px solid blue',
            }}
            // default={{
            //   x: this.blockStyle.left,
            //   y: this.blockStyle.top,
            //   width: this.blockStyle.width,
            //   height: this.blockStyle.height,
            // }}
            size={{ width: this.blockStyle.width, height: this.blockStyle.height }}
            position={{ x: this.blockStyle.left, y: this.blockStyle.top }}
            onContextMenu={(event) => {
              event.preventDefault();
              const { clientX, clientY } = event;
              this.props.setContextMenu?.({
                display: true,
                menu: [
                  {
                    title: 'Tới trước',
                    onClick: () => {
                      this.props.onChangePageViewConfig({
                        key: this.props.component.key,
                        zIndex: this.props.component.zIndex + 1,
                      });
                    },
                  },
                  {
                    title: 'Về sau',
                    onClick: () => {
                      this.props.onChangePageViewConfig({
                        key: this.props.component.key,
                        zIndex: this.props.component.zIndex - 1,
                      });
                    },
                  },
                ],
                x: clientX,
                y: clientY,
              });
              Logger.terminalInfo(1231312);
            }}
            onDragStop={(e, d) => {
              Logger.terminalInfo('123123123', d.x, d.y);
              this.props.onChangePageViewConfig({
                key: this.props.component.key,
                x: (d.x / this.getWidthBase()) * this.col,
                y: (d.y / this.getHeightBase()) * this.row,
              });
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
              const widthFloat = parseFloat(ref.style.width.slice(0, ref.style.width.length - 2));
              const heightFloat = parseFloat(ref.style.height.slice(0, ref.style.height.length - 2));
              const x = position.x;
              const y = position.y;
              this.props.onChangePageViewConfig({
                key: this.props.component.key,
                width: (widthFloat / this.getWidthBase()) * this.col,
                height: (heightFloat / this.getHeightBase()) * this.row,
                x: (x / this.getWidthBase()) * this.col,
                y: (y / this.getHeightBase()) * this.row,
              });
            }}
            resizeHandleStyles={{
              bottomLeft: {
                bottom: '0px',
                left: '0px',
                width: '5px',
                height: '5px',
              },
              topLeft: {
                top: '0px',
                left: '0px',
                width: '5px',
                height: '5px',
              },
              topRight: {
                top: '0px',
                right: '0px',
                width: '5px',
                height: '5px',
              },
              bottomRight: {
                bottom: '0px',
                right: '0px',
                width: '5px',
                height: '5px',
              },
              top: {
                top: '0px',
                // backgroundColor: 'rgb(235,235,235,0.5)',
                backgroundImage: 'linear-gradient(to right, red,orange,yellow,green,blue,indigo,violet)',
                height: '5px',
              },
              left: {
                left: '0px',
                // backgroundColor: 'rgb(235,235,235,0.5)',
                backgroundImage: 'linear-gradient(to right, red,orange,yellow,green,blue,indigo,violet)',
                width: '5px',
              },
              bottom: {
                bottom: '0px',
                backgroundImage: 'linear-gradient(to right, red,orange,yellow,green,blue,indigo,violet)',
                // backgroundColor: 'rgb(235,235,235,0.5)',
                height: '5px',
              },
              right: {
                right: '0px',
                // backgroundColor: 'rgb(235,235,235,0.5)',
                backgroundImage: 'linear-gradient(to right, red,orange,yellow,green,blue,indigo,violet)',
                width: '5px',
              },
            }}>
            {/* <Block {...this.props} /> */}
            <Block {...this.props} />
          </Rnd>
        );
      } else {
        // return <Block {...this.props} />;
        return <Block {...this.props} />;
      }
    } else {
      // return <Block {...this.props} />;
      return <Block {...this.props} />;
    }
  }
}

export default BlockComponent;
