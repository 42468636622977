import fMqtt from '../fMqtt';

export const FiliotController = {
  updateDeviceSetting: fMqtt.updateDeviceSetting,
  requestDeviceSetting: fMqtt.requestDeviceSetting,
  requestDeviceAutomation: fMqtt.requestDeviceAutomation,
  updateDeviceState: fMqtt.updateDeviceState,
  updateDeviceAutomation: fMqtt.updateDeviceAutomation,
  deleteDeviceSetting: fMqtt.deleteDeviceSetting,
  updateDeviceOta: fMqtt.updateDeviceOta,
  updateDeviceSetting: fMqtt.updateDeviceSetting,
  requestDeviceState: fMqtt.requestDeviceState,
  updateDeviceSettingRetail: fMqtt.updateDeviceSettingRetail,
};
