import Logger from '../../../../Helper/Logger';
import BaseComponent from '../BaseComponent/BaseComponent';
import { Button } from 'antd';

export default class FButton extends BaseComponent {
  renderComponent() {
    const image = this.props.image;
    const title = this.props.title;
    if (image) {
      const src = image.src || image.value;
      Logger.terminalInfo('this.style', this.style);
      return (
        <button
          {...this.restProps}
          style={this.style}
          onClick={() => {
            this.props.onClick?.();
          }}>
          <img src={src} alt={image.alt} style={image.style} />
        </button>
      );
    } else if (title) {
      return (
        <button
          {...this.restProps}
          style={this.style}
          onClick={() => {
            this.props.onClick?.();
          }}>
          <span style={title.style}>{title.value}</span>
        </button>
      );
    } else {
      return (
        <button
          {...this.restProps}
          style={this.style}
          onClick={() => {
            this.props.onClick?.();
          }}></button>
      );
    }
  }
}
