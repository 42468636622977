import React, { Component } from 'react';
import Modal from '../Modal';
import { connect } from 'react-redux';
import ModalContentRenameWithError from './modalrenamewitherror';
import { FastImage, ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View } from '../../React-Native/types';

class DetailWifi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      modal: '',
      ssid: '',
      password: '',
      errorPassword: '',
    };
  }

  aRef = [
    { ref: undefined, id: 'wifiName', label: this.props.lang.typeWifiName },
    { ref: undefined, id: 'passwordWifi', label: this.props.lang.enterPassWifi },
  ];

  async componentDidMount() {
    const { wifi } = this.props.route.params;
    if (wifi.ssid === '' || typeof wifi.ssid === 'undefined') {
      this.setState({ isNewWifi: true });
    } else {
      this.setState({
        ssid: wifi.ssid,
        password: wifi.password,
      });
    }
  }

  componentWillUnmount() {
    this.clearIntervalScanWifi();
  }

  clearIntervalScanWifi = () => {
    clearInterval(this.interval);
  };

  getWifis = async () => {
    // const wifisScan = await FiliotController.loadWifiList(); // wifiList will be Array<WifiEntry>
    const wifisScan = []; // wifiList will be Array<WifiEntry>
    if (typeof wifisScan?.[0]?.SSID !== 'undefined') {
      this.clearIntervalScanWifi();
      this.setState({ ssid: wifisScan[0].SSID, wifisScan });
    }
  };

  renderModal = () => {
    const { modal, password } = this.state;
    const { lang } = this.props;
    switch (modal) {
      case 'changepasswifi':
        return (
          <ModalContentRenameWithError
            title={lang.changePass}
            label={lang.changePass}
            name={password}
            saveRename={(password) =>
              this.setState({ password }, () => {
                this.onSaveWifi();
              })
            }
            secureTextEntry={true}
            placeholder={lang.typePassword}
            error={this.state.errorPassword}
          />
        );

      default:
        return;
    }
  };

  onCloseModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  onShowModalChangePass = () => {
    this.onEdit();
    this.setState({ showModal: true, modal: 'changepasswifi' });
  };

  onChangePassword = (password) => {
    this.setState({ password });
  };

  onClearWifi = () => {
    this.setState({ ssid: '' });
  };

  onChangeSSID = (ssid) => {
    const { isNewWifi } = this.state;
    if (isNewWifi) {
      this.setState({ ssid });
    }
  };

  onEdit = () => {
    this.clearIntervalScanWifi();
    this.setState({ isEdit: true });
  };

  onSaveWifi = () => {
    const { wifis } = this.props.route.params;
    const { ssid, password, isNewWifi } = this.state;
    if (isNewWifi && wifis.find((e) => e.ssid === ssid)) {
      this.setState({ error: true });
    } else {
      if (password === '' || (password !== '' && password.length >= 8)) {
        this.props.onSaveWifi({ ssid, pass: password });
        this.goBack();
      } else {
        this.setState(
          {
            errorPassword: this.props.lang.wifiPasswordNote,
          },
          () => {
            setTimeout(() => {
              this.setState({ errorPassword: '' });
            }, 3000);
          },
        );
      }
    }
  };

  goBack = () => {
    this.clearIntervalScanWifi();
    const { navigation } = this.props;
    navigation.goBack();
  };

  onDeleteWifi = () => {
    const { ssid, password } = this.state;
    this.props.onDeleteWifi({ ssid, password });
    this.goBack();
  };

  renderContentNewWifi = () => {
    const { theme, lang } = this.props;
    const { ssid, password, isEdit, error, showModal } = this.state;
    return (
      <>
        <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} contentContainerStyle={{ flexGrow: 1 }}>
          <View style={[styles.root, { backgroundColor: theme.colors.appColorSecond }]}>
            <View style={[styles.header, { backgroundColor: theme.colors.appColor }]}>
              <View style={styles.rowHeader}>
                <TouchableOpacity style={styles.btnBack} onPress={this.goBack}>
                  <FastImage source={theme.icons.back} style={styles.back} />
                </TouchableOpacity>
                <Text style={[styles.title, { color: theme.colors.textColor, fontFamily: theme.fonts.fontBold }]} ellipsizeMode={'tail'} numberOfLines={1}>
                  {lang.editWifi}
                </Text>
                <TouchableOpacity style={[styles.btnSave]} onPress={this.onSaveWifi}>
                  <Text style={[styles.save, { color: theme.colors.linkColor, fontFamily: theme.fonts.fontBold }]}>{lang.save}</Text>
                </TouchableOpacity>
              </View>
            </View>
            <View style={{ flex: 1 }}>
              <View style={[styles.content, { backgroundColor: theme.colors.appColor, borderColor: theme.colors.borderColor }]}>
                <Text style={[styles.wifiName, { color: theme.colors.textColorInactive, fontFamily: theme.fonts.fontBold }]}>{lang.wifiName}</Text>
                <View style={[styles.formInput, { backgroundColor: theme.colors.appColorSecond }]}>
                  <FastImage source={theme.icons.wifi} style={styles.icon} />

                  <TextInput
                    style={[styles.input, { color: theme.colors.textColor, fontFamily: theme.fonts.fontMedium }]}
                    placeholder={lang.typeWifiName}
                    onChangeText={this.onChangeSSID}
                    value={ssid}
                    placeholderTextColor={theme.colors.placeholderColor}
                    inputAccessoryViewID={'wifiName'}
                    ref={(ref) => (this.aRef[0].ref = ref)}
                    keyboardAppearance={theme.type || 'default'}
                  />
                  {ssid !== '' && (
                    <TouchableOpacity style={styles.arrowDown} onPress={this.onClearWifi}>
                      <FastImage source={theme.icons.cancelSecond} style={{ height: 24, width: 24 }} />
                    </TouchableOpacity>
                  )}
                </View>
                <Text style={{ color: theme.colors.errorColor, fontSize: 14, marginTop: 4 }}>{error && lang.wifiAlreadyExists}</Text>

                <Text style={[styles.password, { color: theme.colors.textColorInactive, fontFamily: theme.fonts.fontBold }]}>{lang.password}</Text>
                <View style={[styles.formInput, { backgroundColor: isEdit ? theme.colors.appColorSecond : theme.colors.appColorSecond }]}>
                  <FastImage source={theme.icons.key} style={styles.icon} />

                  <TextInput
                    style={[styles.input, { color: theme.colors.textColor }]}
                    secureTextEntry={!this.state.isShowPass}
                    value={password}
                    onChangeText={this.onChangePassword}
                    onFocus={() => {
                      this.setState({ showWifi: false });
                    }}
                    placeholder={lang.enterPassWifi}
                    placeholderTextColor={theme.colors.placeholderColor}
                    inputAccessoryViewID={'passwordWifi'}
                    ref={(ref) => (this.aRef[1].ref = ref)}
                    keyboardAppearance={theme.type || 'default'}
                  />

                  <TouchableOpacity style={[styles.arrowDown]} onPress={() => this.setState({ isShowPass: !this.state.isShowPass })}>
                    <FastImage source={this.state.isShowPass ? theme.icons.show : theme.icons.hide} style={{ width: 24, height: 24 }} />
                  </TouchableOpacity>
                </View>
              </View>
            </View>
            <Modal visible={showModal} onCloseModal={this.onCloseModal}>
              {this.renderModal()}
            </Modal>
          </View>
        </ScrollView>
      </>
    );
  };

  renderContentOldWifi = () => {
    const { theme, lang } = this.props;
    const { ssid, isEdit, error, showModal } = this.state;
    return (
      <>
        <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} contentContainerStyle={{ flexGrow: 1 }}>
          <View style={[styles.root, { backgroundColor: theme.colors.appColorSecond }]}>
            <View style={[styles.header, { backgroundColor: theme.colors.appColor }]}>
              <View style={styles.rowHeader}>
                <TouchableOpacity style={styles.btnBack} onPress={this.goBack}>
                  <FastImage source={theme.icons.back} style={styles.back} />
                </TouchableOpacity>
                <Text style={[styles.title, { color: theme.colors.textColor, fontFamily: theme.fonts.fontBold }]} ellipsizeMode={'tail'} numberOfLines={1}>
                  {lang.editWifi}
                </Text>

                <TouchableOpacity style={[styles.btnSave]} onPress={this.onDeleteWifi}>
                  <Text style={[styles.save, { color: theme.colors.errorColor, fontFamily: theme.fonts.fontBold }]}>{lang.delete}</Text>
                </TouchableOpacity>
              </View>
            </View>
            <View style={{ flex: 1 }}>
              <View style={[styles.content, { backgroundColor: theme.colors.appColor, borderColor: theme.colors.borderColor }]}>
                <Text style={[styles.wifiName, { color: theme.colors.textColorInactive, fontFamily: theme.fonts.fontBold }]}>{lang.wifiName}</Text>
                <View style={[styles.formInput, { backgroundColor: theme.colors.appColorSecond }]}>
                  <FastImage source={theme.icons.wifi} style={styles.icon} />

                  <Text style={[styles.wifiText, { color: theme.colors.textColor, fontFamily: theme.fonts.fontMedium }]}>{ssid}</Text>
                </View>
                <Text style={{ color: theme.colors.errorColor, fontSize: 14, marginTop: 4 }}>{error && lang.wifiAlreadyExists}</Text>

                <Text style={[styles.password, { color: theme.colors.textColorInactive, fontFamily: theme.fonts.fontBold }]}>{lang.password}</Text>
                <View style={[styles.formInput, { backgroundColor: isEdit ? theme.colors.appColorSecond : theme.colors.appColorSecond }]}>
                  <FastImage source={theme.icons.key} style={styles.icon} />
                  <Text style={[styles.wifiText, { color: theme.colors.textColor, fontFamily: theme.fonts.fontMedium }]}>{'**********'}</Text>
                  <TouchableOpacity onPress={this.onShowModalChangePass}>
                    <FastImage source={theme.icons.edit} style={styles.icon} />
                  </TouchableOpacity>
                </View>
              </View>
            </View>
            <Modal visible={showModal} onCloseModal={this.onCloseModal}>
              {this.renderModal()}
            </Modal>
          </View>
        </ScrollView>
      </>
    );
  };

  render() {
    const { isNewWifi } = this.state;
    if (isNewWifi) {
      return this.renderContentNewWifi();
    } else {
      return this.renderContentOldWifi();
    }
  }
}

export default connect((state) => {
  return {
    theme: state.theme,
    lang: state.lang,
  };
}, {})(DetailWifi);

const styles = StyleSheet.create({
  change: {
    fontSize: 16,
    lineHeight: 24,
  },
  btn: {
    width: '100%',
    height: 56,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 24,
    borderRadius: 16,
  },
  save: {
    fontSize: 14,
    lineHeight: 24,
  },
  input: {
    flex: 1,
    fontSize: 16,
  },
  icon: {
    height: 24,
    width: 24,
    marginRight: 8,
  },
  btnSave: {
    position: 'absolute',
    right: -16,
    padding: 16,
  },
  password: {
    fontSize: 14,
    lineHeight: 24,
    marginTop: 8,
  },
  arrowDown: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  wifiText: {
    flex: 1,
    fontSize: 16,
    lineHeight: 24,
  },
  formInput: {
    width: '100%',
    height: 56,
    flexDirection: 'row',
    marginTop: 8,
    alignItems: 'center',
    borderRadius: 16,
    paddingHorizontal: 20,
  },
  wifiName: {
    fontSize: 14,
    lineHeight: 24,
  },
  content: {
    borderRadius: 16,
    paddingHorizontal: 24,
    paddingBottom: 24,
    paddingTop: 20,
    marginTop: '10%',
    marginHorizontal: 24,
    borderWidth: 1,
  },
  title: {
    fontSize: 20,
    lineHeight: 28,
    minWidth: '70%',
    textAlign: 'center',
  },
  back: {
    width: 32,
    height: 32,
  },
  btnBack: {
    position: 'absolute',
    left: -16,
    padding: 16,
  },
  rowHeader: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    width: '100%',
    paddingBottom: 20,
    paddingHorizontal: 24,
    paddingTop: 50,
  },
  root: {
    width: '100%',
    flex: 1,
  },
});
