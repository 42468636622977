import Builder from '..';

export const build = (SwitchConfig, resource) => {
    const data = SwitchConfig.data;
    if (Array.isArray(data)) {
        for (let i = 0; i < data.length; i++) {
            if (Builder.build(data[i], resource) === true) {
                return i;
            }
        }
        return -1;
    } else {
        return -1;
    }
};

export const type = 'Switch';
