import React, { Component } from 'react';
import Container from './Container';
import Auth from './Auth';
import { connect } from 'react-redux';
import { fAuth } from '../../../Controller/fAuth';
import Modal from './Component/Modal';
import LoadingPage from './Component/LoadingPage';
import ErrorPage from './Component/ErrorPage';
import Controller from '../../../Controller';
import { ControlSource } from '../../../Constant/ControlFlow';
import Logger from '../../../Helper/Logger';
import Store from '../../../Redux';

const AppCom = (props) => {
  const { verifyAuth, resource } = props;
  const AppConfig = resource.appResource.AppConfig;

  const currentPath = resource?.appResource?.location.pathname;

  const containerPages = Array.isArray(AppConfig.container?.main?.pages) ? AppConfig.container?.main?.pages : [];

  const authPages = Array.isArray(AppConfig.auth?.main?.pages) ? AppConfig.auth?.main?.pages : [];

  const authPageUris = authPages.map((page) => page.uri);

  const containerPageUris = containerPages.map((page) => page.uri);

  if (verifyAuth?.active === true) {
    if (containerPageUris.includes(currentPath)) {
      return <Container AppConfig={AppConfig} container={AppConfig.container} resource={resource} />;
    } else if ((currentPath === '/' || (authPageUris.includes(currentPath) && currentPath !== '/')) && containerPageUris.length > 0) {
      Controller.execute({
        serviceName: 'navigator',
        methodName: 'navigate',
        args: {
          uri: containerPageUris[0] || '/',
        },
        resource,
        source: ControlSource.SYSTEM,
      });
    } else {
      return <ErrorPage image={resource?.reduxResource?.theme.icons.accessDenied} />;
    }
  } else {
    if (authPageUris.includes(currentPath)) {
      return <Auth AppConfig={AppConfig} auth={AppConfig.auth} resource={resource} />;
    } else if ((currentPath === '/' || (containerPageUris.includes(currentPath) && currentPath !== '/')) && authPageUris.length > 0) {
      Controller.execute({
        serviceName: 'navigator',
        methodName: 'navigate',
        args: {
          uri: authPageUris[0] || '/',
        },
        resource,
        source: ControlSource.SYSTEM,
      });
    } else {
      return <ErrorPage image={resource?.reduxResource?.theme.icons.accessDenied} />;
    }
  }
};

class _App extends Component {
  componentDidMount() {
    const { resource } = this.props;
    const AppConfig = resource?.appResource?.AppConfig || {};
    const appResource = resource.appResource || {};
    const accessToken = appResource.accessToken;
    const manifest = AppConfig?.manifest;
    if (appResource.init) {
      if (manifest.openApp === true) {
        Controller.execute({
          serviceName: 'fRedux',
          methodName: 'updateObjects',
          args: [
            {
              path: 'verifyAuth',
              data: { active: true },
            },
          ],
          resource,
          source: ControlSource.USER,
        });
      } else {
        if (manifest.fAuth === true) {
          Logger.terminalInfo('accessToken', accessToken);
          fAuth.verifyToken(accessToken).then((response) => {
            Logger.terminalInfo('response', response);
            if (response.status === true) {
              Controller.execute({
                serviceName: 'fRedux',
                methodName: 'updateObjects',
                args: [
                  {
                    path: 'verifyAuth',
                    data: { active: true },
                  },
                  {
                    path: 'user',
                    data: response.result,
                  },
                ],
                resource,
                source: ControlSource.USER,
              });
            } else {
              Controller.execute({
                serviceName: 'fRedux',
                methodName: 'updateObject',
                args: {
                  path: 'verifyAuth',
                  data: { active: false },
                },
                resource,
                source: ControlSource.USER,
              });
            }
          });
        } else {
          Controller.execute({
            serviceName: 'fRedux',
            methodName: 'updateObjects',
            args: [
              {
                path: 'verifyAuth',
                data: { active: false },
              },
            ],
            resource,
            source: ControlSource.USER,
          });
        }
      }
    }
    document.title = manifest?.title;

    // Cập nhật favicon nếu có URL
    if (manifest?.logo) {
      const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = manifest.logo;
      document.getElementsByTagName('head')[0].appendChild(link);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(this.props.resource) !== JSON.stringify(prevProps.resource) &&
      JSON.stringify(this.props.resource?.appResource?.location) === JSON.stringify(prevProps.resource?.appResource?.location) &&
      this.props.resource?.appResource?.width === prevProps.resource?.appResource?.width &&
      this.props.resource?.appResource?.height === prevProps.resource?.appResource?.height
    ) {
      const { resource } = this.props;
      const AppConfig = resource?.appResource?.AppConfig || {};
      const accessToken = resource?.appResource?.accessToken;

      if (resource?.appResource?.init) {
        const manifest = AppConfig.manifest;
        if (manifest.openApp === true) {
          Controller.execute({
            serviceName: 'fRedux',
            methodName: 'updateObjects',
            args: [
              {
                path: 'verifyAuth',
                data: { active: true },
              },
            ],
            resource,
            source: ControlSource.USER,
          });
        } else {
          if (manifest.fAuth === true) {
            Logger.terminalInfo('accessToken', accessToken);
            fAuth.verifyToken(accessToken).then((response) => {
              Logger.terminalInfo('response', response);
              if (response.status === true) {
                Controller.execute({
                  serviceName: 'fRedux',
                  methodName: 'updateObjects',
                  args: [
                    {
                      path: 'verifyAuth',
                      data: { active: true },
                    },
                    {
                      path: 'user',
                      data: response.result,
                    },
                  ],
                  resource,
                  source: ControlSource.USER,
                });
              } else {
                Controller.execute({
                  serviceName: 'fRedux',
                  methodName: 'updateObject',
                  args: {
                    path: 'verifyAuth',
                    data: { active: false },
                  },
                  resource,
                  source: ControlSource.USER,
                });
              }
            });
          } else {
            Controller.execute({
              serviceName: 'fRedux',
              methodName: 'updateObjects',
              args: [
                {
                  path: 'verifyAuth',
                  data: { active: false },
                },
              ],
              resource,
              source: ControlSource.USER,
            });
          }
        }
      }
    }
  }

  render() {
    const { verifyAuth, theme, lang } = this.props;
    const AppConfig = this.props.resource?.appResource?.AppConfig || {};
    const { sidebar } = AppConfig.container;
    const resource = { appResource: { ...this.props.resource.appResource }, reduxResource: { theme, lang, verifyAuth, ...Store.getState()?.filiot } };

    if (typeof verifyAuth?.active !== 'undefined' && resource?.appResource?.init === true) {
      return (
        <div className='App'>
          <div
            style={{
              // width: resource.width + 4,
              // height: resource.height + 4,
              width: resource?.appResource?.width,
              height: resource?.appResource?.height,
              overflow: 'auto',
              display: typeof sidebar !== 'undefined' ? 'flex' : 'block',
              position: 'relative',
              // border: '2px solid #bfbfbf',
              // borderRadius: '8px',
              // boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
            }}>
            <Modal />
            <AppCom verifyAuth={verifyAuth} resource={resource} />
          </div>
        </div>
      );
    } else {
      return <LoadingPage image={resource.reduxResource.theme.icons.spin} />;
    }
  }
}

const App = connect((state) => {
  return {
    verifyAuth: state.filiot?.verifyAuth,
    theme: state.filiot?.theme,
    lang: state.filiot?.lang,
  };
})(_App);

export const build = (AppConfig, resource) => {
  return React.createElement(App, {
    resource: {
      appResource: { ...resource.appResource, AppConfig },
    },
  });
};

export const type = 'App';
