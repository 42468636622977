import React from 'react';
import { connect } from 'react-redux';
import WifiComponent from './wificomponent';
import { DeviceEventEmitter, FastImage, ScrollView, StyleSheet, Text, TouchableOpacity, View } from '../../React-Native/types';
import DetailWifi from './detailwifi';
import Modal from '../Modal';
import Logger from '../../../../../../Helper/Logger';

class ConfigWifi extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      wifi: [],
      modalDetailWifi: {},
    };
  }

  async componentDidMount() {
    const { data } = this.props;
    if (!Array.isArray(data)) {
      this.setState({ wifi: [] });
    } else {
      this.setState({ wifi: data });
    }

    // DeviceEventEmitter.addListener('onSaveWifi', this.onSaveWifi);
    // DeviceEventEmitter.addListener('onDeleteWifi', this.onDeleteWifi);
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
      const { data } = this.props;
      if (!Array.isArray(data)) {
        this.setState({ wifi: [] });
      } else {
        this.setState({ wifi: data });
      }
    }
  }

  componentWillUnmount() {
    DeviceEventEmitter.removeAllListeners();
  }

  onDeleteWifi = (_wifi) => {
    const { wifi } = this.state;
    if (wifi.length > 1 && wifi.find((__wifi) => __wifi.ssid === _wifi.ssid)?.active) {
      this.props.onDeleteSetting?.({ wifi: { ssid: _wifi.ssid } });
    }
  };

  onNavigateDetailWifi = (wifi) => {
    this.setState({
      modalDetailWifi: {
        visible: true,
        route: {
          params: {
            wifi,
            wifis: this.state.wifi,
          },
        },
        navigation: {
          goBack: () =>
            this.setState({
              modalDetailWifi: {
                visible: false,
              },
            }),
        },
        onSaveWifi: this.onSaveWifi,
        onDeleteWifi: this.onDeleteWifi,
      },
    });
  };

  goBack = () => {
    const { navigation } = this.props;
    navigation.goBack?.();
  };

  addWifi = () => {
    const { wifi } = this.state;
    if (wifi.length < 3) {
      this.onNavigateDetailWifi({ ssid: '', pass: '' });
    }
  };

  onOpenDetailWifi = (wifi) => {
    this.onNavigateDetailWifi(wifi);
  };

  onChangeWifi = (wifi, ssid) => {
    const findWifi = this.state.wifi.find((e) => e.ssid === ssid);
    if (typeof findWifi === 'undefined') {
      this.setState({
        wifi: [...this.state.wifi, wifi],
      });
    } else {
      this.setState({
        wifi: [...this.state.wifi].map((e) => {
          if (e.ssid.toString() === ssid.toString()) {
            return wifi;
          }
          return e;
        }),
      });
    }
  };

  onSaveWifi = (wifi) => {
    clearInterval(this.interval);
    this.props.onUpdateSetting?.({
      wifi,
    });
  };

  render() {
    const { theme, lang } = this.props;
    const { wifi, modalDetailWifi } = this.state;

    Logger.terminalInfo('render wifi', wifi);

    return (
      <View style={{ width: '100%', flex: 1, justifyContent: 'center' }}>
        <Modal
          visible={modalDetailWifi.visible}
          style={{
            content: {
              width: '500px',
              height: '600px',
              backgroundColor: 'rgb(249, 249, 252)',
            },
          }}>
          <DetailWifi {...modalDetailWifi} />
        </Modal>
        <View
          style={{
            width: '100%',
            paddingBottom: 16,
            paddingHorizontal: 24,
            paddingTop: 50,
          }}>
          <View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <TouchableOpacity style={{ position: 'absolute', left: -16, padding: 16 }} onPress={this.goBack}>
              <FastImage source={theme.icons.back} style={{ width: 32, height: 32 }} />
            </TouchableOpacity>
            <Text
              ellipsizeMode={'tail'}
              numberOfLines={1}
              style={{
                color: theme.colors.textColor,
                fontFamily: theme.fonts.fontBold,
                width: '100%',
                flex: 1,
                fontSize: 20,
                lineHeight: 28,
                maxWidth: '70%',
                textAlign: 'center',
              }}>
              {lang.selectWifi}
            </Text>
            <TouchableOpacity style={{ position: 'absolute', right: -16, padding: 16 }} onPress={this.addWifi}>
              <FastImage source={theme.icons.add} style={{ width: 32, height: 32 }} />
            </TouchableOpacity>
          </View>
        </View>
        <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
          <View style={[styles.body, { backgroundColor: theme.colors.appColorThird }]}>
            {Array.isArray(wifi) &&
              wifi.map((item, key) => {
                return (
                  <WifiComponent
                    key={key}
                    data={item}
                    onSaveWifi={this.onSaveWifi}
                    onDeleteWifi={this.onDeleteWifi}
                    onOpenDetailWifi={() => this.onOpenDetailWifi(item)}
                  />
                );
              })}
          </View>
        </ScrollView>
      </View>
    );
  }
}

export default connect((state) => {
  return {
    theme: state.theme,
    lang: state.lang,
  };
}, {})(ConfigWifi);

const styles = StyleSheet.create({
  change: {
    fontSize: 14,
  },
  btn: {
    height: 56,
    paddingHorizontal: 8,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    marginTop: 50,
    backgroundColor: '#b2baf5',
    width: '100%',
  },
  body: {
    width: '100%',
    flex: 1,
    paddingHorizontal: 24,
  },
  root: {
    width: '100%',
    flex: 1,
    paddingHorizontal: 24,
  },
});
