import React, { Component } from 'react';
import { Menu, MenuItem, Sidebar, SubMenu } from 'react-pro-sidebar';
import Builder from '../../..';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { fRedux } from '../../../../Controller/fRedux';
import { COLLAPSED_WIDTH, FULL_WIDTH, SIDEBAR_STATES, customBreakPoint } from '../../../../Constant/Sidebar';
import { getSidebarState, getSidebarWidth } from '../../../../Helper/responsive';
import Controller from '../../../../Controller';
import { ControlSource } from '../../../../Constant/ControlFlow';

export default class sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      toggled: false,
      breakPoint: 'sm',
      broken: false,
      key: uuidv4(),
    };
    this.ref = undefined;
    this.brokenRef = React.createRef();
    this.buttonBrokenRef = React.createRef();
  }

  componentDidMount() {
    const { width, height, resolutionRatio } = this.props.resource?.appResource;

    const initSidebarState = getSidebarState(width / resolutionRatio);
    switch (initSidebarState) {
      case SIDEBAR_STATES.FULL:
        fRedux.updateObject({ path: 'sidebar.state', data: SIDEBAR_STATES.FULL });
        this.setState({
          collapsed: false,
        });
        break;
      case SIDEBAR_STATES.COLLAPSED:
        fRedux.updateObject({ path: 'sidebar.state', data: SIDEBAR_STATES.COLLAPSED });
        this.setState({
          collapsed: true,
        });
        break;
      case SIDEBAR_STATES.BREAK:
        this.setState({ broken: true });

      default:
        break;
    }

    this.setState({
      key: uuidv4(),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.resource?.appResource.width !== prevProps.resource?.appResource.width ||
      this.props.resource?.appResource.height !== prevProps.resource?.appResource.height
    ) {
      const { width, resolutionRatio } = this.props.resource?.appResource;

      const sidebarState = getSidebarState(width / resolutionRatio);

      switch (sidebarState) {
        case SIDEBAR_STATES.FULL:
          fRedux.updateObject({ path: 'sidebar.state', data: SIDEBAR_STATES.FULL });
          this.setState({
            collapsed: false,
            broken: false,
          });
          break;
        case SIDEBAR_STATES.COLLAPSED:
          fRedux.updateObject({ path: 'sidebar.state', data: SIDEBAR_STATES.COLLAPSED });
          this.setState({
            collapsed: true,
            broken: false,
          });
          break;
        case SIDEBAR_STATES.BREAK:
          this.setState({ broken: true });
        default:
          break;
      }
    }

    if (this.state.broken !== prevState.broken && this.state.broken === true) {
      fRedux.updateObject({ path: 'sidebar.state', data: { state: SIDEBAR_STATES.BREAK } });
    }
  }

  componentWillUnmount() {
    fRedux.updateObject({ path: 'sidebar.state', data: { state: SIDEBAR_STATES.NONE } });
  }

  onCollapsed = () => {
    fRedux.updateObject({ path: 'sidebar.state', data: !this.state.collapsed ? SIDEBAR_STATES.COLLAPSED : SIDEBAR_STATES.FULL });
    this.setState({ collapsed: !this.state.collapsed });
  };

  renderItem = (item) => {
    const { resource } = this.props;
    switch (item.itemType) {
      case 'sub_menu':
        const menu = item.menu;
        return (
          <SubMenu
            {...item}
            style={{ ...item.style, height: `${50 * resource?.appResource.resolutionRatio}px`, fontSize: `${16 * resource?.appResource.resolutionRatio}px` }}
            label={item.title}
            icon={
              typeof item.icon?.source !== 'undefined' && (
                <img src={item.icon?.source} style={{ width: '20px', height: '20px', ...item.icon?.style }} alt={item.icon?.alt} />
              )
            }>
            {Array.isArray(menu) &&
              menu.map((subItem) => {
                return this.renderItem(subItem);
              })}
          </SubMenu>
        );
      case 'menu_item':
        if (item.title) {
          return (
            <MenuItem
              {...item}
              component={
                typeof item.link === 'string' ? (
                  <div
                    onClick={() => {
                      Controller.execute({
                        serviceName: 'navigator',
                        methodName: 'navigate',
                        args: {
                          uri: item.link,
                        },
                        resource,
                        source: ControlSource.USER,
                      });
                    }}
                  />
                ) : null
              }
              disabled={item.disabled ?? false}
              style={{ ...item.style, height: `${50 * resource?.appResource.resolutionRatio}px`, fontSize: `${16 * resource?.appResource.resolutionRatio}px` }}
              icon={
                typeof item.icon?.source !== 'undefined' && (
                  <img
                    src={item.icon?.source}
                    style={{
                      width: '20px',
                      height: '20px',
                      ...item.icon?.style,
                    }}
                    alt={item.icon?.alt}
                  />
                )
              }>
              {item.title}
            </MenuItem>
          );
        }
        break;
      case 'logo':
        return (
          <MenuItem active={false} disabled={true} style={item.style}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                padding: '15px 12px 15px 12px',
                ...item.logoStyle,
              }}>
              <img
                src={item.src}
                style={{
                  width: `${200 * resource.appResource?.resolutionRatio}px`,
                  height: 'auto',
                }}
                alt='logo'
              />
            </div>
          </MenuItem>
        );
      default:
        return <></>;
    }
  };

  renderCollapsedBtn = () => {
    const { resource } = this.props;
    const collape = {
      width: 25,
      height: 25,
      position: 'absolute',
      right: -15,
      top: resource?.appResource.height / 2,
      zIndex: 999,
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#ffffff',
      borderRadius: 100,
      boxShadow: '1px 1px 8px #888888',
    };

    return (
      <div style={collape} className='collape' onClick={this.onCollapsed}>
        <img src='https://image-app-public.s3.ap-southeast-1.amazonaws.com/giangha/right-arrow.png' alt='menu-button' style={{ width: '70%', height: '70%' }} />
      </div>
    );
  };

  renderBreakPointBtn = () => {
    const toggled = {
      width: 25,
      height: 25,
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'transparent',
    };

    const layoutToggled = {
      backgroundColor: '#ffffff',
      width: '100%',
      height: '5%',
      position: 'absolute',
      zIndex: 999,
      display: 'flex',
      alignItems: 'center',
      padding: '0 3%',
      boxShadow: '1px 1px 8px #888888',
    };

    return (
      <div style={layoutToggled}>
        <div style={toggled} onClick={this.handleToggled}>
          <img src='https://image-app-public.s3.ap-southeast-1.amazonaws.com/giangha/menu.png' alt='menu-button' style={{ width: '100%', height: '100%' }} />
        </div>
      </div>
    );
  };

  handleToggled = () => {
    this.setState({ toggled: !this.state.toggled });
  };

  renderBroken = () => {
    const sidebar = this.props.sidebar;
    const resource = this.props.resource;
    const { width, height } = resource?.appResource;
    if (this.state.broken) {
      if (this.state.toggled) {
        return (
          <div style={{ position: 'fixed', zIndex: 999, width, height, display: 'flex' }} key={this.state.key} ref={this.brokenRef}>
            <Sidebar
              ref={(ref) => (this.ref = ref)}
              transitionDuration={1000}
              onBackdropClick={() => this.setState({ toggled: false })}
              backgroundColor={'#ffffff'}>
              <Menu>
                {Array.isArray(sidebar) &&
                  sidebar.map((item) => {
                    return this.renderItem(item);
                  })}
              </Menu>
            </Sidebar>
            <div
              style={{
                flex: 1,
                backgroundColor: 'rgba(0, 0, 0, .5)',
              }}
              onClick={this.handleToggled}></div>
          </div>
        );
      } else {
        return this.renderBreakPointBtn();
      }
    }
  };

  render() {
    const sidebar = this.props.sidebar;
    const resource = this.props.resource;

    if (this.state.broken) {
      return this.renderBroken();
    } else {
      return (
        <div style={{ height: '100%', position: 'fixed', left: 0, zIndex: 999 }} key={this.state.key}>
          {this.renderCollapsedBtn()}

          <Sidebar
            ref={(ref) => (this.ref = ref)}
            transitionDuration={1000}
            toggled={this.state.toggled}
            collapsed={this.state.collapsed ? true : false}
            onBreakPoint={(broken) => {}}
            onBackdropClick={() => this.setState({ toggled: false })}
            backgroundColor={'#ffffff'}
            style={{
              height: '100%',
            }}
            width={`${FULL_WIDTH * resource?.appResource.resolutionRatio}px`}
            collapsedWidth={`${COLLAPSED_WIDTH * resource?.appResource.resolutionRatio}px`}>
            <Menu>
              {Array.isArray(sidebar) &&
                sidebar.map((item) => {
                  return this.renderItem(item);
                })}
            </Menu>
          </Sidebar>
        </div>
      );
    }
  }
}
