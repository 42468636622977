import React, { Component } from 'react';
import { TYPE_WINDOWS } from '../../../../Constant/Constant';
import Builder from '../../..';
import { getConfigFromSize, getConfigHeaderFromSize } from '../../../../Helper/responsive';
import { v4 as uuidv4 } from 'uuid';
import * as EnvResponsiveHeader from '../../../../Constant/ResponsiveHeader';

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: undefined,
      width: undefined,
      heightBase: undefined,
      widthBase: undefined,
      fontSizeRatio: undefined,
      col: undefined,
      row: undefined,
      key: uuidv4(),
      typeWindow: TYPE_WINDOWS.LARGE,
      init: false,
    };
  }

  componentDidMount() {
    const { header } = this.props;
    const { width, resolutionRatio } = this.props.resource?.appResource;
    const { height } = header || 80;
    // window.addEventListener('resize', () => {
    //   const width = document.documentElement.clientWidth;
    //   Logger.terminalInfo('header listener width', width);
    //   const config = getConfigHeaderFromSize(width, height, EnvResponsiveHeader);
    //   Logger.terminalInfo('configconfigconfigconfig header', config);

    //   this.setState({
    //     widthBase: config.widthBase,
    //     heightBase: config.heightBase,
    //     width: width,
    //     height,
    //     col: config.col,
    //     row: config.row,
    //     typeWindow: config.type,
    //     fontSizeRatio: config.fontSizeRatio,
    //     key: uuidv4(),
    //   });
    // });

    // const width = document.documentElement.clientWidth;

    const config = getConfigHeaderFromSize(width, height * resolutionRatio, resolutionRatio, EnvResponsiveHeader);

    this.setState({
      widthBase: config.widthBase,
      typeWindow: config.type,
      width: width,
      height,
      heightBase: config.heightBase,
      fontSizeRatio: config.fontSizeRatio,
      col: config.col,
      row: config.row,
      key: uuidv4(),
      init: true,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.resource?.appResource?.width !== this.props.resource?.appResource?.width) {
      const { header } = this.props;
      const { width, resolutionRatio } = this.props.resource?.appResource;
      const { height } = header || 80;

      const config = getConfigHeaderFromSize(width, height * resolutionRatio, resolutionRatio, EnvResponsiveHeader);

      this.setState({
        widthBase: config.widthBase,
        typeWindow: config.type,
        width: width,
        height,
        heightBase: config.heightBase,
        fontSizeRatio: config.fontSizeRatio,
        col: config.col,
        row: config.row,
        key: uuidv4(),
        init: true,
      });
    }
  }

  renderView = () => {
    const { resource, header } = this.props;

    const _resource = { ...resource, appResource: { ...resource?.appResource, ...this.state, header } };
    const { typeWindow } = this.state;

    const viewElement = Builder.build(header[typeWindow.name], {
      ..._resource,
      appResource: { ...resource?.appResource, typeWindow: { ..._resource.typeWindow, use: typeWindow.name } },
    });
    if (React.isValidElement(viewElement)) {
      return viewElement;
    } else {
      const _viewElement = Builder.build(header[typeWindow.secondName], {
        ..._resource,
        appResource: { ...resource?.appResource, typeWindow: { ..._resource.typeWindow, use: typeWindow.secondName } },
      });
      if (React.isValidElement(_viewElement)) {
        return _viewElement;
      } else {
        return <></>;
      }
    }
  };

  render() {
    if (this.state.init) {
      const { resource, header } = this.props;
      const { height } = this.props.header;
      const { width, isPreview, resolutionRatio } = resource?.appResource;
      return (
        <div
          style={{
            width: width - 8,
            height: height * resolutionRatio,
            backgroundColor: '#11468c',
            position: isPreview ? 'sticky' : 'fixed',
            top: '0px',
            zIndex: 999,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
          key={this.state.key}>
          <div
            style={{
              position: 'relative',
              width: this.state.widthBase,
              height: this.state.heightBase,
            }}>
            {this.renderView()}
          </div>
        </div>
      );
    }
    return <></>;
  }
}
