import instance from "./api";

export class _API {
  constructor() {
    this.api = instance();
  }

  setInterceptor(requestIn, responseIn) {
    this.api = instance(requestIn, responseIn);
  }

  async get(uri = "", headers = {}, query = {}) {
    try {
      const response = await this.api.get(uri, {
        headers,
        fetch: {
          mode: "cors", // Set the mode to "cors"
        },
        params: {
          ...query,
        },
      });

      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  async post(uri = "", headers = {}, form = {}) {
    try {
      //Logger.terminalInfo('POST/ uri: ', uri);
      //Logger.terminalInfo('POST/ headers: ', headers);
      //Logger.terminalInfo('POST/ form: ', form);

      const response = await this.api.post(uri, form, {
        headers,
      });

      return response?.data;
    } catch (error) {
      //Logger.terminalInfo('POST ERROR/ ', uri, headers, error.response.data);
      throw error.response?.data;
    }
  }

  async put(uri = "", headers = {}, form = {}) {
    try {
      //Logger.terminalInfo('PUT/ uri: ', uri);
      //Logger.terminalInfo('PUT/ requestOptions: ', headers);
      //Logger.terminalInfo('PUT/ form: ', form);

      const response = await this.api.put(uri, form, {
        headers,
      });

      return response?.data;
    } catch (error) {
      //Logger.terminalInfo('PUT ERROR/ ', uri, headers, error.response.data);
      throw error;
    }
  }

  async del(uri = "", headers = {}, form) {
    try {
      //Logger.terminalInfo('DELETE/ uri: ', uri);
      //Logger.terminalInfo('DELETE/ requestOptions: ', headers);
      //Logger.terminalInfo('DELETE/ form: ', form);

      const config = { headers };

      if (form) {
        config.data = { ...form };
      }

      const response = await this.api.delete(uri, {
        ...config,
      });

      return response?.data;
    } catch (error) {
      //Logger.terminalInfo('DELETE ERROR/ ', uri, headers, error.response.data);
      throw error;
    }
  }
}

const api = new _API();

export default api;
