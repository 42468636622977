import React, { Component } from 'react';
import { connect } from 'react-redux';

class SelectModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  render() {
    const { theme } = this.props;
    const styles = { ...style, ...this.style };
    const { value } = this.state;
    return null;
    // return (
    //   <FlatList
    //     style={{ maxHeight: '100%' }}
    //     contentContainerStyle={{ height: 57 * this.props.data?.length }}
    //     data={this.props.data}
    //     renderItem={({ item, index }) => {
    //       return (
    //         <TouchableOpacity
    //           style={[styles.row, { borderTopWidth: index !== 0 ? 1 : 0, borderTopColor: theme.colors.borderColor }]}
    //           key={item?.id || 'key' + index}
    //           onPress={() => {
    //             this.setState({ value: item.value }, () => {
    //               this.props.save(item.value);
    //             });
    //           }}>
    //           <View style={{ maxWidth: '93%' }}>
    //             <Text style={[styles.textSelect, { color: theme.colors.textColor, fontFamily: theme.fonts.fontBold }]}>{item.label}</Text>
    //           </View>
    //           {item.value === value && <FastImage source={theme.icons.check} style={{ width: 20, height: 20 }} />}
    //         </TouchableOpacity>
    //       );
    //     }}
    //   />
    // );
  }
}

const style = {
  textSelect: {
    fontSize: 14,
    lineHeight: 24,
  },
  row: {
    width: '100%',
    paddingVertical: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
};

export default connect((state) => {
  return {
    theme: state.theme,
    lang: state.lang,
  };
})(SelectModal);
