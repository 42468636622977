import { CHANGE_LANG } from '../type.js';
import vi from '../../Lang/vi.json';
import en from '../../Lang/en.json';

const DefaultState = {
  ...vi,
  __type: 'vi',
};

const changeLangReducer = (state = DefaultState, action) => {
  if (action.type === CHANGE_LANG) {
    const { __type } = action.value;
    switch (__type) {
      case 'vi':
        return { vi, __type: 'vi' };
      case 'en':
        return { en, __type: 'en' };
      default:
        return { vi, __type: 'vi' };
    }
  }
  return state;
};

export default changeLangReducer;
