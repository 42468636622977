import React, { Component } from 'react';
import SelectedView from './SelectedView';
import ToolBox from './ToolBox';
import PagePreprocessing from './PagePreprocessing';
import Manifest from './Manifest';
import BlockPreprocessing from './BlockPreprocessing';

export default class index extends Component {
  render() {
    const { resource, onAddUIViewOfPageInProject, onChangeUIViewOfPageInProject, setSelectedView, visualPreviewMode } = this.props;
    return (
      <div
        style={{
          minWidth: '200px',
          maxWidth: '300px',
          height: '100vh',
          backgroundColor: '#e8e8e8',
          flex: 1,
          paddingTop: '40px',
        }}>
        <Manifest resource={resource}/>
        <BlockPreprocessing resource={resource}/>
        <PagePreprocessing resource={resource} />
        <SelectedView
          view={resource.selectedPreviewView}
          onChangeUIViewOfPageInProject={onChangeUIViewOfPageInProject}
          resource={resource}
          setSelectedView={setSelectedView}
          visualPreviewMode={visualPreviewMode}
        />
        <ToolBox onAddUIViewOfPageInProject={onAddUIViewOfPageInProject} resource={resource} visualPreviewMode={visualPreviewMode} />
      </div>
    );
  }
}
