import React from 'react';
import BaseComponent from '../BaseComponent/BaseComponent';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Carousel.css';

export default class Carousel extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isNextArrowHovered: false,
      isPrevArrowHovered: false,
    };

    this.styleNextHover = this.props.sampleNextArrow?.props?.nextStyle;
    this.arrowNextHover = this.props.sampleNextArrow?.props?.nextStyle?.['&:hover'];

    this.stylePrevHover = this.props.samplePrevArrow?.props?.prevStyle;
    this.arrowPrevHover = this.props.samplePrevArrow?.props?.prevStyle?.['&:hover'];
  }

  SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    const { isNextArrowHovered } = this.state;

    const arrowStyle = isNextArrowHovered
      ? {
          ...this.styleNextHover,
          ...this.arrowNextHover,
        }
      : { ...this.styleNextHover };

    return (
      <div
        className={this.props.sampleNextArrow !== undefined ? 'slick-arrow' : className}
        style={{
          ...style,
          ...this.props.sampleNextArrow?.props?.nextStyle,
          position: 'absolute',
          ...arrowStyle,
          zIndex: 99,
        }}
        onMouseEnter={() => {
          this.setState({ isNextArrowHovered: true, isPrevArrowHovered: false });
        }}
        onMouseLeave={() => {
          this.setState({ isNextArrowHovered: false });
        }}
        onClick={onClick}>
        {this.props.sampleNextArrow}
      </div>
    );
  };

  SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    const { isPrevArrowHovered } = this.state;

    const arrowStyle = isPrevArrowHovered
      ? {
          ...this.stylePrevHover,
          ...this.arrowPrevHover,
        }
      : { ...this.stylePrevHover };

    return (
      <div
        className={this.props.samplePrevArrow !== undefined ? 'slick-arrow' : className}
        style={{
          ...style,
          ...this.props.samplePrevArrow?.props?.prevStyle,
          position: 'absolute',
          ...arrowStyle,
          zIndex: 99,
        }}
        onMouseEnter={() => {
          this.setState({ isPrevArrowHovered: true, isNextArrowHovered: false });
        }}
        onMouseLeave={() => {
          this.setState({ isPrevArrowHovered: false });
        }}
        onClick={onClick}>
        {this.props.samplePrevArrow}
      </div>
    );
  };

  renderComponent = () => {
    const { x, y, width, height, value, renderItem, data } = this.props;
    let Item = () => {};

    if (typeof renderItem === 'function') {
      Item = renderItem;
    }

    const SampleNextArrow = this.SampleNextArrow;
    const SamplePrevArrow = this.SamplePrevArrow;

    const settings = {
      dots: this.props.dots,
      infinite: true,
      speed: 500,
      slidesToShow: this.props.slidesToShow || 1,
      slidesToScroll: this.props.slidesToScroll || 1,
      autoplay: this.props.autoplay || false,
      arrows: this.props.arrows,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };

    return (
      <Slider {...this.restProps} {...settings} style={{ width: '100%', height: '100%' }}>
        {data.map((item, index) => {
          return (
            <div>
              <div
                style={{
                  position: 'relative',
                }}>
                <Item item={item} index={index} key={index} />
              </div>
            </div>
          );
        })}
      </Slider>
    );
  };
}
