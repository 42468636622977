import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Popconfirm, Table } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { useForm } from 'antd/es/form/Form';

const EditableCell = ({ editing, title, editable, children, dataIndex, record, handleSave, handleChange, dataSource, ...restProps }) => {
  if ((dataIndex === 'key' && record?.editKeyAble === true && editing) || (dataIndex === 'value' && record?.editValueAble === true && editing)) {
    return (
      <td {...restProps}>
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
            {
              validator: (_, value) => {
                if (dataIndex === 'value') {
                  return Promise.resolve();
                } else {
                  if (
                    !dataSource
                      .filter((row) => row.id !== record.id)
                      .map(({ key }) => key)
                      .includes(value)
                  ) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(new Error('Key is existed'));
                  }
                }
              },
            },
          ]}>
          <Input />
        </Form.Item>
      </td>
    );
  } else {
    const child = (dataIndex) => {
      switch (dataIndex) {
        case 'key':
          return record.key?.toString();
        case 'value':
          return record.value?.toString();
        default:
          return children;
          break;
      }
    };
    return (
      <td {...restProps}>
        <div
          className='editable-cell-value-wrap'
          style={{
            paddingRight: 24,
          }}>
          {child(dataIndex)}
        </div>
      </td>
    );
  }
};

const components = {
  body: {
    cell: EditableCell,
  },
};

const ResourceTable = (props) => {
  const [form] = useForm();
  const [dataSource, setDataSource] = useState(props.dataSource.map((_dataSource) => ({ ..._dataSource, id: uuidv4() })));
  const [editingKey, setEditingKey] = useState('');

  const handleDelete = async (id) => {
    const newDataSource = [...dataSource.filter((_dataSource) => _dataSource.id !== id)];

    setDataSource(newDataSource);
    props.onChangeDataSource?.(newDataSource);
  };
  const handleSave = async () => {
    try {
      const row = await form.validateFields();

      const newDataSource = [
        ...dataSource.map((_dataSource) => {
          if (editingKey === _dataSource.id) {
            return { ..._dataSource, ...row, editing: false, editKeyAble: false, editValueAble: true };
          } else {
            return { ..._dataSource, editing: false, editKeyAble: false, editValueAble: true };
          }
        }),
      ];

      setDataSource(newDataSource);
      props.onChangeDataSource?.(newDataSource);
    } catch (error) {}
  };

  const handleAdd = () => {
    const lastRecord = dataSource[dataSource.length - 1];
    if (lastRecord.key !== '') {
      const newId = uuidv4();
      setDataSource([
        ...dataSource.map((row) => {
          return { ...row, editing: false };
        }),
        {
          key: '',
          value: '',
          deleteable: true,
          editing: true,
          editKeyAble: true,
          editValueAble: true,
          id: newId,
        },
      ]);

      form.setFieldsValue({
        key: '',
        value: '',
      });
      setEditingKey(newId);
    }
  };
  const handleEdit = (id) => {
    const record = dataSource.find((_dataSource) => _dataSource.id === id);
    form.setFieldsValue({
      key: '',
      value: '',
      ...record,
    });
    setEditingKey(id);
    setDataSource(
      [...dataSource].map((row) => {
        if (row.id === id) {
          return { ...row, editing: true };
        }
        return { ...row, editing: false };
      }),
    );
  };

  const defaultColumns = [
    {
      title: 'Key',
      dataIndex: 'key',
      width: '30%',
      ellipsis: true,
    },
    {
      title: 'Value',
      dataIndex: 'value',
      width: '40%',
      ellipsis: true,
    },
    {
      title: 'Operations',
      dataIndex: 'operation',
      render: (_, record) => {
        return (
          <>
            {record.deleteable && (
              <Popconfirm title='Sure to delete?' onConfirm={() => handleDelete(record.id)}>
                <Button style={{ marginRight: '20px' }}>Delete</Button>
              </Popconfirm>
            )}
            {(record.editKeyAble || record.editValueAble) &&
              (record.editing ? <Button onClick={() => handleSave()}>Save</Button> : <Button onClick={() => handleEdit(record.id)}>Edit</Button>)}
          </>
        );
      },
      ellipsis: true,
    },
  ];

  const columns = defaultColumns.map((col) => {
    return {
      ...col,
      onCell: (record) => {
        return {
          record,
          dataSource,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: record.editing,
        };
      },
    };
  });

  return (
    <>
      <Form form={form} component={false}>
        <Table components={components} rowClassName={() => 'editable-row'} bordered dataSource={dataSource} columns={columns} pagination={false} />{' '}
        <Button onClick={handleAdd} type='primary' style={{ marginTop: 16 }}>
          Add a row
        </Button>
      </Form>
    </>
  );
};

export default ResourceTable;
