import Logger from '../../Helper/Logger';
import { extractValue } from '../../Helper/utils';
import { regexTextMarkdown } from './constant';

class _Node {
  constructor() {
    this.start = 0;
    this.end = 0;
    this.value = '';
    this.children = [];
    this.parent = null;
  }
}

const getWordsBetweenCurlies = (str) => {
  const regex = new RegExp(regexTextMarkdown, 'g');
  let element;
  const results = [];
  while ((element = regex.exec(str))) {
    results.push({ text: element[0], fields: element[1].split('.') });
  }
  return results;
};

const extractFromData = (fields, data) => {
  if (fields[0] === 'this') {
    const extractData = extractValue(fields.slice(1, fields.length), data);
    return extractData;
  } else if (fields[0] === 'theme') {
    const extractData = extractValue([...fields], data);
    return extractData;
  } else if (fields[0] === 'lang') {
    const extractData = extractValue([...fields], data);
    return extractData;
  } else {
    const extractData = extractValue(fields, data);
    return extractData;
  }
};

const extractFromResource = (fields, data) => {
  let unmarkValue = undefined;
  if (typeof data.variableEnvironment === 'undefined') {
    unmarkValue = extractFromData(fields, data);
  } else {
    const extractByVariable = extractFromData(fields, data.variableEnvironment);
    if (extractByVariable === null || typeof extractByVariable === 'undefined') {
      unmarkValue = extractFromData(fields, data);
    } else {
      unmarkValue = extractByVariable;
    }
  }

  return unmarkValue;
};

const extractMarkdown = (str, data) => {
  Logger.terminalInfo('str', str);
  let newStr = str;
  const regexResults = getWordsBetweenCurlies(newStr);
  if (regexResults.length === 1) {
    const { fields } = regexResults[0];
    let unmarkValue = undefined;
    if (typeof data.variableEnvironment === 'undefined') {
      unmarkValue = extractFromData(fields, data);
    } else {
      const extractByVariable = extractFromData(fields, data.variableEnvironment);
      if (extractByVariable === null || typeof extractByVariable === 'undefined') {
        unmarkValue = extractFromData(fields, data);
      } else {
        unmarkValue = extractByVariable;
      }
    }
    if (regexResults[0].text === str) {
      return unmarkValue;
    } else {
      newStr = newStr.replace(regexResults[0].text, unmarkValue);
    }
  } else {
    regexResults.forEach(({ text, fields }) => {
      newStr = newStr.replace(text, extractMarkdown(fields, data));
    });
  }
  return newStr;
};

const extractMarkdown2 = (_node, data, forceValue = true, test) => {
  const children = _node.children;
  if (children.length === 0) {
    const fields = _node.value.slice(2, _node.value.length - 2).split('.');
    const result = extractFromResource(fields, data);

    return result === null ? (forceValue === true ? null : _node.value) : result;
  }
  let newStr = _node.value;
  children.forEach((__node) => {
    newStr = newStr.replace(__node.value, extractMarkdown2(__node, data, forceValue));
  });

  const words = getWordsBetweenCurlies(newStr);

  if (words.length === 1 && words[0].text === newStr) {
    const result = extractFromResource(words[0].fields, data);
    return result === null ? (forceValue === true ? null : words[0].text) : result;
  }

  return newStr;
};

function extractExpressions(input) {
  let root = new _Node();
  root.value = input;
  root.start = 0;
  root.end = input.length;
  let currentNode = root;

  const quotesUsed = Object.fromEntries(Array.from(Array(input.length).keys()).map((index) => [index, false]));

  for (let i = 0; i < input.length; i++) {
    const currentChar = input.charAt(i);
    const nextChar = input.charAt(i + 1);

    if (`${currentChar}${nextChar}` === '{{' && quotesUsed[i] === false && quotesUsed[i + 1] === false) {
      quotesUsed[i] = true;
      quotesUsed[i + 1] = true;
      const newNode = new _Node();
      newNode.start = i;
      newNode.parent = currentNode;
      currentNode.children.push(newNode);
      currentNode = newNode;
    }

    if (`${currentChar}${nextChar}` === '}}' && quotesUsed[i] === false && quotesUsed[i + 1] === false) {
      quotesUsed[i] = true;
      quotesUsed[i + 1] = true;
      currentNode.end = i + 2;
      currentNode.value = input.slice(currentNode.start, currentNode.end);
      currentNode = currentNode.parent;
    }
  }

  if (root.children.length === 1 && root.value === root.children[0].value) {
    root = root.children[0];
    root.parent = null;
  }

  return root;
}

export const build = (TextMarkdownConfig, resource, forceValue = true) => {
  const treeMarkdownAnalysis = extractExpressions(TextMarkdownConfig.data);

  const result = extractMarkdown2(
    treeMarkdownAnalysis,
    {
      ...resource.appResource,
      ...resource.reduxResource,
      variableEnvironment: resource.variableEnvironment,
    },
    forceValue,
  );
  return result;
};

export const type = 'TextMarkDown';
