import { Storage } from '../Storage';
import { fRedux } from '../fRedux';
import fAPI from '../fAPI';
import { BASE_URL, API } from '../fAPI/constants';
import Logger from '../../Helper/Logger';

const BASE_URI = BASE_URL.prod;

/**
 * Get data in filiot report year
 * @param { originId: string }: originId of device.
 * @param { time: string }: time to show in api.
 * @param { os: string }: os of app.
 * @param { callback: json }: callback of function.
 * @param { callback.success: function }: function will call when sign in success.
 * @param { callback.fail: function }: function will call when sign in fail.
 */
async function getReportByYear({
  originId,
  time,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.FLOG.GET_BY_YEAR}`;
    Logger.terminalInfo('uri fLog', uri);
    const response = await fAPI.get(uri, {}, { originId, time });

    Logger.terminalInfo('response', response);
    typeof callback?.success === 'function' && callback.success(response);

    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

async function getReportByMonth({
  originId,
  time,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.FLOG.GET_BY_MONTH}`;
    Logger.terminalInfo('uri fLog', uri);
    const response = await fAPI.get(uri, {}, { originId, time });

    typeof callback?.success === 'function' && callback.success(response);

    Logger.terminalInfo('response', response);

    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

async function getReportByDay({
  originId,
  time,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.FLOG.GET_BY_DAY}`;
    Logger.terminalInfo('uri fLog', uri);
    const response = await fAPI.get(uri, {}, { originId, time });
    Logger.terminalInfo('getReportByDay callback.success', callback?.success);

    typeof callback?.success === 'function' && callback.success(response);

    Logger.terminalInfo('response', response);

    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

async function getReportStateChange({
  originId,
  time,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.FLOG.GET_STATE_CHANGE}`;
    Logger.terminalInfo('uri fLog', uri);
    const response = await fAPI.get(uri, {}, { originId, time });
    Logger.terminalInfo('getReportStateChange callback.success', callback?.success);

    typeof callback?.success === 'function' &&
      callback.success({ ...response, items: Array.isArray(response.data) ? response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) : [] });

    Logger.terminalInfo('response', response);

    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

async function getReportStateChanges({
  originIds = [],
  time,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    Promise.all(
      originIds.map(async (originId) => {
        const uri = `${BASE_URI}${API.FLOG.GET_STATE_CHANGE}`;
        Logger.terminalInfo('uri fLog', uri);
        const response = await fAPI.get(uri, {}, { originId, time });
        return response;
      }),
    ).then((logResponses) => {
      typeof callback?.success === 'function' && callback.success({ items: logResponses.map((logResponse) => logResponse.data).flat() });
    });
  } catch (error) {
    Logger.terminalInfo('error', error);
    typeof callback.fail === 'function' && callback.fail({ status: false, error });
    return { status: false, error };
  }
}

export const fLog = {
  getReportByYear,
  getReportByDay,
  getReportByMonth,
  getReportStateChange,
  getReportStateChanges,
};
