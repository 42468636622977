import { THEME_SELECTION } from "../type";
import DefaultTheme from "../../Resource/Theme/Default";
import DarkTheme from "../../Resource/Theme/Dark";
import _ from "lodash";
import DecorationController from "../../Controller/Decoration";

const DefaultState = {
  ...DefaultTheme,
  __type: "default",
};

const themeSelectionReducer = (state = DefaultState, action) => {
  if (action.type === THEME_SELECTION) {
    const { __type } = action.value;
    switch (__type) {
      case "dark":
        return _.cloneDeep({
          ..._.merge(DarkTheme, DecorationController.getTheme()[__type]),
          __type: "dark",
        });

      default:
        return _.cloneDeep({
          ..._.merge(DefaultTheme, DecorationController.getTheme()[__type]),
          __type: "default",
        });
    }
  }
  return state;
};

export default themeSelectionReducer;
