import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Text, TouchableOpacity } from '../../../../React-Native/types';

class DayWeek extends Component {
  constructor(props) {
    super(props);
  }

  renderSuffix = () => {
    const { theme, lang } = this.props;
    const { value } = this.props;

    const getDayInWeek = (number) => {
      switch (number.toString()) {
        case '0':
          return 'T2';
        case '1':
          return 'T3';
        case '2':
          return 'T4';
        case '3':
          return 'T5';
        case '4':
          return 'T6';
        case '5':
          return 'T7';
        case '6':
          return 'CN';

        default:
          return '';
      }
    };

    const getDayWeek = (arrayDate = []) => {
      if (arrayDate.every((e) => e === true)) {
        return lang.everyDay;
      }
      const trueDates = [];
      arrayDate.forEach((date, index) => {
        if (date === true) {
          trueDates.push(index);
        }
      });

      let dayweek = '';
      trueDates.forEach((trueDate, index) => {
        dayweek += getDayInWeek(trueDate);
        if (index < trueDates.length - 1) {
          dayweek += ', ';
        }
      });

      return dayweek;
    };

    return (
      <Text
        style={{
          color: theme.colors.textColor,
          fontFamily: theme.fonts.fontBold,
        }}
        numberOfLines={1}
        ellipsizeMode={'tail'}>
        {getDayWeek(Array.isArray(value) ? value : [])}
      </Text>
    );
  };

  render() {
    const { title, theme } = this.props;
    const titleContent = title?.content;
    const titleStyle = title?.style;
    const styles = { ...style, ...this.style };
    return (
      <TouchableOpacity style={{ backgroundColor: theme.colors.appColor, ...styles.titleContainer, ...titleStyle?.container }} onPress={this.props.showModal}>
        <Text
          style={{
            color: theme.colors.textColor,
            fontFamily: theme.fonts.fontBold,
            ...styles.title,
            ...titleStyle?.title,
          }}
          numberOfLines={1}
          ellipsizeMode={'tail'}>
          {titleContent}
        </Text>
        {this.renderSuffix()}
      </TouchableOpacity>
    );
  }
}

const style = {
  title: {
    flex: 1,
    fontSize: 16,
    lineHeight: 24,
  },
  titleContainer: {
    flex: 1,
    height: 56,
    borderRadius: 16,
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginHorizontal: 20,
  },
};

export default connect((state) => {
  return {
    theme: state.theme,
    lang: state.lang,
  };
})(DayWeek);
