import View from './View';
import UIBuilder from '../BaseComponent';

export default class ViewBuilder extends UIBuilder {
  getType = () => {
    return 'view';
  };

  getComponent = () => {
    return View;
  };
}
export const type = 'view';
