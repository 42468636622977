import Pagination from './Pagination';
import UIBuilder from '../BaseComponent';
import { UI_COMPONENTS_TYPE_FIELD } from '../../../../Constant/Constant';

export default class PaginationBuilder extends UIBuilder {
  constructor() {
    super();
    this.schema = {
      onChange: UI_COMPONENTS_TYPE_FIELD.JSON,
    };
  }

  getType = () => {
    return 'pagination';
  };

  getComponent = () => {
    return Pagination;
  };
}
