import { TYPE_WINDOWS } from './Constant';
export const ViewMode = {
  SmartPhone: {
    type: TYPE_WINDOWS.SMALL,
    width: 390,
    height: 844,
  },
  Tablet: {
    type: TYPE_WINDOWS.MEDIUM,
    width: 750,
    height: 1077,
  },
  Desktop: {
    type: TYPE_WINDOWS.LARGE,
    width: 1440,
    height: 850,
  },
};
