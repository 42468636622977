import { WIDTH_MEDIUM_DEFAULT_MIN } from './Responsive';

export const SIDEBAR_STATES = {
  FULL: 'full',
  COLLAPSED: 'collapsed',
  BREAK: 'break',
  NONE: 'none',
};

export const FULL_WIDTH = 250;
export const COLLAPSED_WIDTH = 80;

export const customBreakPoint = WIDTH_MEDIUM_DEFAULT_MIN;
