import Builder from '..';

export const build = (ValidateConfig, resource) => {
  const { email } = ValidateConfig;
  console.log('email', email);

  const emailValue = Builder.traverseBuild(email, resource);
  const validate = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(emailValue);
  console.log('validate', validate);
  return validate;
};

export const type = 'ValidateEmail';
