import Condition from './Condition';
import UIBuilder from '../BaseComponent';
import { UI_COMPONENTS_TYPE_FIELD } from '../../../../Constant/Constant';

export default class CarouselBuilder extends UIBuilder {
  constructor() {
    super();
    this.schema = {
      test: UI_COMPONENTS_TYPE_FIELD.JSON,
      consequent: UI_COMPONENTS_TYPE_FIELD.JSON,
      alternate: UI_COMPONENTS_TYPE_FIELD.JSON,
    };
  }

  getType = () => {
    return 'condition_component';
  };

  getComponent = () => {
    return Condition;
  };
}
