import { CHANGE_LANG } from "../type";
import vi from "../../Resource/Lang/vi.json";
import en from "../../Resource/Lang/en.json";
import _ from "lodash";
import DecorationController from "../../Controller/Decoration";

const DefaultState = {
  ...vi,
  __type: "vi",
};

const changeLangReducer = (state = DefaultState, action) => {
  if (action.type === CHANGE_LANG) {
    const { __type } = action.value;
    switch (__type) {
      case "vi":
        return _.cloneDeep({
          ..._.merge(vi, DecorationController.getLang()[__type]),
          __type: "vi",
        });
      case "en":
        return _.cloneDeep({
          ..._.merge(en, DecorationController.getLang()[__type]),
          __type: "en",
        });
      default:
        return _.cloneDeep({
          ..._.merge(vi, DecorationController.getLang()[__type]),
          __type: "vi",
        });
    }
  }
  return state;
};

export default changeLangReducer;
