import _ from 'lodash';
import validate from './validate';

const functionSchema = {
  type: 'array',
  items: {
    type: 'object',
    properties: {
      functionName: {
        type: 'string',
      },
      args: {
        type: 'array',
      },
    },
    required: ['functionName'],
  },
  minItems: 1,
};

const listenFunction = {
  onPress: 'onPress',
  onLongPress: 'onLongPress',
  onChange: 'onChange',
  onChangeText: 'onChangeText',
  onChangeValue: 'onChangeValue',
};

const regexStr = '{{([^}]+)}}';

const reactElementSchema = {
  $id: 'reactElement',
  type: 'object',
  properties: {
    type: {
      type: 'string',
    },
    children: {
      type: 'array',
      items: {
        $ref: 'reactElement',
      },
    },
    props: {
      type: 'object',
    },
  },
  required: ['type', 'children', 'props'],
};

function getWordsBetweenCurlies(str) {
  const regex = new RegExp(regexStr, 'g');
  let element;
  const results = [];
  while ((element = regex.exec(str))) {
    results.push({ text: element[0], fields: element[1].split('.') });
  }
  return results;
}

export const updateObjectProperty = (obj, path, newValue) => {
  const head = path[0];
  const rest = path.slice(1, path.length);
  if (Array.isArray(obj)) {
    let newArray = [];
    // if (typeof head === 'undefined') {
    if (typeof head === 'undefined') {
      newArray = newValue;
    } else if (typeof head === -1) {
      newArray = [...obj];
      if (typeof newValue !== 'undefined') {
        newArray.push(newValue);
      }
    } else {
      obj.forEach((element, index) => {
        if (index === head) {
          if (rest.length) {
            newArray.push(updateObjectProperty(obj[head], rest, newValue));
          } else {
            if (typeof newValue !== 'undefined') {
              newArray.push(newValue);
            }
          }
        } else {
          newArray.push(element);
        }
      });
    }

    return [...newArray];
  } else {
    if (rest.length) {
      return {
        ...obj,
        [head]: updateObjectProperty(obj[head], rest, newValue),
      };
    } else {
      if (typeof newValue !== 'undefined') {
        return {
          ...obj,
          [head]: newValue,
        };
      } else {
        return { ...obj };
      }
    }
  }
};

export const extractValue = (array, object) => {
  try {
    if (array.length === 0) {
      return object;
    } else {
      const key = array[0];
      const newObject = object[key];
      return extractValue(array.slice(1, array.length), newObject);
    }
  } catch (error) {
    return '----';
  }
};

export const toString = (str, data) => {
  let newStr = str;
  const regexResults = getWordsBetweenCurlies(newStr);
  if (regexResults.length === 1) {
    const { fields } = regexResults[0];
    const extractData = extractValue(fields, data);
    return extractData;
  } else {
    regexResults.forEach(({ text, fields }) => {
      newStr = newStr.replace(text, extractValue(fields, data));
    });
  }
  return newStr;
};

export const toStringWithoutThis = (str, data) => {
  let newStr = str;
  const regexResults = getWordsBetweenCurlies(newStr);
  if (regexResults.length === 1) {
    const { fields } = regexResults[0];
    if (fields[0] !== 'this') {
      const extractData = extractValue(fields, data);
      return extractData;
    }
  } else {
    regexResults.forEach(({ text, fields }) => {
      newStr = newStr.replace(text, extractValue(fields, data));
    });
  }
  return newStr;
};

export const toStringWithThis = (str, data) => {
  let newStr = str;
  const regexResults = getWordsBetweenCurlies(newStr);
  if (regexResults.length === 1) {
    const { fields } = regexResults[0];
    if (fields[0] === 'this') {
      const extractData = extractValue(fields, data);
      return extractData;
    }

    if (fields[0] === 'components') {
      const extractData = extractValue(fields, data);
      return extractData;
    }
  } else {
    regexResults.forEach(({ text, fields }) => {
      newStr = newStr.replace(text, extractValue(fields, data));
    });
  }
  return newStr;
};

export const extractQuoteWithoutThis = (parent, data = {}, key = '') => {
  let child;
  if (key === '') {
    child = parent;
  } else {
    child = parent[key];
  }

  if (_.isObject(child)) {
    if (key !== 'component') {
      for (let child_key of Object.keys(child)) {
        extractQuoteWithoutThis(child, data, child_key);
      }
    }
  } else {
    const regex = new RegExp(regexStr, 'g');
    if (regex.test(child)) {
      const unMarkString = toStringWithoutThis(child, data);
      parent[key] = unMarkString;
    }
  }
};

export const extractQuoteWithThis = (parent, data = {}, key = '') => {
  let child;
  if (key === '') {
    child = parent;
  } else {
    child = parent[key];
  }
  if (_.isObject(child)) {
    if (key !== 'component') {
      // if (!(key === 'itemComponent' && validate(child, reactElementSchema))) {
      if (!validate(parent[key], functionSchema)) {
        for (let child_key of Object.keys(child)) {
          extractQuoteWithThis(child, data, child_key);
        }
      } else {
        const functions = [];
        const listFuncs = parent[key];
        listFuncs.forEach(({ functionName, args }) => {
          switch (functionName) {
            case 'onChangeState':
              functions.push((value, index) => {
                const path = args[0];
                data.this.onChangeState(path, args[1] ?? value, index);
              });
              break;
            case 'onSaveAutomationRule':
              functions.push((value, index) => {
                const path = args[0];
                data.this.onSaveAutomationRule(path, index);
              });
              break;
            // new 19-04-2024
            case 'onSaveSettingRetail':
              functions.push((value, index) => {
                const path = args[0];
                data.this.onSaveSettingRetail(path, index);
              });
              break;
            //
            case 'onSaveSetting':
              functions.push((value, index) => {
                const path = args[0];
                data.this.onSaveSetting(path, index);
              });
              break;
            case 'onDeleteSetting':
              functions.push((value, index) => {
                const path = args[0];
                data.this.onDeleteSetting(path, index);
              });
              break;
            case 'onChangeStateWithPushSetting':
              functions.push((value, index) => {
                const path = args[0];
                data.this.onChangeStateWithPushSetting(path, args[1] ?? value, index);
              });
              break;
            case 'onChangeStateWithPushAutomation':
              functions.push((value, index) => {
                const path = args[0];
                data.this.onChangeStateWithPushAutomation(path, args[1] ?? value, index);
              });
              break;
            case 'goBack':
              functions.push((value, index) => {
                data.this.goBack();
              });
              break;
            case 'navigate':
              const screenName = args[0];
              const params = args[1];
              extractQuoteWithoutThis(params, data);
              extractQuoteWithThis(params, data);
              functions.push((value, index) => {
                Object.keys(params).forEach((_key) => {
                  if (typeof params[_key] === 'function') {
                    const func = params[_key];
                    params[_key] = (_value) => {
                      func?.(_value, index);
                    };
                  }
                });
                data.this.onNavigate(screenName, { ...params, ...value });
              });
              break;
            case 'onUpdateOta':
              functions.push((value, index) => {
                data.this.onUpdateOta(value, index);
              });
              break;
            default:
              break;
          }
        });
        parent[key] = async (value, index) => {
          for (let i = 0; i < functions.length; i++) {
            const func = functions[i];
            await func(value, index);
          }
        };
      }
      // }
    }
  } else {
    const regex = new RegExp(regexStr, 'g');

    if (regex.test(child)) {
      const unMarkString = toStringWithThis(child, data);
      parent[key] = unMarkString;
    }
  }
};
