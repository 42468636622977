import React, { Component } from 'react';
import { connect } from 'react-redux';

class JsonModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  render() {
    const { value } = this.state;
    return (
      <></>
      // <JsonComponent
      //     value={value}
      //     onSave={(value) => {
      //         this.setState({ value }, () => {
      //             this.props.save(value);
      //         });
      //     }}
      // />
    );
  }
}

export default connect((state) => {
  return {
    theme: state.theme,
    lang: state.lang,
  };
})(JsonModal);
