import React from "react";
import { Component } from "react";
import Logger from "../../../Helper/Logger";

export default class Tab extends Component {
  renderIconHide = () => {
    const { tab } = this.props;
    const { iconHide } = tab;
    return (
      <img
        src={iconHide}
        style={{
          width: "25px",
          height: "25px",
        }}
        alt="hide"
      />
    );
  };

  renderIconShow = () => {
    const { tab } = this.props;
    const { iconShow } = tab;
    return (
      <img
        src={iconShow}
        style={{
          width: "25px",
          height: "25px",
        }}
        alt="show"
      />
    );
  };

  renderIcon = () => {
    const { tab } = this.props;
    const { select } = tab;
    if (select === true) {
      return this.renderIconShow();
    }
    return this.renderIconHide();
  };

  render() {
    const { tab } = this.props;
    const { select } = tab;
    return (
      <div
        style={{
          width: "50px",
          height: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderLeftWidth: "1px",
          borderLeftColor: "white",
          borderLeftStyle: select ? "solid" : "none",
          cursor: "pointer",
        }}
        onClick={() => {
          Logger.terminalInfo("aaaaaaaaaaa", this.props.tab.onClick);
          typeof this.props.tab.onClick === "function" &&
            this.props.tab.onClick();
        }}
      >
        {this.renderIcon()}
      </div>
    );
  }
}
