import React, { Component } from 'react';
import Builder from '../../..';
// import { getConfigFromSize } from '../../../../Helper/utils';
import { getConfigFromSize, getSidebarState, getSidebarWidth } from '../../../../Helper/responsive';
import { TYPE_WINDOWS } from '../../../../Constant/Constant';
import { connect } from 'react-redux';
import { fRedux } from '../../../../Controller/fRedux';
import Logger from '../../../../Helper/Logger';

class main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: undefined,
      width: undefined,
      heightBase: undefined,
      widthBase: undefined,
      fontSizeRatio: undefined,
      col: undefined,
      row: undefined,
      typeWindow: TYPE_WINDOWS.LARGE,
      init: false,
    };
    this.ref = React.createRef();
  }

  componentDidMount() {
    const { resource } = this.props;
    const resolutionRatio = resource?.appResource?.resolutionRatio;

    const initSidebarState = getSidebarState(resource?.appResource?.width, typeof this.props.sidebar !== 'undefined');

    const width = resource?.appResource?.width - getSidebarWidth(initSidebarState, resolutionRatio);
    const height = resource?.appResource?.height;

    const config = getConfigFromSize(width, height, resolutionRatio);

    fRedux.updateObject({ path: 'main.page.pageHeight', data: config.heightBase });

    this.setState({
      widthBase: config.widthBase,
      typeWindow: config.type,
      width: width,
      height,
      heightBase: config.heightBase,
      fontSizeRatio: config.fontSizeRatio,
      col: config.col,
      row: config.row,
      init: true,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { resource } = this.props;
    const resolutionRatio = resource?.appResource?.resolutionRatio;

    if (this.props.sidebarState !== prevProps.sidebarState) {
      const { heightBase } = this.state;

      const width =
        resource?.appResource?.width - getSidebarWidth(this.props.sidebarState, resolutionRatio) - (heightBase >= this.props.pageViewHeight ? 0 : 8);
      const height = resource?.appResource?.height;

      const config = getConfigFromSize(width, height, resolutionRatio);
      this.setState({
        widthBase: config.widthBase,
        typeWindow: config.type,
        width: width,
        height,
        heightBase: config.heightBase,
        fontSizeRatio: config.fontSizeRatio,
        col: config.col,
        row: config.row,
      });
    }

    if (this.props.pageViewHeight !== prevProps.pageViewHeight) {
      const { heightBase } = this.state;
      Logger.terminalInfo('main top', this.ref.current.scrollTop);
      Logger.terminalInfo('main heightBase', heightBase);
      Logger.terminalInfo('main prevProps.pageViewHeight', prevProps.pageViewHeight);
      Logger.terminalInfo('main this.props.pageViewHeight', this.props.pageViewHeight);
      Logger.terminalInfo(
        'main heightBase < this.props.pageViewHeight && heightBase >= prevProps.pageViewHeight',
        heightBase < this.props.pageViewHeight && heightBase >= prevProps.pageViewHeight,
      );
      Logger.terminalInfo(
        'main heightBase >= this.props.pageViewHeight && heightBase < prevProps.pageViewHeight',
        heightBase >= this.props.pageViewHeight && heightBase < prevProps.pageViewHeight,
      );
      if (
        (heightBase < this.props.pageViewHeight && heightBase >= prevProps.pageViewHeight) ||
        (heightBase >= this.props.pageViewHeight && heightBase < prevProps.pageViewHeight)
      ) {
        Logger.terminalInfo(' this.props.pageViewHeight', this.props.pageViewHeight);
        Logger.terminalInfo(' heightBase', heightBase);
        const width =
          resource?.appResource?.width - getSidebarWidth(this.props.sidebarState, resolutionRatio) - (heightBase >= this.props.pageViewHeight ? 0 : 8);
        const height = resource?.appResource?.height;

        Logger.terminalInfo('width', width);
        Logger.terminalInfo('this.state.widthBase', this.state.widthBase);

        this.setState({
          widthBase: this.state.widthBase > width ? width : this.state.widthBase,
          typeWindow: this.state.typeWindow,
          width: width,
          height,
          heightBase: this.state.heightBase,
          fontSizeRatio: this.state.fontSizeRatio,
          col: this.state.col,
          row: this.state.row,
        });
      }
    }

    if (
      this.props.resource?.appResource?.width !== prevProps.resource?.appResource?.width ||
      this.props.resource?.appResource?.height !== prevProps.resource?.appResource?.height
    ) {
      const { heightBase } = this.state;
      const width =
        resource?.appResource?.width - getSidebarWidth(this.props.sidebarState, resolutionRatio) - (heightBase >= this.props.pageViewHeight ? 0 : 8);
      const height = resource?.appResource?.height;

      const config = getConfigFromSize(width, height, resolutionRatio);

      this.setState({
        widthBase: config.widthBase,
        typeWindow: config.type,
        width: width,
        height,
        heightBase: config.heightBase,
        fontSizeRatio: config.fontSizeRatio,
        col: config.col,
        row: config.row,
      });
    }
  }

  render() {
    const main = this.props.main;
    const resource = this.props.resource;
    const resolutionRatio = resource?.appResource?.resolutionRatio;
    Logger.terminalInfo('render main');
    if (this.state.init) {
      return (
        <div
          ref={this.ref}
          style={{
            // ...mainStyle,
            // width: typeof this.state.width !== 'undefined' ? this.state.width : '100%',
            // height: typeof this.state.height !== 'undefined' ? this.state.height : '100%',
            width: this.state.width,
            position: 'relative',
            justifyContent: this.state.widthBase < this.state.width ? 'center' : undefined,
            display: this.state.widthBase < this.state.width ? 'flex' : undefined,
            marginLeft: getSidebarWidth(this.props.sidebarState, resolutionRatio),
          }}>
          {Builder.build(main, {
            ...resource,
            appResource: {
              ...resource?.appResource,
              widthBase: this.state.widthBase,
              heightBase: this.state.heightBase,
              col: this.state.col,
              row: this.state.row,
              typeWindow: this.state.typeWindow,
              fontSizeRatio: this.state.fontSizeRatio,
              containerWidth: this.state.width,
              containerHeight: this.state.height,
            },
          })}
        </div>
      );
    }
    return <></>;
  }
}

export default connect((state) => {
  return {
    sidebarState: state.filiot?.sidebar?.state,
    pageViewHeight: state.filiot?.main?.page?.pageHeight,
  };
}, {})(main);
