import Select from './Select';
import UIBuilder from '../BaseComponent';
import { UI_COMPONENTS_TYPE_FIELD } from '../../../../Constant/Constant';

export default class SelectBuilder extends UIBuilder {
  constructor() {
    super();
    this.schema = {
      placeholder: UI_COMPONENTS_TYPE_FIELD.STRING,
      theme: UI_COMPONENTS_TYPE_FIELD.STRING,
      placeholder_color: UI_COMPONENTS_TYPE_FIELD.STRING,
      value: UI_COMPONENTS_TYPE_FIELD.JSON,
    };
  }

  getType = () => {
    return 'select';
  };

  getComponent = () => {
    return Select;
  };
}
