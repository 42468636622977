import Builder from "..";

export const build = (ForEachConfig, resource) => {
  const data = Builder.build(ForEachConfig.data, resource);

  if (Array.isArray(data)) {
    data.forEach((element, index) => {
      const forFunction = Builder.build(ForEachConfig.forFunction, resource);
      if (typeof forFunction === "function") {
        forFunction(element, index, data);
      }
    });
  } else {
    return [];
  }
};

export const type = "ForEachExpression";
