import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import Builder from '../../..';
import { Modal as ModalController } from '../../../../Controller/Modal';
import Logger from '../../../../Helper/Logger';

class CommonModal extends Component {
  render() {
    const modal = this.props.commonModal.modal;
    const visible = this.props.commonModal.visible;
    const style = this.props.commonModal.style;
    const width = typeof this.props.commonModal.width !== 'undefined' ? this.props.commonModal.width : '600px';
    const height = typeof this.props.commonModal.height !== 'undefined' ? this.props.commonModal.height : '800px';
    const col = typeof this.props.commonModal.col !== 'undefined' ? this.props.commonModal.col : 100;
    const row = typeof this.props.commonModal.row !== 'undefined' ? this.props.commonModal.row : 100;
    Logger.terminalInfo('colcolcolcolcol', col);
    const modalChild = Builder.build(modal, {
      widthBase: width,
      heightBase: height,
      col,
      row,
    });
    Logger.terminalInfo('widthwidthwidthwidth', width);
    return (
      <Modal
        {...this.props}
        isOpen={visible === true}
        style={{
          ...style,
          position: 'fixed',
          overlay: {
            zIndex: 9999,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'rgb(99 99 99 / 50%)',
            color: 'white',
            outline: 'none',
            textAlign: 'center',
          },
          content: { ...style?.content, width, height, margin: 'auto', transition: 'opacity 0.3s ease-in-out' },
        }}
        onRequestClose={() => {
          ModalController.hide();
          typeof this.props.onRequestClose === 'function' && this.props.onRequestClose();
        }}>
        {React.isValidElement(modalChild) ? modalChild : <></>}
      </Modal>
    );
  }
}

export default connect((state) => {
  return {
    commonModal: state.filiot.CommonModal,
    visible: state.filiot.CommonModal.visible,
  };
})(CommonModal);
