const getAlign = (textAlign) => {
    switch (textAlign) {
        case 'left':
            return 'flex-start';
        case 'center':
            return 'center';
        case 'right':
            return 'flex-end';
        default:
            return 'center';
    }
};

const textComponent = (
    data = {
        label: 'attribute 1',
        parameter: 'A1',
        value: 1,
        type: 'number',
        min: 0, // option
        max: 10, // option
        marginHorizontal: 0,
        marginVertical: 0,
        textAlign: 'left',
        height: undefined,
    },
) => {
    return {
        type: 'View',
        children: [
            {
                type: 'TouchableOpacity',
                children: [
                    {
                        type: 'Text',
                        children: data.value,
                        props: {
                            style: {
                                fontSize: 16,
                                color: '{{theme.colors.textColor}}',
                                fontFamily: '{{theme.fonts.fontBold}}',
                                lineHeight: 24,
                            },
                        },
                    },
                ],
                props: {
                    style: {
                        backgroundColor: '{{theme.colors.appColor}}',
                        flex: 1,
                        height: typeof data.height === 'number' ? data.height : undefined,
                        borderRadius: 16,
                        paddingHorizontal: 20,
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: getAlign(data.textAlign),
                        marginHorizontal: 20,
                        marginVertical: 20,
                    },
                },
            },
        ],
        props: {},
    };
};

export default textComponent;
