import BaseComponent from '../BaseComponent/BaseComponent';

class View extends BaseComponent {
  adjustStyle() {
    const { value } = this.props;
    // const fitContent = this.props.fitContent ?? true;
    const fitContent = this.props.fitContent ?? false;
    const baseStyle = this.style;
    let fontSize = this.style?.fontSize ?? 16;

    const widthContent = value?.toString().length * 0.6 * fontSize;

    return {
      ...baseStyle,
      fontSize,
      height: typeof value === 'undefined' || value === '' ? baseStyle.height : baseStyle.height < 1.5 * fontSize ? 1.5 * fontSize : baseStyle.height,
      width: fitContent && baseStyle.width < widthContent ? widthContent : baseStyle.width,
      WebkitLineClamp: 1,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    };
  }

  renderComponent(props) {
    const { value } = props;

    return (
      <div
        {...this.restProps}
        style={this.style}
        onClick={() => {
          typeof this.restProps.onClick === 'function' && this.restProps.onClick();
        }}>
        {value}
      </div>
    );
  }
}

export default View;
