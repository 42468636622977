import Logger from "../../Helper/Logger";
import fAPI from "../fAPI";
import { BASE_URL, API } from "../fAPI/constants";

const BASE_URI = BASE_URL.prod;

/**
 * @param { email: string }: email of user.
 * @param { password: string }: password of user.
 * @param { appCode: string }: appCode of app.
 * @param { type: string }: appCode of app.
 * @param { fcmToken: string }: firebase token of user.
 * @param { deviceUID: string }: deviceUID of app.
 * @param { os: string }: os of app.
 * @param { callback: json }: callback of function.
 * @param { callback.success: function }: function will call when sign in success.
 * @param { callback.fail: function }: function will call when sign in fail.
 */

async function getNotification({
  userId,
  page,
  page_size,
  type,
  appCode,
  token,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.NOTIFICATION.GET_NOTIFICATION}`;
    Logger.terminalInfo("uri", uri);
    const response = await fAPI.get(
      uri,
      {
        Authorization: token,
        "x-user": userId
      },
      {
        page,
        page_size,
        type,
        appCode
      }
    );

    typeof callback?.success === "function" && callback.success(response);
    Logger.terminalInfo('aaaaaaaaaaaaaaaaaaa', callback.success)
    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo("error", error);
    typeof callback.fail === "function" &&
      callback.fail({ status: false, error });
    return { status: false, error };
  }
}

async function seenNotification({
  userId,
  notificationId,
  token,
  callback = {
    success: () => {},
    fail: () => {},
  },
}) {
  try {
    const uri = `${BASE_URI}${API.NOTIFICATION.SEEN_NOTIFICATION(notificationId)}`;
    Logger.terminalInfo("uri", uri);
    const response = await fAPI.put(
      uri,
      {
        Authorization: token,
        "x-user": userId
      },
      {}
    );

    typeof callback?.success === "function" && callback.success(response);

    return { status: true, result: response };
  } catch (error) {
    Logger.terminalInfo("error", error);
    typeof callback.fail === "function" &&
      callback.fail({ status: false, error });
    return { status: false, error };
  }
}

export const fNotification = {
  getNotification,
  seenNotification,
};
