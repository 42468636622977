import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Text, TouchableOpacity, View } from '../../../../React-Native/types';

class ButtonModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
        };
    }

    render() {
        const { theme, lang, value } = this.props;
        const styles = { ...style, ...this.style };

        return (
            <View style={styles.layoutBtn}>
                <TouchableOpacity style={[styles.btnCancel, { borderColor: theme.colors.borderInputColor }]} onPress={this.props.closeModal}>
                    <Text style={[styles.cancel, { color: theme.colors.textColor, fontFamily: theme.fonts.fontMedium }]}>{lang.skip}</Text>
                </TouchableOpacity>
                <TouchableOpacity
                    style={[styles.btnRename, { backgroundColor: theme.colors.textColor, borderColor: theme.colors.textColor }]}
                    onPress={() => this.props.save(value)}>
                    <Text style={[styles.rename, { color: theme.colors.textColorBtn, fontFamily: theme.fonts.fontMedium }]}>{lang.confirm}</Text>
                </TouchableOpacity>
            </View>
        );
    }
}

const style = {
    rename: {
        fontSize: 16,
        lineHeight: 24,
    },
    btnRename: {
        height: 44,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 8,
        borderWidth: 1,
        paddingHorizontal: 16,
        marginHorizontal: 16,
    },
    cancel: {
        fontSize: 16,
        lineHeight: 24,
    },
    btnCancel: {
        height: 44,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 8,
        borderWidth: 1,
        marginRight: 16,
        paddingHorizontal: 16,
        marginHorizontal: 16,
    },
    layoutBtn: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 12,
    },
};

export default connect((state) => {
    return {
        theme: state.theme,
        lang: state.lang,
    };
})(ButtonModal);
