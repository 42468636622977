import React from 'react';
import { makeid } from '../utils';
const ignore_child = ['Image'];

const createElement = (type, props, children, components) => {
    // const key = props?.key || makeid(12);

    if (ignore_child.includes(type)) {
        if (components[type]) {
            // return React.createElement(components[type], { key, __components: components, ...props });
            return React.createElement(components[type], { __components: components, ...props });
        } else {
            return false;
        }
    } else {
        if (components[type]) {
            // return React.createElement(components[type], { key, __components: components, ...props }, children);
            return React.createElement(components[type], { __components: components, ...props }, children);
        } else {
            return false;
        }
    }
};

export const apend = (parent, child) => {
    const key = parent.props?.key || makeid(12);
    return React.createElement(
        parent.component.type,
        // { key, ...parent.component.props },
        { ...parent.component.props },
        parent.component.props.children ? [...parent.component.props.children, child.component] : [child.component],
    );
};

export const buildReactNode = (parent, components) => {
    // const key = parent.props?.key || makeid(12);
    try {
        if (Array.isArray(parent.children)) {
            // const component = createElement(parent.type, { key, ...parent.props }, [], components);
            const component = createElement(parent.type, { ...parent.props }, [], components);
            if (component) {
                parent.component = component;
                for (let i = 0; i < parent.children.length; i++) {
                    const child = parent.children[i];
                    // buildReactNode(child, components);
                    parent.component = apend(parent, buildReactNode(child, components));
                }
            }
        } else {
            // const component = createElement(parent.type, { key, ...parent.props }, parent.children, components);
            const component = createElement(parent.type, { ...parent.props }, parent.children, components);

            parent.component = component ? component : <></>;
        }
        return parent;
    } catch (error) {
      //Logger.terminalInfo('error', error);
    }
};
