import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from '../Modal';
import { FlatList, StyleSheet, Text, TouchableOpacity, View } from '../../React-Native/types';

class Calendar extends Component {
  constructor() {
    super();
    this.state = {
      date: 1,
      timePick: false,
      showModal: false,
      select: 1,
    };
  }

  days = [...Array(29).keys()].slice(1);

  componentDidMount() {
    const { date } = this.props;

    if (date) {
      this.setState({
        select: date,
        date,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.date) !== JSON.stringify(this.props.date)) {
      this.setState({ select: this.props.date || 1, date: this.props.date || 1 });
    }
  }

  renderModal = () => {
    const { theme, lang } = this.props;
    const { select } = this.state;
    return (
      <View style={[styles.contentModal, { backgroundColor: theme.colors.appColor }]}>
        <Text style={[styles.titleAction, { color: theme.colors.textColor, fontFamily: theme.fonts.fontBold }]} numberOfLines={1} ellipsizeMode={'tail'}>
          {lang.selectDay}
        </Text>
        <FlatList
          data={this.days}
          renderItem={({ item }) => {
            return (
              <TouchableOpacity
                style={[
                  styles.selectContainer,
                  {
                    borderBottomColor: item === select ? theme.colors.activeColor : null,
                    borderBottomWidth: item === select ? 2 : 0,
                  },
                ]}
                onPress={() => {
                  this.setState({ select: item });
                }}>
                <Text
                  style={[
                    styles.text,
                    {
                      color: item === select ? theme.colors.activeColor : theme.colors.textColor,
                      fontFamily: item === select ? theme.fonts.fontBold : theme.fonts.fontRegular,
                    },
                  ]}>
                  {item}
                </Text>
              </TouchableOpacity>
            );
          }}
          numColumns={7}
          keyExtractor={(item) => {
            return item;
          }}
          columnWrapperStyle={{ flex: 1, justifyContent: 'space-around' }}
          style={{ paddingHorizontal: 10 }}
        />
        <TouchableOpacity style={[styles.btn, { backgroundColor: theme.colors.activeColor }]} onPress={this.onSaveOption}>
          <Text style={[styles.textBtn, { color: theme.colors.textColorBtn, fontFamily: theme.fonts.fontBold }]}>{lang.save}</Text>
        </TouchableOpacity>
      </View>
    );
  };

  onSaveOption = () => {
    const { select } = this.state;
    if (this.days.includes(select)) {
      this.props.onChangeValue?.(select);
    }
    if (!isNaN(parseInt(select, 10))) {
      this.setState({ date: parseInt(select, 10) });
    }
    this.setState({ showModal: false });
  };

  onCloseModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  render() {
    const { theme } = this.props;
    const { showModal, date } = this.state;

    return (
      <View style={{ ...styles.root, ...this.props.style }}>
        <View style={[styles.content]}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <TouchableOpacity
              onPress={() => {
                this.setState({ showModal: true });
              }}
              style={[styles.valueContainer, { backgroundColor: theme.colors.appColorSecond }]}>
              <Text style={[styles.text, { color: theme.colors.textColor, fontFamily: theme.fonts.fontBold }]}>{date}</Text>
            </TouchableOpacity>
          </View>
        </View>

        <Modal visible={showModal} onCloseModal={this.onCloseModal} position={'bottom'}>
          {this.renderModal()}
        </Modal>
      </View>
    );
  }
}

export default connect((state) => {
  return {
    theme: state.theme,
    lang: state.lang,
  };
})(Calendar);

const styles = StyleSheet.create({
  selectContainer: {
    height: 30,
    width: 25,
    marginHorizontal: 15,
    marginVertical: 20,
    alignItems: 'center',
    paddingBottom: 5,
  },
  valueContainer: {
    paddingHorizontal: 10,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    height: 44,
    width: 60,
    marginHorizontal: 10,
  },
  title: {
    fontSize: 20,
    lineHeight: 28,
    maxWidth: '70%',
    textAlign: 'center',
  },
  back: {
    width: 32,
    height: 32,
  },
  btnBack: {
    position: 'absolute',
    left: -16,
    padding: 16,
  },
  rowHeader: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    width: '100%',
    paddingBottom: 16,
    paddingHorizontal: 24,
    paddingTop: 50,
  },
  titleAction: {
    fontSize: 18,
    lineHeight: 26,
    marginBottom: 20,
  },
  contentModal: {
    width: '100%',
    paddingHorizontal: 24,
    paddingVertical: 8,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: 16,
    lineHeight: 24,
  },
  textBtn: {
    fontSize: 14,
    lineHeight: 24,
  },
  btn: {
    width: '100%',
    height: 56,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 16,
    marginTop: 50,
    marginBottom: 16,
  },
});
