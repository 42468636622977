import bangtin from './Pages/bang-tin.json';
import buildings from './Pages/buildings.json';
import devicedetail from './Pages/device-detail.json';
import khuvuc from './Pages/khu-vuc.json';
import notification from './Pages/notification.json';
import settings from './Pages/settings.json';
import sodotoanha from './Pages/so-do-toa-nha.json';
import thongtinthietbi from './Pages/thong-tin-thiet-bi.json';
import user from './Pages/user.json';
import nhomthietbi from './Pages/nhom-thiet-bi.json';
import loimoi from './Pages/loi-moi.json';

const main = {
  type: 'RouteManager',
  pages: [bangtin, buildings, devicedetail, khuvuc, notification, settings, sodotoanha, thongtinthietbi, user, nhomthietbi, loimoi],
};

export default main;
