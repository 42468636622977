import Input from './Input';
import UIBuilder from '../BaseComponent';
import { UI_COMPONENTS_TYPE_FIELD } from '../../../../Constant/Constant';

export default class InputBuilder extends UIBuilder {
  constructor() {
    super();
    this.schema = {
      onChange: UI_COMPONENTS_TYPE_FIELD.JSON,
    };
  }

  getType = () => {
    return 'input';
  };

  getComponent = () => {
    return Input;
  };
}
