import React, { Component } from 'react';
import { connect } from 'react-redux';
import { StyleSheet, Text, View } from '../../React-Native/types';

class InfoItem extends Component {
    constructor(props) {
        super(props);
        this.setId('src/View/BaseComponent/Common/DynamicComponent/Components/InfoItem/index.js');
    }

    render() {
        const { data, theme } = this.props;
        const { item } = data;
        const styles = { ...style, ...this.style };
        return (
            <View style={[styles.row, { borderBottomWidth: 1, borderBottomColor: theme.colors.borderColor }]}>
                <Text
                    style={[styles.typeInfor, { color: theme.colors.textInfor, fontFamily: theme.fonts.fontMedium }]}
                    ellipsizeMode={'tail'}
                    numberOfLines={1}>
                    {item?.key}
                </Text>
                <Text style={[styles.infor, { color: theme.colors.textColor, fontFamily: theme.fonts.fontMedium }]} numberOfLines={1} ellipsizeMode={'tail'}>
                    {item?.value}
                </Text>
            </View>
        );
    }
}

const style = StyleSheet.create({
    infor: {
        fontSize: 16,
        lineHeight: 24,
        flex: 1,
        textAlign: 'right',
        paddingLeft: 10,
    },
    typeInfor: {
        fontSize: 16,
        lineHeight: 24,
        flex: 1,
    },
    row: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 11,
    },
});

export default connect((state) => {
    return {
        lang: state.lang,
        theme: state.theme,
    };
})(InfoItem);
