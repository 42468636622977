import Builder from '..';
import * as ReturnStatement from '../ReturnStatement';
import * as Return from '../Return';
import * as VariableDeclaration from '../VariableDeclaration';
import * as Define from '../Define';

export const build = (ExeBlockConfig, resource) => {
  const execute = ExeBlockConfig.execute;
  if (execute && Array.isArray(execute)) {
    if (typeof resource === 'undefined') {
      resource = {};
    }

    const newResource = {
      ...resource,
    };

    for (let exe of execute) {
      if (exe.type === ReturnStatement.type || exe.type === Return.type) {
        return Builder.build(exe, newResource);
      } else if (exe.type === VariableDeclaration.type || exe.type === Define.type) {
        Builder.build(exe, newResource);
      } else {
        Builder.build(exe, newResource);
      }
    }
  }
};

export const type = 'ExeBlock';
