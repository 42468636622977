import BaseComponent from '../BaseComponent/BaseComponent';

import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

export const options = {
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Chart.js Bar Chart',
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export default class PieChart extends BaseComponent {
  renderComponent(x, y, width, height) {
    const { options } = this.props;
    if (this.props.data) {
      const _datasets = this.props.data.datasets;
      const labels = this.props.data.labels;
      const datasets = Array.isArray(_datasets)
        ? _datasets.map((dataset) => {
            return {
              label: dataset.label ?? 'Label',
              data: Array.isArray(dataset.data) ? dataset.data : [],
              backgroundColor: dataset.backgroundColor ?? 'rgba(53, 162, 235, 0.5)',
            };
          })
        : [];
      return (
        <Pie
          options={{
            ...options,
            maintainAspectRatio: false,
            // responsive: false,
          }}
          data={{ labels, datasets }}
          style={{ width: '100%', height: '100%' }}
        />
      );
    }
  }
}
