import Builder from '..';

export const build = (SpreadObjectConfig, resource) => {
  const object = Builder.traverseBuild(SpreadObjectConfig.object, resource);
  const newKeyValues = Builder.traverseBuild(SpreadObjectConfig.newKeyValues, resource);
  if (Array.isArray(newKeyValues)) {
    const newObject = { ...object };
    newKeyValues.forEach((keyValue) => {
      const key = keyValue.key;
      const value = keyValue.value;
      if (key !== undefined) {
        newObject[key] = value;
      }
    });
    return newObject;
  }
  return object;
};

export const type = 'SpreadObject';
