import Logger from '../../Helper/Logger';
import { fRedux } from '../fRedux';

const display = (data = { modal: {}, width: '200px', height: '300px', style: {} }) => {
  Logger.terminalInfo('displaydisplaydisplaydisplay');
  fRedux.updateObject({
    path: 'CommonModal',
    data: {
      visible: true,
      modal: data.modal,
      width: data.width,
      height: data.height,
      style: data.style,
      col: data.col,
      row: data.row,
      ...data,
    },
  });
};

/**
 * Hide firebase notification.
 * @param()
 */
const hide = () => {
  fRedux.updateObject({
    path: 'CommonModal.visible',
    data: false,
  });
};

export const Modal = {
  display,
  hide,
};
