import Builder from '..';

export const build = (ReturnStatementConfig, resource) => {
  try {
    const result = Builder.traverseBuild(ReturnStatementConfig.value, resource);
    return result;
  } catch (error) {
    return undefined;
  }
};

export const type = 'ReturnStatement';
