import Logger from "./Logger";

export const addUIViewOfPageInProject = (UIView, uri, typeView, project) => {
  Logger.terminalInfo('onAddUIViewOfPageInProject', UIView, uri, typeView);

  const authRoutes = project?.auth?.main?.pages || [];
  const containerRoutes = project?.container?.main?.pages || [];
  return {
    ...project,
    auth: {
      ...project.auth,
      main: {
        ...project.auth.main,
        pages: authRoutes.map((authRoute) => {
          if (authRoute.uri === uri) {
            return {
              ...authRoute,
              [typeView]: { ...authRoute[typeView], page: [...authRoute[typeView].page, UIView] },
            };
          }
          return authRoute;
        }),
      },
    },
    container: {
      ...project.container,
      main: {
        ...project.container.main,
        pages: containerRoutes.map((containerRoute) => {
          if (containerRoute.uri === uri) {
            return {
              ...containerRoute,
              [typeView]: { ...containerRoute[typeView], page: [...containerRoute[typeView].page, UIView] },
            };
          }
          return containerRoute;
        }),
      },
    },
  };
};

export const changeUIViewOfPageInProject = (UIView, uri, typeView, project) => {
  Logger.terminalInfo('onAddUIViewOfPageInProject', UIView, uri, typeView);

  const authRoutes = project?.auth?.main?.pages || [];
  const containerRoutes = project?.container?.main?.pages || [];
  return {
    ...project,
    auth: {
      ...project.auth,
      main: {
        ...project.auth.main,
        pages: authRoutes.map((authRoute) => {
          if (authRoute.uri === uri) {
            return {
              ...authRoute,
              [typeView]: {
                ...authRoute[typeView],
                page: [...authRoute[typeView].page].map((_UIView) => {
                  if (_UIView.key === UIView.key) {
                    return { ..._UIView, ...UIView };
                  }
                  return _UIView;
                }),
              },
            };
          }
          return authRoute;
        }),
      },
    },
    container: {
      ...project.container,
      main: {
        ...project.container.main,
        pages: containerRoutes.map((containerRoute) => {
          if (containerRoute.uri === uri) {
            return {
              ...containerRoute,
              [typeView]: {
                ...containerRoute[typeView],
                page: [...containerRoute[typeView].page].map((_UIView) => {
                  if (_UIView.key === UIView.key) {
                    return { ..._UIView, ...UIView };
                  }
                  return _UIView;
                }),
              },
            };
          }
          return containerRoute;
        }),
      },
    },
  };
};

export const deleteUIViewOfPageInProject = (UIView, uri, typeView, project) => {
  Logger.terminalInfo('deleteUIViewOfPageInProject', UIView, uri, typeView);

  const authRoutes = project?.auth?.main?.pages || [];
  const containerRoutes = project?.container?.main?.pages || [];

  return {
    ...project,
    auth: {
      ...project.auth,
      main: {
        ...project.auth.main,
        pages: authRoutes.map((authRoute) => {
          if (authRoute.uri === uri) {
            return {
              ...authRoute,
              [typeView]: {
                ...authRoute[typeView],
                page: authRoute[typeView].page.filter((_page) => {
                  return UIView.key !== _page.key;
                }),
              },
            };
          }
          return authRoute;
        }),
      },
    },
    container: {
      ...project.container,
      main: {
        ...project.container.main,
        pages: containerRoutes.map((containerRoute) => {
          if (containerRoute.uri === uri) {
            return {
              ...containerRoute,
              [typeView]: {
                ...containerRoute[typeView],
                page: containerRoute[typeView].page.filter((_page) => {
                  return UIView.key !== _page.key;
                }),
              },
            };
          }
          return containerRoute;
        }),
      },
    },
  };
};

export const changeViewRouteInProject = (view, uri, typeView, project) => {
  Logger.terminalInfo('onViewRouteInProject', view, uri, typeView);
  const authRoutes = project?.auth?.main?.pages || [];
  const containerRoutes = project?.container?.main?.pages || [];
  return {
    ...project,
    auth: {
      ...project.auth,
      main: {
        ...project.auth.main,
        pages: authRoutes.map((authRoute) => {
          if (authRoute.uri === uri) {
            return {
              ...authRoute,
              [typeView]: { ...authRoute[typeView], ...view },
            };
          }
          return authRoute;
        }),
      },
    },
    container: {
      ...project.container,
      main: {
        ...project.container.main,
        pages: containerRoutes.map((containerRoute) => {
          if (containerRoute.uri === uri) {
            return {
              ...containerRoute,
              [typeView]: { ...containerRoute[typeView], ...view },
            };
          }
          return containerRoute;
        }),
      },
    },
  };
};

export const changePageInProject = (page, uri, typeView, project) => {
  Logger.terminalInfo('onChangePageInProject', page, uri, typeView);
  const authRoutes = project?.auth?.main?.pages || [];
  const containerRoutes = project?.container?.main?.pages || [];

  return {
    ...project,
    auth: {
      ...project.auth,
      main: {
        ...project.auth.main,
        pages: authRoutes.map((authRoute) => {
          if (authRoute.uri === uri) {
            return {
              ...authRoute,
              [typeView]: { ...authRoute[typeView], page },
            };
          }
          return authRoute;
        }),
      },
    },
    container: {
      ...project.container,
      main: {
        ...project.container.main,
        pages: containerRoutes.map((containerRoute) => {
          if (containerRoute.uri === uri) {
            return {
              ...containerRoute,
              [typeView]: { ...containerRoute[typeView], page },
            };
          }
          return containerRoute;
        }),
      },
    },
  };
};
