import React, { Component } from 'react';
import Builder from '../../..';
import { fRedux } from '../../../../Controller/fRedux';
import Logger from '../../../../Helper/Logger';
export default class view extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cpnSelectedKey: null,
    };
    this.ref = React.createRef();

    this._children = [];
    this.pageViewHeight = 0;
    this.childrens = {};
    this.maxChild = {};
    this.count = 0;
  }

  componentDidMount() {
    Logger.terminalInfo('componentDidMountcomponentDidMount view page');
    const { pageResource, preprocessing, height } = this.props;
    const preprocessFunction = Builder.build(preprocessing, pageResource);

    if (typeof preprocessFunction === 'function') {
      preprocessFunction();
    }
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevProps.pageResource?.appResource?.path !== this.props.pageResource?.appResource?.path) {
  //     const { pageResource, preprocessing, height } = this.props;
  //     const preprocessFunction = Builder.build(preprocessing, pageResource);

  //     Logger.terminalInfo('Change URL: ', JSON.stringify(this.props.pageResource?.appResource));
  //     Logger.terminalInfo('Change URL: ', JSON.stringify(this.props.page));

  //     if (typeof preprocessFunction === 'function') {
  //       preprocessFunction();
  //     }
  //   }
  // }

  shouldComponentUpdate(nextProps, nextState) {
    this.childrens = {};
    if (nextProps.pageResource?.appResource?.path !== this.props.pageResource?.appResource?.path) {
      const { pageResource, preprocessing, height } = nextProps;
      const preprocessFunction = Builder.build(preprocessing, pageResource);

      Logger.terminalInfo('Change URL: ', JSON.stringify(nextProps.pageResource?.appResource));
      Logger.terminalInfo('Change URL: ', JSON.stringify(nextProps.page));

      if (typeof preprocessFunction === 'function') {
        preprocessFunction();
      }
      this._children.length = 0;
    }
    return true;
  }

  onUpdateViewComponentRef = (FromTop, id, blockcompiledcomponent, infoFromTop) => {
    const { pageResource } = this.props;
    const { heightBase } = pageResource?.appResource;
    this.childrens = { ...this.childrens, [id]: { FromTop, id } };
    if (this.maxChild.id === id) {
      if (this.maxChild.FromTop > FromTop) {
        this.maxChild = { id, FromTop };
        Object.keys(this.childrens).forEach((key) => {
          const child = this.childrens[key];
          if (child.FromTop > this.maxChild.FromTop) {
            this.maxChild = { id: child.id, FromTop: child.FromTop };
          }
        });
      } else {
        this.maxChild = { id, FromTop };
      }
    } else {
      if (this.maxChild.FromTop < FromTop || typeof this.maxChild.id === 'undefined') {
        this.maxChild = { id, FromTop };
      }
    }

    const newPageViewHeight = this.maxChild.FromTop;

    if ((heightBase < newPageViewHeight && heightBase >= this.pageViewHeight) || (heightBase >= newPageViewHeight && heightBase < this.pageViewHeight)) {
      this.pageViewHeight = newPageViewHeight;
      // this.count++;
      // this.count < 10 &&
      fRedux.updateObject({ path: 'main.page.pageHeight', data: newPageViewHeight });
    }
  };

  onChangePageViewConfig = (cpn) => {
    const { page } = this.props;

    this.props.onChangePageViewConfig?.(
      page.map((_page) => {
        Logger.terminalInfo('_page', _page);
        Logger.terminalInfo('cpn', cpn);
        if (_page.key === cpn.key) {
          const newCpn = { ..._page, ...cpn };
          this.props.onSetSelectedView(newCpn);

          return newCpn;
        }
        return _page;
      }),
    );
  };

  onSelectCpnInVisualMode = (view) => {
    const { visualPreviewMode } = this.props.pageResource?.appResource;
    if (visualPreviewMode) {
      this.props.onSetSelectedView(view);
      // this.setState({ cpnSelectedKey: view.key });
    }
  };

  render() {
    const { page, pageResource, width, height, pageStyle } = this.props;
    Logger.terminalInfo('pageResource:::', page, pageResource, width, height);
    if (Array.isArray(page)) {
      this._children.length = 0;
      page.forEach((cpn, index) => {
        if (typeof cpn.zIndex === 'undefined') {
          cpn.zIndex = index;
        }
        const FiliotJSXElement = Builder.build(cpn, {
          ...pageResource,
          appResource: {
            ...pageResource.appResource,
            onChangePageViewConfig: this.onChangePageViewConfig,
            setContextMenu: this.props.setContextMenu,
            onSelectCpnInVisualMode: this.onSelectCpnInVisualMode,
            // selectCpnKeyInVisualMode: this.state.cpnSelectedKey,
            onSetSelectedView: this.props.onSetSelectedView,
            onUpdateViewComponentRef: this.onUpdateViewComponentRef,
          },
        });
        if (React.isValidElement(FiliotJSXElement)) {
          this._children.push({ jsx: FiliotJSXElement, cpn });
        }
      });
    }

    return (
      <div
        style={{
          width: (pageResource?.appResource?.widthBase * width) / pageResource?.appResource?.col,
          height: (pageResource?.appResource?.heightBase * height) / pageResource?.appResource?.row,
          // height: this.props.viewHeight,
          // width: this.props.viewWidth,
          position: 'relative',
          // margin: 'auto',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          ...pageStyle,
        }}
        ref={this.ref}>
        {this._children.map((child) => child.jsx)}
      </div>
    );
  }
}
