import React from 'react';
import BaseComponent from '../BaseComponent/BaseComponent';
import Logger from '../../../../Helper/Logger';

export default class Condition extends BaseComponent {
  renderConsequent = () => {
    const { consequent } = this.props;
    Logger.terminalInfo('consequent', consequent);
    if (React.isValidElement(consequent)) {
      return consequent;
    } else {
      return <></>;
    }
  };

  renderAlternate = () => {
    const { alternate } = this.props;
    Logger.terminalInfo('alternate', alternate);
    if (React.isValidElement(alternate)) {
      return alternate;
    } else {
      return <></>;
    }
  };

  renderResult = () => {
    const { test } = this.props;
    Logger.terminalInfo('test', test);
    if (test === 'unknown') {
      return <div>Test attribute is invalid</div>;
    }
    if (test && typeof test === 'boolean') {
      return this.renderConsequent();
    } else {
      return this.renderAlternate();
    }
  };

  renderComponent(x, y, width, height) {

    return (
      <div
        {...this.restProps}
        style={this.style}
        onClick={this.props.onClick}>
        {this.renderResult()}
      </div>
    );
  }
}
