import BaseComponent from '../BaseComponent/BaseComponent';
import './index.css';

export default class TextInput extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: props.value || '',
      showPassword: false,
    };
  }

  handleValue = (e) => {
    const newValue = e.target.value;
    this.setState({ inputValue: newValue });
    const type = this.props.inputType ?? 'text';
    if (typeof this.props.onChangeText === 'function') {
      switch (type) {
        case 'number':
          if (e.target.value === '') {
            this.props.onChangeText?.('');
          } else if (e.target.value === '' || !isNaN(parseFloat(e.target.value))) {
            this.props.onChangeText?.(parseFloat(e.target.value));
          }
          break;

        default:
          this.props.onChangeText?.(e.target.value);
          break;
      }
    }
  };

  adjustStyle() {
    const { value, x, y, width, height } = this.props;
    const baseStyle = this.style;
    let fontSize = this.style.fontSize;
    // const fitContent = this.props.fitContent ?? true;
    const fitContent = this.props.fitContent ?? false;

    const widthContent = value?.toString().length * 0.6 * fontSize;

    return {
      ...baseStyle,
      fontSize,
      height: baseStyle.height < 1.5 * fontSize ? 1.5 * fontSize : baseStyle.height,
      width: fitContent && baseStyle.width < widthContent ? widthContent : baseStyle.width,
    };
  }

  handleShowHidePassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  typeInput = () => {
    if (this.props.inputType !== 'password' || this.props.inputType === undefined) {
      return this.props.inputType;
    }
    if (this.props.inputType === 'password') {
      return this.state.showPassword ? 'text' : 'password';
    }

    return 'text';
  };

  renderComponent(x, y, width, height) {
    const styles = {
      layout: {
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },

      icon: {
        width: 20,
        height: 20,
        right: 10,
        position: 'absolute',
        cursor: 'pointer',
      },
    };

    return (
      <div style={styles.layout}>
        <input
          {...this.restProps}
          style={this.style}
          type={this.typeInput()}
          value={this.state.inputValue !== null ? this.state.inputValue : undefined}
          placeholder={this.props.placeholder}
          onChange={this.handleValue}
        />
        {this.props.inputType === 'password' && (
          <img
            src={
              this.state.showPassword
                ? this.props.showHideIcon?.showIcon ?? 'https://image-app-public.s3.ap-southeast-1.amazonaws.com/giangha/my-icons/show.png'
                : this.props.showHideIcon?.hideIcon ?? 'https://image-app-public.s3.ap-southeast-1.amazonaws.com/giangha/my-icons/hide.png'
            }
            alt={this.state.showPassword ? 'show' : 'hide'}
            style={{ ...styles.icon, ...this.props.showHideIcon?.iconStyle }}
            onClick={this.handleShowHidePassword}
          />
        )}
      </div>
    );
  }
}
