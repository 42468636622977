import Timer from '../Attributes/timer.json';
import UpdateFirmware from '../Attributes/update_firmware.json';
import Wifi from '../Attributes/Wifi/wifi_old.json';
import TimerHCL from '../Attributes/time_hcl.json';
import externalData from './externalData';

const config = {
    externalData,
    attributes: [Wifi, Timer, TimerHCL, UpdateFirmware],
    version: 'v0.0.1',
};

export default config;
