import { Editor } from '@monaco-editor/react';
import React, { Component } from 'react';
import { isJson } from '../../Helper/utils';
import { v4 as uuidv4 } from 'uuid';
import * as prettier from 'prettier/standalone';
import * as parserBabel from 'prettier/parser-babel';
import ast from 'prettier/plugins/estree';
import { TYPE_WINDOWS } from '../../Constant/Constant';
import Logger from '../../Helper/Logger';

export default class EditArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: uuidv4(),
      value: '{}',
    };
    this.editor = null;
    this.monaco = null;
    this.line = 0;
  }

  async componentDidMount() {
    const value = {
      ...this.props.currentFile?.value,
      [TYPE_WINDOWS.LARGE.name]: this.props.currentFile?.value?.[TYPE_WINDOWS.LARGE.name],
      [TYPE_WINDOWS.MEDIUM.name]: this.props.currentFile?.value?.[TYPE_WINDOWS.MEDIUM.name],
      [TYPE_WINDOWS.SMALL.name]: this.props.currentFile?.value?.[TYPE_WINDOWS.SMALL.name],
    };


    const formattedValue = await this.formatCode(JSON.stringify(value, null, 0));
    this.setState({
      value: formattedValue,
      key: uuidv4(),
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentFile?.path !== this.props.currentFile?.path) {
      const value = {
        ...this.props.currentFile?.value,
        [TYPE_WINDOWS.LARGE.name]: this.props.currentFile?.value?.[TYPE_WINDOWS.LARGE.name],
        [TYPE_WINDOWS.MEDIUM.name]: this.props.currentFile?.value?.[TYPE_WINDOWS.MEDIUM.name],
        [TYPE_WINDOWS.SMALL.name]: this.props.currentFile?.value?.[TYPE_WINDOWS.SMALL.name],
      };
      const formattedValue = await this.formatCode(JSON.stringify(value, null, 0));
      this.setState({ key: uuidv4(), value: formattedValue });
    }

    if (JSON.stringify(prevProps.currentFile?.value) !== JSON.stringify(this.props.currentFile?.value)) {
      if (this.props.currentFile?.reFormat === true) {
        const value = {
          ...this.props.currentFile?.value,
          [TYPE_WINDOWS.LARGE.name]: this.props.currentFile?.value?.[TYPE_WINDOWS.LARGE.name],
          [TYPE_WINDOWS.MEDIUM.name]: this.props.currentFile?.value?.[TYPE_WINDOWS.MEDIUM.name],
          [TYPE_WINDOWS.SMALL.name]: this.props.currentFile?.value?.[TYPE_WINDOWS.SMALL.name],
        };

        const formattedValue = await this.formatCode(JSON.stringify(value, null, 0));
        this.setState({
          value: formattedValue,
        });
      } else {
        // this.setState({
        //   value: JSON.stringify(this.props.currentFile?.value),
        // });
      }
    }
  }

  formatCode = async (code) => {
    try {
      Logger.terminalInfo('codecode', code);
      const formattedCode = await prettier?.format?.(code, {
        semi: false,
        parser: 'json',
        plugins: [parserBabel, ast],
      });

      return formattedCode;
    } catch (error) {
      console.error('Error formatting code:', error);
      // Optionally, notify the user about the error
    }
  };

  moveToLine = (lineNumber) => {
    if (!this.monaco) return; // Check if Monaco is initialized
    const editor = this.editor;
    if (!editor) return;

    const model = editor.getModel();
    if (!model) return;

    const position = { lineNumber: lineNumber, column: 1 };
    editor.setPosition(position);
    editor.revealLineInCenterIfOutsideViewport(lineNumber);
  };

  handleEditorDidMount = (editor, monaco) => {
    this.editor = editor;
    this.monaco = monaco;
  };

  render() {
    const currentFile = this.props.currentFile || {};
    const { onChangeValue } = this.props;
    return (
      <Editor
        key={this.state.key}
        height='calc(100vh - 65px)'
        theme='vs-dark'
        path={currentFile.name}
        onChange={(value, event) => isJson(value) && onChangeValue?.(JSON.parse(value), currentFile)}
        defaultLanguage={currentFile?.language || 'json'}
        defaultValue={'{}'}
        onMount={this.handleEditorDidMount}
        value={this.state.value}
        line={this.line}
      />
    );
  }
}
