import _ from 'lodash';
import Builder from '../../..';
import { validateTextMarkdown } from '../../../TextMarkdown/validate';
import * as TextMarkdown from '../../../TextMarkdown';
import * as FiliotComponent from '../../../FiliotComponent';

export const getMarkdownUI = (UIConfig) => {
  if (_.isObject(UIConfig)) {
    const markdownUI = {};
    Object.keys(UIConfig).forEach((propertyName) => {
      const propertyValue = UIConfig[propertyName];
      if (
        !(
          (_.isObject(propertyValue) && typeof propertyValue.type === 'string' && Builder.builders[propertyValue.type]?.isUI) ||
          FiliotComponent.type === propertyValue.type
        ) ||
        typeof propertyValue === 'string'
      ) {
        const markdownsProperty = getMarkdownProperty(propertyValue);
        markdownsProperty.forEach((markdownProperty) => {
          if (typeof markdownUI[markdownProperty] === 'undefined') {
            markdownUI[markdownProperty] = [propertyName];
          } else {
            if (!markdownUI[markdownProperty].includes(propertyName)) {
              markdownUI[markdownProperty] = [...markdownUI[markdownProperty], propertyName];
            }
          }
        });
      }
    });

    return markdownUI;
  }
  return [];
};

const getMarkdownProperty = (UIPropertyConfig) => {
  const markdownProperty = [];

  const traveseProperty = (UIPropertyConfig, currentPath = '') => {
    if (_.isObject(UIPropertyConfig)) {
      for (let key in UIPropertyConfig) {
        if (typeof UIPropertyConfig[key] === 'string' && validateTextMarkdown(UIPropertyConfig[key])) {
          markdownProperty.push(UIPropertyConfig[key]);
        } else if (typeof UIPropertyConfig[key] === 'object') {
          if (UIPropertyConfig[key]?.type === TextMarkdown.type) {
            markdownProperty.push(UIPropertyConfig[key]?.value);
          }
          // else if (typeof UIPropertyConfig[key]?.type === 'string' && Builder.builders[UIPropertyConfig[key]?.type]?.isUI) {
          // }
          else {
            traveseProperty(UIPropertyConfig[key], currentPath + key + '.');
          }
        }
      }
    } else if (typeof UIPropertyConfig === 'string' && validateTextMarkdown(UIPropertyConfig)) {
      markdownProperty.push(UIPropertyConfig);
    }
  };

  traveseProperty(UIPropertyConfig, '');

  return markdownProperty;
};

export const jsonToCss = (styles) => {
  let cssString = '';
  for (const key in styles) {
    if (key.slice(0, 2) === '&:') {
      const pseudoClass = key.replace('&:', '');
      const pseudoClassStyles = styles[key];
      cssString += `${pseudoClass} { ${jsonToCss(pseudoClassStyles)} }`;
    } else {
      const styleValue = typeof styles[key] === 'object' ? jsonToCss(styles[key]) : styles[key];
      cssString += `${key.replace(/[A-Z]/g, (match) => `-${match.toLowerCase()}`)}: ${styleValue};`;
    }
  }

  return cssString;
};

export function getString(n) {
  let str = '';
  const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const charLen = characters.length;

  for (let i = 0; i < n; i++) {
    // Generating a random index
    const idx = Math.floor(Math.random() * charLen);

    str += characters.charAt(idx);
  }

  return str;
}

export const regexTextMarkdown = '{{([^}]+)}}';

export const getWordsBetweenCurlies = (str) => {
  const regex = new RegExp(regexTextMarkdown, 'g');
  let element;
  const results = [];
  while ((element = regex.exec(str))) {
    results.push({ text: element[0], fields: element[1].split('.') });
  }
  return results;
};

export const blockStyleChecked = (rawcomponent) => [
  'x',
  'y',
  'width',
  'height',
  'blockStyle',
  typeof rawcomponent.blockStyle === 'undefined' ? 'style' : '',
  'zIndex',
  'key',
];
